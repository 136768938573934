import { Typography } from 'antd';

import PageInfoList from './components/PageInfoList';

const Song = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Thông Tin Và Liên Hệ</Typography.Title>

      <PageInfoList />
    </div>
  );
};

export default Song;
