import { useQuery } from '@apollo/client';
import { Button, Space } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import { ORDER_BY_DICTIONARY, PAGE_SIZE, TABLE_PROPS } from 'appConstants';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import { GET_USERS } from 'operations/queries/queryUser';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { processQueryFilerParams } from 'utils/filter';
import { columns } from '../columns';

const UserList = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [users, setUsers] = useState([]);
  const [total, setTotal] = useState([]);

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const pageSize = useMemo(
    () => parseInt(searchParams.pageSize) || PAGE_SIZE,
    [searchParams.pageSize]
  );
  const filterQuery = useMemo(
    () => processQueryFilerParams(searchParams),
    [searchParams]
  );

  const {
    loading: isUsersLoading,
    error: usersError,
    data: usersConnect
  } = useQuery(GET_USERS, {
    variables: {
      take: pageSize,
      skip: pageSize * page - pageSize,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!isUsersLoading && usersConnect) {
      const _data = usersConnect?.users?.items?.map(item => ({
        ...item,
        key: item.id
      }));
      setUsers(_data);
      setTotal(usersConnect?.users?.totalCount);
    }
  }, [usersConnect, isUsersLoading]);

  const onPaginate = (page, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, page, pageSize })
    });
  };

  const setOrderBy = sort => {
    history.push({
      pathname: '/danh-sach-user',
      search: queryString.stringify({
        ...searchParams,
        page: 1,
        sort,
        pageSize
      })
    });
  };

  const onShowSizeChange = (_, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, pageSize })
    });
  };

  if (usersError) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          <SortSelect value={sort} onChange={setOrderBy} />
          <Button
            onClick={() => visibleDrawerVar({ visible: true })}
            type="primary"
          >
            Thêm
          </Button>
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1500 }}
        columns={columns}
        dataSource={users}
        pagination={{
          total,
          pageSize: pageSize,
          onChange: onPaginate,
          current: page * 1,
          quantity: users?.length,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          onShowSizeChange: onShowSizeChange
        }}
        loading={isUsersLoading}
      />
    </ListCard>
  );
};

export default UserList;
