import { Select } from 'antd';
import { SONG_TYPE_DICTIONARY } from 'appConstants';
import React from 'react';
const { Option } = Select;

const SongTypeSelect = props => {
  return (
    <Select
      allowClear
      placeholder="Chọn loại nhạc"
      style={{ width: '100%' }}
      {...props}
    >
      {Object.entries(SONG_TYPE_DICTIONARY).map(([key, value]) => (
        <Option key={key} value={key}>
          {value}
        </Option>
      ))}
    </Select>
  );
};

export default SongTypeSelect;
