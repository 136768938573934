export const initParams = album => {
  const params = {
    id: album.id,
    slug: album.slug,
    title: album.title,
    publicDate: album.publicDate
    // cateIds: album.categories.map(category => category?.id),
    // artistIds: album.artists.map(artist => artist?.id),
    // songIds: album.songs.map(song => song?.id)
  };
  return params;
};
