import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const ADD_ADV = gql`
  mutation AddIcAdvert($input: AddIcAdvertInput) {
    addIcAdvert(input: $input) {
      id
      advertName
    }
  }
`;

export const EDIT_ADV = gql`
  mutation AditIcAdvert($input: EditIcAdvertInput) {
    editIcAdvert(input: $input) {
      id
      advertName
    }
  }
`;

export const REMOVE_ADV = gql`
  mutation RemoveIcAdvert($id: Int!) {
    removeIcAdv(id: $id) {
      id
      advertName
    }
  }
`;

export const BULK_ACTION_ADVS = gql`
  mutation BulkActionPageInfoContacts(
    $input: [BulkActionPageInfoContactInput]
  ) {
    bulkActionPageInfoContacts(input: $input) {
      id
      advertName
    }
  }
`;

// ----------------------------------------------------
// Hook

export const useAddIcAdvert = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_ADV, {
    onCompleted: ({ addIcAdvert }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo quảng cáo{' '}
            <Typography.Text strong>{addIcAdvert.advertName}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useEditIcAdvert = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_ADV, {
    onCompleted: ({ editIcAdvert }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa quảng{' '}
            <Typography.Text strong>{editIcAdvert.advertName}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetIcAdvertById']
  });
  return { mutate, data, error, loading };
};

// export const useBulkActionPageInfoContacts = actionField => {
//   const [mutate, { data, error, loading }] = useMutation(
//     BULK_ACTION_ADVS,
//     {
//       refetchQueries: ['GetPageInfoContacts'],
//       onCompleted: ({ bulkActionPageInfoContacts }) => {
//         getBulkMessage(
//           'Thông tin trang',
//           bulkActionPageInfoContacts,
//           actionField
//         );
//       }
//     }
//   );

//   return { mutate, data, error, loading };
// };

// --------------------------------------------

export const useRemoveIcAdvert = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_ADV, {
    refetchQueries: ['GetIcAdverts'],
    onCompleted: ({ removeIcAdv }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá quảng cáo{' '}
            <Typography.Text strong>
              {removeIcAdv.advertName}
            </Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};
