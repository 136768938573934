import { gql } from '@apollo/client';

export const GET_PERMISSIONS = gql`
  query GetPermissions(
    $skip: Int
    $take: Int
    $order: [PermissionSortInput!]
    $where: PermissionFilterInput
  ) {
    permissions(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        title
        code
        description
        groupCode
        groupName
      }
    }
  }
`;

export const GET_PERMISSION_BY_ID = gql`
  query getPermissionById($id: String) {
    permission(id: $id) {
      id
      title
      code
      description
      isRead
      isCreate
      isUpdate
      isDelete
      createdBy
      createdDate
      updatedBy
      updatedDate
    }
  }
`;
