import { InMemoryCache, makeVar } from '@apollo/client';
import { isLoggedIn } from 'utils/authority';
import moment from 'moment';
import { CHANNEL_BROADCAST_KEY } from 'appConstants';

export const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        visibleDrawer: {
          read() {
            return visibleDrawerVar();
          }
        },
        selectedRows: {
          read() {
            return selectedRowsVar();
          }
        },
        isLoggedIn: {
          read() {
            return isLoggedInVar();
          }
        },
        currentRole: {
          read() {
            return currentRoleVar();
          }
        },
        scheduleFilter: {
          read() {
            return scheduleFilterVar();
          }
        },
        currentTimeAudio: {
          read() {
            return currentTimeAudioVar();
          }
        },
        timeLine: {
          read() {
            return timeLineVar();
          }
        },
        currentPermissions: {
          read() {
            return currentPermissionsVar();
          }
        },
        searchSong: {
          read() {
            return searchSongVar();
          }
        },
        searchVideo: {
          read() {
            return searchVideoVar();
          }
        },
        searchArtist: {
          read() {
            return searchArtistVar();
          }
        },
        searchAlbum: {
          read() {
            return searchAlbumVar();
          }
        },
        searchPlaylistVar: {
          read() {
            return searchPlaylistVar();
          }
        },
        searchPopulartVar: {
          read() {
            return searchPopulartVar();
          }
        },
        searchBannerVar: {
          read() {
            return searchBannerVar();
          }
        }
      }
    }
  }
});

export const visibleDrawerVar = makeVar({ visible: false, record: null });
export const selectedRowsVar = makeVar([]);
export const isLoggedInVar = makeVar(isLoggedIn());
export const currentRoleVar = makeVar(null);
export const scheduleFilterVar = makeVar({
  channel: CHANNEL_BROADCAST_KEY.FM999MHZ,
  broadcastDate: moment()
});
export const currentTimeAudioVar = makeVar(null);
export const timeLineVar = makeVar([]);
export const currentPermissionsVar = makeVar([]);
export const searchSongVar = makeVar([]);
export const searchVideoVar = makeVar([]);
export const searchArtistVar = makeVar([]);
export const searchAlbumVar = makeVar([]);
export const searchPlaylistVar = makeVar([]);
export const searchPopulartVar = makeVar([]);
export const searchBannerVar = makeVar([]);
