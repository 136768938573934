import { Typography } from 'antd';

import AuditLogList from './components/AuditLogList';

const AuditLog = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách Audit Log</Typography.Title>

      {/* <AuditLogFilter /> */}

      <AuditLogList />
    </div>
  );
};

export default AuditLog;
