import { Space, Typography } from 'antd';

import RelatedUser from 'components/RelatedUser';
import RemoveButton from 'components/RemoveButton';

import TimeLine from 'components/TimeLine';

import 'moment/locale/vi';
import { useRemovePlaylist } from 'operations/mutations/playlist/mutatePlaylist';

import {
  DATE_FORMAT,
  PLAYLIST_TYPE_DICTIONARY,
  TIME_FORMAT
} from 'appConstants';
import ColName from 'components/ColName';
import CopyrightTag from 'components/CopyrightTag';
import StatusTag from 'components/StatusTag';
import { Link } from 'react-router-dom';
import moment from 'moment';

const RemovePlaylist = ({ record }) => {
  const { mutate } = useRemovePlaylist();
  return <RemoveButton record={record} actionRemove={mutate} titleKey="name" />;
};

export const columns = [
  {
    title: 'Playlist',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => (
      <ColName
        primary={
          <Link
            style={{ color: 'inherit' }}
            to={`/chi-tiet-playlist/${record?.id}`}
          >
            {record?.name}
          </Link>
        }
        secondary={record?.categories[0]?.name}
        avatar={{ image: record?.thumbnail, name: record?.name }}
        tag={<StatusTag status={record?.status} />}
        webUrl={
          record?.status &&
          `${process.env.REACT_APP_WEB_URL}/playlist/${record?.slug}.${record?.uniqId}.html`
        }
      />
    ),
    fixed: 'left',
    width: '120px'
  },
  {
    title: 'Loại playlist',
    dataIndex: 'typeName',
    render: typeName => (
      <Typography.Text>{PLAYLIST_TYPE_DICTIONARY[typeName]}</Typography.Text>
    ),
    width: '40px'
  },
  {
    title: 'Bản quyền',
    dataIndex: 'isCopyrightInfringement',
    render: isCopyrightInfringement => (
      <CopyrightTag isCopyrightInfringement={isCopyrightInfringement} />
    ),
    width: '80px'
  },
  // {
  //   title: 'Trạng thái',
  //   dataIndex: 'statusName',
  //   render: (_, record) => <Status record={record} />,
  //   align: 'left',
  //   width: '100px'
  // },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '80px',
    render: (_, { createdDate, updatedDate, publicDate }) => (
      <Space direction="vertical">
        <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
        <Typography.Text>
          <Typography.Text strong> Phát hành: </Typography.Text>
          {publicDate &&
            moment(publicDate).format(DATE_FORMAT + ' ' + TIME_FORMAT)}
        </Typography.Text>
      </Space>
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    width: '80px',
    render: (_, { createdBy, updatedBy }) => (
      <RelatedUser creator={createdBy} updater={updatedBy} />
    )
  },
  {
    title: 'Thao tác',
    width: '50px',
    render: (_, record) => <RemovePlaylist record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
