import { Form, Modal } from 'antd';
import { useResetPassword } from 'operations/mutations/user/mutateUser';
import React, { useCallback, useContext } from 'react';

import { UserContext } from '../UserProvider';
import ChangeUserPasswordContent from './ChangeUserPasswordContent';

const ChangeUserPassword = () => {
  const { changePasswordTarget, setChangePasswordTarget } =
    useContext(UserContext);

  const { mutate } = useResetPassword();

  const [form] = Form.useForm();

  const closeDrawer = useCallback(() => {
    setChangePasswordTarget();
  }, [setChangePasswordTarget]);

  const onOk = useCallback(async () => {
    try {
      const { password } = form.getFieldsValue();
      await mutate({
        variables: {
          input: {
            id: changePasswordTarget?.id,
            password
          }
        }
      });
      closeDrawer();
    } catch {}
  }, [changePasswordTarget?.id, closeDrawer, form, mutate]);

  return (
    <Modal
      open={!!changePasswordTarget}
      placement="right"
      width={600}
      onCancel={closeDrawer}
      cancelText="Hủy"
      afterClose={() => {
        form.resetFields();
      }}
      onOk={onOk}
      title={`Đặt lại mật khẩu người dùng: ${changePasswordTarget?.name}`}
      getContainer={false}
      destroyOnClose
    >
      <ChangeUserPasswordContent
        form={form}
        changePasswordTarget={changePasswordTarget}
      />
    </Modal>
  );
};

export default ChangeUserPassword;
