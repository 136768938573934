import { Space } from 'antd';

import RelatedUser from 'components/RelatedUser';
import RemoveButton from 'components/RemoveButton';

import TimeLine from 'components/TimeLine';

import 'moment/locale/vi';

import ColName from 'components/ColName';
import { useRemovePageInfoContact } from 'operations/mutations';
import { Link } from 'react-router-dom';

const RemovePageInfoContact = ({ record }) => {
  const { mutate } = useRemovePageInfoContact();
  return <RemoveButton record={record} actionRemove={mutate} titleKey="name" />;
};

export const columns = [
  {
    title: 'Tên',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => (
      <ColName
        primary={
          <Link
            style={{ color: 'inherit' }}
            to={`/chi-tiet-thong-tin-trang/${record?.id}`}
          >
            {record?.name}
          </Link>
        }
        secondary={record?.singers?.map(singer => singer?.name).join(', ')}
      />
    ),
    fixed: 'left',
    width: '80px'
  },

  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '50px',
    render: (_, { createdDate, updatedDate, publictDay }) => (
      <Space direction="vertical">
        <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
      </Space>
    )
  },

  {
    title: 'Thông tin',
    dataIndex: 'related',
    width: '50px',
    render: (_, { createdBy, updatedBy }) => (
      <RelatedUser creator={createdBy} updater={updatedBy} />
    )
  },
  {
    // title: 'Thao tác',
    width: '40px',
    render: (_, record) => <RemovePageInfoContact record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
