import { useQuery } from '@apollo/client';
import { Spin, Tabs, Typography } from 'antd';
import {
  useAddPLaylistForSong,
  useAddPLaylistForVideo,
  useEditPlaylistForSong,
  useEditPlaylistForVideo
} from 'operations/mutations';
import { GET_PLAYLIST_BY_ID } from 'operations/queries';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PlaylistInfo from './components/PlaylistInfo';
import SeoInfo from './components/SeoInfo';

const ArtistDetail = () => {
  const { id } = useParams();
  const isUpdate = useMemo(() => !!id, [id]);
  const { data, loading: fetchLoading } = useQuery(GET_PLAYLIST_BY_ID, {
    variables: { id: parseInt(id) },
    skip: !isUpdate
  });
  const { mutate: editPlaylistForSong, loading: editForSongLoading } =
    useEditPlaylistForSong();
  const { mutate: editPlaylistForVideo, loading: editForVideoLoading } =
    useEditPlaylistForVideo();
  const { mutate: addPlaylistForSong, loading: addForSongLoading } =
    useAddPLaylistForSong();
  const { mutate: addPlaylistForVideo, loading: addForVideoLoading } =
    useAddPLaylistForVideo();

  const playlist = useMemo(() => data?.playlist, [data?.playlist]);

  const items = useMemo(
    () => [
      {
        label: 'Thông tin playlist',
        key: 'info',
        children: (
          <PlaylistInfo
            isUpdate={isUpdate}
            playlist={playlist}
            editPlaylistForSong={editPlaylistForSong}
            editPlaylistForVideo={editPlaylistForVideo}
            addPlaylistForSong={addPlaylistForSong}
            addPlaylistForVideo={addPlaylistForVideo}
          />
        )
      },

      {
        label: 'SEO',
        key: 'seo',
        children: (
          <SeoInfo
            playlist={playlist}
            editPlaylistForSong={editPlaylistForSong}
            editPlaylistForVideo={editPlaylistForVideo}
          />
        ),
        disabled: !isUpdate
      }
    ],
    [
      addPlaylistForSong,
      addPlaylistForVideo,
      editPlaylistForSong,
      editPlaylistForVideo,
      isUpdate,
      playlist
    ]
  );

  return (
    <Spin
      spinning={
        fetchLoading ||
        editForSongLoading ||
        editForVideoLoading ||
        addForSongLoading ||
        addForVideoLoading
      }
    >
      <Typography.Title>
        {isUpdate ? 'Chi tiết playlist' : 'Tạo playlist'}
      </Typography.Title>
      <Tabs type="card" items={items} />
    </Spin>
  );
};

export default ArtistDetail;
