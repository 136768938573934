import { Typography } from 'antd';
import PartnerList from './components/PartnerList';
import PartnerFilter from './PartnerFilter';
import PartnerForm from './PartnerForm';

const Partner = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Quản lý Đối tác</Typography.Title>

      <PartnerFilter />

      <PartnerList />

      <PartnerForm />
    </div>
  );
};

export default Partner;
