import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { searchSongVar } from 'api/cache';
import AutoComplete from 'components/AutoComplete';
import { GET_SONGS } from 'operations/queries';
import { generateSongLabel } from 'utils/parser';

const SongSearch = ({ onSelect, ...props }) => {
  const [searchSong] = useLazyQuery(GET_SONGS);
  const searchedSongs = useReactiveVar(searchSongVar);

  const onChange = value => {
    const song = searchedSongs.find(_song => _song.id === value);
    return onSelect(song);
  };

  return (
    <AutoComplete
      size="large"
      value={null}
      handleSearch={searchSong}
      labelKey="title"
      valueKey="id"
      dataKey="songs"
      placeholder="Tìm bài hát thêm vào danh sách"
      style={{ width: '100%' }}
      onChange={onChange}
      updateCache={searchSongVar}
      getLabel={generateSongLabel}
      optionQuery={{
        isCopyrightInfringement: {
          eq: 0
        },
        status: {
          eq: 1
        }
      }}
      {...props}
    />
  );
};

export default SongSearch;
