import SeoCommon from 'containers/SeoCommon/SeoCommon';
import { initParams } from '../util';

const SeoInfo = ({ artist, onEditArtist }) => {
  return (
    <SeoCommon
      initSeo={artist?.metaSeo}
      initValues={initParams(artist)}
      onUpdate={onEditArtist}
    />
  );
};

export default SeoInfo;
