import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_PLAYLIST_FOR_SONG = gql`
  mutation EditPlaylistForSong($input: EditPlaylistForSongInput!) {
    editPlaylistForSong(input: $input) {
      id
      name
    }
  }
`;

export const EDIT_PLAYLIST_FOR_VIDEO = gql`
  mutation EditPlaylistForVideo($input: EditPlaylistForVideoInput!) {
    editPlaylistForVideo(input: $input) {
      id
      name
    }
  }
`;

export const ADD_PLAYLIST_FOR_SONG = gql`
  mutation AddPlaylistForSong($input: AddPlaylistForSongInput!) {
    addPlaylistForSong(input: $input) {
      id
      name
    }
  }
`;
export const ADD_PLAYLIST_FOR_VIDEO = gql`
  mutation AddPlaylistForVideoInput($input: AddPlaylistForVideoInput!) {
    addPlaylistForVideo(input: $input) {
      id
      name
    }
  }
`;

export const REMOVE_PLAYLIST = gql`
  mutation RemovePlaylist($playlistId: Int!) {
    removePlaylist(playlistId: $playlistId, removeType: SOFT_REMOVE) {
      id
      name
    }
  }
`;

export const BULK_ACTION_PLAYLISTS = gql`
  mutation BulkActionPlaylist($input: [ActionPlaylistInput]) {
    bulkActionPlaylist(input: $input) {
      id
      name
    }
  }
`;

export const BULK_COPYRIGHT_INFRINGTMENT_PLAYLISTS = gql`
  mutation BulkActionCopyRightInfringementPlaylists($playlistIds: [Int!]) {
    bulkActionCopyRightInfringementPlaylists(playlistIds: $playlistIds) {
      id
      name
    }
  }
`;

export const BULK_EXPORT_PLAYLIST = gql`
  mutation BulkExportPlaylists($input: ExportBulkPlaylistInput) {
    bulkExportPlaylists(input: $input) {
      link
      id
      fileName
      eventId
      createdDate
      createdBy
    }
  }
`;

export const BULK_EXPORT_PLAYLIST_BY_FIELDS = gql`
  mutation ExportPlaylistsByFields($input: ExportPlaylistInput) {
    exportPlaylistsByFields(input: $input) {
      link
      id
      fileName
      eventId
      createdDate
      createdBy
    }
  }
`;
// ----------------------------------------------------
// Hook

export const useAddPLaylistForSong = () => {
  const [mutate, { data, error, loading }] = useMutation(
    ADD_PLAYLIST_FOR_SONG,
    {
      onCompleted: ({ addPlaylistForSong }) => {
        notification.success({
          message: (
            <Typography.Text>
              Đã tạo playlist{' '}
              <Typography.Text strong>
                {addPlaylistForSong.name}
              </Typography.Text>
            </Typography.Text>
          )
        });
      }
    }
  );
  return { mutate, data, error, loading };
};
export const useAddPLaylistForVideo = () => {
  const [mutate, { data, error, loading }] = useMutation(
    ADD_PLAYLIST_FOR_VIDEO,
    {
      onCompleted: ({ addPlaylistForVideo }) => {
        notification.success({
          message: (
            <Typography.Text>
              Đã tạo playlist{' '}
              <Typography.Text strong>
                {addPlaylistForVideo.name}
              </Typography.Text>
            </Typography.Text>
          )
        });
      }
    }
  );
  return { mutate, data, error, loading };
};

export const useEditPlaylistForSong = () => {
  const [mutate, { data, error, loading }] = useMutation(
    EDIT_PLAYLIST_FOR_SONG,
    {
      onCompleted: ({ editPlaylistForSong }) => {
        notification.success({
          message: (
            <Typography.Text>
              Đã chỉnh sửa playlist{' '}
              <Typography.Text strong>
                {editPlaylistForSong.title}
              </Typography.Text>
            </Typography.Text>
          )
        });
      },
      refetchQueries: ['GetPlaylistById', 'GetPlaylists']
    }
  );
  return { mutate, data, error, loading };
};

export const useEditPlaylistForVideo = () => {
  const [mutate, { data, error, loading }] = useMutation(
    EDIT_PLAYLIST_FOR_VIDEO,
    {
      onCompleted: ({ editPlaylistForVideo }) => {
        notification.success({
          message: (
            <Typography.Text>
              Đã chỉnh sửa playlist{' '}
              <Typography.Text strong>
                {editPlaylistForVideo.title}
              </Typography.Text>
            </Typography.Text>
          )
        });
      },
      refetchQueries: ['GetPlaylistById', 'GetPlaylists']
    }
  );
  return { mutate, data, error, loading };
};

export const useBulkActionPlaylists = actionField => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ACTION_PLAYLISTS,
    {
      refetchQueries: ['GetPlaylists'],
      onCompleted: ({ bulkActionPlaylist }) => {
        getBulkMessage('Playlist', bulkActionPlaylist, actionField);
      }
    }
  );

  return { mutate, data, error, loading };
};

export const useBulkActionCopyRightInfringementPlaylists = actionField => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_COPYRIGHT_INFRINGTMENT_PLAYLISTS,
    {
      refetchQueries: ['GetPlaylists'],
      onCompleted: ({ bulkActionCopyRightInfringementPlaylists }) => {
        getBulkMessage(
          'Playlist',
          bulkActionCopyRightInfringementPlaylists,
          actionField
        );
      }
    }
  );

  return { mutate, data, error, loading };
};

// --------------------------------------------

export const useRemovePlaylist = () => {
  const [mutate, { data, error, loading }] = useMutation(REMOVE_PLAYLIST, {
    refetchQueries: ['GetPlaylists'],
    onCompleted: ({ removePlaylist }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá playlist{' '}
            <Typography.Text strong>{removePlaylist.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useBulkExportPlaylists = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_EXPORT_PLAYLIST,
    {}
  );

  return { mutate, data, error, loading };
};

export const useBulkExportPlaylistByFields = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_EXPORT_PLAYLIST_BY_FIELDS,
    {}
  );

  return { mutate, data, error, loading };
};
