import { Space, Avatar, Typography, Tag } from 'antd';
import RemoveButton from 'components/RemoveButton';

import {
  COMMON_STATUS_INT_DICTIONARY,
  COMMON_STATUS_KEY_INT,
  ADV_CONTENT,
  ADV_CONTENT_TYPE_ID
} from 'appConstants';
import {
  ContainerOutlined,
  UserOutlined,
  CalendarOutlined,
  AppstoreOutlined
} from '@ant-design/icons';
import 'moment/locale/vi';
import moment from 'moment';
import { useRemoveIcAdvert } from 'operations/mutations';
import ImgNotFoundIcon from 'components/Icons/ImgNotFoundIcon';
import { Link } from 'react-router-dom';

const RemoveAdv = ({ record }) => {
  const { mutate } = useRemoveIcAdvert();
  return <RemoveButton record={record} actionRemove={mutate} titleKey="name" />;
};

const generateColor = status => {
  switch (status) {
    case COMMON_STATUS_KEY_INT.WAITING:
      return 'default';

    case COMMON_STATUS_KEY_INT.APPROVED:
      return 'green';

    default:
      break;
  }
};

const StatusTag = ({ status, ...props }) => {
  return (
    <Tag color={generateColor(status)} {...props}>
      {COMMON_STATUS_INT_DICTIONARY[status]}
    </Tag>
  );
};

export const columns = [
  {
    title: 'Stt',
    width: '50px',
    key: 'stt',
    dataIndex: 'stt',
  },
  {
    title: 'Tên quảng cáo',
    width: '220px',
    key: 'name',
    render: (_, record) => (
      <Space direction="vertical">
        <Typography.Text strong>
          <Link
            style={{ color: 'inherit' }}
            to={`/chi-tiet-quang-cao/${record?.id}`}
          >
            {record?.advertName}
          </Link>
        </Typography.Text>
        {record.advertContentTypeId == ADV_CONTENT_TYPE_ID.IMAGE
          && record.imagePath &&
          <Avatar
            style={{ userSelect: 'none' }}
            size={64}
            shape='square'
            src={record.imagePath}
            icon={<ImgNotFoundIcon />}
          />}
        <Typography.Text>
          {record?.advertDesc}
        </Typography.Text>
      </Space>
    ),
  },
  {
    title: 'Vị trí đã đặt',
    key: 'advp',
    width: '280px',
    render: (_, { positionAdvs }) => (
      <Space direction="vertical">
        {
          positionAdvs && positionAdvs?.map((item, index) =>
            <Space key={index} direction="horizontal">

              {item.positionName
                && <Typography>
                  <AppstoreOutlined title='Vị trí' />{' '}
                  {item.positionName}
                </Typography>}
              {'   '}
              {item.pageName
                && <Typography>
                  <ContainerOutlined title='Trang' />{' '}
                  {item.pageName}
                </Typography>}
            </Space>
          )
        }
      </Space>
    ),
    fixed: 'left',
  },
  {
    title: 'Thời gian',
    width: '200px',
    dataIndex: 'time',
    render: (_, { startTime, endTime }) => (
      <Space direction="vertical">
        <Typography.Text >
          Bắt đầu {startTime && moment(startTime).format('HH:mm DD/MM/YYYY')}
        </Typography.Text>
        <Typography.Text>
          Kết thúc {endTime && moment(endTime).format('HH:mm DD/MM/YYYY')}
        </Typography.Text>
      </Space>
    )
  },
  {
    title: 'Loại ',
    width: '100px',
    dataIndex: 'advertContentTypeId',
    render: (_, { advertContentTypeId }) => (
      <Space direction="vertical">
        {ADV_CONTENT[advertContentTypeId]}
      </Space>
    )
  },
  {
    title: 'Trạng thái',
    width: '100px',
    dataIndex: 'advertStatusId',
    render: (_, { advertStatusId }) => (
      <Space direction="vertical">
        <StatusTag status={advertStatusId} />
      </Space>
    )
  },
  {
    title: 'Thông tin',
    dataIndex: 'related',
    width: '170px',
    render: (_, record) => (
      <Space direction="vertical">
        <Typography.Text>
          <CalendarOutlined title='ngày tạo' />
          {"  "}
          {record.crDateTime && moment(record.crDateTime).format('HH:mm DD/MM/YYYY')}
        </Typography.Text>
        {record.userName && (
          <Typography.Text>
            <UserOutlined title='người tạo' />
            {"  "}
            {record.userName}
          </Typography.Text>
        )}
      </Space>
    ),
  },
  {
    title: 'Thao tác',
    width: '90px',
    render: (_, record) => <RemoveAdv record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
