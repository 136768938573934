import { Typography } from 'antd';
import React from 'react';
import HotSongList from './components/HotSongList';

const HotSong = () => {
  return (
    <div>
      <Typography.Title>HomePage - Bài hát nổi bật</Typography.Title>
      <HotSongList />
    </div>
  );
};

export default HotSong;
