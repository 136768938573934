import { Button, Card, Col, DatePicker, Form, Input, Row } from 'antd';
import {
  ACTION_NOTIFICATION_VALUE,
  APP_NOTIFICATION_SITE_VALUE,
  DATE_FORMAT,
  NOTIFICATION_VALUE,
  TIME_FORMAT,
  UPLOAD_TYPE
} from 'appConstants';
import ActionNotificationSelect from 'components/ActionNotificationSelect';
import AppNotificationSiteSelect from 'components/AppNotificationSiteSelect';
import ImageUpload from 'components/ImageUpload';
import NotificationTypeSelect from 'components/NotificationTypeSelect';
import moment from 'moment';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { get } from 'utils/lodash';

const AppNotificationInfo = ({
  appNotification,
  isUpdate,
  editAppNotification,
  addAppNotification
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (appNotification) {
      form.setFieldsValue({
        title: appNotification.title,
        body: appNotification.body,
        nid: appNotification.nid,
        url: appNotification.url,
        type: NOTIFICATION_VALUE[appNotification.type],
        siteId: APP_NOTIFICATION_SITE_VALUE[appNotification.siteId],
        clickAction: ACTION_NOTIFICATION_VALUE[appNotification.clickAction],
        image: appNotification.image,
        sendingTime:
          appNotification.sendingTime && moment(appNotification.sendingTime),
        nextSentAt:
          appNotification.nextSentAt && moment(appNotification.nextSentAt)
      });
    }
  }, [appNotification, form]);

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const image = get(
        values.image,
        '[0].response.Data',
        appNotification?.image
      );
      const iconLink = get(
        values.iconLink,
        '[0].response.Data',
        appNotification?.iconLink
      );

      const params = {
        ...values,
        image,
        iconLink
      };

      delete params.urlFile;

      if (isUpdate) {
        await editAppNotification({
          variables: {
            input: {
              id: parseInt(id),
              ...params
            }
          }
        });
      } else {
        await addAppNotification({
          variables: {
            input: {
              ...params
            }
          },
          onCompleted: ({ addAppNotification }) => {
            history.push(
              '/chi-tiet-app-notification/' + addAppNotification?.id
            );
          }
        });
      }
    } catch (error) {}
  };

  return (
    <Card>
      <Form form={form} layout="vertical">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Tiêu đề"
              rules={[{ required: true, message: 'Nhập tiêu đề!' }]}
            >
              <Input placeholder="Nhập tiêu đề" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="body"
              label="Nội dung"
              rules={[{ required: true, message: 'Nhập nội dung!' }]}
            >
              <Input placeholder="Nhập nội dung" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="nid" label="Nid">
              <Input placeholder="Nhập Nid" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="type"
              label="Loại thông báo"
              rules={[{ required: true, message: 'Chọn loại thông báo!' }]}
            >
              <NotificationTypeSelect />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="siteId"
              label="Site"
              rules={[{ required: true, message: 'Chọn site!' }]}
            >
              <AppNotificationSiteSelect />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="clickAction"
              label="Hành động khi click vào thông báo"
              rules={[{ required: true, message: 'Chọn hành động khi click!' }]}
            >
              <ActionNotificationSelect />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="sendingTime"
              label="Thời gian đăng"
              rules={[{ required: true, message: 'Chọn thời gian đăng!' }]}
            >
              <DatePicker
                showTime
                style={{ width: '100%' }}
                format={DATE_FORMAT + ' ' + TIME_FORMAT}
                placeholder="Chọn thời gian đăng"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="nextSentAt"
              label="Lần gửi tiếp theo"
              rules={[{ required: true, message: 'Chọn lần gửi tiếp theo!' }]}
            >
              <DatePicker
                showTime
                style={{ width: '100%' }}
                format={DATE_FORMAT + ' ' + TIME_FORMAT}
                placeholder="Chọn lần gửi tiếp theo"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item
                  name="image"
                  label="Hình đại diện (Ratio 2:1, max 500KB)"
                  valuePropName="file"
                  getValueFromEvent={normFile}
                >
                  <ImageUpload
                    avatarShape="square"
                    size={128}
                    fontSize={64}
                    fullName="Hình đại diện"
                    {...UPLOAD_TYPE.THUMBNAIL_APP_NOTIFICATION}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Row justify="end">
        <Col>
          <Button onClick={onSave} type="primary">
            Lưu
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default AppNotificationInfo;
