import { useQuery } from '@apollo/client';
import { Spin, Tabs, Typography } from 'antd';
import {
  useAddPageInfoContact,
  useEditPageInfoContact
} from 'operations/mutations';
import { GET_PAGE_INFO_BY_ID } from 'operations/queries';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import PageInfo from './components/PageInfo';

const PageInfoDetail = () => {
  const { id } = useParams();
  const isUpdate = useMemo(() => !!id, [id]);

  const { data, loading: fetchLoading } = useQuery(GET_PAGE_INFO_BY_ID, {
    variables: { id: parseInt(id) },
    skip: !isUpdate
  });

  const { mutate: editPage, loading: editLoading } = useEditPageInfoContact();
  const { mutate: addPage, loading: addLoading } = useAddPageInfoContact();

  const items = useMemo(
    () => [
      {
        label: 'Thông tin trang',
        key: 'info',
        children: (
          <PageInfo
            pageInfo={data?.pageInfoContact}
            isUpdate={isUpdate}
            onEditPageInfoContact={editPage}
            onAddPageInfoContact={addPage}
          />
        )
      }
    ],
    [addPage, data?.pageInfoContact, editPage, isUpdate]
  );

  return (
    <Spin spinning={fetchLoading || editLoading || addLoading}>
      <Typography.Title>
        {isUpdate ? 'Chi tiết thông tin trang' : 'Tạo thông tin trang'}
      </Typography.Title>
      <Tabs type="card" items={items} />
    </Spin>
  );
};

export default PageInfoDetail;
