import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';

import DeleteConfirmPopup from 'components/DeleteConfirmPopup';

import React from 'react';

const RemoveButton = ({ record, actionRemove, titleKey = 'name' }) => {
  return (
    <Space>
      <DeleteConfirmPopup
        onDelete={() =>
          actionRemove({
            variables: {
              id: parseInt(record.id),
              playlistId: parseInt(record.id)
            }
          })
        }
        title={
          <Typography.Text>
            Bạn muốn xoá{' '}
            <Typography.Text strong>{record[titleKey]}</Typography.Text> ?
          </Typography.Text>
        }
      >
        <Button danger type="primary" size="small" icon={<DeleteOutlined />} />
      </DeleteConfirmPopup>
    </Space>
  );
};

export default RemoveButton;
