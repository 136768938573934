import { gql, useQuery } from '@apollo/client';
import { currentPermissionsVar } from 'api/cache';

export const GET_CURRENT_USER = gql`
  query UserDetail {
    detail {
      userId
      email
      fullName
      phone
      status
      userType
      userTypeName
      phongBan {
        id
        name
      }
      roleName {
        id
        title
        description
        permissions {
          id
          title
          code
          groupCode
          groupName
        }
      }
      userName
    }
  }
`;

export const GET_LOGGIN_LOG = gql`
  query LoginAttemptByUserId($id: String!) {
    loginAttemptByUserId(id: $id) {
      id
      userId
      ip
      userAgent
      success
      createdDate
      user {
        id
        name
        email
        status
      }
    }
  }
`;

export const useCurrentUser = () => {
  const { client, data, error, loading } = useQuery(GET_CURRENT_USER, {
    onCompleted: ({
      detail: {
        userTypeName,
        roleName: { permissions }
      }
    }) => {
      const readPermissions = permissions
        .filter(permission => permission.code.includes('_read'))
        ?.map(permission => permission.groupCode);

      if (readPermissions.includes('homepage')) {
        readPermissions.push('topPopular', 'hotSong', 'topBanner');
      }
      const permissionDetail = [
        ...readPermissions.map(permission => permission + 'Detail')
      ];

      readPermissions.push(...permissionDetail);

      currentPermissionsVar(readPermissions);
    }
  });

  return { client, data, error, loading };
};
