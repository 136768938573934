import { Select } from 'antd';
import { USER_TYPE_STR_DICTIONARY } from 'appConstants';

const UserTypeSelect = props => {
  return (
    <Select placeholder="Chọn loại user" allowClear {...props}>
      {Object.keys(USER_TYPE_STR_DICTIONARY).map(key => (
        <Select.Option value={key} key={key}>
          {USER_TYPE_STR_DICTIONARY[key]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default UserTypeSelect;
