import { Layout } from 'antd';
import CurrentUser from 'containers/CurrentUserMenu';
import React from 'react';

const { Header } = Layout;

const NaviHeader = () => {
  return (
    <Header className="navi-header" style={{ backgroundColor: '#a94913' }}>
      <CurrentUser />
    </Header>
  );
};

export default NaviHeader;
