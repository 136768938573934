import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Spin,
  Typography
} from 'antd';
import PermissionGroup from 'containers/PermissionGroup';
import { useAddRole, useEditRole } from 'operations/mutations';
import { GET_PERMISSIONS, GET_ROLE_BY_ID } from 'operations/queries';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const CheckboxGroup = Checkbox.Group;

const RoleDetail = () => {
  const { id } = useParams();
  const [form] = Form.useForm();
  const isUpdate = useMemo(() => !!id, [id]);
  const history = useHistory();

  const { data: dataPermissions, loading: permissionLoading } = useQuery(
    GET_PERMISSIONS,
    {
      variables: {
        take: 150
      }
    }
  );

  const { data: dataRole, loading: roleLoading } = useQuery(GET_ROLE_BY_ID, {
    variables: { id: parseInt(id) },
    skip: !isUpdate
  });
  const { mutate: editRole, loading: editLoading } = useEditRole();
  const { mutate: addRole, loading: addLoading } = useAddRole();
  const isLoading = useMemo(
    () => permissionLoading || roleLoading || editLoading || addLoading,
    [addLoading, editLoading, permissionLoading, roleLoading]
  );
  const permissions = useMemo(() => {
    const _permissions = dataPermissions?.permissions?.items;
    if (!_permissions?.length) return [];
    const groupByGroupCode = _permissions.reduce((group, permission) => {
      const { groupCode } = permission;
      group[groupCode] = group[groupCode] ?? [];
      group[groupCode].push(permission);
      return group;
    }, {});

    const result = Object.keys(groupByGroupCode).map(
      key => groupByGroupCode[key]
    );
    return result;
  }, [dataPermissions?.permissions?.items]);

  const role = useMemo(() => dataRole?.role, [dataRole?.role]);

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const params = { ...values, permissionIds: values?.permissionIds || [] };

      if (isUpdate) {
        // update topic
        await editRole({
          variables: {
            input: {
              ...params,
              id: parseInt(id)
            }
          }
        });
      } else {
        //  create topic
        await addRole({
          variables: {
            input: params
          },
          onCompleted: ({ addRole }) => {
            history.push('/chi-tiet-vai-tro/' + addRole?.id);
          }
        });
      }
    } catch (error) {}
  };

  const onChange = values => {};

  useEffect(() => {
    if (role) {
      const { title, description, permissions } = role;
      const permissionIds = permissions.map(({ id }) => id);
      form.setFieldsValue({ title, description, permissionIds });
    }
  }, [form, role]);

  return (
    <Spin spinning={isLoading}>
      <Typography.Title>
        {isUpdate ? 'Chi tiết vai trò' : 'Tạo vai trò'}
      </Typography.Title>
      <Card>
        <Row justify="end">
          <Button type="primary" onClick={onSave}>
            Lưu
          </Button>
        </Row>
        <Form form={form} onValuesChange={onChange} layout="vertical">
          <Row gutter={16}>
            <Col span={12}>
              <Form.Item name="title" label="Vai trò">
                <Input placeholder="Tên vai trò" />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="description" label="Mô tả">
                <Input placeholder="Mô tả vai trò" />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item name="permissionIds" label="Danh sách quyền hạn">
            <CheckboxGroup style={{ width: '100%' }}>
              {permissions?.map((groupPermission, index) => (
                <PermissionGroup
                  key={index}
                  groupPermission={groupPermission}
                  form={form}
                />
              ))}
            </CheckboxGroup>
          </Form.Item>
        </Form>
      </Card>
    </Spin>
  );
};
export default RoleDetail;
