import { Space, Typography } from 'antd';

import RelatedUser from 'components/RelatedUser';
import RemoveButton from 'components/RemoveButton';

import TimeLine from 'components/TimeLine';

import 'moment/locale/vi';

import ColName from 'components/ColName';
import CopyrightTag from 'components/CopyrightTag';
import StatusTag from 'components/StatusTag';
import { useRemoveSong } from 'operations/mutations';
import { Link } from 'react-router-dom';
import RelatedInfo from 'components/RelatedInfo';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from 'appConstants';

const RemoveSong = ({ record }) => {
  const { mutate } = useRemoveSong();
  return (
    <RemoveButton record={record} actionRemove={mutate} titleKey="title" />
  );
};

export const columns = [
  {
    title: 'Bài hát',
    dataIndex: 'title',
    key: 'title',
    render: (_, record) => (
      <ColName
        primary={
          <Link
            style={{ color: 'inherit' }}
            to={`/chi-tiet-bai-hat/${record?.id}`}
          >
            {record?.title}
          </Link>
        }
        secondary={record?.singers?.map(singer => singer?.name).join(', ')}
        avatar={{ image: record?.thumbnail, name: record?.title }}
        tag={<StatusTag status={record?.status} />}
        webUrl={
          record?.status &&
          `${process.env.REACT_APP_WEB_URL}/bai-hat/${record?.slug}.${record?.uniqId}.html`
        }
      />
    ),
    fixed: 'left',
    width: '200px'
  },
  {
    title: 'Thể loại',
    dataIndex: 'categories',
    render: categories => (
      <Space direction="vertical">
        <Typography.Text>
          {categories?.map(cate => cate?.name).join(', ')}
        </Typography.Text>
      </Space>
    ),
    width: '100px'
  },
  {
    title: 'Bản quyền',
    dataIndex: 'isCopyrightInfringement',
    render: isCopyrightInfringement => (
      <CopyrightTag isCopyrightInfringement={isCopyrightInfringement} />
    ),
    width: '80px'
  },
  {
    title: "Playlist",
    dataIndex: 'playlists',
    key: 'playlists',
    width: '150px',
    render: (_, record) => (
      record?.playlists.map((item) =>
        <>
          <Link
            style={{ color: 'inherit' }}
            to={`/chi-tiet-playlist/${item?.id}`}
          >
            {item?.name}
          </Link>
          <br></br>
        </>
      )
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    render: (_, { uniqId, relatedSongs, id }) => (
      <Space direction="vertical">
        <RelatedInfo
          data={relatedSongs}
          redirectUrl={`/chi-tiet-bai-hat/${id}?tab=2`}
          subject="bài hát"
        />
        <Typography.Text>UniqId: {uniqId}</Typography.Text>
      </Space>
    ),
    width: '120px'
  },
  {
    title: 'Thống kê',
    dataIndex: 'statistic',
    render: (_, { clicks, views }) => (
      <Space direction="vertical">
        <Typography.Text>Lượt click: {clicks}</Typography.Text>
        <Typography.Text>Lượt view: {views}</Typography.Text>
      </Space>
    ),
    width: '120px'
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '140px',
    render: (_, { createdDate, updatedDate, publictDay }) => (
      <Space direction="vertical">
        <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
        <Typography.Text>
          <Typography.Text strong> Phát hành: </Typography.Text>
          {publictDay &&
            moment(publictDay).format(DATE_FORMAT + ' ' + TIME_FORMAT)}
        </Typography.Text>
      </Space>
    )
  },

  {
    title: 'Thông tin',
    dataIndex: 'related',
    width: '120px',
    render: (_, { createdBy, updatedBy }) => (
      <RelatedUser creator={createdBy} updater={updatedBy} />
    )
  },
  {
    // title: 'Thao tác',
    width: '50px',
    render: (_, record) => <RemoveSong record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
