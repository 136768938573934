import { Tag } from 'antd';
import { COPYRIGHT_INT_DICTIONARY, COPYRIGHT_KEY_INT } from 'appConstants';

const generateColor = isCopyrightInfringement => {
  switch (isCopyrightInfringement) {
    case COPYRIGHT_KEY_INT.NON_COPYRIGHT:
      return 'red';

    case COPYRIGHT_KEY_INT.COPYRIGHT:
      return 'green';

    default:
      break;
  }
};

const CopyrightTag = ({ isCopyrightInfringement, ...props }) => {
  return (
    <Tag color={generateColor(isCopyrightInfringement)} {...props}>
      {COPYRIGHT_INT_DICTIONARY[isCopyrightInfringement]}
    </Tag>
  );
};

export default CopyrightTag;
