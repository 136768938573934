import { useQuery } from '@apollo/client';
import { Button, Divider, Space } from 'antd';

import {
  BULK_DATA_KEY,
  COMMON_STATUS_KEY_BY_INT,
  COMMON_STATUS_KEY_INT,
  COPYRIGHT_KEY_INT,
  DATE_FORMAT,
  ORDER_BY_DICTIONARY,
  PAGE_SIZE,
  TABLE_PROPS
} from 'appConstants';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import {
  useBulkActionCopyRightInfringementSongs,
  useBulkActionSongs,
  useBulkEncodeAudioSong,
  useBulkExportSongByFields,
  useBulkExportSongs,
  useBulkUpdateDurationSong
} from 'operations/mutations';
import { GET_SONGS } from 'operations/queries';

import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import ApproveButton from 'components/ApproveButton';
import CopyrightInfringementButton from 'components/CopyrightInfringementButton';
import ImportSong from 'components/ImportSong';
import  Importupdate  from 'components/ImportSong/ImportSongv1';
import moment from 'moment';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { processQueryFilerParams } from 'utils/filter';
import { isObjEmpty } from 'utils/lodash';
import { generateFileDownload } from 'utils/other';
import { columns } from '../columns';

const SongList = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [songs, setSongs] = useState([]);
  const [total, setTotal] = useState();

  const { mutate: updateDuration, loading: durationLoading } =
    useBulkUpdateDurationSong();
  const { mutate: encodeAudioSong, loading: encodeLoading } =
    useBulkEncodeAudioSong();

  const { mutate: updateStatus, loading: actionLoading } = useBulkActionSongs(
    BULK_DATA_KEY.STATUS
  );
  const { mutate: updateCopyright, loading: copyrightLoading } =
    useBulkActionCopyRightInfringementSongs(BULK_DATA_KEY.COPYRIGHT);

  const { mutate: exportSongs, loading: exportLoading } = useBulkExportSongs();
  const { mutate: exportSongByFields } = useBulkExportSongByFields();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const pageSize = useMemo(
    () => parseInt(searchParams.pageSize) || PAGE_SIZE,
    [searchParams.pageSize]
  );

  const filterQuery = useMemo(() => {
    let additonalQueries = {};
    const processedSearchParams = processQueryFilerParams(searchParams, true);
    if (!processedSearchParams) return null;

    if (processedSearchParams?.songCates) {
      processedSearchParams.songCates = {
        some: {
          cateId: {
            in: processedSearchParams?.songCates
          }
        }
      };
    }

    if (processedSearchParams?.publictDay) {
      processedSearchParams.publictDay = {
        [processedSearchParams.publictDay.eq ? 'neq' : 'eq']: null
      };
    }

    if (processedSearchParams?.songTags) {
      processedSearchParams.songTags = {
        some: {
          tagId: {
            in: processedSearchParams?.songTags
          }
        }
      };
    }

    if (processedSearchParams?.songArtists) {
      processedSearchParams.songArtists = {
        some: {
          or: {
            artistId: {
              in: processedSearchParams?.songArtists
            }
          }
        }
      };
    }

    if (processedSearchParams?.vocalRank) {
      processedSearchParams.songArtists = {
        some: {
          or: {
            ...processedSearchParams.songArtists?.some?.or,
            vocalRank: {
              eq: parseInt(processedSearchParams?.vocalRank.eq)
            }
          }
        }
      };
      delete processedSearchParams.vocalRank;
    }

    const finalQuery = { ...processedSearchParams, ...additonalQueries };
    return isObjEmpty(finalQuery) ? null : finalQuery;
  }, [searchParams]);

  const createdDateQuery = useMemo(
    () => filterQuery?.createdDate,
    [filterQuery]
  );

  const {
    loading: isSongsLoading,
    error: songsError,
    data: songsConnect
  } = useQuery(GET_SONGS, {
    variables: {
      take: pageSize,
      skip: pageSize * page - pageSize,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    },
    fetchPolicy: 'no-cache'
  });

  const isLoading = useMemo(
    () =>
      isSongsLoading ||
      durationLoading ||
      encodeLoading ||
      actionLoading ||
      copyrightLoading,
    [
      actionLoading,
      copyrightLoading,
      durationLoading,
      encodeLoading,
      isSongsLoading
    ]
  );

  useEffect(() => {
    if (!isSongsLoading && songsConnect) {
      const _data = songsConnect?.songs?.items?.map(item => ({
        ...item,
        key: item.id
      }));
      setSongs(_data);
      setTotal(songsConnect?.songs?.totalCount);
    }
  }, [isSongsLoading, songsConnect]);

  const onPaginate = (page, pageSize) => {
    setSelectedKeys([]);
    history.push({
      search: queryString.stringify({ ...searchParams, page, pageSize })
    });
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({
        ...searchParams,
        page: 1,
        sort,
        pageSize
      })
    });
  };

  const onShowSizeChange = (_, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, pageSize })
    });
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedKeys);
    }
  };

  const status = useMemo(() => {
    return selectedRows.some(
      _row => _row.status === COMMON_STATUS_KEY_INT.WAITING
    )
      ? COMMON_STATUS_KEY_INT.APPROVED
      : COMMON_STATUS_KEY_INT.WAITING;
  }, [selectedRows]);

  const isCopyrightInfringement = useMemo(() => {
    return selectedRows.some(
      _row => _row.isCopyrightInfringement === COPYRIGHT_KEY_INT.COPYRIGHT
    )
      ? COPYRIGHT_KEY_INT.NON_COPYRIGHT
      : COPYRIGHT_KEY_INT.COPYRIGHT;
  }, [selectedRows]);

  const handleChangeStatus = async () => {
    try {
      const params = selectedRows.map(({ id }) => ({
        id,
        status: COMMON_STATUS_KEY_BY_INT[status]
      }));
      await updateStatus({
        variables: {
          input: params
        }
      });
      setSelectedKeys([]);
    } catch (error) { }
  };

  const onUpdateDuration = async () => {
    try {
      if (!selectedRows.length) return;
      await updateDuration({ variables: { songIds: selectedKeys } });
      setSelectedKeys([]);
    } catch (error) {
      setSelectedKeys([]);
    }
  };

  const onEncodeRadioSong = async () => {
    try {
      if (!selectedRows.length) return;
      await encodeAudioSong({ variables: { songIds: selectedKeys } });
      setSelectedKeys([]);
    } catch (error) {
      setSelectedKeys([]);
    }
  };

  const handleChangeCopyright = async () => {
    try {
      const params = selectedRows
        .filter(
          _item => _item.isCopyrightInfringement !== isCopyrightInfringement
        )
        .map(({ id }) => id);
      await updateCopyright({
        variables: {
          songIds: params
        }
      });
      setSelectedKeys([]);
    } catch (error) {
      setSelectedKeys([]);
    }
  };

  const getInputExport = (searchParams) => {
    let _input = {}
    if (searchParams.filter__songArtists__in__arr) {
      if (searchParams.filter__songArtists__in__arr.map) {
        _input.songArtists = searchParams.filter__songArtists__in__arr.map(x => parseInt(x))
      } else {
        _input.songArtists = [parseInt(searchParams.filter__songArtists__in__arr)]
      }
    }

    if (searchParams.filter__songCates__in__arr) {
      if (searchParams.filter__songCates__in__arr.map) {
        _input.songCates = searchParams.filter__songCates__in__arr.map(x => parseInt(x))
      } else {
        _input.songCates = [parseInt(searchParams.filter__songCates__in__arr)]
      }
    }
    if (searchParams.filter__songTags__in__arr) {
      if (searchParams.filter__songTags__in__arr.map) {
        _input.songTags = searchParams.filter__songTags__in__arr.map(x => parseInt(x))
      } else {
        _input.songTags = [parseInt(searchParams.filter__songTags__in__arr)]
      }
    }
    _input.publicDay = searchParams.filter__publictDay__eq__int ? parseInt(searchParams.filter__publictDay__eq__int) : undefined
    _input.vocalRank = searchParams.filter__vocalRank__eq ? parseInt(searchParams.filter__vocalRank__eq) : undefined
    _input.isCopyrightInfringement = searchParams.filter__isCopyrightInfringement__eq__int ? parseInt(searchParams.filter__isCopyrightInfringement__eq__int) : undefined
    _input.isYoutube = searchParams.filter__isYoutube__eq__int ? parseInt(searchParams.filter__isYoutube__eq__int) : undefined
    _input.isDelete = searchParams.filter__isDelete__eq__int ? parseInt(searchParams.filter__isDelete__eq__int) : undefined

    return {
      createdFrom: createdDateQuery?.gte,
      createdTo: createdDateQuery?.lte,
      title: searchParams.filter__title__contains,
      status: filterQuery.status?.eq,
      createdBy: searchParams.filter__createdBy__eq,
      provider: searchParams.filter__provider__contains,
      uniqId: searchParams.filter__uniqId__contains,
      ..._input
    }
  }

  const onExport = async () => {
    let a = filterQuery
    const exportBySelect = !!selectedKeys.length;


    const { data } = exportBySelect
      ? await exportSongs({
        variables: {
          input: {
            ids: selectedKeys
          }
        }
      })
      :
      await exportSongByFields({
        variables: {
          input: getInputExport(searchParams)
        }
      });

    const eventId = exportBySelect
      ? data.bulkExportSongs.eventId
      : data.exportSongsByFields.eventId;
    await generateFileDownload(eventId);
    setSelectedKeys([]);
  };

  if (songsError) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          {total ? (!!selectedKeys.length || createdDateQuery) && (
            <Button
              icon={<DownloadOutlined />}
              type="primary"
              onClick={onExport}
              loading={exportLoading}
            >
              Export (
              {!!selectedKeys.length
                ? selectedKeys?.length
                : `${moment(createdDateQuery?.gte.replace('+00:00', '')).format(
                  DATE_FORMAT
                )} - ${moment(
                  createdDateQuery?.lte.replace('+00:00', '')
                ).format(DATE_FORMAT)}`}
              )
            </Button>
          ) : ''}

          {!!selectedKeys.length && (
            <>
              <Divider type="vertical" />
              <CopyrightInfringementButton
                isCopyrightInfringement={isCopyrightInfringement}
                onClick={handleChangeCopyright}
                total={
                  selectedRows?.filter(
                    _item =>
                      _item.isCopyrightInfringement !== isCopyrightInfringement
                  )?.length
                }
              />
              <ApproveButton
                status={status}
                onClick={handleChangeStatus}
                total={
                  selectedRows?.filter(_item => _item.status !== status)?.length
                }
              />
              <Divider type="vertical" />
              <Button type="primary" onClick={onEncodeRadioSong}>
                Encode Audio
              </Button>
              <Button type="primary" onClick={onUpdateDuration}>
                Cập nhật duration
              </Button>
            </>
          )}
          <ImportSong />
          <SortSelect value={sort} onChange={setOrderBy} />
          <Link to={'/chi-tiet-bai-hat'}>
            <Button icon={<PlusOutlined />} type="primary">
              Thêm
            </Button>
          </Link>
          {/* <div style={{ display: 'none' }}>
            <Importupdate />
          </div> */}
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1500 }}
        columns={columns}
        dataSource={songs}
        pagination={{
          total,
          pageSize: pageSize,
          onChange: onPaginate,
          current: page * 1,
          quantity: songs?.length,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          onShowSizeChange: onShowSizeChange
        }}
        loading={isLoading}
        rowSelection={rowSelection}
      />
    </ListCard>
  );
};

export default SongList;
