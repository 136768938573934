import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';

export const BULK_ACTION_TOP_POPULARS = gql`
  mutation BulkActionTopPopulars($input: [BulkActionTopPopularInput]) {
    bulkActionTopPopulars(input: $input) {
      id
    }
  }
`;

export const BULK_ACTION_TOP_SONGS = gql`
  mutation BulkActionTopSongs($input: [BulkActionTopSongInput]) {
    bulkActionTopSongs(input: $input) {
      id
    }
  }
`;

export const BULK_ACTION_TOP_BANNERS = gql`
  mutation BulkActionTopBanners($input: [Int!]) {
    bulkActionTopBanners(ids: $input) {
      id
    }
  }
`;

export const useBulkActionTopPopulars = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ACTION_TOP_POPULARS,
    {
      refetchQueries: ['GetTopPopular'],
      onCompleted: () => {
        notification.success({
          message: (
            <Typography.Text>Đã cập nhật danh sách thịnh hành</Typography.Text>
          )
        });
      }
    }
  );

  return { mutate, data, error, loading };
};

export const useBulkActionTopSongs = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ACTION_TOP_SONGS,
    {
      refetchQueries: ['GetHotSongs'],
      onCompleted: () => {
        notification.success({
          message: (
            <Typography.Text>Đã cập nhật bài hát nổi bật</Typography.Text>
          )
        });
      }
    }
  );

  return { mutate, data, error, loading };
};

export const useBulkActionTopBanners = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ACTION_TOP_BANNERS,
    {
      refetchQueries: ['GetTopBanners'],
      onCompleted: () => {
        notification.success({
          message: (
            <Typography.Text>Đã cập nhật banner trang chủ</Typography.Text>
          )
        });
      }
    }
  );

  return { mutate, data, error, loading };
};
