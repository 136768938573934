import { ScissorOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Tooltip } from 'antd';
import {
  COMMON_STATUS_KEY_BY_INT,
  DEBOUNCE_VALUE,
  TOPIC_TYPE_VALUE
} from 'appConstants';
import TopicTypeSelect from 'components/TopicTypeSelect';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { debounce } from 'utils/lodash';
import { sanitizeSlug } from 'utils/parser';
import AlbumOfTopic from './AlbumOfTopic';
import PlaylistOfTopic from './PlaylistOfTopic';

const TopicInfo = ({ topic, isUpdate, onEditTopic, onAddTopic }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const [albumIds, setAlbumIds] = useState([]);
  const [playlistIds, setPlaylistIds] = useState([]);
  const [topicType, setTopicType] = useState(TOPIC_TYPE_VALUE.PLAYLIST);

  useEffect(() => {
    if (topic) {
      setPlaylistIds(topic?.playlists?.map(({ id }) => id));
      setAlbumIds(topic?.albums?.map(({ id }) => id));
      setTopicType(topic?.typeName);
      form.setFieldsValue({
        title: topic.title,
        slug: topic.slug,
        type: topic.typeName,
        intro: topic.intro
      });
    }
  }, [topic, form]);

  const onChangeTitle = debounce(() => {
    const { title, slug } = form.getFieldsValue();
    if (!slug) {
      const _slug = sanitizeSlug(title);
      form.setFieldsValue({ slug: _slug });
    }
  }, DEBOUNCE_VALUE);

  const onGenerateSlug = () => {
    const { title } = form.getFieldsValue();
    if (!title) return;
    const _slug = sanitizeSlug(title);
    form.setFieldsValue({ slug: _slug });
  };

  const onSave = async () => {
    try {
      const values = await form.validateFields();

      const params = {
        ...values,
        ids: values?.type === TOPIC_TYPE_VALUE.ALBUM ? albumIds : playlistIds
      };

      if (isUpdate) {
        // update topic
        await onEditTopic({
          variables: {
            input: {
              ...params,
              status: COMMON_STATUS_KEY_BY_INT[topic?.status],
              id: parseInt(id)
            }
          }
        });
      } else {
        //  create topic
        await onAddTopic({
          variables: {
            input: params
          },
          onCompleted: ({ addTopic }) => {
            history.push('/chi-tiet-topic/' + addTopic?.id);
          }
        });
      }
    } catch (error) {}
  };

  return (
    <Card>
      <Form form={form} layout="vertical">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Tên chủ đề"
              rules={[{ required: true, message: 'Nhập tên chủ đề!' }]}
            >
              <Input placeholder="Nhập tên chủ đề" onMouseOut={onChangeTitle} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="slug"
              label="Đường dẫn"
              rules={[{ required: true, message: 'Nhập slug!' }]}
            >
              <Input
                suffix={
                  <Tooltip title="Khởi tạo đường dẫn">
                    <ScissorOutlined
                      style={{ fontSize: 16 }}
                      onClick={onGenerateSlug}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="type"
              label="Loại topic"
              rules={[{ required: true, message: 'Nhập loại topic!' }]}
            >
              <TopicTypeSelect
                disabled={isUpdate}
                onChange={type => setTopicType(type)}
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="intro" label="Giới thiệu">
              <Input.TextArea rows={7} placeholder="Nhập giới thiệu" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      {topicType === TOPIC_TYPE_VALUE.ALBUM ? (
        <AlbumOfTopic
          albums={topic?.albums}
          onSelect={ids => setAlbumIds(ids)}
        />
      ) : (
        <PlaylistOfTopic
          playlists={topic?.playlists}
          onSelect={ids => setPlaylistIds(ids)}
        />
      )}

      <Row justify="end">
        <Col>
          <Button onClick={onSave} type="primary">
            Lưu
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default TopicInfo;
