import { Typography } from 'antd';

import PlaylistList from './components/PlaylistList';
import PlaylistFilter from './PlaylistFilter';

const Playlist = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách playlist</Typography.Title>

      <PlaylistFilter />

      <PlaylistList />
    </div>
  );
};

export default Playlist;
