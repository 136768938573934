// import { useCallback } from 'react';
import {
  BoldOutlined,
  ItalicOutlined,
  StrikethroughOutlined,
  EnterOutlined,
  LinkOutlined,
  DisconnectOutlined
} from '@ant-design/icons';
import { Button, Divider, Tooltip } from 'antd';
import { useCallback } from 'react';
// import Unlink from 'components/Icons/Unlink';

const MenuBar = ({ editor }) => {
  const setLink = useCallback(() => {
    const previousUrl = editor.getAttributes('link').href;
    const url = window.prompt('URL', previousUrl);

    // cancelled
    if (url === null) {
      return;
    }

    // empty
    if (url === '') {
      editor.chain().focus().extendMarkRange('link').unsetLink().run();

      return;
    }

    // update link
    editor.chain().focus().extendMarkRange('link').setLink({ href: url }).run();
  }, [editor]);

  if (!editor) {
    return null;
  }

  return (
    <>
      <Tooltip title="In đậm">
        <Button
          type="text"
          icon={<BoldOutlined />}
          onClick={() => editor.chain().focus().toggleBold().run()}
          className={
            editor.isActive('bold')
              ? 'is-active editor-menu-item'
              : 'editor-menu-item'
          }
        />
      </Tooltip>

      <Tooltip title="In nghiêng">
        <Button
          type="text"
          icon={<ItalicOutlined />}
          onClick={() => editor.chain().focus().toggleItalic().run()}
          className={
            editor.isActive('italic')
              ? 'is-active editor-menu-item'
              : 'editor-menu-item'
          }
        />
      </Tooltip>

      <Tooltip title="Strike">
        <Button
          type="text"
          icon={<StrikethroughOutlined />}
          onClick={() => editor.chain().focus().toggleStrike().run()}
          className={
            editor.isActive('strike')
              ? 'is-active editor-menu-item'
              : 'editor-menu-item'
          }
        />
      </Tooltip>

      <Divider type="vertical" />

      {/* <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 1 }).run()}
        className={
          editor.isActive('heading', { level: 1 })
            ? 'is-active editor-menu-item'
            : 'editor-menu-item'
        }
      >
        H1
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        className={
          editor.isActive('heading', { level: 2 })
            ? 'is-active editor-menu-item'
            : 'editor-menu-item'
        }
      >
        H2
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        className={
          editor.isActive('heading', { level: 3 })
            ? 'is-active editor-menu-item'
            : 'editor-menu-item'
        }
      >
        H3
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 4 }).run()}
        className={
          editor.isActive('heading', { level: 4 })
            ? 'is-active editor-menu-item'
            : 'editor-menu-item'
        }
      >
        H4
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 5 }).run()}
        className={
          editor.isActive('heading', { level: 5 })
            ? 'is-active editor-menu-item'
            : 'editor-menu-item'
        }
      >
        H5
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 6 }).run()}
        className={
          editor.isActive('heading', { level: 6 })
            ? 'is-active editor-menu-item'
            : 'editor-menu-item'
        }
      >
        H6
      </Button> */}
      <Tooltip title="Xuống hàng">
        <Button
          type="text"
          icon={<EnterOutlined />}
          onClick={() => editor.chain().focus().setHardBreak().run()}
        />
      </Tooltip>
      <Divider type="vertical" />
      <Button
        icon={<LinkOutlined />}
        onClick={setLink}
        className={
          editor.isActive('link')
            ? 'is-active editor-menu-item'
            : 'editor-menu-item'
        }
      />
      <Button
        icon={<DisconnectOutlined />}
        onClick={() => editor.chain().focus().unsetLink().run()}
        disabled={!editor.isActive('link')}
        className="editor-menu-item"
      />
    </>
  );
};

export default MenuBar;
