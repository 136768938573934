import { Space, Typography, Tag } from 'antd';
import RemoveButton from 'components/RemoveButton';
import { useRemoveComment } from 'operations/mutations';
import { COMMENT_STATUS_CONTACT, COMMENT_STATUS_KEY_INT } from 'appConstants';
import 'moment/locale/vi';
import moment from 'moment';

const RemoveComment = ({ record }) => {
  const { mutate } = useRemoveComment();
  return <RemoveButton record={record} actionRemove={mutate} titleKey="name" />;
};
const generateColor = status => {
  switch (status) {
    case COMMENT_STATUS_KEY_INT.WAITING:
      return 'default';

    case COMMENT_STATUS_KEY_INT.APPROVED:
      return 'green';

    default:
      break;
  }
};

const StatusTag = ({ status, ...props }) => {
  return (
    <Tag color={generateColor(status)} {...props}>
      {COMMENT_STATUS_CONTACT[status]}
    </Tag>
  );
};

export const columns = [
  {
    title: 'Người dùng',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => (
      <Space direction="vertical">
        {record.clientFullName && (
          <Typography.Text>
            <Typography.Text strong>Tên: </Typography.Text>
            {record?.clientFullName}
          </Typography.Text>
        )}

        {record.clientEmail && (
          <Typography.Text>
            <Typography.Text strong>Email: </Typography.Text>
            {record.clientEmail}
          </Typography.Text>
        )}
        <StatusTag status={record.statusId} />
      </Space>
    ),
    fixed: 'left'
  },

  {
    title: 'Nội dung comment',
    dataIndex: 'content',
    render: (_, { content }) => (
      <Space direction="vertical">
        {content && <Typography.Text>{content}</Typography.Text>}
      </Space>
    )
  },
  {
    title: 'Loại Comment ',
    dataIndex: 'ItemType',
    render: (_, { itemType, itemTitle }) => (
      <Space direction="vertical">
        {itemType && <Typography.Text>{itemType}</Typography.Text>}
        {itemTitle && <Typography.Text>{itemTitle}</Typography.Text>}
      </Space>
    )
  },
  {
    title: 'Thông tin',
    dataIndex: 'related',
    render: (_, record) => (
      <Space direction="vertical">
        <Typography.Text>
          <Typography.Text strong>Ngày tạo: </Typography.Text>
          {record.crDateTime &&
            moment(record.crDateTime).format('HH:mm DD/MM/YYYY')}
        </Typography.Text>

        {record.updateTime && (
          <Typography.Text>
            <Typography.Text strong>Ngày sửa: </Typography.Text>
            {moment(record.updateTime).format('HH:mm DD/MM/YYYY')}
          </Typography.Text>
        )}
        {record.contentUpdate && (
          <Typography.Text>
            <Typography.Text strong>Nội dung sửa: </Typography.Text>
            {record.contentUpdate}
          </Typography.Text>
        )}
      </Space>
    )
  },
  {
    // title: 'Thao tác',
    width: '80px',
    render: (_, record) => <RemoveComment record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
