import { gql } from '@apollo/client';

export const GET_TOP_POPULAR = gql`
  query GetTopPopular(
    $skip: Int
    $take: Int
    $order: [NsThinhHanhSortInput!]
    $where: NsThinhHanhFilterInput
  ) {
    topPopular(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        itemId
        itemType
        order
        playlist {
          id
          name
          thumbnail
          status
          views
        }
        song {
          id
          title
          thumbnail
          status
          views
        }
        album {
          id
          title
          thumbnail
          status
          views
        }
      }
    }
  }
`;

export const GET_HOT_SONGS = gql`
  query GetHotSongs(
    $skip: Int
    $take: Int
    $order: [NsHmSongSortInput!]
    $where: NsHmSongFilterInput
  ) {
    hotSongs(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        sid
        itemType
        order
        song {
          id
          title
          thumbnail
          status
          views
          singers {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_TOP_BANNERS = gql`
  query GetTopBanners {
    topBanners {
      id
      title
      slug
      image
    }
  }
`;
