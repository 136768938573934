import { MenuOutlined } from '@ant-design/icons';
import { Space, Table, Typography } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const SortingList = ({ dataSource, setDataSource, loading }) => {
  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: 'grab',
        color: '#999'
      }}
    />
  ));

  const columns = [
    {
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Thứ tự',
      width: 80,
      align: 'center',
      className: 'drag-visible',
      render: (_, __, index) => (
        <Typography.Title level={2} style={{ margin: 0 }}>
          {index + 1}
        </Typography.Title>
      )
    },
    {
      title: 'Topic',
      dataIndex: 'title',
      className: 'drag-visible',
      render: (title, { slug }) => (
        <Space direction="vertical" size={0}>
          <Typography.Text strong>{title}</Typography.Text>
          <Typography.Text type="secondary">{slug}</Typography.Text>
        </Space>
      )
    }
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter(el => !!el);
      setDataSource(newData);
    }
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      x => x.index === restProps['data-row-key']
    );
    return (
      <SortableItem
        className="sorting-topic"
        style={{ zIndex: 999 }}
        index={index}
        {...restProps}
      />
    );
  };

  return (
    <Table
      loading={loading}
      showHeader={false}
      pagination={false}
      dataSource={dataSource}
      columns={columns}
      rowKey="index"
      components={{
        body: {
          wrapper: DraggableContainer,
          row: DraggableBodyRow
        }
      }}
    />
  );
};

export default SortingList;
