import SeoCommon from 'containers/SeoCommon/SeoCommon';
import { initParams } from '../util';

const SeoInfo = ({ song, editSong }) => {
  return (
    <SeoCommon
      initSeo={song?.metaSeo}
      initValues={initParams(song)}
      onUpdate={editSong}
    />
  );
};

export default SeoInfo;
