import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_TOPIC = gql`
  mutation EditTopic($input: EditTopicInput!) {
    editTopic(input: $input) {
      id
      title
    }
  }
`;

export const ADD_TOPIC = gql`
  mutation AddTopic($input: AddTopicInput!) {
    addTopic(input: $input) {
      id
      title
    }
  }
`;

export const REMOVE_TOPIC = gql`
  mutation RemoveTopic($id: Int!) {
    removeTopic(id: $id) {
      id
      title
    }
  }
`;

export const BULK_ACTION_TOPICS = gql`
  mutation BulkActionTopics($input: [BulkActionTopicInput]) {
    bulkActionTopics(input: $input) {
      id
      title
      status
    }
  }
`;

export const SORTING_TOPICS = gql`
  mutation SortingTopics($input: SortingTopicInput) {
    sortingTopics(input: $input) {
      id
      title
      sort
    }
  }
`;

export const useAddTopic = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_TOPIC, {
    onCompleted: ({ addTopic }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo topic{' '}
            <Typography.Text strong>{addTopic.title}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetTopics', 'getTopicById']
  });
  return { mutate, data, error, loading };
};

export const useEditTopic = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_TOPIC, {
    refetchQueries: ['GetTopics', 'getTopicById'],
    onCompleted: ({ editTopic }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa topic{' '}
            <Typography.Text strong>{editTopic.title}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useRemoveTopic = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_TOPIC, {
    refetchQueries: ['GetTopics'],
    onCompleted: ({ removeTopic }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá topic{' '}
            <Typography.Text strong>{removeTopic.title}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useBulkActionTopics = actionField => {
  const [mutate, { data, error }] = useMutation(BULK_ACTION_TOPICS, {
    refetchQueries: ['GetTopics'],
    onCompleted: ({ bulkActionTopics }) => {
      getBulkMessage('Topic', bulkActionTopics, actionField);
    }
  });
  return { mutate, data, error };
};

export const useSortingTopic = () => {
  const [mutate, { data, error, loading }] = useMutation(SORTING_TOPICS, {
    onCompleted: ({ sortingTopics }) => {
      notification.success({
        message: <Typography.Text>Sắp xếp topic thành công</Typography.Text>
      });
    }
    // refetchQueries: ['GetTopics']
  });
  return { mutate, data, error, loading };
};
