import React from 'react';
import { Select } from 'antd';
import { DELETE_INT_DICTIONARY } from 'appConstants';

const DeleteSelect = props => {
  return (
    <Select placeholder="Chọn loại xoá" {...props} allowClear>
      {Object.keys(DELETE_INT_DICTIONARY).map(key => (
        <Select.Option value={key} key={key}>
          {DELETE_INT_DICTIONARY[key]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default DeleteSelect;
