import { Collapse, Typography } from 'antd';

import React from 'react';

import SongList from './components/SongList';
import SongFilter from './SongFilter';

const Song = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách bài hát</Typography.Title>

      <Collapse defaultActiveKey={['1']} style={{ marginBottom: 16 }}>
        <Collapse.Panel header="Bộ lọc bài hát" key="1">
          <SongFilter />
        </Collapse.Panel>
      </Collapse>

      <SongList />
    </div>
  );
};

export default Song;
