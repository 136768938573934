import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_PARTNER = gql`
  mutation EditPartner($input: EditPartnerInput) {
    editPartner(input: $input) {
      id
    }
  }
`;

export const ADD_PARTNER = gql`
  mutation AddNewPartner($addPartnerInput: AddPartnerInput!) {
    addPartner(input: $addPartnerInput) {
      id
    }
  }
`;

export const REMOVE_PARTNER = gql`
  mutation RemovePartner($id: Int!) {
    removePartner(id: $id) {
      id
      name
    }
  }
`;

export const BULK_ACTION_PARTNERS = gql`
  mutation BulkActionPartners($input: [BulkActionPartnerInput]) {
    bulkActionPartners(input: $input) {
      id
      status
      isDeleted
    }
  }
`;

export const useAddPartner = () => {
  const [mutate, { data, error }] = useMutation(ADD_PARTNER, {
    refetchQueries: ['GetPartners'],
    onCompleted: ({ addPartner }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo đối tác{' '}
            <Typography.Text strong>{addPartner.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useEditPartner = () => {
  const [mutate, { data, error }] = useMutation(EDIT_PARTNER, {
    refetchQueries: ['GetPartners'],

    onCompleted: ({ editPartner }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa đối tác{' '}
            <Typography.Text strong>{editPartner.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useRemovePartner = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_PARTNER, {
    refetchQueries: ['GetPartners'],
    onCompleted: ({ removePartner }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá đối tác{' '}
            <Typography.Text strong>{removePartner.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useBulkPartners = actionField => {
  const [mutate, { data, error }] = useMutation(BULK_ACTION_PARTNERS, {
    refetchQueries: ['GetPartners'],
    onCompleted: ({ bulkActionPartners }) => {
      getBulkMessage('Đối tác', bulkActionPartners, actionField);
    }
  });

  return { mutate, data, error };
};
