import { Button, Space, Tooltip, Typography } from 'antd';

import RelatedUser from 'components/RelatedUser';
import RemoveButton from 'components/RemoveButton';

import TimeLine from 'components/TimeLine';

import 'moment/locale/vi';

import { BellOutlined } from '@ant-design/icons';
import ColName from 'components/ColName';
import SendingStatusTag from 'components/SendingStatusTag';
import StatusTag from 'components/StatusTag';
import {
  useRemoveAppNotification,
  useSendAppNotification
} from 'operations/mutations';
import { Link } from 'react-router-dom';

const ActionButtons = ({ record }) => {
  const { mutate: removeNotification } = useRemoveAppNotification();
  const { mutate: sendNotification } = useSendAppNotification();
  const onSend = async () => {
    try {
      await sendNotification({
        variables: {
          id: parseInt(record.id)
        }
      });
    } catch (error) {}
  };
  return (
    <Space>
      <Tooltip title="Gửi thông báo">
        <Button size="small" onClick={onSend} icon={<BellOutlined />}></Button>
      </Tooltip>

      <RemoveButton
        record={record}
        actionRemove={removeNotification}
        titleKey="title"
      />
    </Space>
  );
};

export const columns = [
  {
    title: 'Thông báo',
    dataIndex: 'title',
    key: 'title',
    render: (_, record) => (
      <ColName
        primary={
          <Link
            style={{ color: 'inherit' }}
            to={`/chi-tiet-app-notification/${record?.id}`}
          >
            {record?.title}
          </Link>
        }
        secondary={record?.singers?.map(singer => singer?.name).join(', ')}
        avatar={{ image: record?.image, name: record?.title }}
        tag={<StatusTag status={record?.approvingStatus} />}
      />
    ),
    fixed: 'left',
    width: '160px'
  },
  {
    title: 'Nội dung',
    dataIndex: 'body',
    render: body => <Typography.Text>{body}</Typography.Text>,
    width: '100px'
  },

  {
    title: 'trạng thái gửi',
    dataIndex: 'sendingStatus',
    render: sendingStatus => <SendingStatusTag status={sendingStatus} />,
    width: '100px'
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '120px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },

  {
    title: 'Thông tin',
    dataIndex: 'related',
    width: '120px',
    render: (_, { createdBy, updatedBy }) => (
      <RelatedUser creator={createdBy} updater={updatedBy} />
    )
  },
  {
    title: 'Thao tác',
    width: '50px',
    render: (_, record) => <ActionButtons record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
