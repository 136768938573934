export const initParams = video => {
  if (!video) return;
  const params = {
    id: video.id,
    isMedley: video.isMedley,
    title: video.title,
    slug: video.slug,
    singerIds: video.singers.map(singer => singer?.id),
    cateIds: video.categories.map(category => category?.id),
    tagIds: video.tags.map(tag => tag?.id),
    musicianIds: video.musicians.map(musician => musician?.id)
  };
  return params;
};
