import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Divider,
  message,
  Row,
  Space,
  Table,
  Typography
} from 'antd';
import { arrayMoveImmutable } from 'array-move';
import UserAvatar from 'components/UserAvatar';
import { useBulkAddVideoRelated } from 'operations/mutations';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import CopyRelatedVideo from './CopyRelatedVideo';
import VideoSearch from './VideoSearch';

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const RelatedVideos = ({ videoName, videos }) => {
  const { id } = useParams();
  const [dataSource, setDataSource] = useState(videos || []);
  const { mutate: updateVideoRelated } = useBulkAddVideoRelated();
  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: 'grab',
        color: '#999'
      }}
    />
  ));

  const onClearVideo = id => {
    const newData = dataSource.filter(_d => _d.id !== id);
    setDataSource(newData);
  };

  const columns = [
    {
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Thứ tự',
      width: 80,
      align: 'center',
      render: (_, __, index) => (
        <Typography.Title level={2} style={{ margin: 0 }}>
          {index + 1}
        </Typography.Title>
      )
    },
    {
      title: 'Video',
      dataIndex: 'title',
      className: 'drag-visible',
      render: (title, { id, thumbnail }) => (
        <Space>
          <UserAvatar
            fullName={title}
            avatar={thumbnail}
            shape="square"
            size={48}
          />
          <Link target="_blank" to={`/chi-tiet-bai-hat/${id}`}>
            {title}
          </Link>
        </Space>
      )
    },
    {
      title: 'Ca sĩ',
      dataIndex: 'singers',
      render: singers => (
        <Space direction="vertical">
          {singers?.map(singer => (
            <Typography.Text key={singer?.id}>{singer?.name}</Typography.Text>
          ))}
        </Space>
      )
    },
    {
      title: 'Nhạc sĩ',
      dataIndex: 'musicians',
      render: musicians => (
        <Space direction="vertical">
          {musicians?.map(musician => (
            <Typography.Text key={musician?.id}>
              {musician?.name}
            </Typography.Text>
          ))}
        </Space>
      )
    },
    {
      title: 'Thể loại',
      dataIndex: 'categories',
      render: categories => (
        <Space direction="vertical">
          {categories?.map(cate => (
            <Typography.Text key={cate?.id}>{cate?.name}</Typography.Text>
          ))}
        </Space>
      )
    },
    {
      dataIndex: 'id',
      width: 50,
      render: id => (
        <Button
          onClick={() => onClearVideo(id)}
          size="small"
          danger
          icon={<DeleteOutlined />}
        ></Button>
      )
    }
  ];

  useEffect(() => {
    if (!!videos?.length) {
      const _videos = videos.map((video, index) => ({
        ...video,
        index,
        key: video.id
      }));
      setDataSource(_videos);
    }
  }, [videos]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter(el => !!el);
      setDataSource(newData);
    }
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      x => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const onAddVideoToPlaylist = video => {
    if (!video) return;
    const isExist = !!dataSource.find(_data => _data.id === video.id);
    if (isExist) {
      return message.error('Video đã tồn tại trong danh sách');
    }
    setDataSource(dataSource => [
      ...dataSource,
      { ...video, index: dataSource?.length }
    ]);
    return message.success('Đã thêm video vào danh sách');
  };

  const onUpdate = async () => {
    try {
      const videoIds = dataSource?.map(({ id }) => id);
      await updateVideoRelated({
        variables: {
          input: {
            videoId: parseInt(id),
            relatedVideoIds: videoIds
          }
        }
      });
    } catch (error) {}
  };

  return (
    <Card className="mb-md">
      <div className="mb-md">
        <Row justify="space-between" className="mb-md">
          <Space>
            <Typography.Title level={4}>Video liên quan</Typography.Title>
            <Divider type="vertical" style={{ height: '24px' }} />
            <Typography.Title level={4} type="success">
              {videoName}
            </Typography.Title>
          </Space>
          <Space>
            <CopyRelatedVideo dataSource={dataSource} />
            <Button type="primary" onClick={onUpdate}>
              Cập nhật
            </Button>
          </Space>
        </Row>
        <VideoSearch onSelect={onAddVideoToPlaylist} />
      </div>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }}
      />
    </Card>
  );
};

export default RelatedVideos;
