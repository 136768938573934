import { hot } from 'react-hot-loader';

import MainLayout from 'layouts/MainLayout';
import './App.less';

function App() {
  return <MainLayout />;
}

export default hot(module)(App);

// #################################################################
// #                             _`				                         #
// #                          _ooOoo_			                      	 #
// #                         o8888888o		                      	 #
// #                         88" . "88			                       #
// #                         (| -_- |)			                       #
// #                         O\  =  /O			                       #
// #                      ____/`---'\____				                   #
// #                    .'  \\|     |//  `.		                     #
// #                   /  \\|||  :  |||//  \		                 	 #
// #                  /  _||||| -:- |||||_  \		                 	 #
// #                  |   | \\\  -  /'| |   |		                 	 #
// #                  | \_|  `\`---'//  |_/ |		                 	 #
// #                  \  .-\__ `-. -'__/-.  /		                 	 #
// #                ___`. .'  /--.--\  `. .'___		                 #
// #             ."" '<  `.___\_<|>_/___.' _> \"".		           	 #
// #            | | :  `- \`. ;`. _/; .'/ /  .' ; |		             #
// #            \  \ `-.   \_\_`. _.'_/_/  -' _.' /		             #
// #=============`-.`___`-.__\ \___  /__.-'_.'_.-'=================#
//                            `=--=-'
