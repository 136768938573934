import { Typography } from 'antd';

import ContactList from './components/ContactList';

const Contact = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Báo cáo gỡ link nhúng YouTube</Typography.Title>

      <ContactList />
    </div>
  );
};

export default Contact;
