import React from 'react';
import { Tag } from 'antd';
import {
  SENDING_STATUS_INT_DICTIONARY,
  SENDING_STATUS_KEY_INT
} from 'appConstants';

const generateColor = status => {
  switch (status) {
    case SENDING_STATUS_KEY_INT.WAITING:
      return 'default';

    case SENDING_STATUS_KEY_INT.SENT:
      return 'green';

    default:
      break;
  }
};

const SendingStatusTag = ({ status, ...props }) => {
  return (
    <Tag color={generateColor(status)} {...props}>
      {SENDING_STATUS_INT_DICTIONARY[status]}
    </Tag>
  );
};

export default SendingStatusTag;
