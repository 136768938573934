import { useQuery } from '@apollo/client';
import { Button, Space } from 'antd';

import { ORDER_BY_DICTIONARY, PAGE_SIZE, TABLE_PROPS } from 'appConstants';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import { GET_PAGE_INFOS } from 'operations/queries';

import { PlusOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { columns } from '../columns';

const PageInfoList = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [pageInfos, setPageInfos] = useState([]);
  const [total, setTotal] = useState();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const pageSize = useMemo(
    () => parseInt(searchParams.pageSize) || PAGE_SIZE,
    [searchParams.pageSize]
  );

  const {
    loading: isPageInfosLoading,
    error: pageInfosError,
    data: pageInfosConnect
  } = useQuery(GET_PAGE_INFOS, {
    variables: {
      take: pageSize,
      skip: pageSize * page - pageSize,
      order: [ORDER_BY_DICTIONARY[sort].value]
    },
    fetchPolicy: 'no-cache'
  });

  const isLoading = useMemo(() => isPageInfosLoading, [isPageInfosLoading]);

  useEffect(() => {
    if (!isPageInfosLoading && pageInfosConnect) {
      const _data = pageInfosConnect?.pageInfoContacts?.items?.map(item => ({
        ...item,
        key: item.id
      }));
      setPageInfos(_data);
      setTotal(pageInfosConnect?.pageInfoContacts?.totalCount);
    }
  }, [isPageInfosLoading, pageInfosConnect]);

  const onPaginate = (page, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, page, pageSize })
    });
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({
        ...searchParams,
        page: 1,
        sort,
        pageSize
      })
    });
  };

  const onShowSizeChange = (_, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, pageSize })
    });
  };

  if (pageInfosError) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          <SortSelect value={sort} onChange={setOrderBy} />
          <Link to={'/chi-tiet-thong-tin-trang'}>
            <Button icon={<PlusOutlined />} type="primary">
              Thêm
            </Button>
          </Link>
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={pageInfos}
        pagination={{
          total,
          pageSize: pageSize,
          onChange: onPaginate,
          current: page * 1,
          quantity: pageInfos?.length,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          onShowSizeChange: onShowSizeChange
        }}
        loading={isLoading}
        // rowSelection={rowSelection}
      />
    </ListCard>
  );
};

export default PageInfoList;
