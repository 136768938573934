import { useQuery } from '@apollo/client';
import { Button, Card, Result, Space } from 'antd';

import {
  BULK_DATA_KEY,
  COMMON_STATUS_KEY_BY_INT,
  COMMON_STATUS_KEY_INT,
  ORDER_BY_DICTIONARY,
  PAGE_SIZE,
  TABLE_PROPS
} from 'appConstants';
import ApproveButton from 'components/ApproveButton';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import { useBulkActionTopics } from 'operations/mutations';
import { GET_TOPICS } from 'operations/queries';

import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { processQueryFilerParams } from 'utils/filter';
import { isObjEmpty } from 'utils/lodash';
import { columns } from '../columns';
import SortingTopic from './SortingTopic';
const TopicList = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [topics, setTopics] = useState([]);
  const [total, setTotal] = useState([]);

  const { mutate: updateStatus, loading: isUpdateLoading } =
    useBulkActionTopics(BULK_DATA_KEY.STATUS);

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const filterQuery = useMemo(() => {
    let additonalQueries = {};
    const processedSearchParams = processQueryFilerParams(searchParams);

    if (!processedSearchParams) return null;

    if (processedSearchParams?.topicCates) {
      processedSearchParams.topicCates = {
        some: {
          cateId: {
            in: processedSearchParams?.topicCates
          }
        }
      };
    }

    const finalQuery = { ...processedSearchParams, ...additonalQueries };
    return isObjEmpty(finalQuery) ? null : finalQuery;
  }, [searchParams]);

  const {
    loading: isTopicsLoading,
    error: topicsError,
    data: topicsConnect
  } = useQuery(GET_TOPICS, {
    variables: {
      take: PAGE_SIZE,
      skip: PAGE_SIZE * page - PAGE_SIZE,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!isTopicsLoading && topicsConnect) {
      const _data = topicsConnect?.topics?.items?.map(item => ({
        ...item,
        key: item.id
      }));
      setTopics(_data);
      setTotal(topicsConnect?.topics?.totalCount);
    }
  }, [topicsConnect, isTopicsLoading]);

  const onPaginate = page => {
    history.push({
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const rowSelection = {
    selectedRowKeys: selectedRows.map(({ id }) => id),
    onChange: (_, selectedRows) => {
      setSelectedRows(selectedRows);
    }
  };

  const status = useMemo(() => {
    return selectedRows.some(
      _row => _row.status === COMMON_STATUS_KEY_INT.WAITING
    )
      ? COMMON_STATUS_KEY_INT.APPROVED
      : COMMON_STATUS_KEY_INT.WAITING;
  }, [selectedRows]);

  const handleChangeStatus = async () => {
    try {
      const params = selectedRows.map(({ id }) => ({
        id,
        status: COMMON_STATUS_KEY_BY_INT[status]
      }));
      await updateStatus({
        variables: {
          input: params
        }
      });
      setSelectedRows([]);
    } catch (error) {}
  };

  if (topicsError)
    return (
      <Card>
        <Result
          status="500"
          // title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      </Card>
    );
  // if (!topicsConnect) return <div>None</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          {!!selectedRows.length && (
            <>
              <ApproveButton
                status={status}
                onClick={handleChangeStatus}
                total={
                  selectedRows?.filter(_item => _item.status !== status)?.length
                }
              />
            </>
          )}
          {/* <Button onClick={() => setIsSorting(isSorting => !isSorting)}>
            Sắp xếp Topic
          </Button> */}
          <SortingTopic />
          <SortSelect value={sort} onChange={setOrderBy} />
          <Link to={'/chi-tiet-topic'}>
            <Button type="primary">Thêm</Button>
          </Link>
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1500 }}
        columns={columns}
        dataSource={topics}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: topics?.length
        }}
        loading={isTopicsLoading || isUpdateLoading}
        rowSelection={rowSelection}
      />
    </ListCard>
  );
};

export default TopicList;
