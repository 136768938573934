import { DeleteOutlined } from '@ant-design/icons';
import { Button, Typography } from 'antd';
import ColName from 'components/ColName';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import RelatedUser from 'components/RelatedUser';
import TimeLine from 'components/TimeLine';
import 'moment/locale/vi';
import { useRemoveRole } from 'operations/mutations';
import { Link } from 'react-router-dom';

const RemoveRole = ({ record }) => {
  const { mutate } = useRemoveRole();
  return (
    <DeleteConfirmPopup
      onDelete={() =>
        mutate({
          variables: {
            input: {
              id: parseInt(record.id)
            }
          }
        })
      }
      title={
        <Typography.Text>
          Bạn muốn xoá <Typography.Text strong>{record?.title}</Typography.Text>{' '}
          ?
        </Typography.Text>
      }
    >
      <Button danger type="primary" size="small" icon={<DeleteOutlined />} />
    </DeleteConfirmPopup>
  );
};

export const columns = [
  {
    title: 'Vai trò',
    dataIndex: 'title',
    key: 'title',
    render: (_, record) => (
      <ColName
        primary={
          <Link
            style={{ color: 'inherit' }}
            to={`/chi-tiet-vai-tro/${record?.id}`}
          >
            {record?.title}
            {/* {<EditOutlined />} */}
          </Link>
        }
        // secondary={record?.uniqId}
        // avatar={{ image: record?.thumbnail, name: record?.title }}
        // tag={<StatusTag status={record?.status} />}
      />
    ),
    fixed: 'left',
    width: '120px'
  },
  {
    title: 'Mô tả',
    dataIndex: 'description',
    key: 'description',
    width: '120px',
    render: description => <Typography.Text>{description}</Typography.Text>
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '80px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    width: '80px',
    render: (_, { createdBy, updatedBy }) => (
      <RelatedUser creator={createdBy} updater={updatedBy} />
    )
  },
  {
    title: 'Thao tác',
    width: '50px',
    render: (_, record) => <RemoveRole record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
