import slugify from 'slugify';

export const sanitizeSlug = value => {
  if (!value) return;
  const slug = slugify(value, { lower: true, locale: 'vi' });

  return slug;
};

export const convertObjToIntValue = (initValue, keys) => {
  // Magic 🎆
  const listkeys = Object.keys(initValue);
  const arrIntValues = listkeys.filter(key => {
    return !!keys.find(intKey => {
      return key.includes(intKey);
    });
  });

  const _objFilter = arrIntValues.map(key => {
    const value = initValue[key];
    return {
      [key]: Array.isArray(value)
        ? value?.map(_v => parseInt(_v))
        : parseInt(value)
    };
  });

  const _obj = _objFilter.reduce((accumulator, value) => {
    return { ...accumulator, ...value };
  }, {});

  return _obj;
};

export const generateSongLabel = song => {
  const { singers, title } = song;
  const singerLabel = singers.map(({ name }) => name).join(', ');
  return `${title} - ( ${singerLabel} )`;
};
