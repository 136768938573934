import { ScissorOutlined } from '@ant-design/icons';
import { Button, Card, Col, DatePicker, Form, Input, Row, Tooltip } from 'antd';
import {
  CATEGORY_TYPE,
  DATE_FORMAT,
  DEBOUNCE_VALUE,
  TIME_FORMAT,
  UPLOAD_TYPE
} from 'appConstants';
import ArtistAutocomplete from 'components/ArtistAutocomplete';
import CategoryAutocomplete from 'components/CategoryAutocomplete';
import ImageUpload from 'components/ImageUpload';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { debounce, get } from 'utils/lodash';
import { sanitizeSlug } from 'utils/parser';
import SongOfAlbum from './SongOfAlbum';

const AlbumInfo = ({ album, isUpdate, editAlbum, addAlbum }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const [songIds, setSongIds] = useState([]);

  useEffect(() => {
    if (album) {
      form.setFieldsValue({
        title: album.title,
        slug: album.slug,
        intro: album.intro,
        producer: album.producer,
        thumbnail: album.thumbnail,
        thumbnailSocial: album.thumbnailSocial,
        cateIds: album.categories.map(category => category?.id),
        artistIds: album.artists.map(artist => artist?.id),
        publicDate: album.publicDate && moment(album.publicDate)
      });
      setSongIds(album?.songs?.map(song => song.id));
    }
  }, [album, form]);

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const onChangeTitle = debounce(() => {
    const { title, slug } = form.getFieldsValue();
    if (!slug) {
      const _slug = sanitizeSlug(title);
      form.setFieldsValue({ slug: _slug });
    }
  }, DEBOUNCE_VALUE);

  const onGenerateSlug = () => {
    const { title } = form.getFieldsValue();
    if (!title) return;
    const _slug = sanitizeSlug(title);
    form.setFieldsValue({ slug: _slug });
  };

  const onUpdateSong = songs => {
    setSongIds(songs);
  };

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const thumbnail = get(
        values.thumbnail,
        '[0].response.Data',
        album?.thumbnail
      );
      const thumbnailSocial = get(
        values.thumbnailSocial,
        '[0].response.Data',
        album?.thumbnailSocial
      );

      const params = {
        ...values,
        songIds,
        thumbnail,
        thumbnailSocial
      };

      delete params.urlFile;

      if (isUpdate) {
        await editAlbum({
          variables: {
            input: {
              id: parseInt(id),
              songIds: album.songs.map(song => song?.id),
              ...params
            }
          }
        });
      } else {
        await addAlbum({
          variables: {
            input: {
              ...params
            }
          },
          onCompleted: ({ addAlbum }) => {
            history.push('/chi-tiet-album/' + addAlbum?.id);
          }
        });
      }
    } catch (error) {}
  };

  return (
    <Card>
      <Form form={form} layout="vertical">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Tên album"
              rules={[{ required: true, message: 'Nhập tên album!' }]}
            >
              <Input placeholder="Nhập tên album" onMouseOut={onChangeTitle} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="slug"
              label="Slug"
              rules={[{ required: true, message: 'Nhập slug!' }]}
            >
              <Input
                suffix={
                  <Tooltip title="Generate slug">
                    <ScissorOutlined
                      style={{ fontSize: 16 }}
                      onClick={onGenerateSlug}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="cateIds"
              label="Thể loại"
              rules={[{ required: true, message: 'Chọn thể loại!' }]}
            >
              <CategoryAutocomplete
                allowClear
                category={album?.categories}
                type={CATEGORY_TYPE.ALBUM}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="artistIds"
              label="Nghệ sĩ"
              rules={[{ required: true, message: 'Chọn nghệ sĩ!' }]}
            >
              <ArtistAutocomplete allowClear artist={album?.artists} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="publicDate"
              label="Thời gian đăng"
              rules={[{ required: true, message: 'Chọn thời gian đăng!' }]}
            >
              <DatePicker
                showTime
                style={{ width: '100%' }}
                format={DATE_FORMAT + ' ' + TIME_FORMAT}
                placeholder="Chọn thời gian đăng"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="producer" label="Nhà sản xuất">
              <Input placeholder="Nhập nhà sản xuất" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item name="intro" label="Giới thiệu album">
              <Input.TextArea rows={7} placeholder="Nhập giới thiệu" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="thumbnail"
              label="Thumbnail (max 500KB)"
              valuePropName="file"
              getValueFromEvent={normFile}
              rules={[{ required: true, message: 'Chọn thumbnail!' }]}
            >
              <ImageUpload
                avatarShape="square"
                size={128}
                fontSize={64}
                fullName="Thumbnail"
                {...UPLOAD_TYPE.THUMBNAIL_ALBUM}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="thumbnailSocial"
              label="Share thumbnail (max 500KB)"
              valuePropName="file"
              getValueFromEvent={normFile}
            >
              <ImageUpload
                avatarShape="square"
                size={128}
                fontSize={64}
                fullName="Share thumbnail"
                freeStyle={isUpdate}
                {...UPLOAD_TYPE.SHARE_ALBUM}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <SongOfAlbum songs={album?.songs} onSelect={onUpdateSong} />

      <Row justify="end">
        <Col>
          <Button onClick={onSave} type="primary">
            Lưu
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default AlbumInfo;
