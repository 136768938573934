import { Typography } from 'antd';
import ColName from 'components/ColName';
import RelatedUser from 'components/RelatedUser';
import RemoveButton from 'components/RemoveButton';
import StatusTag from 'components/StatusTag';
import TimeLine from 'components/TimeLine';
import 'moment/locale/vi';
import { useRemoveCategory } from 'operations/mutations';
import { Link } from 'react-router-dom';

const RemoveCategory = ({ record }) => {
  const { mutate } = useRemoveCategory();
  return (
    <RemoveButton record={record} actionRemove={mutate} titleKey="title" />
  );
};

export const columns = [
  {
    title: 'Chuyên mục',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => (
      <ColName
        primary={
          <Link
            style={{ color: 'inherit' }}
            to={`/chi-tiet-chuyen-muc/${record?.id}`}
          >
            {record?.name}
          </Link>
        }
        secondary={record?.uniqId}
        avatar={{ image: record?.thumbnail, name: record?.name }}
        tag={<StatusTag status={record?.status} />}
      />
    ),
    fixed: 'left',
    width: '120px'
  },
  {
    title: 'Thể loại',
    dataIndex: 'typeName',
    width: '40px',
    render: typeName => <Typography.Text>{typeName}</Typography.Text>
  },
  {
    title: 'Nhóm',
    dataIndex: 'group',
    width: '40px',
    render: group => <Typography.Text>{group?.name}</Typography.Text>
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '80px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    width: '80px',
    render: (_, { createdBy, updatedBy }) => (
      <RelatedUser creator={createdBy} updater={updatedBy} />
    )
  },
  {
    title: 'Thao tác',
    width: '50px',
    render: (_, record) => <RemoveCategory record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
