import { gql } from '@apollo/client';

export const GET_CONTACT = gql`
query GetContacts(
    $skip: Int, 
    $take: Int, 
    $order: [ContactSortInput!], 
    $where: ContactFilterInput) {
    contacts(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        status
        createdDate
        fullName
        question
        problemId
        phone
        email
        createDate
        answer
        filePath
        __typename
      }
      __typename
    }
  }
`;