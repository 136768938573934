import { useQuery } from '@apollo/client';
import LoginHistoryList from 'components/LoginHistoryList';
import 'moment/locale/vi';
import { GET_LOGGIN_LOG } from 'operations/queries/queryCurrentUser';
import React, { useMemo } from 'react';

const LoginHistory = ({ userId }) => {
  const { data } = useQuery(GET_LOGGIN_LOG, {
    skip: !userId,
    variables: {
      id: userId
    }
  });

  const list = useMemo(
    () => data?.loginAttemptByUserId || [],
    [data?.loginAttemptByUserId]
  );

  return <LoginHistoryList loggingHistory={list} />;
};

export default LoginHistory;
