import React from 'react';
import { Select } from 'antd';
import { USER_STATUS_DICTIONARY } from 'appConstants';

const UserStatusSelect = ({
  filter = () => true,
  value,
  disableSelected,
  ...props
}) => {
  return (
    <Select
      style={{ width: 200, fontWeight: 'normal' }}
      placeholder="Chọn trạng thái"
      value={value}
      allowClear
      {...props}
    >
      {Object.keys(USER_STATUS_DICTIONARY)
        .filter(filter)
        .map(key => (
          <Select.Option
            key={key}
            value={key}
            disabled={disableSelected ? value === key : false}
          >
            {USER_STATUS_DICTIONARY[key]}
          </Select.Option>
        ))}
    </Select>
  );
};

export default UserStatusSelect;
