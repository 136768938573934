import { Button, Col, Form, Input, Row, Spin } from 'antd';
import MetaCard from 'components/MetaCard';
import { useUpdateProfile } from 'operations/mutations/user/mutateUser';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import { useEffect } from 'react';

const PersonalInfo = () => {
  const [form] = Form.useForm();
  const { mutate: updateProfile, loading: isUpdateLoading } =
    useUpdateProfile();
  const { data, loading: isGetUserLoading } = useCurrentUser();
  const currentUser = data?.detail;

  const onSave = async () => {
    try {
      const formParams = await form.validateFields();

      const { fullName } = formParams;

      await updateProfile({ variables: { input: { fullName } } });
    } catch (e) {}
  };

  useEffect(() => {
    if (currentUser) {
      const {
        email,
        fullName,
        roleName: { title },
        phongBan: { name }
      } = currentUser;
      form.setFieldsValue({
        email,
        role: title,
        fullName,
        phongBan: name
      });
    }
  }, [form, currentUser]);

  return (
    <MetaCard title="Thông tin cá nhân">
      <Spin spinning={isGetUserLoading || isUpdateLoading}>
        <Form layout="vertical" form={form}>
          <Row gutter={32}>
            <Col span={12}>
              <Form.Item label="Họ tên" name="fullName">
                <Input />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Email" name="email">
                <Input disabled />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item label="Vai trò" name="role">
                <Input disabled />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item label="Phòng ban" name="phongBan">
                <Input disabled />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end" align="middle">
            {/* <Tag color="processing" icon={<InfoCircleOutlined />}>
              Chỉ có thể thay đổi những thông tin được thao tác.
            </Tag> */}

            <Button onClick={onSave} type="primary">
              Cập nhật
            </Button>
          </Row>
        </Form>
      </Spin>
    </MetaCard>
  );
};

export default PersonalInfo;
