import { PLAYLIST_TYPE_VALUE } from 'appConstants';
import SeoCommon from 'containers/SeoCommon/SeoCommon';
import { initParams } from '../util';

const SeoInfo = ({ playlist, editPlaylistForSong, editPlaylistForVideo }) => {
  return (
    <SeoCommon
      initSeo={playlist?.metaSeo}
      initValues={initParams(playlist)}
      onUpdate={
        playlist?.typeName === PLAYLIST_TYPE_VALUE.SONG
          ? editPlaylistForSong
          : editPlaylistForVideo
      }
    />
  );
};

export default SeoInfo;
