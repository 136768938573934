import { Typography } from 'antd';
import React from 'react';
import UserList from './components/UserList';
import UserFilter from './UserFilter';
import UserForm from './UserForm';
import UserProvider from './UserProvider';
import UserAuditLog from './UserAuditLog';
import ChangeUserPassword from './ChangeUserPassword';

const User = () => {
  return (
    <UserProvider>
      <div className="spacing-container">
        <Typography.Title>Quản lý User</Typography.Title>

        <UserFilter />

        <UserList />

        <UserForm />

        <UserAuditLog />

        <ChangeUserPassword />
      </div>
    </UserProvider>
  );
};

export default User;
