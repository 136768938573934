import { useQuery } from '@apollo/client';
import { Select } from 'antd';
import { PAGE_SIZE_MAX } from 'appConstants';
import { GET_DEPARTMENTS } from 'operations/queries';

const DepartmentSelect = props => {
  const { data, loading: fetchLoading } = useQuery(GET_DEPARTMENTS, {
    variables: { take: PAGE_SIZE_MAX }
  });

  return (
    <Select
      placeholder="Chọn phòng ban"
      options={data?.phongBans?.items}
      fieldNames={{ label: 'name', value: 'id' }}
      loading={fetchLoading}
      {...props}
    />
  );
};

export default DepartmentSelect;
