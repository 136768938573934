import { useLazyQuery } from '@apollo/client';
import AutoComplete from 'components/AutoComplete';
import { GET_TAGS } from 'operations/queries/queryTag';
import React, { useMemo } from 'react';

const TagAutocomplete = ({ tag, ...props }) => {
  const [searchTag] = useLazyQuery(GET_TAGS);
  const defaultOption = useMemo(
    () =>
      tag
        ? Array.isArray(tag)
          ? tag.map(({ id, name }) => ({ value: id, label: name }))
          : { value: tag.id, label: tag.name }
        : null,
    [tag]
  );

  return (
    <AutoComplete
      mode="multiple"
      defaultOption={defaultOption}
      handleSearch={searchTag}
      labelKey="name"
      valueKey="id"
      dataKey="tags"
      placeholder="Tìm tag"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default TagAutocomplete;
