import { gql } from '@apollo/client';
export const GET_ALL_TOPICS = gql`
  query GetAllTopics($skip: Int, $take: Int) {
    topics(skip: $skip, take: $take) {
      totalCount
      items {
        id
        title
      }
    }
  }
`;

export const GET_TOPICS = gql`
  query GetTopics(
    $skip: Int
    $take: Int
    $order: [TopicSortInput!]
    $where: TopicFilterInput
  ) {
    topics(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        title
        status
        slug
        type
        typeName
        createdBy
        updatedBy
        createdDate
        updatedDate
      }
    }
  }
`;

export const GET_TOPIC_BY_ID = gql`
  query getTopicById($id: Int!) {
    topic(id: $id) {
      id
      title
      status
      slug
      intro
      type
      typeName
      playlists {
        id
        name
        thumbnail
      }
      albums {
        id
        title
        thumbnail
      }
    }
  }
`;
