/* eslint-disable no-loop-func */
import {
  ApolloClient,
  createHttpLink,
  from,
  fromPromise
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { notification, Typography } from 'antd';
import { DEFAULT_ERROR_MESSAGE } from 'appConstants';
import { REFRESH_TOKEN_MUTATION } from 'operations/mutations/auth/mutateAuth';
import {
  getAccessToken,
  getRefreshToken,
  setAccessToken,
  setRefreshToken,
  removeRefreshToken,
  removeAccessToken
} from 'utils/authority';
import { cache } from './cache';

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_GRAPHQL_API
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getAccessToken();
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  };
});

const getNewToken = () => {
  if (getAccessToken() && getRefreshToken()) {
    return apolloClient
      .mutate({
        mutation: REFRESH_TOKEN_MUTATION,
        variables: {
          input: {
            accessToken: getAccessToken(),
            refreshToken: getRefreshToken()
          }
        }
      })
      .then(response => {
        const { accessToken, refreshToken } = response.data.refreshToken;
        setRefreshToken(refreshToken);
        setAccessToken(accessToken);
        return accessToken;
      })
      .catch(error => {
        removeRefreshToken();
        removeAccessToken();
        window.location.href = window.location.origin + '/dang-nhap';
      });
  }
};

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = () => {
  pendingRequests.map(callback => callback());
  pendingRequests = [];
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (let err of graphQLErrors) {
        // eslint-disable-next-line default-case
        switch (err?.extensions?.code) {
          case 'AUTH_NOT_AUTHENTICATED':
            let forward$;
            if (err?.path.includes('login')) {
              return notification.error({
                message: <Typography.Text strong>Lỗi</Typography.Text>,
                description: (
                  <Typography.Text>
                    {err?.message || DEFAULT_ERROR_MESSAGE}
                  </Typography.Text>
                )
              });
            }
            // Logout fail
            if (err?.path.includes('revoke')) {
              apolloClient.resetStore();
              removeRefreshToken();
              removeAccessToken();
              window.location.href = window.location.origin + '/dang-nhap';
            }
            // Refresh token expired
            if (err?.path.includes('refreshToken')) {
              apolloClient.resetStore();
              removeRefreshToken();
              removeAccessToken();
              window.location.href = window.location.origin + '/dang-nhap';
            }

            if (!isRefreshing) {
              isRefreshing = true;
              forward$ = fromPromise(
                getNewToken()
                  .then(({ accessToken }) => {
                    // Store the new tokens for your auth link
                    resolvePendingRequests();
                    return accessToken;
                  })
                  .catch(() => {
                    pendingRequests = [];
                    return;
                  })
                  .finally(() => {
                    isRefreshing = false;
                  })
              ).filter(value => Boolean(value));
            }
            forward$ = fromPromise(
              new Promise(resolve => {
                pendingRequests.push(() => resolve());
              })
            );

            // Refresh token expired
            if (forward$ === undefined) {
              removeRefreshToken();
              removeAccessToken();
              return (window.location.href =
                window.location.origin + '/dang-nhap');
            }

            return forward$.flatMap(() => forward(operation));
          default:
            notification.error({
              message: <Typography.Text strong>Lỗi</Typography.Text>,
              description: (
                <Typography.Text>
                  {err?.message || DEFAULT_ERROR_MESSAGE}
                </Typography.Text>
              )
            });
        }
      }
    }
    if (networkError) {
      // window.location.href = window.location.origin;
      return notification.error({
        message: <Typography.Text strong>Lỗi</Typography.Text>,
        description: (
          <Typography.Text>
            {networkError?.message || DEFAULT_ERROR_MESSAGE}
          </Typography.Text>
        )
      });
    }
  }
);

const apolloClient = new ApolloClient({
  link: from([errorLink, authLink.concat(httpLink)]),
  cache: cache,
  connectToDevTools: true
});

export default apolloClient;
