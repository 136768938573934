import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { searchPlaylistVar } from 'api/cache';
import AutoComplete from 'components/AutoComplete';
import { GET_PLAYLISTS } from 'operations/queries';

const PlaylistSearch = ({ onSelect, ...props }) => {
  const [searchPlaylist] = useLazyQuery(GET_PLAYLISTS);
  const searchedPlaylists = useReactiveVar(searchPlaylistVar);

  const onChange = value => {
    const playlist = searchedPlaylists.find(
      _playlist => _playlist.id === value
    );
    return onSelect(playlist);
  };

  return (
    <AutoComplete
      size="large"
      value={null}
      handleSearch={searchPlaylist}
      labelKey="name"
      valueKey="id"
      dataKey="playlists"
      placeholder="Tìm playlist thêm vào danh sách"
      style={{ width: '100%' }}
      onChange={onChange}
      updateCache={searchPlaylistVar}
      {...props}
    />
  );
};

export default PlaylistSearch;
