import { useQuery } from '@apollo/client';
import { Button, Card, Result, Space } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import {
  BULK_DATA_KEY,
  COMMON_STATUS_KEY_BY_INT,
  COMMON_STATUS_KEY_INT,
  DATE_FORMAT,
  ORDER_BY_DICTIONARY,
  PAGE_SIZE,
  TABLE_PROPS
} from 'appConstants';
import ApproveButton from 'components/ApproveButton';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import moment from 'moment';
import {
  useBulkActionArtists,
  useBulkExportArtistByFields,
  useBulkExportArtists
} from 'operations/mutations';
import { GET_ARTISTS } from 'operations/queries';

import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { processQueryFilerParams } from 'utils/filter';
import { isObjEmpty } from 'utils/lodash';
import { generateFileDownload } from 'utils/other';
import { columns } from '../columns';
const ArtistList = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [artists, setArtists] = useState([]);
  const [total, setTotal] = useState([]);

  const { mutate: updateStatus, loading: isUpdateLoading } =
    useBulkActionArtists(BULK_DATA_KEY.STATUS);
  const { mutate: exportArtists, loading: exportLoading } =
    useBulkExportArtists();
  const { mutate: exportArtistByFields } = useBulkExportArtistByFields();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const pageSize = useMemo(
    () => parseInt(searchParams.pageSize) || PAGE_SIZE,
    [searchParams.pageSize]
  );

  const filterQuery = useMemo(() => {
    let additonalQueries = {};
    const processedSearchParams = processQueryFilerParams(searchParams);

    if (!processedSearchParams) return null;

    if (processedSearchParams?.artistCates) {
      processedSearchParams.artistCates = {
        some: {
          cateId: {
            in: processedSearchParams?.artistCates
          }
        }
      };
    }

    const finalQuery = { ...processedSearchParams, ...additonalQueries };
    return isObjEmpty(finalQuery) ? null : finalQuery;
  }, [searchParams]);

  const createdDateQuery = useMemo(
    () => filterQuery?.createdDate,
    [filterQuery]
  );

  const {
    loading: isArtistsLoading,
    error: artistsError,
    data: artistsConnect
  } = useQuery(GET_ARTISTS, {
    variables: {
      take: pageSize,
      skip: pageSize * page - pageSize,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!isArtistsLoading && artistsConnect) {
      const _data = artistsConnect?.artists?.items?.map(item => ({
        ...item,
        key: item.id
      }));
      setArtists(_data);
      setTotal(artistsConnect?.artists?.totalCount);
    }
  }, [artistsConnect, isArtistsLoading]);

  const onPaginate = (page, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, page, pageSize })
    });
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({
        ...searchParams,
        page: 1,
        sort,
        pageSize
      })
    });
  };

  const onShowSizeChange = (_, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, pageSize })
    });
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const rowSelection = {
    selectedRowKeys: selectedRows.map(({ id }) => id),
    onChange: (_, selectedRows) => {
      setSelectedRows(selectedRows);
    }
  };

  const status = useMemo(() => {
    return selectedRows.some(
      _row => _row.status === COMMON_STATUS_KEY_INT.WAITING
    )
      ? COMMON_STATUS_KEY_INT.APPROVED
      : COMMON_STATUS_KEY_INT.WAITING;
  }, [selectedRows]);

  const handleChangeStatus = async () => {
    try {
      const params = selectedRows.map(({ id }) => ({
        id,
        status: COMMON_STATUS_KEY_BY_INT[status]
      }));
      await updateStatus({
        variables: {
          input: params
        }
      });
      setSelectedRows([]);
    } catch (error) { }
  };

  const onExport = async () => {
    debugger
    const exportBySelect = !!selectedRows.length;
    const { data } = exportBySelect
      ? await exportArtists({
        variables: {
          input: {
            ids: selectedRows.map(({ id }) => id)
          }
        }
      })
      : await exportArtistByFields({
        variables: {
          input: {
            name:filterQuery.name?.contains,
            createdFrom: createdDateQuery?.gte,
            createdTo: createdDateQuery?.lte,
            artistRank: filterQuery.artistRank?.eq,
            priority: filterQuery.priority?.eq,
            status: filterQuery.status?.eq
          }
        }
      });

    const eventId = exportBySelect
      ? data.bulkExportArtists.eventId
      : data.exportArtistsByFields.eventId;
    await generateFileDownload(eventId);
    setSelectedRows([]);
  };

  if (artistsError)
    return (
      <Card>
        <Result
          status="500"
          // title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      </Card>
    );
  // if (!artistsConnect) return <div>None</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>{total ? (!!selectedRows.length || createdDateQuery) && (
          <Button
            icon={<DownloadOutlined />}
            type="primary"
            onClick={onExport}
            loading={exportLoading}
          >
            Export (
            {!!selectedRows.length
              ? selectedRows?.length
              : `${moment(createdDateQuery?.gte.replace('+00:00', '')).format(
                DATE_FORMAT
              )} - ${moment(
                createdDateQuery?.lte.replace('+00:00', '')
              ).format(DATE_FORMAT)}`}
            )
          </Button>
        ) : ''}
          {!!selectedRows.length && (
            <>
              <ApproveButton
                status={status}
                onClick={handleChangeStatus}
                total={
                  selectedRows?.filter(_item => _item.status !== status)?.length
                }
              />
            </>
          )}
          <SortSelect value={sort} onChange={setOrderBy} />
          <Link to={'/chi-tiet-nghe-si'}>
            <Button type="primary">Thêm</Button>
          </Link>
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1500 }}
        columns={columns}
        dataSource={artists}
        pagination={{
          total,
          pageSize: pageSize,
          onChange: onPaginate,
          current: page * 1,
          quantity: artists?.length,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          onShowSizeChange: onShowSizeChange
        }}
        loading={isArtistsLoading || isUpdateLoading}
        rowSelection={rowSelection}
      />
    </ListCard>
  );
};

export default ArtistList;
