import { Button, notification } from 'antd';
import axios from 'axios';
import { getAccessToken } from 'utils/authority';
import { useState } from 'react';

const GenerateFile = ({ values, onGenerate, ...props }) => {
  const [loading, setLoading] = useState(false);
  const onGenerateFile = async () => {
    setLoading(true);
    const action = process.env.REACT_APP_UPLOAD_GENERATE_API;
    const config = {
      headers: {
        'content-type': 'application/json; charset=utf-8',
        authorization: `Bearer ${getAccessToken()}`
      }
    };

    const formData = new FormData();

    for (const [key, value] of Object.entries(values)) {
      formData.append(key, value);
    }

    try {
      const res = await axios.post(action, formData, config);
      onGenerate(res?.data);
      setLoading(false);
    } catch (err) {
      notification.error({
        message: 'Lỗi gen file',
        description: 'Vui lòng thử lại sau!'
      });
      setLoading(false);
    }
  };

  return (
    <Button loading={loading} onClick={onGenerateFile}>
      Gen File
    </Button>
  );
};

export default GenerateFile;
