import SeoCommon from 'containers/SeoCommon/SeoCommon';
import { initParams } from '../util';

const SeoInfo = ({ album, editAlbum }) => {
  return (
    <SeoCommon
      initSeo={album?.metaSeo}
      initValues={initParams(album)}
      onUpdate={editAlbum}
    />
  );
};

export default SeoInfo;
