import { Card, Tabs } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import ChangePassword from './components/ChangePassword';
import PersonalInfo from './components/PersonalInfo';

const { TabPane } = Tabs;

const PERSONAL_INFO_URL_VALUES = {
  PERSONAL_INFO: 'thong-tin-ca-nhan',
  DEPARTMENT: 'phong-ban',
  ROLE: 'vai-tro',
  LOGIN_HISTORY: 'lich-su-dang-nhap',
  CHANGE_PASSWORD: 'doi-mat-khau'
};

const CurrentUserDetail = () => {
  const history = useHistory();
  const { id } = useParams();
  // const { data: currentUser } = useCurrentUser();

  const onChange = value => history.push('/ca-nhan/' + value);

  return (
    <Card>
      <Tabs
        onChange={onChange}
        activeKey={id}
        type="card"
        className="current-user-tabs"
      >
        <TabPane
          tab="Thông tin cá nhân"
          key={PERSONAL_INFO_URL_VALUES.PERSONAL_INFO}
        >
          <PersonalInfo />
        </TabPane>
        <TabPane
          tab="Đổi mật khẩu"
          key={PERSONAL_INFO_URL_VALUES.CHANGE_PASSWORD}
        >
          <ChangePassword />
        </TabPane>

        {/* <TabPane
          tab="Lịch sử đăng nhập"
          key={PERSONAL_INFO_URL_VALUES.LOGIN_HISTORY}
          disabled
        >
          <LoginHistory userId={currentUser?.detail?.id} />
        </TabPane> */}
      </Tabs>
    </Card>
  );
};

export default CurrentUserDetail;
