import { Select } from 'antd';
import {
  PLAYLIST_STATUS_DICTIONARY,
  PLAYLIST_STATUS_INT_DICTIONARY
} from 'appConstants';
import React from 'react';

const CategoryStatusSelect = ({ disabled, intValue, ...props }) => {
  return (
    <Select
      options={Object.entries(
        intValue ? PLAYLIST_STATUS_INT_DICTIONARY : PLAYLIST_STATUS_DICTIONARY
      ).map(([key, value]) => ({
        value: key,
        label: value
      }))}
      style={{ width: '100%' }}
      placeholder="Chọn trạng thái"
      allowClear
      {...props}
    />
  );
};

export default CategoryStatusSelect;
