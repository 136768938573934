import { useQuery } from '@apollo/client';
import { Spin, Tabs, Typography } from 'antd';
import { useAddArtist, useEditArtist } from 'operations/mutations';
import { GET_ARTIST_BY_ID } from 'operations/queries';
import { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import ArtistInfo from './components/ArtistInfo';
import RelatedArtists from './components/RelatedArtists';
import SeoInfo from './components/SeoInfo';
import queryString from 'query-string';

const ArtistDetail = () => {
  const { id } = useParams();
  const isUpdate = useMemo(() => !!id, [id]);
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const { data, loading: fetchLoading } = useQuery(GET_ARTIST_BY_ID, {
    variables: { id: parseInt(id) },
    skip: !isUpdate
  });

  const { mutate: editArtist, loading: editLoading } = useEditArtist();
  const { mutate: addArtist, loading: addLoading } = useAddArtist();

  const items = useMemo(
    () => [
      {
        label: 'Thông tin nghệ sĩ',
        key: '1',
        children: (
          <ArtistInfo
            artist={data?.artist}
            isUpdate={isUpdate}
            onEditArtist={editArtist}
            onAddArtist={addArtist}
          />
        )
      },
      {
        label: 'Nghệ sĩ liên quan',
        key: '2',
        children: (
          <RelatedArtists
            artistName={data?.artist?.name}
            artists={data?.artist?.relatedArtists}
          />
        ),
        disabled: !isUpdate
      },
      {
        label: 'SEO',
        key: '3',
        children: (
          <SeoInfo
            isUpdate={isUpdate}
            artist={data?.artist}
            onEditArtist={editArtist}
          />
        ),
        disabled: !isUpdate
      }
    ],
    [addArtist, data?.artist, editArtist, isUpdate]
  );

  const onChangeTab = tab => {
    history.push({
      search: queryString.stringify({
        tab
      })
    });
  };

  return (
    <Spin spinning={fetchLoading || editLoading || addLoading}>
      <Typography.Title>
        {isUpdate ? 'Chi tiết nghệ sĩ' : 'Tạo nghệ sĩ'}
      </Typography.Title>
      <Tabs
        onChange={onChangeTab}
        defaultActiveKey={searchParams?.tab || '1'}
        type="card"
        items={items}
      />
    </Spin>
  );
};

export default ArtistDetail;
