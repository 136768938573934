export * from './playlist/mutatePlaylist';
export * from './song/mutateSong';
export * from './artist/mutateArtist';
export * from './video/mutateVideo';
export * from './mood/mutateMood';
export * from './album/mutateAlbum';
export * from './category/mutateCategory';
export * from './tag/mutateTag';
export * from './topic/mutateTopic';
export * from './role/mutateRole';
export * from './notification/mutateNotification';
export * from './mutateHomepage';
export * from './mutatePageInfo';
export * from './mutatePartner';
export * from './contact/mutateContact';
export * from './comment/mutateComment';
export * from './Adv/mutateAdv';
export * from './Adv/mutateAdvPosition';
export * from './Adv/mutateAdvPositionAdv';
