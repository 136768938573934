import { useQuery } from '@apollo/client';
import { Button, Card, Result, Space } from 'antd';

import {
  SEARCH_ORDER_BY_DICTIONARY,
  PAGE_SIZE,
  TABLE_PROPS
} from 'appConstants';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import { GET_SEARCHS } from 'operations/queries';

import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { processQueryFilerParams } from 'utils/filter';
import { isObjEmpty } from 'utils/lodash';
import { columns } from '../columns';
const SearchList = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [searches, setSearchs] = useState([]);
  const [total, setTotal] = useState([]);

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );

  const pageSize = useMemo(
    () => parseInt(searchParams.pageSize) || PAGE_SIZE,
    [searchParams.pageSize]
  );

  const onShowSizeChange = (_, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, pageSize })
    });
  };

  const filterQuery = useMemo(() => {
    let additonalQueries = {};
    const processedSearchParams = processQueryFilerParams(searchParams);

    if (!processedSearchParams) return null;

    if (processedSearchParams?.searchCates) {
      processedSearchParams.searchCates = {
        some: {
          cateId: {
            in: processedSearchParams?.searchCates
          }
        }
      };
    }

    const finalQuery = { ...processedSearchParams, ...additonalQueries };
    return isObjEmpty(finalQuery) ? null : finalQuery;
  }, [searchParams]);

  const {
    loading: isSearchsLoading,
    error: searchesError,
    data: searchesConnect
  } = useQuery(GET_SEARCHS, {
    variables: {
      take: pageSize,
      skip: pageSize * page - pageSize,
      order: [SEARCH_ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!isSearchsLoading && searchesConnect) {
      const _data = searchesConnect?.searches?.items?.map(item => ({
        ...item,
        key: item.id
      }));
      setSearchs(_data);
      setTotal(searchesConnect?.searches?.totalCount);
    }
  }, [searchesConnect, isSearchsLoading]);

  const onPaginate = (page, pageSize) => {
    history.push({
      search: queryString.stringify({
        ...searchParams,
        page,
        pageSize
      })
    });
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({
        ...searchParams,
        page: 1,
        sort,
        pageSize
      })
    });
  };

  if (searchesError)
    return (
      <Card>
        <Result
          status="500"
          // title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      </Card>
    );
  // if (!searchesConnect) return <div>None</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          <SortSelect value={sort} onChange={setOrderBy} />
          {/* <Link to={'/chi-tiet-chu-de'}>
            <Button type="primary">Thêm</Button>
          </Link> */}
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 'auto' }}
        columns={columns}
        dataSource={searches}
        pagination={{
          total,
          pageSize: pageSize,
          onChange: onPaginate,
          current: page * 1,
          quantity: searches?.length,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          onShowSizeChange: onShowSizeChange
        }}
        loading={isSearchsLoading}
      />
    </ListCard>
  );
};

export default SearchList;
