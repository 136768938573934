import { gql } from '@apollo/client';

export const GET_CATEGORIES = gql`
  query GetCategories(
    $skip: Int
    $take: Int
    $order: [CateSortInput!]
    $where: CateFilterInput
  ) {
    categories(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        name
        status
        thumbnail
        thumbnailSocial
        updatedBy
        updatedDate
        createdBy
        createdDate
        type
        typeName
        uniqId
        group {
          id
          name
        }
      }
    }
  }
`;

export const GET_CATEGORY_BY_ID = gql`
  query getCategoryById($id: Int!) {
    category(id: $id) {
      id
      name
      status
      statusName
      type
      typeName
      thumbnail
      thumbnailSocial
      intro
      slug
      onHeader
      onFooter
      groupId
      metaSeo {
        metaTitle
        metaKeywords
        metaDescription
      }
    }
  }
`;

export const GET_TYPES = gql`
  query GetTypes(
    $skip: Int
    $take: Int
    $order: [TypesSortInput!]
    $where: TypesFilterInput
  ) {
    types(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        name
        value
      }
    }
  }
`;

export const GET_CATEGORY_GROUPS = gql`
  query GetCategoryGroups(
    $skip: Int
    $take: Int
    $order: [CateGroupSortInput!]
    $where: CateGroupFilterInput
  ) {
    categoryGroups(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        name
        description
      }
    }
  }
`;
