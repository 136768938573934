import { Typography } from 'antd';
import React from 'react';
import TopBannerList from './components/TopBannerList';

const TopBanner = () => {
  return (
    <div>
      <Typography.Title>HomePage - Banner</Typography.Title>
      <TopBannerList />
    </div>
  );
};

export default TopBanner;
