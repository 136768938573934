import { useLazyQuery } from '@apollo/client';
import AutoComplete from 'components/AutoComplete';
import { GET_ADV } from 'operations/queries';
import { useMemo } from 'react';

const AdvAutocomplete = ({  ...props }) => {
  const [search] = useLazyQuery(GET_ADV);
  
  return (
    <AutoComplete
      handleSearch={search}
      labelKey="advertName"
      valueKey="id"
      dataKey="icAdverts"
      placeholder="Tìm quảng cáo"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default AdvAutocomplete;
