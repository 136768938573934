import { useLazyQuery } from '@apollo/client';
import AutoComplete from 'components/AutoComplete';
import { GET_PLAYLISTS } from 'operations/queries/queryPlaylist';
import React, { useMemo } from 'react';

const PlaylistAutocomplete = ({ playlist, ...props }) => {
  const [searchPlaylist] = useLazyQuery(GET_PLAYLISTS);
  const defaultOption = useMemo(
    () =>
      playlist
        ? Array.isArray(playlist)
          ? playlist.map(({ id, name }) => ({ value: id, label: name }))
          : { value: playlist.id, label: playlist.name }
        : [],
    [playlist]
  );

  return (
    <AutoComplete
      mode="multiple"
      defaultOption={defaultOption}
      handleSearch={searchPlaylist}
      labelKey="name"
      valueKey="id"
      dataKey="playlists"
      placeholder="Tìm playlist"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default PlaylistAutocomplete;
