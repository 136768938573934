import { Typography } from 'antd';
import { DATE_FORMAT, TIME_FORMAT } from 'appConstants';
import moment from 'moment';
import 'moment/locale/vi';

export const columns = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: '100px'
  },
  {
    title: 'Hoạt động',
    dataIndex: 'eventType',
    width: '100px'
  },
  {
    title: 'Table',
    dataIndex: 'tableName',
    width: '80px'
  },
  {
    title: 'Cột',
    dataIndex: 'columnName',
    width: '80px'
  },
  {
    title: 'Giá trị cũ',
    dataIndex: 'originalValue',
    width: '100px'
  },
  {
    title: 'Giá trị mới',
    dataIndex: 'newValue',
    width: '100px'
  },

  {
    title: 'Ngày tạo',
    dataIndex: 'createDate',
    width: '80px',
    render: createDate => (
      <Typography.Text>
        {createDate &&
          moment(createDate).format(DATE_FORMAT + ' ' + TIME_FORMAT)}
      </Typography.Text>
    )
  }
];
