import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';

export const STATUS_COMMENT = gql`
  mutation BulkActionComments($input: [BulkActionCommentInput]) {
    bulkActionComments(input: $input) {
      id,
      statusId
    }
  }
`;
export const useStatusComment = () => {
  const [mutate, { data, error }] = useMutation(STATUS_COMMENT, {
    refetchQueries: ['GetComments'],
    onCompleted: ({ removeRole }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã cập nhật
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const REMOVE_COMOMMENT = gql`
  mutation RemoveComment($id: Int!) {
   removeComment(id: $id) {
      id
    }
  }
`;

export const useRemoveComment = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_COMOMMENT, {
    refetchQueries: ['GetComments'],
    onCompleted: () => {
      notification.success({
        message: (
          <Typography.Text>
            Xóa thành công
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};