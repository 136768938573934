import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  notification, Typography,
  Select
} from 'antd';
import { Link } from 'react-router-dom';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';

const AdvPosition = ({
  adv,
  isUpdate,
  onEditAdvContact,
  onAddAdvContact
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  useEffect(async () => {
    if (adv) {
      form.setFieldsValue({
        positionName: adv.positionName,
        advertDisplayTypeId: adv.advertDisplayTypeId,
        overflowWidth: adv.overflowWidth,
        overflowHeight: adv.overflowHeight,
        width: adv.width,
        height: adv.height,
        positionDesc: adv.positionDesc,
        id: adv.id,
      });
    }
  }, [adv, form]);

  const onSave = async (addMore) => {
    try {
      const values = await form.validateFields();
      const params = {
        ...values
      };
      if (isUpdate) {
        // update
        await onEditAdvContact({
          variables: {
            input: {
              ...params,
              id: parseInt(id)
            }
          }, onCompleted: () => {
            if (addMore) {
              form.resetFields()
              history.push('/chi-tiet-vi-tri-quang-cao');
            }
          }
        });
      } else {
        //  create
        await onAddAdvContact({
          variables: {
            input: params
          },
          onCompleted: ({ addIcAdvertPosition }) => {
            if (addMore) {
              form.resetFields()
              history.push('/chi-tiet-vi-tri-quang-cao');
            } else
              history.push('/chi-tiet-vi-tri-quang-cao/' + addIcAdvertPosition?.id);
          }
        });
      }
    } catch (error) { }
  };

  return (
    <Card>
      <Form form={form} layout="vertical">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="positionName"
              label="Tên vị trí"
              rules={[{ required: true, message: 'Nhập tên vị trí!' }]}
            >
              <Input placeholder="Nhập tên (tối đa 200 kí tự)" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="advertDisplayTypeId"
              label="Loại vị trí quảng cáo"
              rules={[{ required: true, message: 'Chọn loại quảng cáo!' }]}

            >
              <Select placeholder="Chọn loại" >
                <Select.Option value={1} >Desktop
                </Select.Option>
                <Select.Option value={2} >Mobile
                </Select.Option>
                <Select.Option value={3} >Amp
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="width"
              label="Độ rộng"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="height"
              label="Chiều cao"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="overflowWidth"
              label="Độ rộng tràn"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="overflowHeight"
              label="Chiều cao tràn"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="positionDesc"
              label="Mô tả"
            >
              <Input.TextArea rows={3} placeholder="Nhập giới thiệu" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row justify="end" align="middle" gutter={16}>
        <Col>
          <Link to={'/vi-tri-quang-cao'}>
            <Button type="primary">
              Quay lại
            </Button>
          </Link>
        </Col>
        <Col>
          <Button onClick={() => onSave(true)} type="primary">
            Lưu và thêm mới
          </Button>
        </Col>
        <Col>
          <Button onClick={() => onSave(false)} type="primary">
            Lưu
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default AdvPosition;
