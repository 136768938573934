import { gql } from '@apollo/client';

export const GET_PLAYLISTS = gql`
  query GetPlaylists(
    $skip: Int
    $take: Int
    $order: [PlaylistSortInput!]
    $where: PlaylistFilterInput
  ) {
    playlists(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        name
        thumbnail
        statusName
        createdBy
        createdDate
        updatedBy
        updatedDate
        typeName
        status
        slug
        uniqId
        publicDate
        isCopyrightInfringement
        categories {
          id
          name
        }
      }
    }
  }
`;

export const GET_PLAYLIST_BY_ID = gql`
  query GetPlaylistById($id: Int!) {
    playlist(id: $id) {
      id
      name
      slug
      type
      typeName
      publicDate
      intro
      slide
      thumbnail
      thumbnailSocial
      playlistRelateds {
        id
        name
      }
      playlistSongs {
        id
        title
        url
        thumbnail
        categories {
          id
          name
        }
        singers {
          id
          name
        }
        musicians {
          id
          name
        }
      }
      playlistVideos {
        id
        title
        thumbnail
        categories {
          id
          name
        }
        singers {
          id
          name
        }
        musicians {
          id
          name
        }
      }
      categories {
        id
        name
      }
      playlistMoods {
        id
        title
      }
      playlistTags {
        id
        name
      }
      metaSeo {
        metaTitle
        metaKeywords
        metaDescription
      }
    }
  }
`;
