import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Col,
  Form,
  message,
  Row,
  Select,
  Space,
  Table,
  Typography
} from 'antd';
import {
  PAGE_SIZE_MAX,
  POPULAR_TYPE_DICTIONARY,
  POPULAR_TYPE_KEY_INT
} from 'appConstants';
import { arrayMoveImmutable } from 'array-move';
import UserAvatar from 'components/UserAvatar';
import { useBulkActionTopPopulars } from 'operations/mutations';
import { GET_TOP_POPULAR } from 'operations/queries';
import { useEffect, useState } from 'react';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import PlaylistSearch from './PlaylistSearch';

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const TopPlaylist = () => {
  const [form] = Form.useForm();
  const [dataSource, setDataSource] = useState([]);
  const { data, loading: fetchLoading } = useQuery(GET_TOP_POPULAR, {
    variables: {
      take: PAGE_SIZE_MAX
    }
  });
  const { mutate: updateTopPlaylist } = useBulkActionTopPopulars();

  const popularType = Form.useWatch('type', form);

  useEffect(() => {
    if (data?.topPopular?.items) {
      const _data = data?.topPopular?.items?.map((item, index) => ({
        ...item,
        itemType: POPULAR_TYPE_KEY_INT[item.itemType],
        record: item?.playlist || item?.song || item?.album,
        index
      }));
      setDataSource(_data);
    }

    return () => {
      setDataSource([]);
    };
  }, [data?.topPopular?.items]);

  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: 'grab',
        color: '#999'
      }}
    />
  ));

  const onClearSong = index => {
    var newData = [...dataSource].filter((_, _index) => _index !== index);
    const _newData = newData?.map((_data, index) => ({ ..._data, index }));
    setDataSource(_newData);
  };

  const onSave = async () => {
    try {
      const input = dataSource?.map(data => ({
        itemId: data?.record?.id,
        itemType: data.itemType
      }));
      await updateTopPlaylist({
        variables: {
          input
        }
      });
    } catch (error) {}
  };

  const columns = [
    {
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Thứ tự',
      width: 80,
      align: 'center',
      render: (_, __, index) => (
        <Typography.Title level={2} style={{ margin: 0 }}>
          {index + 1}
        </Typography.Title>
      )
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'name',
      className: 'drag-visible',
      render: (_, { record: { thumbnail, name, title } }) => (
        <Space>
          <UserAvatar
            fullName={name}
            avatar={thumbnail}
            shape="square"
            size={48}
          />
          <Typography.Text>{name || title}</Typography.Text>
        </Space>
      )
    },
    {
      title: 'Thể loại',
      dataIndex: 'itemType',
      render: itemType => (
        <Typography.Text>{POPULAR_TYPE_DICTIONARY[itemType]}</Typography.Text>
      )
    },
    {
      dataIndex: 'id',
      width: 50,
      render: (_, __, index) => (
        <Button
          onClick={() => onClearSong(index)}
          size="small"
          danger
          icon={<DeleteOutlined />}
        ></Button>
      )
    }
  ];

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter(el => !!el);
      setDataSource(newData);
    }
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      x => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const onAddForPopular = record => {
    if (!record) return;
    const isExist = !!dataSource.find(
      _data => _data.record?.id === record.id && _data.itemType === popularType
    );
    if (isExist) {
      return message.error('Đã tồn tại trong danh sách');
    }
    setDataSource(dataSource => [
      ...dataSource,
      { record, index: dataSource?.length, itemType: popularType }
    ]);
    return message.success('Đã thêm vào danh sách');
  };

  return (
    <Card>
      <div className="mb-md">
        <div className="mb-md">
          <Form form={form}>
            <Row gutter={16}>
              <Col>
                <Form.Item name="type">
                  <Select
                    size="large"
                    placeholder="Chọn thể loại"
                    style={{
                      width: 160
                    }}
                  >
                    <Select.Option value="PLAYLIST">Playlist</Select.Option>
                    <Select.Option value="SONG">Bài hát</Select.Option>
                    <Select.Option value="ALBUM">Album</Select.Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col flex={1}>
                <PlaylistSearch type={popularType} onSelect={onAddForPopular} />
              </Col>
            </Row>
          </Form>
        </div>
        <Table
          loading={fetchLoading}
          className="mb-md"
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          rowKey="index"
          components={{
            body: {
              wrapper: DraggableContainer,
              row: DraggableBodyRow
            }
          }}
        />
        <Row justify="end">
          <Button type="primary" onClick={onSave}>
            Cập nhật
          </Button>
        </Row>
      </div>
    </Card>
  );
};

export default TopPlaylist;
