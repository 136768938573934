import { gql } from '@apollo/client';

export const GET_SEARCHS = gql`
  query GetSearchs(
    $skip: Int
    $take: Int
    $order: [SearchSortInput!]
    $where: SearchFilterInput
  ) {
    searches(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        keyword
        safeKeyword
        count
        createdDate
        clientId
        createDate
      }
    }
  }
`;
