import SeoCommon from 'containers/SeoCommon/SeoCommon';
import { initParams } from '../util';

const SeoInfo = ({ video, editVideo }) => {
  return (
    <SeoCommon
      initSeo={video?.metaSeo}
      initValues={initParams(video)}
      onUpdate={editVideo}
    />
  );
};

export default SeoInfo;
