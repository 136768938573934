import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Divider,
  message,
  Row,
  Space,
  Table,
  Typography
} from 'antd';
import { arrayMoveImmutable } from 'array-move';
import UserAvatar from 'components/UserAvatar';
import { useBulkAddArtistRelated } from 'operations/mutations';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import CopyRelatedArtist from './CopyRelatedArtist';
import ArtistSearch from './ArtistSearch';
import { ARTIST_RANK_DICTIONARY, PRIORITY_DICTIONARY } from 'appConstants';

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const RelatedArtists = ({ artistName, artists }) => {
  const { id } = useParams();
  const [dataSource, setDataSource] = useState(artists || []);
  const { mutate: updateArtistRelated } = useBulkAddArtistRelated();
  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: 'grab',
        color: '#999'
      }}
    />
  ));

  const onClearArtist = id => {
    const newData = dataSource.filter(_d => _d.id !== id);
    setDataSource(newData);
  };

  const columns = [
    {
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Thứ tự',
      width: 80,
      align: 'center',
      render: (_, __, index) => (
        <Typography.Title level={2} style={{ margin: 0 }}>
          {index + 1}
        </Typography.Title>
      )
    },
    {
      title: 'Nghệ sĩ',
      dataIndex: 'name',
      className: 'drag-visible',
      render: (name, { id, image }) => (
        <Space>
          <UserAvatar fullName={name} avatar={image} shape="square" size={48} />
          <Link target="_blank" to={`/chi-tiet-nghe-si/${id}`}>
            {name}
          </Link>
        </Space>
      )
    },
    {
      title: 'Xếp hạng',
      dataIndex: 'artistRank',
      // width: '50px',
      render: artistRank => (
        <Typography.Text>{ARTIST_RANK_DICTIONARY[artistRank]}</Typography.Text>
      )
    },
    {
      title: 'Độ ưu tiên',
      dataIndex: 'priority',
      // width: '50px',
      render: priority => (
        <Typography.Text>{PRIORITY_DICTIONARY[priority]}</Typography.Text>
      )
    },
    {
      dataIndex: 'id',
      width: 50,
      render: id => (
        <Button
          onClick={() => onClearArtist(id)}
          size="small"
          danger
          icon={<DeleteOutlined />}
        ></Button>
      )
    }
  ];

  useEffect(() => {
    if (!!artists?.length) {
      const _artists = artists.map((artist, index) => ({
        ...artist,
        index,
        key: artist.id
      }));
      setDataSource(_artists);
    }
  }, [artists]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter(el => !!el);
      setDataSource(newData);
    }
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      x => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const onAddArtistToPlaylist = artist => {
    if (!artist) return;
    const isExist = !!dataSource.find(_data => _data.id === artist.id);
    if (isExist) {
      return message.error('Nghệ sĩ đã tồn tại trong danh sách');
    }
    setDataSource(dataSource => [
      ...dataSource,
      { ...artist, index: dataSource?.length }
    ]);
    return message.success('Đã thêm nghệ sĩ vào danh sách');
  };

  const onUpdate = async () => {
    try {
      const artistIds = dataSource?.map(({ id }) => id);
      await updateArtistRelated({
        variables: {
          input: {
            artistId: parseInt(id),
            relatedArtistIds: artistIds
          }
        }
      });
    } catch (error) {}
  };

  return (
    <Card className="mb-md">
      <div className="mb-md">
        <Row justify="space-between" className="mb-md">
          <Space>
            <Typography.Title level={4}>Nghệ sĩ liên quan</Typography.Title>
            <Divider type="vertical" style={{ height: '24px' }} />
            <Typography.Title level={4} type="success">
              {artistName}
            </Typography.Title>
          </Space>
          <Space>
            <CopyRelatedArtist dataSource={dataSource} />
            <Button type="primary" onClick={onUpdate}>
              Cập nhật
            </Button>
          </Space>
        </Row>
        <ArtistSearch onSelect={onAddArtistToPlaylist} />
      </div>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }}
      />
    </Card>
  );
};

export default RelatedArtists;
