import { useQuery } from '@apollo/client';
import { Button, Card, Drawer, Result } from 'antd';
import { PAGE_SIZE_MAX } from 'appConstants';
import { useSortingTopic } from 'operations/mutations';
import { GET_TOPICS } from 'operations/queries';
import { useEffect } from 'react';
import { useState } from 'react';
import SortingList from './SortingList';

const SortingTopic = () => {
  const [dataSource, setDataSource] = useState([]);
  const [open, setOpen] = useState(false);
  const { mutate: sortingTopics, loading: sortLoading } = useSortingTopic();
  const {
    loading: isTopicsLoading,
    error: topicsError,
    data: topicsConnect
  } = useQuery(GET_TOPICS, {
    variables: {
      take: PAGE_SIZE_MAX,
      order: [
        {
          sort: 'ASC'
        }
      ],
      where: {
        status: {
          eq: 1
        }
      }
    }
  });

  useEffect(() => {
    if (!isTopicsLoading && topicsConnect) {
      const _data = topicsConnect?.topics?.items?.map((item, index) => ({
        ...item,
        key: item.id,
        index
      }));
      setDataSource(_data);
    }
  }, [topicsConnect, isTopicsLoading]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onUpdate = async () => {
    try {
      await sortingTopics({
        variables: {
          input: {
            topicIds: dataSource.map(({ id }) => id)
          }
        }
      });
    } catch (error) {}
  };

  if (topicsError)
    return (
      <Card>
        <Result
          status="500"
          // title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      </Card>
    );

  return (
    <>
      <Button onClick={showDrawer}>Sắp xếp Topic</Button>
      <Drawer
        zIndex={10}
        size="large"
        title="Sắp xếp Topic"
        placement="right"
        onClose={onClose}
        open={open}
        extra={
          <Button type="primary" onClick={onUpdate}>
            Cập nhật
          </Button>
        }
      >
        <SortingList
          dataSource={dataSource}
          setDataSource={setDataSource}
          loading={isTopicsLoading || sortLoading}
        />
      </Drawer>
    </>
  );
};

export default SortingTopic;
