import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import { Button, Col, Form, Input, Row } from 'antd';
import {
  colProps,
  FILTER_PARAMS_DATA_TYPE_SUFFIX,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import FormBase from 'containers/FormBase';
import moment from 'moment';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { processFormItemName } from 'utils/filter';
import { cleanseObj } from 'utils/other';

const SearchFilter = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!location?.search) {
      form.resetFields();
    }
  }, [form, location?.search]);

  const createdDateFormName = useMemo(
    () =>
      processFormItemName({
        prefix: FILTER_PARAMS_PREFIX,
        name: 'createdDate',
        operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.BTW,
        dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.DATE
      }),
    []
  );

  useEffect(() => {
    const initValue = queryString.parse(history.location.search);

    const publicPlaylistTimes = initValue[createdDateFormName];

    form.setFieldsValue({
      ...initValue,
      ...(publicPlaylistTimes
        ? {
            [createdDateFormName]: publicPlaylistTimes.map(broadcastTime =>
              moment(broadcastTime)
            )
          }
        : {})
    });
  }, [createdDateFormName, form, history.location.search]);

  const onFilter = useCallback(() => {
    const params = cleanseObj(form.getFieldsValue());

    history.push({
      search: queryString.stringify({
        ...params
      })
    });
  }, [form, history]);

  const onClearFilter = useCallback(() => {
    form.resetFields();
    history.push({
      search: queryString.stringify({})
    });
  }, [form, history]);

  return (
    <FormBase form={form} onFinish={onFilter}>
      <Row gutter={8}>
        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'keyword',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.CONTAINS
            })}
            label="Từ khoá"
          >
            <Input placeholder="Nhập từ khoá" />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="end" gutter={8}>
        <Col>
          <Button icon={<ClearOutlined />} onClick={onClearFilter}>
            Xoá lọc
          </Button>
        </Col>
        <Col>
          <Button icon={<FilterOutlined />} htmlType="submit">
            Lọc
          </Button>
        </Col>
      </Row>
    </FormBase>
  );
};

export default SearchFilter;
