import { gql } from '@apollo/client';

export const GET_ALBUMS = gql`
  query GetAlbums(
    $skip: Int
    $take: Int
    $order: [AlbumSortInput!]
    $where: AlbumFilterInput
  ) {
    albums(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        title
        intro
        thumbnail
        status
        slug
        uniqId
        publicDate
        isCopyrightInfringement
        categories {
          id
          name
        }
        createdDate
        updatedDate
        createdBy
        updatedBy
      }
    }
  }
`;

export const GET_ALBUM_BY_ID = gql`
  query GetAlbumById($id: Int!) {
    album(id: $id) {
      id
      title
      intro
      thumbnail
      thumbnailSocial
      status
      slug
      uniqId
      producer
      createdDate
      updatedDate
      createdBy
      updatedBy
      publicDate
      categories {
        id
        name
      }
      artists {
        id
        name
      }
      songs {
        id
        title
        url
        thumbnail
        singers {
          id
          name
        }
        musicians {
          id
          name
        }
        categories {
          id
          name
        }
      }
      metaSeo {
        metaTitle
        metaKeywords
        metaDescription
      }
    }
  }
`;
