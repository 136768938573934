import { Collapse, Typography } from 'antd';

import React from 'react';

import AppNotificationList from './components/AppNotificationList';
import AppNotificationFilter from './AppNotificationFilter';

const AppNotification = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách Thông báo app</Typography.Title>

      <Collapse defaultActiveKey={['1']} style={{ marginBottom: 16 }}>
        <Collapse.Panel header="Bộ lọc thông báo" key="1">
          <AppNotificationFilter />
        </Collapse.Panel>
      </Collapse>

      <AppNotificationList />
    </div>
  );
};

export default AppNotification;
