import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { searchAlbumVar } from 'api/cache';
import AutoComplete from 'components/AutoComplete';
import { GET_ALBUMS } from 'operations/queries';

const AlbumSearch = ({ onSelect, ...props }) => {
  const [searchAlbum] = useLazyQuery(GET_ALBUMS);
  const searchedAlbums = useReactiveVar(searchAlbumVar);

  const onChange = value => {
    const album = searchedAlbums.find(_album => _album.id === value);
    return onSelect(album);
  };

  return (
    <AutoComplete
      size="large"
      value={null}
      handleSearch={searchAlbum}
      labelKey="title"
      valueKey="id"
      dataKey="albums"
      placeholder="Tìm album thêm vào danh sách"
      style={{ width: '100%' }}
      onChange={onChange}
      updateCache={searchAlbumVar}
      {...props}
    />
  );
};

export default AlbumSearch;
