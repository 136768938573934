import { Button } from 'antd';
import {
  STATUS_ACTION_TIMEOUT,
  USER_STATUS_KEY,
  USER_STATUS_VALUE,
  USER_TYPE_VALUE
} from 'appConstants';
import UserStatusTag from 'components/UserStatusTag';
import { useCallback, useRef, useState } from 'react';
import { LockOutlined, UnlockOutlined } from '@ant-design/icons';

const UserStatusButton = ({ record, onClick }) => {
  const { userId, status, roleId, userType } = record;
  const [actionVisible, setActionVisible] = useState(false);
  const timerRef = useRef();

  const buttonStyle = useCallback(
    (downStatus = false) => ({
      style: {
        padding: '0 7px',
        height: 'auto',
        width: '100px'
      },
      icon: downStatus ? <LockOutlined /> : <UnlockOutlined />,
      type: 'primary',
      danger: downStatus
    }),
    []
  );

  const onMouseEnter = () => {
    timerRef.current = setTimeout(() => {
      setActionVisible(true);
    }, STATUS_ACTION_TIMEOUT);
  };

  const onMouseLeave = () => {
    setActionVisible(false);
    clearTimeout(timerRef.current);
  };

  const onChangeStatus = useCallback(
    async status => {
      try {
        await onClick({
          variables: {
            input: {
              userId,
              roleId,
              status,
              userType: USER_TYPE_VALUE[userType]
            }
          }
        });
      } catch (error) {}
    },
    [onClick, roleId, userId, userType]
  );

  const renderButtonAction = useCallback(() => {
    switch (status) {
      case USER_STATUS_VALUE.DISABLED:
        return (
          <Button
            {...buttonStyle()}
            onClick={() => onChangeStatus(USER_STATUS_KEY.ACTIVATED)}
          >
            Mở khoá
          </Button>
        );
      case USER_STATUS_VALUE.ACTIVATED:
        return (
          <Button
            {...buttonStyle(true)}
            onClick={() => onChangeStatus(USER_STATUS_KEY.DISABLED)}
          >
            Khoá
          </Button>
        );

      default:
        break;
    }
  }, [status, buttonStyle, onChangeStatus]);

  return (
    <div
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={{ width: 'fit-content' }}
    >
      {actionVisible ? (
        renderButtonAction()
      ) : (
        <UserStatusTag
          status={status}
          style={{ cursor: 'pointer', width: '100px', textAlign: 'center' }}
        />
      )}
    </div>
  );
};

export default UserStatusButton;
