import { gql } from '@apollo/client';

export const GET_USERS = gql`
  query GetUsers(
    $skip: Int
    $take: Int
    $order: [UserSortInput!]
    $where: UserFilterInput
  ) {
    users(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        userName
        userId
        email
        fullName
        phone
        status
        userType
        role
        phongBanId
        roleId
        roleName {
          id
          title
        }
        phongBan {
          id
          name
        }
        createdBy
        updatedBy
        createdDate
        updatedDate
      }
    }
  }
`;

export const GET_USER_BY_ID = gql`
  query getUserById($id: String) {
    user(id: $id) {
      id
      name
      email
      status
      roleId
      createdBy
      createdDate
      updatedBy
      updatedDate
      userTypeName
      creator {
        id
        name
      }
      updater {
        id
        name
      }
    }
  }
`;

export const GET_DEPARTMENTS = gql`
  query GetDepartments(
    $skip: Int
    $take: Int
    $order: [PhongBanSortInput!]
    $where: PhongBanFilterInput
  ) {
    phongBans(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        name
      }
    }
  }
`;
