import moment from 'moment';

export const initParams = playlist => {
  const params = {
    id: playlist.id,
    name: playlist.name,
    slug: playlist.slug,
    intro: playlist.intro,
    slide: playlist.slide,
    thumbnail: playlist.thumbnail,
    thumbnailSocial: playlist.thumbnailSocial,
    cateIds: playlist.categories.map(category => category?.id),
    tagIds: playlist.playlistTags.map(tag => tag?.id),
    moodsIds: playlist.playlistMoods.map(mood => mood?.id),
    playlistRelatedIds: playlist.playlistRelateds.map(playlist => playlist?.id),
    publicDate: playlist.publicDate && moment(playlist.publicDate)
  };
  return params;
};
