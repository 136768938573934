import { Button, Col, DatePicker, Form, Input, Row, Select } from 'antd';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo } from 'react';

import {
  colProps,
  FILTER_PARAMS_DATA_TYPE_SUFFIX,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX,
  ADV_DISPLAY
} from 'appConstants';
import { useHistory, useLocation } from 'react-router-dom';

import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import FormBase from 'containers/FormBase';
import moment from 'moment';
import { processFormItemName } from 'utils/filter';
import { cleanseObj } from 'utils/other';

const AdvPositionFilter = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const publicDateFormName = useMemo(
    () =>
      processFormItemName({
        prefix: FILTER_PARAMS_PREFIX,
        name: 'crDateTime',
        operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.BTW,
        dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.DATE
      }),
    []
  );
  useEffect(() => {
    const initValue = queryString.parse(history.location.search);
    const CreateTimes = initValue[publicDateFormName];

    form.setFieldsValue({
      ...initValue,
      ...(CreateTimes
        ? {
          [publicDateFormName]: CreateTimes.map(time => moment(time))
        }
        : {})
    });
  }, [publicDateFormName, form, history.location.search]);

  const onFilter = useCallback(() => {
    const params = cleanseObj(form.getFieldsValue());

    history.push({
      search: queryString.stringify({
        ...params
      })
    });
  }, [form, history]);

  const onClearFilter = useCallback(() => {
    form.resetFields();
    history.push({
      search: queryString.stringify({})
    });
  }, [form, history]);
  useEffect(() => {
    if (!location?.search) {
      form.resetFields();
    }
  }, [form, location?.search]);

  return (
    <FormBase form={form} onFinish={onFilter} bordered={false}>
      <Row gutter={8}>
        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'positionName',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.CONTAINS
            })}
            label="Tên vị trí quảng cáo"
          >
            <Input placeholder="Nhập tên quảng cáo" />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'advertDisplayTypeId',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
              dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.INT
            })}
            label="Loại"
          >
            <Select
              style={{ width: '100%', fontWeight: 'normal' }}
              placeholder="Chọn loại"
              allowClear
            >
              {Object.keys(ADV_DISPLAY)
                .map((key, index) => (
                  <Select.Option
                    key={key}
                    value={key}
                  >
                    {ADV_DISPLAY[key]}
                  </Select.Option>
                ))}
            </Select>
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item name={publicDateFormName} label="Khoảng thời gian tạo">
            <DatePicker.RangePicker
              showTime={false}
              style={{ width: '100%' }}
              allowClear
              format="DD/MM/YYYY"
              placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
            />
          </Form.Item>
        </Col>

      </Row>

      <Row justify="end" gutter={8}>
        <Col>
          <Button icon={<ClearOutlined />} onClick={onClearFilter}>
            Xoá lọc
          </Button>
        </Col>
        <Col>
          <Button icon={<FilterOutlined />} htmlType="submit">
            Lọc
          </Button>
        </Col>
      </Row>
    </FormBase>
  );
};

export default AdvPositionFilter;
