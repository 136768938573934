import { gql } from '@apollo/client';

export const GET_MOODS = gql`
  query GetMoods(
    $skip: Int
    $take: Int
    $order: [MoodSortInput!]
    $where: MoodFilterInput
  ) {
    moods(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        title
        thumbnail
        thumbnailSocial
        banner
        intro
        status
        createdDate
        updatedDate
        createdBy
        updatedBy
        statusName
        uniqId
        slug
      }
    }
  }
`;

export const GET_MOOD_BY_ID = gql`
  query GetMoodById($id: Int!) {
    mood(id: $id) {
      id
      title
      thumbnail
      thumbnailSocial
      banner
      intro
      status
      createdDate
      updatedDate
      createdBy
      updatedBy
      statusName
      uniqId
      slug
      metaSeo {
        metaTitle
        metaKeywords
        metaDescription
      }
    }
  }
`;
