import SeoCommon from 'containers/SeoCommon/SeoCommon';
import { initParams } from '../util';

const SeoInfo = ({ mood, onEditMood }) => {
  return (
    <SeoCommon
      initSeo={mood?.metaSeo}
      initValues={initParams(mood)}
      onUpdate={onEditMood}
    />
  );
};

export default SeoInfo;
