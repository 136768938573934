import { useQuery } from '@apollo/client';
import { Spin, Tabs, Typography } from 'antd';
import { useAddAlbum, useEditAlbum } from 'operations/mutations';
import { GET_ALBUM_BY_ID } from 'operations/queries';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import AlbumInfo from './components/AlbumInfo';
import SeoInfo from './components/SeoInfo';

const AlbumDetail = () => {
  const { id } = useParams();
  const isUpdate = useMemo(() => !!id, [id]);

  const { data, loading: fetchLoading } = useQuery(GET_ALBUM_BY_ID, {
    variables: { id: parseInt(id) },
    skip: !isUpdate
  });

  const { mutate: editAlbum, loading: editLoading } = useEditAlbum();
  const { mutate: addAlbum, loading: addLoading } = useAddAlbum();

  const items = useMemo(
    () => [
      {
        label: 'Thông tin Album/MV',
        key: 'info',
        children: (
          <AlbumInfo
            album={data?.album}
            isUpdate={isUpdate}
            editAlbum={editAlbum}
            addAlbum={addAlbum}
          />
        )
      },

      {
        label: 'SEO',
        key: 'seo',
        children: (
          <SeoInfo
            isUpdate={isUpdate}
            album={data?.album}
            editAlbum={editAlbum}
          />
        ),
        disabled: !isUpdate
      }
    ],
    [addAlbum, data?.album, editAlbum, isUpdate]
  );

  return (
    <Spin spinning={fetchLoading || editLoading || addLoading}>
      <Typography.Title>
        {isUpdate ? 'Chi tiết Album' : 'Tạo Album'}
      </Typography.Title>
      <Tabs type="card" items={items} />
    </Spin>
  );
};

export default AlbumDetail;
