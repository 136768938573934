import {
  message,
  notification,
  Space,
  Typography,
  Upload as AntdUpload
} from 'antd';
import axios from 'axios';
import { getAccessToken } from 'utils/authority';
import { apolloClient } from 'api';

const Import = ({ children, type, imageType, operations, ...props }) => {
  const uploadFile = async options => {
    message.loading({
      content: 'Đợi tí nha... !',
      key: 'import'
    });
    const { onSuccess, onError, file } = options;

    const action = process.env.REACT_APP_GRAPHQL_API;
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        authorization: `Bearer ${getAccessToken()}`
      }
    };
    const formData = new FormData();
    formData.append('0', file);
    formData.append(
      'operations',
      '{ "query": "mutation ($file: Upload!) { importSongs(file: $file) { status, messages } }", "variables": { "file": null } }'
    );
    formData.append('map', '{ "0": ["variables.file"] }');

    try {
      const res = await axios.post(action, formData, config);
      const dataRes = res?.data?.data?.importSongs;

      if (dataRes?.status === 'SUCCEED') {
        await apolloClient.refetchQueries({
          include: ['GetSongs']
        });
        return message.success({
          content: 'Import thành công!',
          key: 'import'
        });
      }

      if (dataRes?.status === 'FAILED') {
        return message.error({
          content: (
            <Space direction="vertical">
              {dataRes?.messages?.map(message => (
                <Typography.Text>{message}</Typography.Text>
              ))}
            </Space>
          ),
          duration: 5,
          key: 'import'
        });
      }

      onSuccess(res?.data);
    } catch (err) {
      onError({ err });
      notification.error({
        message: 'Lỗi upload file',
        description: 'Vui lòng thử lại sau!'
      });
    }
  };

  const onChange = args => {
    if (props.onChange) {
      props.onChange(args);
    }
  };

  return (
    <>
      <AntdUpload {...props} onChange={onChange} customRequest={uploadFile}>
        {children}
      </AntdUpload>
    </>
  );
};
export const Importupdate = ({ children, type, imageType, operations, ...props }) => {
  const uploadFile = async options => {
    message.loading({
      content: 'Đợi tí nha... !',
      key: 'import'
    });
    const { onSuccess, onError, file } = options;

    const action = process.env.REACT_APP_GRAPHQL_API;
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        authorization: `Bearer ${getAccessToken()}`
      }
    };
    const formData = new FormData();
    formData.append('0', file);
    formData.append(
      'operations',
      '{ "query": "mutation ($file: Upload!) { importUpdateSongs(file: $file) { status, messages } }", "variables": { "file": null } }'
    );
    formData.append('map', '{ "0": ["variables.file"] }');

    try {
      const res = await axios.post(action, formData, config);
      const dataRes = res?.data?.data?.importSongs;

      if (dataRes?.status === 'SUCCEED') {
        await apolloClient.refetchQueries({
          include: ['GetSongs']
        });
        return message.success({
          content: 'Import thành công!',
          key: 'import'
        });
      }

      if (dataRes?.status === 'FAILED') {
        return message.error({
          content: (
            <Space direction="vertical">
              {dataRes?.messages?.map(message => (
                <Typography.Text>{message}</Typography.Text>
              ))}
            </Space>
          ),
          duration: 5,
          key: 'import'
        });
      }

      onSuccess(res?.data);
    } catch (err) {
      onError({ err });
      notification.error({
        message: 'Lỗi upload file',
        description: 'Vui lòng thử lại sau!'
      });
    }
  };

  const onChange = args => {
    if (props.onChange) {
      props.onChange(args);
    }
  };

  return (
    <>
      <AntdUpload {...props} onChange={onChange} customRequest={uploadFile}>
        {children}
      </AntdUpload>
    </>
  );
};
export default Import;
