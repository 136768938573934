import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_ALBUM = gql`
  mutation EditAlbum($input: EditAlbumInput!) {
    editAlbum(input: $input) {
      id
      title
    }
  }
`;

export const ADD_ALBUM = gql`
  mutation AddAlbum($input: AddAlbumInput!) {
    addAlbum(input: $input) {
      id
      title
    }
  }
`;

export const REMOVE_ALBUM = gql`
  mutation RemoveAlbum($id: Int!) {
    removeAlbum(id: $id) {
      id
      title
    }
  }
`;

export const BULK_UPDATE_DURATION_ALBUM = gql`
  mutation BulkUpdateDurationAlbums($albumIds: [Int!]) {
    bulkUpdateDurationAlbums(albumIds: $albumIds) {
      id
      title
    }
  }
`;

export const BULK_ENCODE_AUDIO_ALBUM = gql`
  mutation BulkEncodeAudioAlbums($albumIds: [Int!]) {
    bulkEncodeAudioAlbums(albumIds: $albumIds) {
      id
      title
    }
  }
`;

export const BULK_EXPORT_ALBUM = gql`
  mutation BulkExportAlbums($input: ExportBulkAlbumInput) {
    bulkExportAlbums(input: $input) {
      link
      id
      fileName
      eventId
      createdDate
      createdBy
    }
  }
`;

export const BULK_EXPORT_ALBUM_BY_FIELDS = gql`
  mutation ExportAlbumsByFields($input: ExportAlbumInput) {
    exportAlbumsByFields(input: $input) {
      link
      id
      fileName
      eventId
      createdDate
      createdBy
    }
  }
`;

// export const BULK_COPYRIGHT_INFRINGEMENT = gql`
//   mutation BulkMarkCopyRightInfringementAlbums($albumIds: [Int!]) {
//     bulkMarkCopyRightInfringementAlbums(albumIds: $albumIds) {
//       id
//       title
//     }
//   }
// `;

// Clear copyright infringement
export const BULK_COPYRIGHT_INFRINGTMENT_ALBUMS = gql`
  mutation BulkActionCopyRightInfringementAlbums($albumIds: [Int!]) {
    bulkActionCopyRightInfringementAlbums(albumIds: $albumIds) {
      id
      title
    }
  }
`;

export const BULK_ACTION_CATEGORIES = gql`
  mutation BulkActionCategories($input: [BulkActionCategoryInput]) {
    bulkActionCategories(input: $input) {
      id
      title
      status
      isDeleted
    }
  }
`;

export const BULK_ACTION_ALBUMS = gql`
  mutation BulkActionAlbums($input: [BulkActionAlbumInput]) {
    bulkActionAlbums(input: $input) {
      id
      title
    }
  }
`;

export const useAddAlbum = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_ALBUM, {
    onCompleted: ({ addAlbum }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo album{' '}
            <Typography.Text strong>{addAlbum.name}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetAlbums']
  });
  return { mutate, data, error, loading };
};

export const useEditAlbum = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_ALBUM, {
    onCompleted: ({ editAlbum }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa album{' '}
            <Typography.Text strong>{editAlbum.title}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetAlbums', 'GetAlbumById']
  });
  return { mutate, data, error, loading };
};

export const useRemoveAlbum = () => {
  const [mutate, { data, error, loading }] = useMutation(REMOVE_ALBUM, {
    refetchQueries: ['GetAlbums'],
    onCompleted: ({ removeAlbum }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá album{' '}
            <Typography.Text strong>{removeAlbum.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useBulkUpdateDurationAlbum = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_UPDATE_DURATION_ALBUM,
    {
      refetchQueries: ['GetAlbums'],
      onCompleted: () => {
        notification.success({
          message: (
            <Typography.Text>Đã cập nhật thời lượng album</Typography.Text>
          )
        });
      }
    }
  );

  return { mutate, data, error, loading };
};

export const useBulkEncodeAudioAlbum = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ENCODE_AUDIO_ALBUM,
    {
      refetchQueries: ['GetAlbums'],
      onCompleted: () => {
        notification.success({
          message: <Typography.Text>Đã cập nhật encode album</Typography.Text>
        });
      }
    }
  );

  return { mutate, data, error, loading };
};

// export const useBulkCopyrightInfringment = () => {
//   const [mutate, { data, error, loading }] = useMutation(BULK_COPYRIGHT_INFRINGEMENT, {
//     refetchQueries: ['GetAlbums'],
//     onCompleted: () => {
//       notification.success({
//         message: (
//           <Typography.Text>Đã cập nhật vi phạm bản quyền</Typography.Text>
//         )
//       });
//     }
//   });

//   return { mutate, data, error, loading };
// };

export const useBulkActionCopyRightInfringementAlbums = actionField => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_COPYRIGHT_INFRINGTMENT_ALBUMS,
    {
      refetchQueries: ['GetAlbums'],
      onCompleted: ({ bulkActionCopyRightInfringementAlbums }) => {
        getBulkMessage(
          'album',
          bulkActionCopyRightInfringementAlbums,
          actionField
        );
      }
    }
  );

  return { mutate, data, error, loading };
};

export const useBulkActionAlbums = actionField => {
  const [mutate, { data, error, loading }] = useMutation(BULK_ACTION_ALBUMS, {
    refetchQueries: ['GetAlbums'],
    onCompleted: ({ bulkActionAlbums }) => {
      getBulkMessage('album', bulkActionAlbums, actionField);
    }
  });

  return { mutate, data, error, loading };
};

export const useBulkExportAlbums = () => {
  const [mutate, { data, error, loading }] = useMutation(BULK_EXPORT_ALBUM, {});

  return { mutate, data, error, loading };
};

export const useBulkExportAlbumByFields = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_EXPORT_ALBUM_BY_FIELDS
  );

  return { mutate, data, error, loading };
};
