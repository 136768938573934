import { Row, Tag } from 'antd';
import { PAGE_SIZE } from 'appConstants';

import React from 'react';

const QuantityTableFooter = ({ page, quantity, total }) => {
  const a = (page - 1) * PAGE_SIZE + 1;
  const renderContent = () => {
    return `${a}-${a + quantity - 1}/${total}`;
  };
  return (
    <Row justify="end">
      {total !== 1 && total !== 0 && (
        <Tag color="processing">{renderContent()}</Tag>
      )}
    </Row>
  );
};

export default QuantityTableFooter;
