import { Typography } from 'antd';

import React from 'react';

import ArtistList from './components/ArtistList';
import ArtistFilter from './ArtistFilter';

const Artist = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách nghệ sĩ</Typography.Title>

      <ArtistFilter />

      <ArtistList />
    </div>
  );
};

export default Artist;
