import { gql } from '@apollo/client';

export const GET_WEB_NOTIFICATIONS = gql`
  query GetWebNotifications(
    $skip: Int
    $take: Int
    $order: [WebNotificationSortInput!]
    $where: WebNotificationFilterInput
  ) {
    webNotifications(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        content
        title
        type
        siteId
        siteName
        url
        iconLink
        image
        sendingStatus
        sendingStatusName
        approvingStatus
        sendingTime
        updatedBy
        updatedDate
        createdBy
        createdDate
      }
    }
  }
`;

export const GET_WEB_NOTIFICATION_BY_ID = gql`
  query GetWebNotificationById($id: Int!) {
    webNotification(id: $id) {
      id
      content
      title
      type
      siteId
      siteName
      url
      iconLink
      image
      sendingTime
    }
  }
`;

export const GET_APP_NOTIFICATIONS = gql`
  query GetAppNotifications(
    $skip: Int
    $take: Int
    $order: [AppNotificationSortInput!]
    $where: AppNotificationFilterInput
  ) {
    appNotifications(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        title
        body
        type
        siteId
        siteName
        image
        sendingStatus
        sendingTime
        approvingStatus
        updatedBy
        updatedDate
        createdBy
        createdDate
      }
    }
  }
`;

export const GET_APP_NOTIFICATION_BY_ID = gql`
  query GetAppNotificationById($id: Int!) {
    appNotification(id: $id) {
      id
      title
      type
      siteId
      clickAction
      siteName
      image
      sendingTime
      body
      nid
      nextSentAt
    }
  }
`;
