import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_USER = gql`
  mutation EditUser($input: EditUserInput) {
    editUser(input: $input) {
      userId
    }
  }
`;

export const ADD_USER = gql`
  mutation AddNewUser($addUserInput: AddUserInput!) {
    addUser(input: $addUserInput) {
      userId
    }
  }
`;

export const REMOVE_USER = gql`
  mutation RemoveUser($input: RemoveUserInput) {
    removeUser(input: $input) {
      userId
      fullName
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile($input: UpdateProfileInput) {
    updateProfile(input: $input) {
      fullName
    }
  }
`;

export const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      userId
    }
  }
`;

export const BULK_ACTION_USERS = gql`
  mutation BulkActionUsers($input: [BulkActionUserInput]) {
    bulkActionUsers(input: $input) {
      id
      status
      isDeleted
    }
  }
`;

export const RESET_PASSWORD = gql`
  mutation ResetPasswordUser($input: ResetPasswordUserInput!) {
    resetPasswordUser(input: $input) {
      id
      name
    }
  }
`;

export const useResetPassword = () => {
  const [mutate, { data, error }] = useMutation(RESET_PASSWORD, {
    onCompleted: ({ resetPasswordUser }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã đặt lại mật khẩu cho{' '}
            <Typography.Text strong>{resetPasswordUser.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useAddUser = () => {
  const [mutate, { data, error }] = useMutation(ADD_USER, {
    refetchQueries: ['GetUsers'],
    onCompleted: ({ addUser }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo user <Typography.Text strong>{addUser.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useEditUser = () => {
  const [mutate, { data, error }] = useMutation(EDIT_USER, {
    refetchQueries: ['GetUsers'],

    onCompleted: ({ editUser }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa user{' '}
            <Typography.Text strong>{editUser.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useRemoveUser = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_USER, {
    refetchQueries: ['GetUsers'],
    onCompleted: ({ removeUser }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá user{' '}
            <Typography.Text strong>{removeUser.fullName}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useUpdateProfile = () => {
  const [mutate, { data, error, loading }] = useMutation(UPDATE_PROFILE, {
    onCompleted: () => {
      notification.success({
        message: (
          <Typography.Text>Đã cập nhật thông tin cá nhân</Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useChangePassword = () => {
  const [mutate, { data, error, loading }] = useMutation(CHANGE_PASSWORD, {
    onCompleted: () => {
      notification.success({
        message: <Typography.Text>Đổi mật khẩu thành công</Typography.Text>
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useBulkUsers = actionField => {
  const [mutate, { data, error }] = useMutation(BULK_ACTION_USERS, {
    refetchQueries: ['GetUsers'],
    onCompleted: ({ bulkActionUsers }) => {
      getBulkMessage('User', bulkActionUsers, actionField);
    }
  });

  return { mutate, data, error };
};
