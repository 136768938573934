import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const ADD_ADV_POSITION = gql`
  mutation AddIcAdvertPosition($input: AddIcAdvertPositionInput) {
    addIcAdvertPosition(input: $input) {
      id
      positionName
    }
  }
`;

export const EDIT_ADV_POSITION = gql`
  mutation EditIcAdvertPosition($input: EditIcAdvertPositionInput) {
    editIcAdvertPosition(input: $input) {
      id
      positionName
    }
  }
`;

export const REMOVE_ADV_POSITION = gql`
  mutation RemoveIcAdvPosition($id: Int!) {
    removeIcAdvPosition(id: $id) {
      id
      
    }
  }
`;

export const BULK_ACTION_ADV_POSITIONS = gql`
  mutation BulkActionPageInfoContacts(
    $input: [BulkActionPageInfoContactInput]
  ) {
    bulkActionPageInfoContacts(input: $input) {
      id
      name
    }
  }
`;

// ----------------------------------------------------
// Hook

export const useAddIcAdvertPosition = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_ADV_POSITION, {
    onCompleted: ({ addIcAdvertPosition }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo vị trí quảng cáo{' '}
            <Typography.Text strong>{addIcAdvertPosition.positionName}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useEditIcAdvertPosition = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_ADV_POSITION, {
    onCompleted: ({ editIcAdvertPosition }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa quảng{' '}
           <Typography.Text strong>{editIcAdvertPosition.positionName}</Typography.Text> 
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

// export const useBulkActionPageInfoContacts = actionField => {
//   const [mutate, { data, error, loading }] = useMutation(
//     BULK_ACTION_ADVS,
//     {
//       refetchQueries: ['GetPageInfoContacts'],
//       onCompleted: ({ bulkActionPageInfoContacts }) => {
//         getBulkMessage(
//           'Thông tin trang',
//           bulkActionPageInfoContacts,
//           actionField
//         );
//       }
//     }
//   );

//   return { mutate, data, error, loading };
// };

// --------------------------------------------

export const useRemoveIcAdvertPosition = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_ADV_POSITION, {
    refetchQueries: ['GetIcAdvertPositions'],
    onCompleted: ({ removeIcAdvPosition }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá vị trí quảng cáo{' '}
            <Typography.Text strong>
              {removeIcAdvPosition.positionName}
            </Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};
