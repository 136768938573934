import { Card, List, Typography } from 'antd';
import UserAvatar from 'components/UserAvatar';
import { Link } from 'react-router-dom';

const SongPlaylistInfo = ({ song }) => {
  return (
    <Card>
      <List
        header={
          <Typography.Title strong level={3}>
            Danh sách playlist
          </Typography.Title>
        }
        itemLayout="horizontal"
        dataSource={song?.playlists}
        renderItem={item => (
          <List.Item>
            <List.Item.Meta
              avatar={
                <UserAvatar
                  fullName={item.name}
                  avatar={item.thumbnail}
                  shape="square"
                  size={48}
                />
              }
              title={
                <Link
                  style={{ color: 'inherit' }}
                  to={`/chi-tiet-playlist/${item?.id}`}
                >
                  {item?.name}
                </Link>
              }
              description={item?.categories?.map(cate => cate?.name).join(', ')}
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default SongPlaylistInfo;
