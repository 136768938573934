import { gql } from '@apollo/client';

export const GET_PAGE_INFOS = gql`
  query GetPageInfoContacts(
    $skip: Int
    $take: Int
    $order: [PageInfoContactSortInput!]
    $where: PageInfoContactFilterInput
  ) {
    pageInfoContacts(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        contactInfo {
          email
          address
          phone
        }
        footer
        content
        createBy
        createDate
        createdBy
        createdDate
        id
        name
        slug
        status
        statusName
        updateBy
        updateDate
        updatedBy
        updatedDate
      }
    }
  }
`;

export const GET_PAGE_INFO_BY_ID = gql`
  query GetPageInfoContactById($id: Int!) {
    pageInfoContact(id: $id) {
      contactInfo {
        email
        address
        phone
      }
      footer
      content
      createBy
      createDate
      createdBy
      createdDate
      id
      name
      slug
      status
      statusName
      updateBy
      updateDate
      updatedBy
      updatedDate
    }
  }
`;
