import React from 'react';
import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { COMMENT_STATUS_DICTIONARY } from 'appConstants';

const StatusButton = ({ status, onClick, total }) => {
  status = status == 1 || status == 3 ? 2 : 3
  const _status = status;
  return (
    <Button
      type="primary"
      danger={_status === 0}
      onClick={onClick}
      block
      icon={
        _status === 2 ? <CheckCircleOutlined /> : <StopOutlined />
      }
      style={{
        background: _status === 2 ? 'green' : '',
        border: 'none',
      }}
    >
      {COMMENT_STATUS_DICTIONARY[_status]} ({total})
    </Button>
  );
};

export default StatusButton;
