import { useQuery } from '@apollo/client';
import { Button, Space } from 'antd';

import { ORDER_BY_DICTIONARYV2, PAGE_SIZE, TABLE_PROPS } from 'appConstants';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import { GET_COMMENT } from 'operations/queries';
import { COMMENT_STATUS_CODE } from 'appConstants';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { columns } from '../columns';
import StatusButton from './StatusButton';
import { useStatusComment } from 'operations/mutations';
import { COMMENT_STATUS_KEY_INT } from 'appConstants';
const orderValue = {
  LATEST: {
    value: { createDate: 'ASC' },
    displayName: 'Cũ nhất'
  },
  EARLIEST: {
    value: { createDate: 'DESC' },
    displayName: 'Mới nhất'
  }
};

const statusSelectValue = {
  all: {
    value: undefined,
    displayName: 'Tất cả'
  },
  boDuyet: {
    value: 'ABORT',
    displayName: 'Bỏ duyệt'
  },
  doiDuyet: {
    value: 'WAITING',
    displayName: 'Đợi duyệt'
  },
  duyet: {
    value: 'APPROVED',
    displayName: 'Đã duyệt'
  }
};

const CommentList = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [Comments, setComments] = useState([]);
  const [total, setTotal] = useState();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const filterQuery = useMemo(() => {
    const query = {};
    if (searchParams.statusId != undefined) {
      query.statusId = {
        eq: parseInt(searchParams.statusId)
      };
    }
    return query;
  }, [searchParams]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const selectStatus = useMemo(() => {
    if (searchParams.statusId == 3) return 'boDuyet';
    if (searchParams.statusId == 2) return 'Duyet';
    if (searchParams.statusId == 1) return 'doiDuyet';
    return 'all';
  }, [searchParams.statusId]);
  const pageSize = useMemo(
    () => parseInt(searchParams.pageSize) || PAGE_SIZE,
    [searchParams.pageSize]
  );
  const {
    loading: isCommentsLoading,
    error: CommentsError,
    data: CommentsConnect
  } = useQuery(GET_COMMENT, {
    variables: {
      take: pageSize,
      skip: pageSize * page - pageSize,
      order: [ORDER_BY_DICTIONARYV2[sort].value],
      where: searchParams.statusId > 0 ? filterQuery : undefined
    },
    fetchPolicy: 'no-cache'
  });
  const isLoading = useMemo(() => isCommentsLoading, [isCommentsLoading]);

  useEffect(() => {
    if (!isCommentsLoading && CommentsConnect) {
      const _data = CommentsConnect?.comments?.items?.map(item => {
        let moreInfor = {};
        try {
          moreInfor = JSON.parse(item.question);
        } catch {}
        const _item = {
          ...item,
          key: item.id,
          cmnd: moreInfor.cmnd,
          confirm: moreInfor.confirm,
          link: moreInfor.link,
          rights: moreInfor.rights
        };
        return _item;
      });
      setComments(_data);
      setTotal(CommentsConnect?.comments?.totalCount);
    }
  }, [isCommentsLoading, CommentsConnect]);

  const onPaginate = (page, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, page, pageSize })
    });
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({
        ...searchParams,
        page: 1,
        sort,
        pageSize
      })
    });
  };

  const setSelectStatus = val => {
    var statusId = COMMENT_STATUS_KEY_INT[statusSelectValue[val].value];
    history.push({
      search: queryString.stringify({
        ...searchParams,
        page: 1,
        statusId,
        pageSize
      })
    });
  };

  const onShowSizeChange = (_, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, pageSize })
    });
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedKeys);
    }
  };

  const { mutate } = useStatusComment();
  async function changeStatus(status) {
    var _status = status == 2 ? 3 : 2;
    _status = COMMENT_STATUS_CODE[_status];
    var input = selectedRows.map((item, index) => {
      let _item = {
        id: item.id,
        status: _status
      };
      return _item;
    });
    await mutate({
      variables: {
        input
      }
    });
    setSelectedKeys([]);
    setSelectedRows([]);
  }

  if (CommentsError) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          {selectedRows && selectedRows.length > 0 ? (
            <StatusButton
              status={selectedRows[0]?.statusId}
              onClick={() => changeStatus(selectedRows[0]?.statusId)}
              total={selectedRows.length}
            />
          ) : (
            <>
              <div>Trạng thái: </div>
              <SortSelect
                value={selectStatus}
                dict={statusSelectValue}
                onChange={setSelectStatus}
              />
              <SortSelect
                value={sort}
                dict={orderValue}
                onChange={setOrderBy}
              />
            </>
          )}
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={Comments}
        pagination={{
          total,
          pageSize: pageSize,
          onChange: onPaginate,
          current: page * 1,
          quantity: Comments?.length,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          onShowSizeChange: onShowSizeChange
        }}
        loading={isLoading}
        rowSelection={rowSelection}
      />
    </ListCard>
  );
};

export default CommentList;
