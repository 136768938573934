import { Typography } from 'antd';
import { TOPIC_TYPE_DICTIONARY } from 'appConstants';
import ColName from 'components/ColName';
import RelatedUser from 'components/RelatedUser';
import RemoveButton from 'components/RemoveButton';
import StatusTag from 'components/StatusTag';
import TimeLine from 'components/TimeLine';
import 'moment/locale/vi';
import { useRemoveTopic } from 'operations/mutations';
import { Link } from 'react-router-dom';

const RemoveMood = ({ record }) => {
  const { mutate } = useRemoveTopic();
  return (
    <RemoveButton record={record} actionRemove={mutate} titleKey="title" />
  );
};

export const columns = [
  {
    title: 'Topic',
    dataIndex: 'title',
    key: 'title',
    render: (_, record) => (
      <ColName
        primary={
          <Link
            style={{ color: 'inherit' }}
            to={`/chi-tiet-topic/${record?.id}`}
          >
            {record?.title}
            {/* {<EditOutlined />} */}
          </Link>
        }
        secondary={record?.uniqId}
        // avatar={{ image: record?.thumbnail, name: record?.title }}
        tag={<StatusTag status={record?.status} />}
      />
    ),
    fixed: 'left',
    width: '120px'
  },
  {
    title: 'Loại topic',
    dataIndex: 'typeName',
    key: 'typeName',
    width: '120px',
    render: typeName => (
      <Typography.Text>{TOPIC_TYPE_DICTIONARY[typeName]}</Typography.Text>
    )
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '80px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    width: '80px',
    render: (_, { createdBy, updatedBy }) => (
      <RelatedUser creator={createdBy} updater={updatedBy} />
    )
  },
  {
    title: 'Thao tác',
    width: '50px',
    render: (_, record) => <RemoveMood record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
