import { useLazyQuery } from '@apollo/client';
import AutoComplete from 'components/AutoComplete';
import { GET_CATEGORIES } from 'operations/queries/queryCategory';
import React, { useMemo } from 'react';

const CategoryAutoComplete = ({ category, type = 1, ...props }) => {
  const [searchCategory] = useLazyQuery(GET_CATEGORIES);
  const defaultOption = useMemo(
    () =>
      category
        ? Array.isArray(category)
          ? category.map(({ id, name }) => ({
              value: id,
              label: name
            }))
          : { value: category.id, label: category.name }
        : null,
    [category]
  );

  return (
    <AutoComplete
      mode="multiple"
      defaultOption={defaultOption}
      handleSearch={searchCategory}
      labelKey="name"
      valueKey="id"
      dataKey="categories"
      placeholder="Tìm thể loại"
      style={{ width: '100%' }}
      optionQuery={{
        type: {
          eq: type
        }
      }}
      {...props}
    />
  );
};

export default CategoryAutoComplete;
