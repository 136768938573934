import { Space, Typography, Tag } from 'antd';
import RemoveButton from 'components/RemoveButton';

import {
    COMMON_STATUS_INT_DICTIONARY,
    COMMON_STATUS_KEY_INT,
    ADV_CONTENT
} from 'appConstants';
import 'moment/locale/vi';
import moment from 'moment';

import { useRemoveIcAdvertPositionAdv } from 'operations/mutations';
import { Link } from 'react-router-dom';

const RemoveAdv = ({ record }) => {
    const { mutate } = useRemoveIcAdvertPositionAdv();
    return <RemoveButton record={record} actionRemove={mutate} titleKey="name" />;
};

const generateColor = status => {
    switch (status) {
        case COMMON_STATUS_KEY_INT.WAITING:
            return 'default';

        case COMMON_STATUS_KEY_INT.APPROVED:
            return 'green';

        default:
            break;
    }
};

const StatusTag = ({ status, ...props }) => {
    return (
        <Tag color={generateColor(status)} {...props}>
            {COMMON_STATUS_INT_DICTIONARY[status]}
        </Tag>
    );
};

export const columnsApa = [
    {
        title: 'Tên quảng cáo',
        dataIndex: 'name',
        key: 'name',
        render: (_, { advert }) => (
            <Space direction="vertical">
                <Typography.Text strong>
                    <Link
                        style={{ color: 'inherit' }}
                        to={`/chi-tiet-quang-cao/${advert?.id}`}
                    >
                        {advert?.advertName}
                    </Link>
                </Typography.Text>
                <Space direction="horizontal">
                    {ADV_CONTENT[advert?.advertContentTypeId]}
                    <StatusTag status={advert?.advertStatusId} />
                </Space>
                <Typography.Text>
                    {advert?.advertDesc}
                </Typography.Text>

            </Space>
        ),
        fixed: 'left',
    },
    {
        title: 'Trang gán quảng cáo',
        dataIndex: 'advertStatusId',
        render: (_, { pageName }) => (
            <Space direction="horizontal">
                {pageName}
            </Space>
        )
    },
    {
        title: '',
        width: '150px',
        render: (_, record) => <RemoveAdv record={record} />,
        toggleEdit: true,
        align: 'center'
    }
];
