import { useLazyQuery, useQuery } from '@apollo/client';
import AutoComplete from 'components/AutoComplete';
import { GET_ROLES } from 'operations/queries/queryRole';
import React, { useMemo } from 'react';

const RoleAutocomplete = ({ role, ...props }) => {
  const [searchRole] = useLazyQuery(GET_ROLES);
  const { data } = useQuery(GET_ROLES, {
    variables: {
      take: 50
    }
  });

  const defaultOption = useMemo(
    () =>
      role
        ? Array.isArray(role)
          ? role.map(({ id, title }) => ({ value: id, label: title }))
          : { value: role.id, label: role.title }
        : data?.roles?.items?.map(({ id, title }) => ({
            value: id,
            label: title
          })),
    [data, role]
  );

  return (
    <AutoComplete
      mode="single"
      defaultOption={defaultOption}
      handleSearch={searchRole}
      labelKey="title"
      valueKey="id"
      dataKey="roles"
      placeholder="Tìm vai trò"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default RoleAutocomplete;
