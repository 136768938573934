import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import {
  COMMON_ACTION_STATUS_DICTIONARY,
  COMMON_STATUS_KEY_INT
} from 'appConstants';

const ApproveButton = ({ status, onClick, total }) => {
  return (
    <Button
      type="primary"
      danger={status === COMMON_STATUS_KEY_INT.APPROVED}
      onClick={onClick}
      block
      icon={
        status === COMMON_STATUS_KEY_INT.APPROVED ? (
          <CheckCircleOutlined />
        ) : (
          <StopOutlined />
        )
      }
      style={{
        background: status === COMMON_STATUS_KEY_INT.APPROVED && 'green',
        border: 'none'
      }}
    >
      {COMMON_ACTION_STATUS_DICTIONARY[status]} ({total})
    </Button>
  );
};

export default ApproveButton;
