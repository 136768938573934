import { ScissorOutlined } from '@ant-design/icons';
import { Button, Card, Col, Form, Input, Row, Tooltip } from 'antd';
import {
  COMMON_STATUS_KEY_BY_INT,
  DEBOUNCE_VALUE,
  UPLOAD_TYPE
} from 'appConstants';
import ImageUpload from 'components/ImageUpload';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { debounce, get } from 'utils/lodash';
import { sanitizeSlug } from 'utils/parser';

const MoodInfo = ({ mood, isUpdate, onEditMood, onAddMood }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (mood) {
      form.setFieldsValue({
        title: mood.title,
        slug: mood.slug,
        intro: mood.intro,
        thumbnail: mood.thumbnail,
        banner: mood.banner,
        thumbnailSocial: mood.thumbnailSocial
      });
    }
  }, [mood, form]);

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const onChangeTitle = debounce(() => {
    const { title, slug } = form.getFieldsValue();
    if (!slug) {
      const _slug = sanitizeSlug(title);
      form.setFieldsValue({ slug: _slug });
    }
  }, DEBOUNCE_VALUE);

  const onGenerateSlug = () => {
    const { title } = form.getFieldsValue();
    if (!title) return;
    const _slug = sanitizeSlug(title);
    form.setFieldsValue({ slug: _slug });
  };

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const thumbnail = get(
        values?.thumbnail,
        '[0].response.Data',
        mood?.thumbnail
      );
      const banner = get(values?.banner, '[0].response.Data', mood?.banner);
      const thumbnailSocial = get(
        values?.thumbnailSocial,
        '[0].response.Data',
        mood?.thumbnailSocial
      );

      const params = {
        ...values,
        thumbnail,
        thumbnailSocial,
        banner
      };

      if (isUpdate) {
        // update mood
        await onEditMood({
          variables: {
            input: {
              ...params,
              status: COMMON_STATUS_KEY_BY_INT[mood?.status],
              id: parseInt(id)
            }
          }
        });
      } else {
        //  create mood
        await onAddMood({
          variables: {
            input: params
          },
          onCompleted: ({ addMood }) => {
            history.push('/chi-tiet-chu-de/' + addMood?.id);
          }
        });
      }
    } catch (error) {}
  };

  return (
    <Card>
      <Form form={form} layout="vertical">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Tên chủ đề"
              rules={[{ required: true, message: 'Nhập tên chủ đề!' }]}
            >
              <Input placeholder="Nhập tên chủ đề" onMouseOut={onChangeTitle} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="slug"
              label="Đường dẫn"
              rules={[{ required: true, message: 'Nhập slug!' }]}
            >
              <Input
                suffix={
                  <Tooltip title="Khởi tạo đường dẫn">
                    <ScissorOutlined
                      style={{ fontSize: 16 }}
                      onClick={onGenerateSlug}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="intro"
              label="Giới thiệu"
              rules={[{ required: true, message: 'Nhập giới thiệu!' }]}
            >
              <Input.TextArea rows={7} placeholder="Nhập giới thiệu" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row justify="space-between">
              <Form.Item
                name="thumbnail"
                label="Thumbnail"
                valuePropName="file"
                getValueFromEvent={normFile}
              >
                <ImageUpload
                  avatarShape="square"
                  size={128}
                  fontSize={64}
                  fullName="Thumbnail"
                  {...UPLOAD_TYPE.THUMBNAIL_MOOD}
                />
              </Form.Item>

              <Form.Item
                name="thumbnailSocial"
                label="Share thumbnail"
                valuePropName="file"
                getValueFromEvent={normFile}
              >
                <ImageUpload
                  avatarShape="square"
                  size={128}
                  fontSize={64}
                  fullName="Share thumbnail"
                  freeStyle={isUpdate}
                  {...UPLOAD_TYPE.SHARE_MOOD}
                />
              </Form.Item>

              <Form.Item
                name="banner"
                label="Banner"
                valuePropName="file"
                getValueFromEvent={normFile}
              >
                <ImageUpload
                  avatarShape="square"
                  size={128}
                  fontSize={64}
                  fullName="Banner"
                  {...UPLOAD_TYPE.BANNER_MOOD}
                />
              </Form.Item>
            </Row>
          </Col>
        </Row>
      </Form>

      <Row justify="end">
        <Col>
          <Button onClick={onSave} type="primary">
            Lưu
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default MoodInfo;
