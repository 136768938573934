import { Collapse, Typography } from 'antd';

import React from 'react';

import WebNotificationList from './components/WebNotificationList';
import WebNotificationFilter from './WebNotificationFilter';

const WebNotification = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách Thông báo website</Typography.Title>

      <Collapse defaultActiveKey={['1']} style={{ marginBottom: 16 }}>
        <Collapse.Panel header="Bộ lọc thông báo" key="1">
          <WebNotificationFilter />
        </Collapse.Panel>
      </Collapse>

      <WebNotificationList />
    </div>
  );
};

export default WebNotification;
