import {
  ApartmentOutlined,
  BulbOutlined,
  DatabaseOutlined,
  NotificationOutlined,
  ProjectOutlined,
  SearchOutlined,
  TagsOutlined,
  UserOutlined,
  SmileOutlined,
  ContainerOutlined,
  TeamOutlined
} from '@ant-design/icons';
import { useReactiveVar } from '@apollo/client';
import { Layout, Menu } from 'antd';
import { currentPermissionsVar } from 'api/cache';
import logo from 'assets/images/logo.svg';
import Music from 'components/Icons/Music';
import { useMemo } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { get } from 'utils/lodash';

const { Sider } = Layout;

const iconStyle = { fontSize: '20px' };

const menuItems = [
  {
    name: 'Âm nhạc',
    icon: <Music style={iconStyle} />,
    code: 'music',
    children: [
      {
        to: '/danh-sach-bai-hat',
        name: 'Bài hát',
        code: 'song'
      },
      {
        to: '/danh-sach-video',
        name: 'Video/MV',
        code: 'video'
      },
      {
        to: '/danh-sach-album',
        name: 'Album',
        code: 'album'
      },
      {
        to: '/danh-sach-playlist',
        name: 'Playlist',
        code: 'playlistDetail'
      },
      {
        to: '/danh-sach-chu-de',
        name: 'Chủ đề',
        code: 'mood'
      },
      {
        to: '/danh-sach-nghe-si',
        name: 'Nghệ sĩ',
        code: 'artist'
      }
    ]
  },
  {
    // to: '/danh-sach-chuyen-muc',
    name: 'Chuyên mục',
    icon: <BulbOutlined style={iconStyle} />,
    code: 'category-list',
    children: [
      {
        to: '/danh-sach-chuyen-muc',
        name: 'Quản lý chuyên mục',
        code: 'cate'
      },
      {
        to: '/danh-sach-nhom-chuyen-muc',
        name: 'Nhóm chuyên mục',
        code: 'categroup'
      }
    ]
  },
  {
    name: 'Tag',
    icon: <TagsOutlined style={iconStyle} />,
    code: 'tag',
    to: '/danh-sach-tag'
  },
  {
    name: 'Topic',
    icon: <DatabaseOutlined style={iconStyle} />,
    code: 'topic',
    to: '/danh-sach-topic'
  },

  {
    name: 'Notification',
    icon: <NotificationOutlined style={iconStyle} />,
    code: 'notification-list',
    children: [
      {
        name: 'Web Notification',
        code: 'webNotification',
        to: '/danh-sach-web-notification'
      },
      {
        name: 'App Notification',
        code: 'appNotification',
        to: '/danh-sach-app-notification'
      }
    ]
  },
  {
    name: 'Homepage',
    icon: <ProjectOutlined style={iconStyle} />,
    code: 'homepage',
    children: [
      {
        name: 'Thịnh hành',
        // icon: <RiseOutlined style={iconStyle} />,
        code: 'topPopular',
        to: '/homepage-thinh-hanh'
      },
      {
        name: 'Bài hát nổi bật',
        // icon: <ThunderboltOutlined style={iconStyle} />,
        code: 'hotSong',
        to: '/homepage-bai-hat-noi-bat'
      },
      {
        name: 'Banner',
        // icon: <ThunderboltOutlined style={iconStyle} />,
        code: 'topBanner',
        to: '/homepage-banner'
      }
    ]
  },
  {
    name: 'Từ khoá tìm kiếm',
    icon: <SearchOutlined style={iconStyle} />,
    code: 'search',
    to: '/danh-sach-tu-khoa-tim-kiem'
  },
  {
    name: 'Thông tin trang',
    icon: <ContainerOutlined style={iconStyle} />,
    code: 'pageInfoContact',
    to: '/danh-sach-thong-tin-trang'
  },
  {
    name: 'Thống kê client',
    icon: <SmileOutlined style={iconStyle} />,
    code: 'client',
    to: '/danh-sach-client'
  },

  // {
  //   name: 'User Auditlogs',
  //   icon: <SearchOutlined style={iconStyle} />,
  //   code: 'auditLog',
  //   to: '/danh-sach-audit-log'
  // },
  {
    name: 'Đối tác',
    icon: <TeamOutlined style={iconStyle} />,
    code: 'partner',
    to: '/danh-sach-partner'
  },
  {
    name: 'User',
    icon: <UserOutlined style={iconStyle} />,
    code: 'user',
    to: '/danh-sach-user'
  },
  {
    name: 'Vai trò',
    icon: <ApartmentOutlined style={iconStyle} />,
    code: 'role',
    to: '/danh-sach-vai-tro'
  },
  {
    name: 'Danh sách comment',
    icon: <ProjectOutlined style={iconStyle} />,
    code: 'comment',
    to: '/danh-sach-comment'
  },
  {
    name: 'Báo cáo Link YouTube',
    icon: <ContainerOutlined style={iconStyle} />,
    code: 'contact',
    to: '/bao-cao-link-nhung-youtube'
  },
  {
    name: 'Quảng cáo',
    icon: <ProjectOutlined style={iconStyle} />,
    code: 'advpage',
    children: [
      {
        name: 'Quản lý quảng cáo',
        code: 'adv',
        to: '/quang-cao'
      },
      {
        name: 'Quản lý vị trí quảng cáo',
        code: 'advPosition',
        to: '/vi-tri-quang-cao'
      },

    ]
  }
];

const Sidebar = () => {
  const location = useLocation();
  const selectedKey = get(location.pathname.split('/'), [1], '');
  const currentPermissions = useReactiveVar(currentPermissionsVar);
  let menuByPermissions = useMemo(() => {
    let menus = [];
    menuItems.forEach(rootMenu => {
      if (rootMenu?.children) {
        let subMenus = [];
        if (rootMenu?.code === 'homepage') {
          // Check homepage permissions
          if (currentPermissions?.includes('homepage')) menus.push(rootMenu);
          return;
        }

        rootMenu?.children.forEach(sub => {
          if (currentPermissions?.includes(sub?.code)) subMenus.push(sub);
          return false;
        });
        if (!!subMenus.length) menus.push({ ...rootMenu, children: subMenus });
      } else {
        if (
          currentPermissions?.includes(rootMenu?.code) ||
          rootMenu?.code === 'client' ||
          rootMenu?.code === 'pageInfo'
        )
          menus.push(rootMenu);
        return false;
      }
    });
    return menus;
  }, [currentPermissions]);

  const currentMenus = useMemo(
    () =>
      menuByPermissions.map(({ icon, name, to, code, params, children }) => {
        return {
          label: to ? <Link to={to + (params || '')}>{name}</Link> : name,
          key: to?.slice(1) || code || '',
          icon,
          children: children?.map(c => ({
            ...c,
            key: c?.to?.slice(1) || '',
            label: <Link to={c?.to + (c?.params || '')}>{c?.name}</Link>
          }))
        };
      }),
    [menuByPermissions]
  );

  return (
    <Sider trigger={null} collapsible breakpoint="lg">
      <div className="navi-logo">
        <Link to="/">
          <img alt="logo" src={logo} className="navi-logo-img" />
        </Link>
      </div>
      <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[selectedKey]}
        items={currentMenus}
      ></Menu>
    </Sider>
  );
};

export default Sidebar;
