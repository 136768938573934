import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, message, Space, Table, Typography } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import UserAvatar from 'components/UserAvatar';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import PlaylistSearch from './PlaylistSearch';

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const PlaylistOfPlaylist = ({ onSelect, playlists }) => {
  const [dataSource, setDataSource] = useState(playlists || []);

  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: 'grab',
        color: '#999'
      }}
    />
  ));

  const onClearPlaylist = id => {
    const newData = dataSource.filter(_d => _d.id !== id);
    const _newData = newData?.map((_data, index) => ({ ..._data, index }));
    setDataSource(_newData);
    onSelect(newData.map(_d => _d.id));
  };

  const columns = [
    {
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Playlist',
      dataIndex: 'name',
      className: 'drag-visible',
      render: (name, { id, thumbnail }) => (
        <Space>
          <UserAvatar
            fullName={name}
            avatar={thumbnail}
            shape="square"
            size={48}
          />
          <Link target="_blank" to={`/chi-tiet-playlist/${id}`}>
            {name}
          </Link>
        </Space>
      )
    },
    {
      title: 'Ca sĩ',
      dataIndex: 'artist',
      render: artist => (
        <Space direction="vertical">
          {artist?.map(_a => (
            <Typography.Text>{_a?.name}</Typography.Text>
          ))}
        </Space>
      )
    },
    {
      title: 'Nhạc sĩ',
      dataIndex: 'musician',
      render: musician => (
        <Space direction="vertical">
          {musician?.map(_m => (
            <Typography.Text>{_m?.name}</Typography.Text>
          ))}
        </Space>
      )
    },
    {
      title: 'Thể loại',
      dataIndex: 'category',
      render: category => (
        <Space direction="vertical">
          {category?.map(_c => (
            <Typography.Text>{_c?.name}</Typography.Text>
          ))}
        </Space>
      )
    },

    {
      dataIndex: 'id',
      width: 50,
      render: id => (
        <Button
          onClick={() => onClearPlaylist(id)}
          size="small"
          danger
          icon={<DeleteOutlined />}
        ></Button>
      )
    }
  ];

  useEffect(() => {
    if (!!playlists?.length) {
      const _playlists = playlists.map((playlist, index) => ({
        ...playlist,
        index,
        key: playlist.id
      }));
      setDataSource(_playlists);
    }
  }, [playlists]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter(el => !!el);
      setDataSource(newData);
      onSelect(newData.map(_d => _d.id));
    }
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      x => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const onAddPlaylistToPlaylist = playlist => {
    if (!playlist) return;
    const isExist = !!dataSource.find(_data => _data.id === playlist.id);
    if (isExist) {
      return message.error('Playlist đã tồn tại trong danh sách');
    }
    setDataSource(dataSource => [
      ...dataSource,
      { ...playlist, index: dataSource?.length }
    ]);
    onSelect([...dataSource, playlist].map(_playlist => _playlist.id));
    return message.success('Đã thêm playlist vào danh sách');
  };

  return (
    <div className="mb-md">
      <div className="mb-md">
        <Typography.Text>Danh playlist hát của topic</Typography.Text>
        <PlaylistSearch onSelect={onAddPlaylistToPlaylist} />
      </div>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }}
      />
    </div>
  );
};

export default PlaylistOfPlaylist;
