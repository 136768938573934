import React, { useState } from 'react';
import { notification, Progress, Upload as AntdUpload } from 'antd';
import { getAccessToken } from 'utils/authority';
import axios from 'axios';
import { removeAccents } from 'utils/other';

const Upload = ({ children, type, imageType, ...props }) => {
  const [_progress, _setProgress] = useState({});

  const uploadFile = async options => {
    const { onSuccess, onError, file, onProgress, filename, data } = options;

    const action = process.env.REACT_APP_UPLOAD_API;
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        authorization: `Bearer ${getAccessToken()}`
      },
      onUploadProgress: event => {
        const percent = Math.floor((event.loaded / event.total) * 100);

        if (percent <= 99) {
          _setProgress(prev => ({
            ...prev,
            [file.name]: percent
          }));

          onProgress({ percent: (event.loaded / event.total) * 100 });
        }
      }
    };
    const formData = new FormData();

    if (data) {
      Object.keys(data).forEach(key => {
        formData.append(key, data[key]);
      });
    }
    const fileParser = new File([file], removeAccents(file.name));

    formData.append(filename, fileParser);
    formData.append('type', type);
    formData.append('imageType', imageType);

    try {
      const res = await axios.post(action, formData, config);

      onSuccess(res?.data);
    } catch (err) {
      onError({ err });
      notification.error({
        message: 'Lỗi upload file',
        description: 'Vui lòng thử lại sau!'
      });
      _setProgress({});
    }
  };

  const onChange = args => {
    if (props.onChange) {
      props.onChange(args);
    }

    if (args.file.status === 'done') {
      _setProgress(prev => ({
        ...prev,
        [args.file.name]: 100
      }));
      setTimeout(() => {
        _setProgress(prev => {
          const res = { ...prev };

          delete res[args.file.name];

          return res;
        });
      }, 1000);
    }
  };

  return (
    <>
      <AntdUpload {...props} onChange={onChange} customRequest={uploadFile}>
        {children}
      </AntdUpload>
      {Object.entries(_progress).map(
        ([key, value]) => value > 0 && <Progress key={key} percent={value} />
      )}
    </>
  );
};

export default Upload;
