import { Button, Col, DatePicker, Form, Input, Row } from 'antd';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo } from 'react';

import {
  colProps,
  FILTER_PARAMS_DATA_TYPE_SUFFIX,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import { useHistory, useLocation } from 'react-router-dom';

import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import CopyrightSelect from 'components/CopyrightSelect';
import DeleteSelect from 'components/DeleteSelect';
import ProviderSelect from 'components/ProviderSelect';
import PublishSelect from 'components/PublishSelect';
import RankIntSelect from 'components/RankIntSelect';
import SongTypeSelect from 'components/SongTypeSelect';
import StatusSelect from 'components/StatusSelect';
import FormBase from 'containers/FormBase';
import moment from 'moment';
import { processFormItemName } from 'utils/filter';
import { cleanseObj } from 'utils/other';
import ArtistAutoCompleteFilter from './ArtistAutoCompleteFilter';
import CategoryAutoCompleteFilter from './CategoryAutoCompleteFilter';
import TagAutoCompleteFilter from './TagAutoCompleteFilter';
import UserAutoCompleteFilter from './UserAutoCompleteFilter';
import { convertObjToIntValue } from 'utils/parser';

const CategoryFilter = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  const publicDateFormName = useMemo(
    () =>
      processFormItemName({
        prefix: FILTER_PARAMS_PREFIX,
        name: 'createdDate',
        operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.BTW,
        dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.DATE
      }),
    []
  );
  useEffect(() => {
    const initValue = queryString.parse(history.location.search);

    const publicSongTimes = initValue[publicDateFormName];
    form.setFieldsValue({
      ...initValue,
      ...convertObjToIntValue(initValue, [
        'songCates',
        'songTags',
        'songArtists'
      ]),
      ...(publicSongTimes
        ? {
            [publicDateFormName]: publicSongTimes.map(time => moment(time))
          }
        : {})
    });
  }, [publicDateFormName, form, history.location.search]);

  const onFilter = useCallback(() => {
    const params = cleanseObj(form.getFieldsValue());

    history.push({
      search: queryString.stringify({
        ...params
      })
    });
  }, [form, history]);

  const onClearFilter = useCallback(() => {
    form.resetFields();
    history.push({
      search: queryString.stringify({})
    });
  }, [form, history]);
  useEffect(() => {
    if (!location?.search) {
      form.resetFields();
    }
  }, [form, location?.search]);

  return (
    <FormBase form={form} onFinish={onFilter} bordered={false}>
      <Row gutter={8}>
        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'title',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.CONTAINS
            })}
            label="Tên bài hát"
          >
            <Input placeholder="Nhập tên bài hát" />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'provider',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.CONTAINS
            })}
            label="Nhà cung cấp"
          >
            <ProviderSelect />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'vocalRank',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ
            })}
            label="Xếp hạng giọng hát"
          >
            <RankIntSelect />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'isCopyrightInfringement',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
              dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.INT
            })}
            label="Bản quyền"
          >
            <CopyrightSelect />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'isDelete',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
              dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.INT
            })}
            label="Loại xoá"
          >
            <DeleteSelect />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'isYoutube',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
              dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.INT
            })}
            label="Loại nhạc"
          >
            <SongTypeSelect style={{ style: '100%' }} />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'uniqId',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.CONTAINS
            })}
            label="UniqId"
          >
            <Input placeholder="Nhập UniqId" />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'status',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
              dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.INT
            })}
            label="Trạng thái"
          >
            <StatusSelect style={{ width: '100%' }} />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item name={publicDateFormName} label="Khoản thời gian tạo">
            <DatePicker.RangePicker
              showTime={false}
              style={{ width: '100%' }}
              allowClear
              format="DD/MM/YYYY"
              placeholder={['Chọn ngày bắt đầu', 'Chọn ngày kết thúc']}
            />
          </Form.Item>
        </Col>
        <Col {...colProps}>
          <CategoryAutoCompleteFilter form={form} />
        </Col>
        <Col {...colProps}>
          <TagAutoCompleteFilter form={form} />
        </Col>
        <Col {...colProps}>
          <ArtistAutoCompleteFilter form={form} />
        </Col>
        <Col {...colProps}>
          <UserAutoCompleteFilter form={form} />
        </Col>
        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'publictDay',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
              dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.INT
            })}
            label="Phát hành"
          >
            <PublishSelect />
          </Form.Item>
        </Col>
      </Row>

      <Row justify="end" gutter={8}>
        <Col>
          <Button icon={<ClearOutlined />} onClick={onClearFilter}>
            Xoá lọc
          </Button>
        </Col>
        <Col>
          <Button icon={<FilterOutlined />} htmlType="submit">
            Lọc
          </Button>
        </Col>
      </Row>
    </FormBase>
  );
};

export default CategoryFilter;
