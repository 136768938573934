import { useLazyQuery } from '@apollo/client';
import AutoComplete from 'components/AutoComplete';
import { GET_PAGE_INFOS } from 'operations/queries';

const PageAutocomplete = ({ ...props }) => {
  const [search] = useLazyQuery(GET_PAGE_INFOS);
  return (
    <AutoComplete
      optionQuery={{
        content: {
          "contains": "AdvertPage"
        }
      }}
      handleSearch={search}
      labelKey="name"
      valueKey="id"
      dataKey="pageInfoContacts"
      placeholder="Tìm trang"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default PageAutocomplete;
