import { Typography } from 'antd';

import React from 'react';

import AlbumList from './components/AlbumList';
import AlbumFilter from './AlbumFilter';

const Album = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách Album</Typography.Title>

      <AlbumFilter />

      <AlbumList />
    </div>
  );
};

export default Album;
