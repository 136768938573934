import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { searchArtistVar } from 'api/cache';
import AutoComplete from 'components/AutoComplete';
import { GET_ARTISTS } from 'operations/queries';

const ArtistSearch = ({ onSelect, ...props }) => {
  const [searchArtist] = useLazyQuery(GET_ARTISTS);
  const searchedArtists = useReactiveVar(searchArtistVar);

  const onChange = value => {
    const artist = searchedArtists.find(_artist => _artist.id === value);
    return onSelect(artist);
  };

  return (
    <AutoComplete
      size="large"
      value={null}
      handleSearch={searchArtist}
      labelKey="name"
      valueKey="id"
      dataKey="artists"
      placeholder="Tìm nghệ sĩ thêm vào danh sách"
      style={{ width: '100%' }}
      onChange={onChange}
      updateCache={searchArtistVar}
      {...props}
    />
  );
};

export default ArtistSearch;
