import { useLazyQuery } from '@apollo/client';
import { Button, Input } from 'antd';
import AutoComplete from 'components/AutoComplete';
import { useBulkAddVideoRelated } from 'operations/mutations';
import { GET_VIDEOS } from 'operations/queries';
import { useState } from 'react';

const CopyRelatedVideo = ({ dataSource }) => {
  const [searchvideo] = useLazyQuery(GET_VIDEOS);
  const [videoId, setVideoId] = useState(null);
  const { mutate: updateVideoRelated } = useBulkAddVideoRelated();

  const onCloneRelatedVideo = async () => {
    try {
      if (!videoId) return;
      await updateVideoRelated({
        variables: {
          input: {
            videoId,
            relatedVideoIds: dataSource?.map(({ id }) => id)
          }
        }
      });
      setVideoId(null);
    } catch (error) {}
  };

  return (
    <Input.Group
      compact
      style={{
        width: '400px'
      }}
    >
      <AutoComplete
        allowClear
        value={videoId}
        handleSearch={searchvideo}
        labelKey="title"
        valueKey="id"
        dataKey="videos"
        onChange={value => setVideoId(value)}
        size="middle"
        placeholder="Tìm video để sao chép"
        style={{
          width: 'calc(100% - 100px)'
        }}
      />
      <Button disabled={!videoId} type="primary" onClick={onCloneRelatedVideo}>
        Sao chép
      </Button>
    </Input.Group>
  );
};

export default CopyRelatedVideo;
