import { Avatar, Spin } from 'antd';
import ImgNotFoundIcon from 'components/Icons/ImgNotFoundIcon';
import { encodeUrlFile } from 'utils/other';

const UserAvatar = ({
  fullName,
  defaultAvatar = <></>,
  avatar,
  loading,
  size,
  ...props
}) => {
  // const generateAvatarContent = useCallback((fullName, defaultAvatar) => {
  //   try {
  //     if (!fullName || typeof fullName !== 'string') {
  //       return defaultAvatar;
  //     }

  //     const arr = fullName.trim().split(' ');

  //     if (arr.length === 1) {
  //       return arr.shift()[0].toUpperCase();
  //     }

  //     return (arr.shift()[0] + arr.pop()[0]).toUpperCase();
  //   } catch (e) {
  //     return fullName;
  //   }
  // }, []);

  return (
    <Spin spinning={!!loading}>
      <Avatar
        style={{ userSelect: 'none' }}
        size={size || 64}
        {...props}
        src={avatar ? encodeUrlFile(avatar) : ''}
        icon={<ImgNotFoundIcon />}
      />
    </Spin>
  );
};

export default UserAvatar;
