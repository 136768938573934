import { gql } from '@apollo/client';

export const GET_TAGS = gql`
  query GetTags(
    $skip: Int
    $take: Int
    $order: [TagSortInput!]
    $where: TagFilterInput
  ) {
    tags(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        name
        safeName
        countSong
        countVideo
        countAlbum
        updatedDate
        createdDate
        updatedBy
        createdBy
      }
    }
  }
`;
