import { Space, Typography } from 'antd';

import RelatedUser from 'components/RelatedUser';
import RemoveButton from 'components/RemoveButton';

import TimeLine from 'components/TimeLine';

import 'moment/locale/vi';

import { ARTIST_RANK_DICTIONARY, PRIORITY_DICTIONARY } from 'appConstants';
import ColName from 'components/ColName';
import StatusTag from 'components/StatusTag';
import { useRemoveArtist } from 'operations/mutations';
import { Link } from 'react-router-dom';
import RelatedInfo from 'components/RelatedInfo';

const RemoveArtist = ({ record }) => {
  const { mutate } = useRemoveArtist();
  return <RemoveButton record={record} actionRemove={mutate} titleKey="name" />;
};

export const columns = [
  {
    title: 'Nghệ sĩ',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => (
      <ColName
        primary={
          <Link
            style={{ color: 'inherit' }}
            to={`/chi-tiet-nghe-si/${record?.id}`}
          >
            {record?.name}
            {/* {<EditOutlined />} */}
          </Link>
        }
        secondary={record?.uniqId}
        avatar={{ image: record?.image, name: record?.name }}
        tag={<StatusTag status={record?.status} />}
        webUrl={
          record?.status &&
          `${process.env.REACT_APP_WEB_URL}/nghe-si/${record?.slug}.${record?.uniqId}.html`
        }
      />
    ),
    fixed: 'left',
    width: '120px'
  },
  {
    title: 'Xếp hạng',
    dataIndex: 'artistRank',
    width: '50px',
    render: artistRank => (
      <Typography.Text>{ARTIST_RANK_DICTIONARY[artistRank]}</Typography.Text>
    )
  },
  {
    title: 'Độ ưu tiên',
    dataIndex: 'priority',
    width: '50px',
    render: priority => (
      <Typography.Text>{PRIORITY_DICTIONARY[priority]}</Typography.Text>
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    render: (_, { uniqId, relatedArtists, id }) => (
      <Space direction="vertical">
        <RelatedInfo
          data={relatedArtists}
          redirectUrl={`/chi-tiet-nghe-si/${id}?tab=2`}
          subject="nghệ sĩ"
        />
        <Typography.Text>UniqId: {uniqId}</Typography.Text>
      </Space>
    ),
    width: '120px'
  },
  {
    title: 'Số lượng',
    dataIndex: 'count',
    width: '50px',
    render: (_, { countSong, countVideo, countAlbum }) => (
      <Space size={0} direction="vertical">
        <Typography.Text>Bài hát: {countSong}</Typography.Text>
        <Typography.Text>Video: {countVideo}</Typography.Text>
        <Typography.Text>Album: {countAlbum}</Typography.Text>
      </Space>
    )
  },

  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '80px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Thông tin',
    dataIndex: 'related',
    width: '80px',
    render: (_, { createdBy, updatedBy }) => (
      <RelatedUser creator={createdBy} updater={updatedBy} />
    )
  },
  {
    title: 'Thao tác',
    width: '50px',
    render: (_, record) => <RemoveArtist record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
