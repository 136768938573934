import { CloseOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Result,
  Space,
  Table,
  Typography
} from 'antd';
import { ORDER_BY_DICTIONARY, PAGE_SIZE } from 'appConstants';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import {
  useAddCategoryGroup,
  useEditCategoryGroup
} from 'operations/mutations';
import { GET_CATEGORY_GROUPS } from 'operations/queries';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber /> : <Input />;
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Please Input ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const CategoryGroupList = () => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [categoryGroups, setCategoryGroups] = useState([]);
  const [total, setTotal] = useState([]);
  const history = useHistory();

  const { mutate: editCategoryGroup } = useEditCategoryGroup();
  const { mutate: addCategoryGroup } = useAddCategoryGroup();

  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );

  const isEditing = record => record.key === editingKey;
  const {
    loading: isCategoryGroupsLoading,
    error: categoryGroupsError,
    data: categoryGroupsConnect
  } = useQuery(GET_CATEGORY_GROUPS, {
    variables: {
      take: PAGE_SIZE,
      skip: PAGE_SIZE * page - PAGE_SIZE,
      order: [ORDER_BY_DICTIONARY[sort].value]
    }
  });

  useEffect(() => {
    if (!isCategoryGroupsLoading && categoryGroupsConnect) {
      const _data = categoryGroupsConnect?.categoryGroups?.items?.map(item => ({
        ...item,
        key: item.id
      }));
      setCategoryGroups(_data);
      setTotal(categoryGroupsConnect?.categoryGroups?.totalCount);
    }
  }, [categoryGroupsConnect, isCategoryGroupsLoading]);

  const edit = record => {
    form.setFieldsValue({
      name: '',
      age: '',
      address: '',
      ...record
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    setEditingKey('');
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  const onAddCategoryGroup = () => {
    setCategoryGroups(categoryGroups => [...categoryGroups, { key: 'addNew' }]);
    setEditingKey('addNew');
  };

  const save = async key => {
    try {
      const row = await form.validateFields();
      const newData = [...categoryGroups];
      const index = newData.findIndex(item => key === item.key);

      if (key === 'addNew') {
        setEditingKey('');
        return await addCategoryGroup({
          variables: {
            input: row
          }
        });
      }

      if (index > -1) {
        const item = newData[index];
        const params = { id: item.id, ...row };

        await editCategoryGroup({
          variables: {
            input: params
          }
        });
        setEditingKey('');
      }
    } catch (errInfo) {}
  };

  const columns = [
    {
      title: 'Nhóm chuyên mục',
      dataIndex: 'name',
      width: 'auto',
      editable: true
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      width: '40%',
      editable: true
    },
    {
      title: 'Thao tác',
      dataIndex: 'operation',
      width: '120px',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Typography.Link
              onClick={() => save(record.key)}
              style={{
                marginRight: 8
              }}
            >
              <Button icon={<SaveOutlined />} type="link"></Button>
            </Typography.Link>
            <Popconfirm title="Bạn muốn huỷ bỏ?" onConfirm={cancel}>
              <Button icon={<CloseOutlined />} danger type="link"></Button>
            </Popconfirm>
          </span>
        ) : (
          <Typography.Link
            disabled={editingKey !== ''}
            onClick={() => edit(record)}
          >
            <Button type="link" icon={<EditOutlined />}></Button>
          </Typography.Link>
        );
      }
    }
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: col.dataIndex === 'age' ? 'number' : 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  if (categoryGroupsError)
    return (
      <Card>
        <Result
          status="500"
          // title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      </Card>
    );
  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          <SortSelect value={sort} onChange={setOrderBy} />
          <Button type="primary" onClick={onAddCategoryGroup}>
            Thêm
          </Button>
        </Space>
      }
    >
      <Form form={form} component={false}>
        <Table
          components={{
            body: {
              cell: EditableCell
            }
          }}
          bordered
          dataSource={categoryGroups}
          columns={mergedColumns}
          rowClassName="editable-row"
          pagination={{
            onChange: cancel
          }}
        />
      </Form>
    </ListCard>
  );
};

export default CategoryGroupList;
