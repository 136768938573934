import { Typography } from 'antd';
import CommentList from './components/CommentList';
const Comment = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách comment</Typography.Title>

      <CommentList />
    </div>
  );
};

export default Comment;
