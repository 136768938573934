import { Typography } from 'antd';
import React from 'react';
import TagFilter from './components/TagFilter';
import TagList from './components/TagList';

const Tag = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách Tag</Typography.Title>
      <TagFilter />
      <TagList />
    </div>
  );
};

export default Tag;
