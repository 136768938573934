import { Typography } from 'antd';

import IcAdvertList from './components/IcAdvertPositionList';
import AdvPositionFilter from './components/AdvPositionFilter';

const icAdvert = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Quản lý vị trí quảng cáo</Typography.Title>
      <AdvPositionFilter />
      <IcAdvertList />
    </div>
  );
};

export default icAdvert;
