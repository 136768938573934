import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_WEB_NOTIFICATION = gql`
  mutation EditWebNotification($input: EditWebNotificationInput!) {
    editWebNotification(input: $input) {
      id
      title
    }
  }
`;

export const ADD_WEB_NOTIFICATION = gql`
  mutation AddWebNotification($input: AddWebNotificationInput!) {
    addWebNotification(input: $input) {
      id
      title
    }
  }
`;

export const REMOVE_WEB_NOTIFICATION = gql`
  mutation RemoveWebNotification($id: Int!) {
    removeWebNotification(id: $id) {
      id
      title
    }
  }
`;

export const BULK_SEND_WEB_NOTIFICATION = gql`
  mutation SendWebNotification($id: Int!) {
    sendWebNotification(id: $id) {
      id
    }
  }
`;

export const BULK_ENCODE_AUDIO_WEB_NOTIFICATION = gql`
  mutation BulkEncodeAudioWebNotifications($webNotificationIds: [Int!]) {
    bulkEncodeAudioWebNotifications(webNotificationIds: $webNotificationIds) {
      id
      title
    }
  }
`;

// export const BULK_COPYRIGHT_INFRINGEMENT = gql`
//   mutation BulkMarkCopyRightInfringementWebNotifications($webNotificationIds: [Int!]) {
//     bulkMarkCopyRightInfringementWebNotifications(webNotificationIds: $webNotificationIds) {
//       id
//       title
//     }
//   }
// `;

// Clear copyright infringement
export const BULK_COPYRIGHT_INFRINGTMENT_WEB_NOTIFICATIONS = gql`
  mutation BulkActionCopyRightInfringementWebNotifications(
    $webNotificationIds: [Int!]
  ) {
    bulkActionCopyRightInfringementWebNotifications(
      webNotificationIds: $webNotificationIds
    ) {
      id
      title
    }
  }
`;

export const BULK_ACTION_CATEGORIES = gql`
  mutation BulkActionCategories($input: [BulkActionCategoryInput]) {
    bulkActionCategories(input: $input) {
      id
      title
      status
      isDeleted
    }
  }
`;

export const BULK_ACTION_WEB_NOTIFICATIONS = gql`
  mutation BulkActionWebNotifications(
    $input: [BulkActionWebNotificationInput]
  ) {
    bulkActionWebNotifications(input: $input) {
      id
      title
    }
  }
`;

export const BULK_ADD_WEB_NOTIFICATIONS_RELATED = gql`
  mutation BulkAddWebNotificationRelated(
    $input: BulkAddWebNotificationRelatedInput
  ) {
    bulkAddWebNotificationRelated(input: $input) {
      id
      webNotification {
        id
        title
      }
    }
  }
`;

export const useAddWebNotification = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_WEB_NOTIFICATION, {
    onCompleted: ({ addWebNotification }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo webNotification{' '}
            <Typography.Text strong>{addWebNotification.name}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetWebNotifications']
  });
  return { mutate, data, error, loading };
};

export const useEditWebNotification = () => {
  const [mutate, { data, error, loading }] = useMutation(
    EDIT_WEB_NOTIFICATION,
    {
      onCompleted: ({ editWebNotification }) => {
        notification.success({
          message: (
            <Typography.Text>
              Đã chỉnh sửa webNotification{' '}
              <Typography.Text strong>
                {editWebNotification.title}
              </Typography.Text>
            </Typography.Text>
          )
        });
      },
      refetchQueries: ['GetWebNotifications', 'GetWebNotificationById']
    }
  );
  return { mutate, data, error, loading };
};

export const useRemoveWebNotification = () => {
  const [mutate, { data, error, loading }] = useMutation(
    REMOVE_WEB_NOTIFICATION,
    {
      refetchQueries: ['GetWebNotifications'],
      onCompleted: ({ removeWebNotification }) => {
        notification.success({
          message: (
            <Typography.Text>
              Đã xoá webNotification{' '}
              <Typography.Text strong>
                {removeWebNotification.name}
              </Typography.Text>
            </Typography.Text>
          )
        });
      }
    }
  );
  return { mutate, data, error, loading };
};

export const useSendWebNotification = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_SEND_WEB_NOTIFICATION,
    {
      refetchQueries: ['GetWebNotifications'],
      onCompleted: () => {
        notification.success({
          message: <Typography.Text>Đã gửi web notification</Typography.Text>
        });
      }
    }
  );

  return { mutate, data, error, loading };
};

export const useBulkEncodeAudioWebNotification = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ENCODE_AUDIO_WEB_NOTIFICATION,
    {
      refetchQueries: ['GetWebNotifications'],
      onCompleted: () => {
        notification.success({
          message: (
            <Typography.Text>
              Đã cập nhật encode webNotification
            </Typography.Text>
          )
        });
      }
    }
  );

  return { mutate, data, error, loading };
};

// export const useBulkCopyrightInfringment = () => {
//   const [mutate, { data, error, loading }] = useMutation(BULK_COPYRIGHT_INFRINGEMENT, {
//     refetchQueries: ['GetWebNotifications'],
//     onCompleted: () => {
//       notification.success({
//         message: (
//           <Typography.Text>Đã cập nhật vi phạm bản quyền</Typography.Text>
//         )
//       });
//     }
//   });

//   return { mutate, data, error, loading };
// };

export const useBulkActionCopyRightInfringementWebNotifications =
  actionField => {
    const [mutate, { data, error, loading }] = useMutation(
      BULK_COPYRIGHT_INFRINGTMENT_WEB_NOTIFICATIONS,
      {
        refetchQueries: ['GetWebNotifications'],
        onCompleted: ({ bulkActionCopyRightInfringementWebNotifications }) => {
          getBulkMessage(
            'webNotification',
            bulkActionCopyRightInfringementWebNotifications,
            actionField
          );
        }
      }
    );

    return { mutate, data, error, loading };
  };

export const useBulkActionWebNotifications = actionField => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ACTION_WEB_NOTIFICATIONS,
    {
      refetchQueries: ['GetWebNotifications'],
      onCompleted: ({ bulkActionWebNotifications }) => {
        getBulkMessage(
          'webNotification',
          bulkActionWebNotifications,
          actionField
        );
      }
    }
  );

  return { mutate, data, error, loading };
};

export const useBulkAddWebNotificationRelated = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ADD_WEB_NOTIFICATIONS_RELATED,
    {
      onCompleted: () => {
        notification.success({
          message: (
            <Typography.Text>
              Đã cập nhật webNotification liên quan
            </Typography.Text>
          )
        });
      },
      refetchQueries: ['GetWebNotifications', 'GetWebNotificationById']
    }
  );
  return { mutate, data, error, loading };
};

// App Notification
export const EDIT_APP_NOTIFICATION = gql`
  mutation EditAppNotification($input: EditAppNotificationInput!) {
    editAppNotification(input: $input) {
      id
      title
    }
  }
`;

export const ADD_APP_NOTIFICATION = gql`
  mutation AddAppNotification($input: AddAppNotificationInput!) {
    addAppNotification(input: $input) {
      id
      title
    }
  }
`;

export const REMOVE_APP_NOTIFICATION = gql`
  mutation RemoveAppNotification($id: Int!) {
    removeAppNotification(id: $id) {
      id
      title
    }
  }
`;

export const BULK_SEND_APP_NOTIFICATION = gql`
  mutation SendAppNotification($id: Int!) {
    sendAppNotification(id: $id) {
      id
    }
  }
`;

export const BULK_ACTION_APP_NOTIFICATIONS = gql`
  mutation BulkActionAppNotifications(
    $input: [BulkActionAppNotificationInput]
  ) {
    bulkActionAppNotifications(input: $input) {
      id
      title
    }
  }
`;

export const useAddAppNotification = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_APP_NOTIFICATION, {
    onCompleted: ({ addAppNotification }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo appNotification{' '}
            <Typography.Text strong>{addAppNotification.name}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetAppNotifications']
  });
  return { mutate, data, error, loading };
};

export const useEditAppNotification = () => {
  const [mutate, { data, error, loading }] = useMutation(
    EDIT_APP_NOTIFICATION,
    {
      onCompleted: ({ editAppNotification }) => {
        notification.success({
          message: (
            <Typography.Text>
              Đã chỉnh sửa appNotification{' '}
              <Typography.Text strong>
                {editAppNotification.title}
              </Typography.Text>
            </Typography.Text>
          )
        });
      },
      refetchQueries: ['GetAppNotifications', 'GetAppNotificationById']
    }
  );
  return { mutate, data, error, loading };
};

export const useRemoveAppNotification = () => {
  const [mutate, { data, error, loading }] = useMutation(
    REMOVE_APP_NOTIFICATION,
    {
      refetchQueries: ['GetAppNotifications'],
      onCompleted: ({ removeAppNotification }) => {
        notification.success({
          message: (
            <Typography.Text>
              Đã xoá appNotification{' '}
              <Typography.Text strong>
                {removeAppNotification.name}
              </Typography.Text>
            </Typography.Text>
          )
        });
      }
    }
  );
  return { mutate, data, error, loading };
};

export const useSendAppNotification = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_SEND_APP_NOTIFICATION,
    {
      refetchQueries: ['GetAppNotifications'],
      onCompleted: () => {
        notification.success({
          message: <Typography.Text>Đã gửi app notification</Typography.Text>
        });
      }
    }
  );

  return { mutate, data, error, loading };
};

export const useBulkActionAppNotifications = actionField => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ACTION_APP_NOTIFICATIONS,
    {
      refetchQueries: ['GetAppNotifications'],
      onCompleted: ({ bulkActionAppNotifications }) => {
        getBulkMessage(
          'appNotification',
          bulkActionAppNotifications,
          actionField
        );
      }
    }
  );

  return { mutate, data, error, loading };
};
