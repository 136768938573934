import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, message, Space, Table, Typography } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import UserAvatar from 'components/UserAvatar';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import SongSearch from './SongSearch';

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const SongOfPlaylist = ({ onSelect, songs }) => {
  const [dataSource, setDataSource] = useState(songs || []);

  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: 'grab',
        color: '#999'
      }}
    />
  ));

  const onClearSong = id => {
    const newData = dataSource.filter(_d => _d.id !== id);
    setDataSource(newData);
    onSelect(newData.map(_d => _d.id));
  };

  const columns = [
    {
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Thứ tự',
      width: 80,
      align: 'center',
      render: (_, __, index) => (
        <Typography.Title level={2} style={{ margin: 0 }}>
          {index + 1}
        </Typography.Title>
      )
    },
    {
      title: 'Bài hát',
      dataIndex: 'title',
      className: 'drag-visible',
      render: (title, { id, thumbnail }) => (
        <Space>
          <UserAvatar
            fullName={title}
            avatar={thumbnail}
            shape="square"
            size={48}
          />
          <Link target="_blank" to={`/chi-tiet-bai-hat/${id}`}>
            {title}
          </Link>
        </Space>
      )
    },
    {
      title: 'Ca sĩ',
      dataIndex: 'singers',
      render: singers => (
        <Space direction="vertical">
          {singers?.map(singer => (
            <Typography.Text key={singer?.id}>{singer?.name}</Typography.Text>
          ))}
        </Space>
      )
    },
    {
      title: 'Nhạc sĩ',
      dataIndex: 'musicians',
      render: musicians => (
        <Space direction="vertical">
          {musicians?.map(musician => (
            <Typography.Text key={musician?.id}>
              {musician?.name}
            </Typography.Text>
          ))}
        </Space>
      )
    },
    {
      title: 'Thể loại',
      dataIndex: 'categories',
      render: categories => (
        <Space direction="vertical">
          {categories?.map(cate => (
            <Typography.Text key={cate?.id}>{cate?.name}</Typography.Text>
          ))}
        </Space>
      )
    },
    {
      dataIndex: 'id',
      width: 50,
      render: id => (
        <Button
          onClick={() => onClearSong(id)}
          size="small"
          danger
          icon={<DeleteOutlined />}
        ></Button>
      )
    }
  ];

  useEffect(() => {
    if (!!songs?.length) {
      const _songs = songs.map((song, index) => ({
        ...song,
        index,
        key: song.id
      }));
      setDataSource(_songs);
    }
  }, [songs]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter(el => !!el);
      setDataSource(newData);
      onSelect(newData.map(_song => _song.id));
    }
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      x => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const onAddSongToPlaylist = song => {
    if (!song) return;
    const isExist = !!dataSource.find(_data => _data.id === song.id);
    if (isExist) {
      return message.error('Bài hát đã tồn tại trong danh sách');
    }
    setDataSource(dataSource => [
      ...dataSource,
      { ...song, index: dataSource?.length }
    ]);
    onSelect([...dataSource, song].map(_song => _song.id));
    return message.success('Đã thêm bài hát vào danh sách');
  };

  return (
    <div className="mb-md">
      <div className="mb-md">
        <Typography.Text>Danh sách bài hát của playlist</Typography.Text>
        <SongSearch onSelect={onAddSongToPlaylist} />
      </div>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }}
      />
    </div>
  );
};

export default SongOfPlaylist;
