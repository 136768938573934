import { Checkbox, Divider, Select, Typography } from 'antd';
import { DEBOUNCE_VALUE } from 'appConstants';
import React, { useEffect, useState } from 'react';
import { debounce } from 'utils/lodash';

const AutoComplete = ({
  defaultOption,
  labelKey,
  valueKey,
  dataKey,
  handleSearch,
  disableValue,
  prefixDataKey,
  getLabel,
  isResetOptions,
  optionQuery,
  showCheckEqual = true,
  updateCache = f => f,
  ...props
}) => {
  const [options, setOptions] = useState([]);
  const [checkEqual, setCheckEqual] = useState(false);

  useEffect(() => {
    if (Array.isArray(defaultOption)) {
      defaultOption && setOptions(defaultOption);
    } else {
      defaultOption && setOptions([defaultOption]);
    }
    if (!defaultOption || defaultOption.length < 1) onSearch()
  }, [defaultOption]);

  useEffect(() => {
    if (isResetOptions && handleSearch) {
      // Reset options - change action Search
      onSearch()
    }
  }, [handleSearch, isResetOptions]);

  const onSearch = async searchText => {
    if (!searchText) {
      searchText = "";
    }
    if (typeof (handleSearch) != 'function') return
    const results = await handleSearch({
      variables: {
        where: {
          [labelKey]: { [checkEqual ? 'eq' : 'contains']: searchText },
          ...optionQuery
        }
      }
    });
    if (updateCache) {
      updateCache(results.data[dataKey].items);
    }

    if (!results) {
      return;
    }

    return setOptions(
      results.data[dataKey].items.map(result => ({
        label: getLabel
          ? getLabel(result)
          : (prefixDataKey ? result[prefixDataKey] + ' - ' : '') +
          result[labelKey],
        value: result[valueKey]
      }))
    );
  };

  const debouncedOnSearch = debounce(value => onSearch(value), DEBOUNCE_VALUE);

  const renderOptions = () => {
    return options.map(({ value, label }) => (
      <Select.Option
        value={value}
        key={value}
        disabled={disableValue === value}
      >
        {label}
      </Select.Option>
    ));
  };

  return (
    <Select
      showArrow
      filterOption={false}
      // notFoundContent={null}
      showSearch
      onSearch={debouncedOnSearch}
      notFoundContent={<Typography.Text>Gõ để tìm kiếm gợi ý</Typography.Text>}
      dropdownRender={menu => (
        <>
          {menu}
          {showCheckEqual && (
            <>
              <Divider style={{ margin: '8px 0' }} />
              <Checkbox
                checked={checkEqual}
                onChange={e => setCheckEqual(e.target.checked)}
                style={{ padding: '0 8px 4px' }}
              >
                Tìm kiếm tuyệt đối
              </Checkbox>
            </>
          )}
        </>
      )}
      {...props}
    >
      {renderOptions()}
    </Select>
  );
};

export default AutoComplete;
