import { Typography } from 'antd';
import React from 'react';
import CategoryGroupList from './components/CategoryGroupList';

const CategoryGroup = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách nhóm chuyên mục</Typography.Title>

      <CategoryGroupList />
    </div>
  );
};

export default CategoryGroup;
