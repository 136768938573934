import { useQuery } from '@apollo/client';
import { Spin, Tabs, Typography } from 'antd';
import { useAddSong, useEditSong } from 'operations/mutations';
import { GET_SONG_BY_ID } from 'operations/queries';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import RelatedSongs from './components/RelatedSongs';
import SeoInfo from './components/SeoInfo';
import SongInfo from './components/SongInfo';
import SongPlaylistInfo from './components/SongPlaylistInfo';

const SongDetail = () => {
  const { id } = useParams();
  const isUpdate = useMemo(() => !!id, [id]);
  const history = useHistory();
  const { search } = useLocation();

  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const { data, loading: fetchLoading } = useQuery(GET_SONG_BY_ID, {
    variables: { id: parseInt(id) },
    skip: !isUpdate
  });

  const { mutate: editSong, loading: editLoading } = useEditSong();
  const { mutate: addSong, loading: addLoading } = useAddSong();

  const items = useMemo(
    () => [
      {
        label: 'Thông tin bài hát',
        key: '1',
        children: (
          <SongInfo
            song={data?.song}
            isUpdate={isUpdate}
            editSong={editSong}
            addSong={addSong}
          />
        )
      },
      {
        label: 'Bài hát liên quan',
        key: '2',
        children: (
          <RelatedSongs
            songName={data?.song?.title}
            songs={data?.song?.relatedSongs}
            onSelect={() => {}}
          />
        ),
        disabled: !isUpdate
      },
      {
        label: 'Playlist',
        key: '3',
        children: <SongPlaylistInfo song={data?.song} />,
        disabled: !isUpdate
      },
      // {
      //   label: 'Album/Video',
      //   key: '3',
      //   children: (
      //     <SongRelatedInfo
      //       song={data?.song}
      //       isUpdate={isUpdate}
      //       editSong={editSong}
      //       addSong={addSong}
      //     />
      //   ),
      //   disabled: !isUpdate
      // },
      {
        label: 'SEO',
        key: '4',
        children: (
          <SeoInfo isUpdate={isUpdate} song={data?.song} editSong={editSong} />
        ),
        disabled: !isUpdate
      }
    ],
    [addSong, data?.song, editSong, isUpdate]
  );

  const onChangeTab = tab => {
    history.push({
      search: queryString.stringify({
        tab
      })
    });
  };

  return (
    <Spin spinning={fetchLoading || editLoading || addLoading}>
      <Typography.Title>
        {isUpdate ? 'Chi tiết bài hát' : 'Tạo bài hát'}
      </Typography.Title>
      <Tabs
        onChange={onChangeTab}
        defaultActiveKey={searchParams?.tab || '1'}
        type="card"
        items={items}
      />
    </Spin>
  );
};

export default SongDetail;
