import { Typography } from 'antd';

import IcAdvertList from './components/IcAdvertList';
import AdvFilter from './components/AdvFilter';

const icAdvert = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Quản lý quảng cáo</Typography.Title>
      <AdvFilter />
      <IcAdvertList />
    </div>
  );
};

export default icAdvert;
