import { ScissorOutlined } from '@ant-design/icons';
import {
  Badge,
  Button,
  Card,
  Col,
  DatePicker,
  Form,
  Input,
  Radio,
  Row,
  Space,
  Tooltip,
  Typography
} from 'antd';
import {
  CATEGORY_TYPE,
  DATE_FORMAT,
  DEBOUNCE_VALUE,
  PLAYLIST_TYPE_DICTIONARY,
  PLAYLIST_TYPE_VALUE,
  TIME_FORMAT,
  UPLOAD_TYPE
} from 'appConstants';
import ImageUpload from 'components/ImageUpload';
import CategoryAutocomplete from 'components/CategoryAutocomplete';
import MoodAutocomplete from 'components/MoodAutocomplete';
import PlaylistAutocomplete from 'components/PlaylistAutocomplete';
import TagAutocomplete from 'components/TagAutocomplete';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { debounce, get } from 'utils/lodash';
import { sanitizeSlug } from 'utils/parser';
import SongOfPlaylist from './SongOfPlaylist/SongOfPlaylist';
import VideoOfPlaylist from './VideoOfPlaylist/VideoOfPlaylist';
import GenerateFile from 'components/GenerateFile';

const PlaylistInfo = ({
  isUpdate,
  playlist,
  editPlaylistForSong,
  editPlaylistForVideo,
  addPlaylistForSong,
  addPlaylistForVideo
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const [playlistType, setPlaylistType] = useState(PLAYLIST_TYPE_VALUE.SONG);
  const [songIds, setSongIds] = useState([]);
  const [videoIds, setVideoIds] = useState([]);
  const name = Form.useWatch('name', form);
  const thumbnail = Form.useWatch('thumbnailSocial', form);
  const slug = Form.useWatch('slug', form);

  const reqGenFile = useMemo(
    () => ({
      title: name,
      thumbnail:
        typeof thumbnail === 'string'
          ? thumbnail
          : get(thumbnail, '[0].response.Data', playlist?.thumbnailSocial),
      slug: slug,
      type: UPLOAD_TYPE.SHARE_PLAYLIST.type,
      imageType: 'DEFAULT'
    }),
    [name, playlist?.thumbnailSocial, slug, thumbnail]
  );

  const onGenerateSharingFile = res => {
    form.setFieldsValue({ thumbnailSocial: res?.Data });
  };

  useEffect(() => {
    if (playlist) {
      setSongIds(playlist?.playlistSongs?.id);
      setVideoIds(playlist?.playlistVideos?.id);
      setPlaylistType(playlist?.typeName);
      form.setFieldsValue({
        name: playlist.name,
        slug: playlist.slug,
        intro: playlist.intro,
        slide: playlist.slide,
        thumbnail: playlist.thumbnail,
        thumbnailSocial: playlist.thumbnailSocial,
        cateIds: playlist.categories.map(category => category?.id),
        tagIds: playlist.playlistTags.map(tag => tag?.id),
        moodsIds: playlist.playlistMoods.map(mood => mood?.id),
        playlistRelatedIds: playlist.playlistRelateds.map(
          playlist => playlist?.id
        ),
        publicDate: playlist.publicDate && moment(playlist.publicDate)
      });
    }
  }, [playlist, form]);

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const onChangePlaylistType = ({ target: { value } }) => {
    setPlaylistType(value);
  };

  const onChangeName = debounce(() => {
    const { name, slug } = form.getFieldsValue();
    if (!slug) {
      const _slug = sanitizeSlug(name);
      form.setFieldsValue({ slug: _slug });
    }
  }, DEBOUNCE_VALUE);

  const onGenerateSlug = () => {
    const { name } = form.getFieldsValue();
    if (!name) return;
    const _slug = sanitizeSlug(name);
    form.setFieldsValue({ slug: _slug });
  };

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const slide = get(values?.slide, '[0].response.Data', playlist?.slide);
      const thumbnail = get(
        values?.thumbnail,
        '[0].response.Data',
        playlist?.thumbnail
      );
      const thumbnailSocial =
        typeof values.thumbnailSocial === 'string'
          ? values.thumbnailSocial
          : get(
              values.thumbnailSocial,
              '[0].response.Data',
              playlist?.thumbnailSocial
            );

      const params = { ...values, slide, thumbnail, thumbnailSocial };

      if (isUpdate) {
        // update
        playlistType === PLAYLIST_TYPE_VALUE.SONG
          ? await editPlaylistForSong({
              variables: {
                input: {
                  id: parseInt(id),
                  songIds,
                  ...params
                }
              }
            })
          : await editPlaylistForVideo({
              variables: {
                input: {
                  id: parseInt(id),
                  videoIds,
                  ...params
                }
              }
            });
      } else {
        if (playlistType === PLAYLIST_TYPE_VALUE.SONG) {
          // Create playlist with song
          await addPlaylistForSong({
            variables: {
              input: {
                ...params,
                songIds
              }
            },
            onCompleted: ({ addPlaylistForSong }) => {
              history.push('/chi-tiet-playlist/' + addPlaylistForSong?.id);
            }
          });
        } else {
          // Create playlist with video
          await addPlaylistForVideo({
            variables: {
              input: {
                ...params,
                videoIds
              }
            },
            onCompleted: ({ addPlaylistForVideo }) => {
              history.push('/chi-tiet-playlist/' + addPlaylistForVideo?.id);
            }
          });
        }
      }
    } catch (error) {}
  };

  const onUpdateSong = songs => {
    setSongIds(songs);
  };

  const onUpdateVideo = videos => {
    setVideoIds(videos);
  };

  return (
    <Badge.Ribbon
      text={PLAYLIST_TYPE_DICTIONARY[playlistType || PLAYLIST_TYPE_VALUE.SONG]}
      color="primary"
    >
      {!isUpdate && (
        <Card className="mb-md">
          <Row justify="center" className="mb-md">
            <Space direction="vertical" align="center">
              <Typography.Text strong style={{ fontSize: 24 }}>
                Loại playlist
              </Typography.Text>
              <Radio.Group
                defaultValue={PLAYLIST_TYPE_VALUE.SONG}
                buttonStyle="solid"
                onChange={onChangePlaylistType}
              >
                <Radio.Button value={PLAYLIST_TYPE_VALUE.SONG}>
                  Bài hát
                </Radio.Button>
                <Radio.Button value={PLAYLIST_TYPE_VALUE.VIDEO}>
                  Video
                </Radio.Button>
              </Radio.Group>
            </Space>
          </Row>
        </Card>
      )}
      <Card>
        <Form form={form} layout="vertical">
          <Row gutter={12}>
            <Col span={12}>
              <Form.Item
                name="name"
                label="Tên playlist"
                rules={[{ required: true, message: 'Nhập tên playlist!' }]}
              >
                <Input
                  placeholder="Nhập tên playlist"
                  onMouseOut={onChangeName}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="slug"
                label="Slug"
                rules={[{ required: true, message: 'Nhập slug!' }]}
              >
                <Input
                  suffix={
                    <Tooltip title="Generate slug">
                      <ScissorOutlined
                        style={{ fontSize: 16 }}
                        onClick={onGenerateSlug}
                      />
                    </Tooltip>
                  }
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="cateIds"
                label="Thể loại"
                rules={[{ required: true, message: 'Chọn thể loại!' }]}
              >
                <CategoryAutocomplete
                  allowClear
                  category={playlist?.categories}
                  type={CATEGORY_TYPE.PLAYLIST}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item name="playlistRelatedIds" label="Playlist liên quan">
                <PlaylistAutocomplete
                  allowClear
                  playlist={playlist?.playlistRelateds}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="tagIds"
                label="Tag"
                rules={[{ required: true, message: 'Chọn tag!' }]}
              >
                <TagAutocomplete allowClear tag={playlist?.playlistTags} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                name="moodsIds"
                label="Chủ đề"
                rules={[{ required: true, message: 'Chọn chủ đề!' }]}
              >
                <MoodAutocomplete allowClear mood={playlist?.playlistMoods} />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="publicDate"
                label="Thời gian đăng"
                rules={[{ required: true, message: 'Chọn thời gian đăng!' }]}
              >
                <DatePicker
                  showTime
                  style={{ width: '100%' }}
                  format={DATE_FORMAT + ' ' + TIME_FORMAT}
                  placeholder="Chọn thời gian đăng"
                />
              </Form.Item>
            </Col>
            <Col span={24}>
              <Form.Item name="intro" label="Nội dung">
                <Input.TextArea rows={7} placeholder="Nhập giới thiệu" />
              </Form.Item>
            </Col>

            <Col span={24}>
              <Row justify="space-between">
                <Form.Item
                  name="slide"
                  label="Slide"
                  valuePropName="file"
                  getValueFromEvent={normFile}
                >
                  <ImageUpload
                    avatarShape="square"
                    size={128}
                    fontSize={64}
                    fullName="Slide"
                    {...UPLOAD_TYPE.SLIDE_PLAYLIST}
                  />
                </Form.Item>

                <Form.Item
                  name="thumbnail"
                  label="Hình đại diện"
                  valuePropName="file"
                  getValueFromEvent={normFile}
                >
                  <ImageUpload
                    avatarShape="square"
                    size={128}
                    fontSize={64}
                    fullName="Hình đại diện"
                    {...UPLOAD_TYPE.THUMBNAIL_PLAYLIST}
                  />
                </Form.Item>

                <Form.Item
                  name="thumbnailSocial"
                  label={
                    <Space>
                      <Typography.Text>
                        Share Thumbnail (max 500KB)
                      </Typography.Text>{' '}
                      <GenerateFile
                        values={reqGenFile}
                        onGenerate={onGenerateSharingFile}
                      />
                    </Space>
                  }
                  valuePropName="file"
                  getValueFromEvent={normFile}
                >
                  <ImageUpload
                    avatarShape="square"
                    size={128}
                    fontSize={64}
                    fullName="Share thumbnail"
                    freeStyle={isUpdate}
                    {...UPLOAD_TYPE.SHARE_PLAYLIST}
                  />
                </Form.Item>
              </Row>
            </Col>
          </Row>
        </Form>
        {playlistType === PLAYLIST_TYPE_VALUE.SONG ? (
          <SongOfPlaylist
            songs={playlist?.playlistSongs}
            onSelect={onUpdateSong}
          />
        ) : (
          <VideoOfPlaylist
            videos={playlist?.playlistVideos}
            onSelect={onUpdateVideo}
          />
        )}

        <Row justify="end">
          <Col>
            <Button onClick={onSave} type="primary">
              Lưu
            </Button>
          </Col>
        </Row>
      </Card>
    </Badge.Ribbon>
  );
};

export default PlaylistInfo;
