import React from 'react';
import { Select } from 'antd';
import { GENDER_DICTIONARY } from 'appConstants';

const GenderSelect = props => {
  return (
    <Select placeholder="Chọn giới tính" {...props}>
      {Object.keys(GENDER_DICTIONARY).map(key => (
        <Select.Option value={key} key={key}>
          {GENDER_DICTIONARY[key]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default GenderSelect;
