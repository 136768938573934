import { useQuery } from '@apollo/client';
import { Spin, Tabs, Typography } from 'antd';
import { useAddMood, useEditMood } from 'operations/mutations';
import { GET_MOOD_BY_ID } from 'operations/queries';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import MoodInfo from './components/MoodInfo';
import SeoInfo from './components/SeoInfo';

const MoodDetail = () => {
  const { id } = useParams();
  const isUpdate = useMemo(() => !!id, [id]);
  const { data, loading: fetchLoading } = useQuery(GET_MOOD_BY_ID, {
    variables: { id: parseInt(id) },
    skip: !isUpdate
  });

  const { mutate: editMood, loading: editLoading } = useEditMood();
  const { mutate: addMood, loading: addLoading } = useAddMood();

  const items = useMemo(
    () => [
      {
        label: 'Thông tin chủ đề',
        key: 'info',
        children: (
          <MoodInfo
            mood={data?.mood}
            isUpdate={isUpdate}
            onEditMood={editMood}
            onAddMood={addMood}
          />
        )
      },
      {
        label: 'SEO',
        key: 'seo',
        children: (
          <SeoInfo
            isUpdate={isUpdate}
            mood={data?.mood}
            onEditMood={editMood}
          />
        ),
        disabled: !isUpdate
      }
    ],
    [addMood, data?.mood, editMood, isUpdate]
  );

  return (
    <Spin spinning={fetchLoading || editLoading || addLoading}>
      <Typography.Title>
        {isUpdate ? 'Chi tiết chủ đề' : 'Tạo chủ đề'}
      </Typography.Title>
      <Tabs type="card" items={items} />
    </Spin>
  );
};

export default MoodDetail;
