import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_MOOD = gql`
  mutation EditMood($input: EditMoodInput) {
    editMood(input: $input) {
      id
      title
    }
  }
`;

export const ADD_MOOD = gql`
  mutation AddMood($input: AddMoodInput) {
    addMood(input: $input) {
      id
      title
    }
  }
`;

export const REMOVE_MOOD = gql`
  mutation RemoveMood($id: Int!) {
    removeMood(id: $id) {
      id
      title
    }
  }
`;

export const BULK_ACTION_MOODS = gql`
  mutation BulkActionMoods($input: [BulkActionMoodInput]) {
    bulkActionMoods(input: $input) {
      id
      title
    }
  }
`;

// ----------------------------------------------------
// Hook

export const useAddMood = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_MOOD, {
    onCompleted: ({ addMood }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo chủ đề{' '}
            <Typography.Text strong>{addMood.title}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useEditMood = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_MOOD, {
    onCompleted: ({ editMood }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa chủ đề{' '}
            <Typography.Text strong>{editMood.title}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetMoodById', 'GetMoods']
  });
  return { mutate, data, error, loading };
};

export const useBulkActionMoods = actionField => {
  const [mutate, { data, error, loading }] = useMutation(BULK_ACTION_MOODS, {
    refetchQueries: ['GetMoods'],
    onCompleted: ({ bulkActionMoods }) => {
      getBulkMessage('Chủ đề', bulkActionMoods, actionField);
    }
  });

  return { mutate, data, error, loading };
};

// --------------------------------------------

export const useRemoveMood = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_MOOD, {
    refetchQueries: ['GetMoods'],
    onCompleted: ({ removeMood }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá chủ đề{' '}
            <Typography.Text strong>{removeMood.title}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};
