import { ARTIST_RANK_VALUE, GENDER_VALUE, PRIORITY_VALUE } from 'appConstants';

export const initParams = artist => {
  if (!artist) return;
  const params = {
    id: artist.id,
    gender: GENDER_VALUE[artist?.gender],
    priority: PRIORITY_VALUE[artist.priority],
    artistRank: ARTIST_RANK_VALUE[artist.artistRank]
  };
  return params;
};
