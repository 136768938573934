import React from 'react';
import { Select } from 'antd';
import { COMMON_STATUS_INT_DICTIONARY } from 'appConstants';

const StatusSelect = ({
  filter = () => true,
  value,
  disableSelected,
  integerValue,
  ...props
}) => {
  return (
    <Select
      style={{ width: 200, fontWeight: 'normal' }}
      placeholder="Chọn trạng thái"
      value={value}
      allowClear
      {...props}
    >
      {Object.keys(COMMON_STATUS_INT_DICTIONARY)
        // .map(i => i * 1)
        .filter(filter)
        .map((key, index) => (
          <Select.Option
            key={key}
            value={key}
            disabled={disableSelected ? value === key : false}
          >
            {COMMON_STATUS_INT_DICTIONARY[key]}
          </Select.Option>
        ))}
    </Select>
  );
};

export default StatusSelect;
