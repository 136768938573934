import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';

export const STATUS_CONTACT = gql`
  mutation BulkActionStatusContacts($input: [BulkActionStatusContactInput]) {
    bulkActionStatusContacts(input: $input) {
      id
    }
  }
`;

export const REMOVE_CONTACT = gql`
  mutation RemoveIcContacts($id: Int!) {
    bulkActionRemoveContacts(id: $id) {
      id
    }
  }
`;

export const useStatusContact = () => {
  const [mutate, { data, error }] = useMutation(STATUS_CONTACT, {
    refetchQueries: ['GetContacts'],
    onCompleted: ({ removeRole }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã cập nhật
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};
export const useRemoveContact = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_CONTACT, {
    refetchQueries: ['GetContacts'],
    onCompleted: () => {
      notification.success({
        message: (
          <Typography.Text>
            Xóa thành công
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

