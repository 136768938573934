import { useReactiveVar } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import { USER_TYPE_VALUE } from 'appConstants';
import DepartmentSelect from 'components/DepartmentSelect';
import RoleAutocomplete from 'components/RoleAutocomplete';
import UserTypeSelect from 'components/UserTypeSelect/UserTypeSelect';

import { useEffect } from 'react';

const UserFormContent = ({ form, onSubmit }) => {
  const visibleDrawer = useReactiveVar(visibleDrawerVar);
  const user = visibleDrawer.record;

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        fullName: user?.fullName,
        userName: user?.userName,
        userType: USER_TYPE_VALUE[user?.userType],
        email: user?.email,
        roleId: user?.roleName?.id,
        phone: user?.phone,
        phongBanId: user?.phongBanId
      });
    }
    return () => form.resetFields();
  }, [user, form]);

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="userName"
        label="Tên đăng nhập"
        rules={[{ required: true, message: 'Nhập tên đăng nhập!' }]}
      >
        <Input placeholder="Nhập tên đăng nhập" disabled={!!user} />
      </Form.Item>
      <Form.Item
        name="fullName"
        label="Họ tên"
        rules={[{ required: true, message: 'Nhập tên!' }]}
      >
        <Input placeholder="Nhập họ tên" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={
          !!user
            ? []
            : [{ required: true, type: 'email', message: 'Nhập email!' }]
        }
      >
        <Input placeholder="Nhập email" />
      </Form.Item>

      {!user && (
        <Form.Item
          name="password"
          label="Mật khẩu"
          rules={[
            { required: true, message: 'Nhập mật khẩu!' },
            { min: 8, message: 'Tối thiểu 8 ký tự' }
          ]}
        >
          <Input.Password
            placeholder="Nhập mật khẩu"
            autoComplete="new-password"
          />
        </Form.Item>
      )}
      <Form.Item
        name="phone"
        label="Điện thoại"
        rules={[
          { required: true, message: 'Nhập điện thoại!' },
          { min: 10, message: 'Tối thiểu 8 ký tự' }
        ]}
      >
        <Input placeholder="Nhập điện thoại" />
      </Form.Item>
      <Form.Item
        name="userType"
        label="Loại user"
        rules={[{ required: true, message: 'Chọn loại user!' }]}
      >
        <UserTypeSelect integerValue={true} />
      </Form.Item>
      <Form.Item
        name="roleId"
        label="Vai trò"
        rules={[{ required: true, message: 'Chọn vai trò!' }]}
      >
        <RoleAutocomplete allowClear role={user?.roleName} />
      </Form.Item>
      <Form.Item
        name="phongBanId"
        label="Phòng ban"
        rules={[{ required: true, message: 'Chọn phòng ban!' }]}
      >
        <DepartmentSelect />
      </Form.Item>

      <Form.Item style={{ display: 'none' }}>
        <Button htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  );
};

export default UserFormContent;
