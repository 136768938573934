import { Col, Row, Typography } from 'antd';
import UserAvatar from 'components/UserAvatar';
import React from 'react';

const ColName = ({ primary, secondary, popover, avatar, tag, webUrl }) => {
  return (
    <div style={{ display: 'flex', gap: '12px', alignItems: 'center' }}>
      {avatar &&
        (webUrl ? (
          <a href={webUrl} target="_blank" rel="noreferrer">
            <UserAvatar
              fullName={avatar.name}
              avatar={avatar.image}
              shape="square"
              // size={80}
            />
          </a>
        ) : (
          <UserAvatar
            fullName={avatar.name}
            avatar={avatar.image}
            shape="square"
            // size={80}
          />
        ))}
      <Row>
        <Col span={24}>
          {/* <Tooltip placement="topLeft" title={popover}>
            <Typography.Text
              ellipsis
              strong
              // style={{
              //   color: 'inherit',
              //   marginBottom: 4,
              //   cursor: 'pointer',
              //   WebkitLineClamp: '2',
              //   // '-webkit-line-clamp': '2',
              //   display: '-webkit-box',
              //   WebkitBoxOrient: 'vertical',
              //   whiteSpace: 'normal'
              // }}
            >
              {primary}
            </Typography.Text>
          </Tooltip> */}
          <Typography.Paragraph
            strong
            ellipsis={{ rows: 2, expandable: false }}
            className="col-name"
          >
            {primary}
          </Typography.Paragraph>
        </Col>

        {secondary && (
          <Col span={24}>
            <Typography.Text
              ellipsis={{ tooltip: secondary }}
              style={{ color: 'gray', fontSize: 13 }}
            >
              {secondary}
            </Typography.Text>
          </Col>
        )}
        {tag && <Col span={24}>{tag}</Col>}
      </Row>
    </div>
  );
};

export default ColName;
