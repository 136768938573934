import { gql } from '@apollo/client';

export const GET_SONGS = gql`
  query GetSongs(
    $skip: Int
    $take: Int
    $order: [SongSortInput!]
    $where: SongFilterInput
  ) {
    songs(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        title
        thumbnail
        status
        slug
        clicks
        views
        url
        updateBy
        updatedDate
        createBy
        createdBy
        updatedBy
        uniqId
        vocalRank
        isDelete
        publictDay
        isCopyrightInfringement
        createdDate
        relatedSongs {
          id
          title
        }
        playlists {
          id
          name
        }
        singers {
          id
          name
        }
        musicians {
          id
          name
        }
        categories {
          id
          name
        }
      }
    }
  }
`;

export const GET_SONG_BY_ID = gql`
  query GetSongById($id: Int!) {
    song(id: $id) {
      id
      title
      intro
      thumbnail
      status
      slug
      clicks
      views
      url
      updateBy
      updateDate
      createBy
      isMedley
      lyric
      releaseYear
      publictDay
      vocalRank
      lyricFileUrl
      maHd
      maBaiHat
      provider
      subProvider
      producer
      previewsStartTime
      previewsDuration
      thumbnailSocial
      relatedSongs {
        id
        title
        thumbnail
        categories {
          id
          name
        }
        singers {
          id
          name
        }
        musicians {
          id
          name
        }
      }
      playlists {
        id
        name
        thumbnail
        categories {
          id
          name
        }
      }
      categories {
        id
        name
      }
      tags {
        id
        name
      }
      singers {
        id
        name
      }
      musicians {
        id
        name
      }
      albums {
        id
        title
      }
      videos {
        id
        title
      }
      metaSeo {
        metaTitle
        metaKeywords
        metaDescription
      }
    }
  }
`;
