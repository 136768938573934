import { Button, Card, Col, DatePicker, Form, Input, Row } from 'antd';
import {
  DATE_FORMAT,
  DEBOUNCE_VALUE,
  NOTIFICATION_SITE_VALUE,
  NOTIFICATION_VALUE,
  TIME_FORMAT,
  UPLOAD_TYPE
} from 'appConstants';
import ImageUpload from 'components/ImageUpload';
import NotificationSiteSelect from 'components/NotificationSiteSelect';
import NotificationTypeSelect from 'components/NotificationTypeSelect';
import moment from 'moment';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { debounce, get } from 'utils/lodash';
import { sanitizeSlug } from 'utils/parser';

const WebNotificationInfo = ({
  webNotification,
  isUpdate,
  editWebNotification,
  addWebNotification
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (webNotification) {
      form.setFieldsValue({
        title: webNotification.title,
        content: webNotification.content,
        url: webNotification.url,
        type: NOTIFICATION_VALUE[webNotification.type],
        siteId: NOTIFICATION_SITE_VALUE[webNotification.siteId],
        image: webNotification.image,
        iconLink: webNotification.iconLink,
        sendingTime:
          webNotification.sendingTime && moment(webNotification.sendingTime)
      });
    }
  }, [webNotification, form]);

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const onChangeTitle = debounce(() => {
    const { title, slug } = form.getFieldsValue();
    if (!slug) {
      const _slug = sanitizeSlug(title);
      form.setFieldsValue({ slug: _slug });
    }
  }, DEBOUNCE_VALUE);

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const image = get(
        values.image,
        '[0].response.Data',
        webNotification?.image
      );
      const iconLink = get(
        values.iconLink,
        '[0].response.Data',
        webNotification?.iconLink
      );

      const params = {
        ...values,
        image,
        iconLink
      };

      delete params.urlFile;

      if (isUpdate) {
        await editWebNotification({
          variables: {
            input: {
              id: parseInt(id),
              ...params
            }
          }
        });
      } else {
        await addWebNotification({
          variables: {
            input: {
              ...params
            }
          },
          onCompleted: ({ addWebNotification }) => {
            history.push(
              '/chi-tiet-web-notification/' + addWebNotification?.id
            );
          }
        });
      }
    } catch (error) {}
  };

  return (
    <Card>
      <Form form={form} layout="vertical">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Tiêu đề"
              rules={[{ required: true, message: 'Nhập tiêu đề!' }]}
            >
              <Input placeholder="Nhập tiêu đề" onMouseOut={onChangeTitle} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="content"
              label="Nội dung"
              rules={[{ required: true, message: 'Nhập nội dung!' }]}
            >
              <Input placeholder="Nhập nội dung" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="url"
              label="Đường dẫn"
              rules={[{ required: true, message: 'Nhập đường dẫn!' }]}
            >
              <Input placeholder="Nhập đường dẫn" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="type"
              label="Loại thông báo"
              rules={[{ required: true, message: 'Chọn loại thông báo!' }]}
            >
              <NotificationTypeSelect />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="siteId"
              label="Site"
              rules={[{ required: true, message: 'Chọn site!' }]}
            >
              <NotificationSiteSelect />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="sendingTime"
              label="Thời gian đăng"
              rules={[{ required: true, message: 'Chọn thời gian đăng!' }]}
            >
              <DatePicker
                showTime
                style={{ width: '100%' }}
                format={DATE_FORMAT + ' ' + TIME_FORMAT}
                placeholder="Chọn thời gian đăng"
              />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item
                  name="image"
                  label="Hình đại diện (Ratio 2:1, max 500KB)"
                  valuePropName="file"
                  getValueFromEvent={normFile}
                >
                  <ImageUpload
                    avatarShape="square"
                    size={128}
                    fontSize={64}
                    fullName="Hình đại diện"
                    {...UPLOAD_TYPE.THUMBNAIL_WEB_NOTIFICATION}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="iconLink"
                  label="Icon (Ratio 1:1, max 500KB)"
                  valuePropName="file"
                  getValueFromEvent={normFile}
                >
                  <ImageUpload
                    avatarShape="square"
                    size={128}
                    fontSize={64}
                    fullName="Hình đại diện"
                    {...UPLOAD_TYPE.ICON_WEB_NOTIFICATION}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Row justify="end">
        <Col>
          <Button onClick={onSave} type="primary">
            Lưu
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default WebNotificationInfo;
