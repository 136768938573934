import React from 'react';
import { Tag } from 'antd';
import {
  COMMON_STATUS_INT_DICTIONARY,
  COMMON_STATUS_KEY_INT
} from 'appConstants';

const generateColor = status => {
  switch (status) {
    case COMMON_STATUS_KEY_INT.WAITING:
      return 'default';

    case COMMON_STATUS_KEY_INT.APPROVED:
      return 'green';

    default:
      break;
  }
};

const StatusTag = ({ status, ...props }) => {
  return (
    <Tag color={generateColor(status)} {...props}>
      {COMMON_STATUS_INT_DICTIONARY[status]}
    </Tag>
  );
};

export default StatusTag;
