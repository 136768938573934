import { Typography } from 'antd';

import RoleList from './components/RoleList';

const Role = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách Vai trò</Typography.Title>

      {/* <RoleFilter /> */}

      <RoleList />
    </div>
  );
};

export default Role;
