import React from 'react';
import { Select } from 'antd';
import { PUBLISH_DICTIONARY } from 'appConstants';

const PublishSelect = props => {
  return (
    <Select placeholder="Phát hành" {...props} allowClear>
      {Object.keys(PUBLISH_DICTIONARY).map(key => (
        <Select.Option value={key} key={key}>
          {PUBLISH_DICTIONARY[key]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default PublishSelect;
