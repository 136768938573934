import { gql } from '@apollo/client';

export const GET_COMMENT = gql`
  query GetComments(
    $skip: Int
    $take: Int
    $order: [CommentSortInput!]
    $where: CommentFilterInput
  ) {
    comments(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        itemID
        itemType
        itemTitle
        content
        clientFullName
        clientEmail
        statusId
        contentUpdate
        crDateTime
        updateBy
        updateTime
        __typename
      }
      __typename
    }
  }
`;
