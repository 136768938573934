import { useQuery } from '@apollo/client';
import { Spin, Tabs, Typography } from 'antd';
import { useAddCategory, useEditCategory } from 'operations/mutations';
import { GET_CATEGORY_BY_ID } from 'operations/queries';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import CategoryInfo from './components/CategoryInfo';
import SeoInfo from './components/SeoInfo';

const CategoryDetail = () => {
  const { id } = useParams();
  const isUpdate = useMemo(() => !!id, [id]);
  const { data, loading: fetchLoading } = useQuery(GET_CATEGORY_BY_ID, {
    variables: { id: parseInt(id) },
    skip: !isUpdate
  });

  const { mutate: editCategory, loading: editLoading } = useEditCategory();
  const { mutate: addCategory, loading: addLoading } = useAddCategory();

  const items = useMemo(
    () => [
      {
        label: 'Thông tin chuyên mục',
        key: 'info',
        children: (
          <CategoryInfo
            category={data?.category}
            isUpdate={isUpdate}
            onEditCategory={editCategory}
            onAddCategory={addCategory}
          />
        )
      },
      {
        label: 'SEO',
        key: 'seo',
        children: (
          <SeoInfo
            isUpdate={isUpdate}
            category={data?.category}
            onEditCategory={editCategory}
          />
        ),
        disabled: !isUpdate
      }
    ],
    [addCategory, data?.category, editCategory, isUpdate]
  );

  return (
    <Spin spinning={fetchLoading || editLoading || addLoading}>
      <Typography.Title>
        {isUpdate ? 'Chi tiết chuyên mục' : 'Tạo chuyên mục'}
      </Typography.Title>
      <Tabs type="card" items={items} />
    </Spin>
  );
};

export default CategoryDetail;
