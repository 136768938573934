import { Button, Typography } from 'antd';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import React from 'react';
import { Link } from 'react-router-dom';
import { UserOutlined } from '@ant-design/icons';

// TODO
const UserInfoDisplay = () => {
  const { data, loading, error } = useCurrentUser();
  if (loading) return <Typography.Text>loading</Typography.Text>;
  if (error) return null;
  const { fullName } = data.detail;

  return (
    <Link to="/ca-nhan">
      <Button type="text">
        <Typography.Text strong>
          <UserOutlined /> {fullName}
        </Typography.Text>
      </Button>
    </Link>
  );
};

export default UserInfoDisplay;
