import { Card, Checkbox, Col, Row, Space, Typography } from 'antd';

const PermissionGroup = ({ groupPermission }) => {
  return (
    <Card
      //   bordered={false}
      size="small"
      className="mb-md"
      title={
        <Typography.Title level={4}>
          {groupPermission[0]?.groupName.charAt(0).toUpperCase() +
            groupPermission[0]?.groupName.slice(1)}
        </Typography.Title>
      }
    >
      <Row gutter={[16, 16]}>
        {groupPermission.map(permission => (
          <Col span={24} key={permission.id}>
            <Checkbox value={permission.id}>
              <Space direction="vertical" size={0}>
                <Typography.Text>{permission.title}</Typography.Text>
                <Typography.Text type="secondary">
                  {permission.description}
                </Typography.Text>
              </Space>
            </Checkbox>
          </Col>
        ))}
      </Row>
    </Card>
  );
};

export default PermissionGroup;
