export * from './queryPlaylist';
export * from './querySong';
export * from './queryArtist';
export * from './querySong';
export * from './queryVideo';
export * from './queryMood';
export * from './queryAlbum';
export * from './queryCategory';
export * from './queryTag';
export * from './queryTopic';
export * from './queryPermission';
export * from './queryRole';
export * from './queryUser';
export * from './queryNotification';
export * from './querySearch';
export * from './queryUserAuditLog';
export * from './queryHomepage';
export * from './queryBanner';
export * from './queryClient';
export * from './queryPageInfo';
export * from './queryPartner';
export * from './queryContact';
export * from './queryComment';
export * from './queryAdv';
export * from './queryAdvPosition';
