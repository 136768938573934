import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { searchVideoVar } from 'api/cache';
import AutoComplete from 'components/AutoComplete';
import { GET_VIDEOS } from 'operations/queries';

const VideoSearch = ({ onSelect, ...props }) => {
  const [searchVideo] = useLazyQuery(GET_VIDEOS);
  const searchedVideos = useReactiveVar(searchVideoVar);

  const onChange = value => {
    const video = searchedVideos.find(_video => _video.id === value);
    return onSelect(video);
  };

  return (
    <AutoComplete
      size="large"
      value={null}
      handleSearch={searchVideo}
      labelKey="title"
      valueKey="id"
      dataKey="videos"
      placeholder="Tìm video thêm vào danh sách"
      style={{ width: '100%' }}
      onChange={onChange}
      updateCache={searchVideoVar}
      {...props}
    />
  );
};

export default VideoSearch;
