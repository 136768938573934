import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_SONG = gql`
  mutation EditSong($input: EditSongInput!) {
    editSong(input: $input) {
      id
      title
      publictDay
    }
  }
`;

export const ADD_SONG = gql`
  mutation AddSong($input: AddSongInput!) {
    addSong(input: $input) {
      id
      title
      publictDay
    }
  }
`;

export const REMOVE_SONG = gql`
  mutation RemoveSong($id: Int!) {
    removeSong(id: $id) {
      id
      title
    }
  }
`;

export const BULK_UPDATE_DURATION_SONG = gql`
  mutation BulkUpdateDurationSongs($songIds: [Int!]) {
    bulkUpdateDurationSongs(songIds: $songIds) {
      id
      title
    }
  }
`;

export const BULK_ENCODE_AUDIO_SONG = gql`
  mutation BulkEncodeAudioSongs($songIds: [Int!]) {
    bulkEncodeAudioSongs(songIds: $songIds) {
      id
      title
    }
  }
`;

export const BULK_EXPORT_SONG = gql`
  mutation BulkExportSongs($input: ExportBulkSongInput) {
    bulkExportSongs(input: $input) {
      link
      id
      fileName
      eventId
      createdDate
      createdBy
    }
  }
`;

export const BULK_EXPORT_SONG_BY_FIELDS = gql`
  mutation ExportSongsByFields($input: ExportSongInput) {
    exportSongsByFields(input: $input) {
      link
      id
      fileName
      eventId
      createdDate
      createdBy
    }
  }
`;

// export const BULK_COPYRIGHT_INFRINGEMENT = gql`
//   mutation BulkMarkCopyRightInfringementSongs($songIds: [Int!]) {
//     bulkMarkCopyRightInfringementSongs(songIds: $songIds) {
//       id
//       title
//     }
//   }
// `;

// Clear copyright infringement
export const BULK_COPYRIGHT_INFRINGTMENT_SONGS = gql`
  mutation BulkActionCopyRightInfringementSongs($songIds: [Int!]) {
    bulkActionCopyRightInfringementSongs(songIds: $songIds) {
      id
      title
    }
  }
`;

export const BULK_ACTION_CATEGORIES = gql`
  mutation BulkActionCategories($input: [BulkActionCategoryInput]) {
    bulkActionCategories(input: $input) {
      id
      title
      status
      isDeleted
    }
  }
`;

export const BULK_ACTION_SONGS = gql`
  mutation BulkActionSongs($input: [BulkActionSongInput]) {
    bulkActionSongs(input: $input) {
      id
      title
    }
  }
`;

export const BULK_ADD_SONGS_RELATED = gql`
  mutation BulkAddSongRelated($input: BulkAddSongRelatedInput) {
    bulkAddSongRelated(input: $input) {
      id
      song {
        id
        title
      }
    }
  }
`;

export const useAddSong = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_SONG, {
    onCompleted: ({ addSong }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo bài hát{' '}
            <Typography.Text strong>{addSong.name}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetSongs']
  });
  return { mutate, data, error, loading };
};

export const useEditSong = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_SONG, {
    onCompleted: ({ editSong }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa bài hát{' '}
            <Typography.Text strong>{editSong.title}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetSongs', 'GetSongById']
  });
  return { mutate, data, error, loading };
};

export const useRemoveSong = () => {
  const [mutate, { data, error, loading }] = useMutation(REMOVE_SONG, {
    refetchQueries: ['GetSongs'],
    onCompleted: ({ removeSong }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá bài hát{' '}
            <Typography.Text strong>{removeSong.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useBulkUpdateDurationSong = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_UPDATE_DURATION_SONG,
    {
      refetchQueries: ['GetSongs'],
      onCompleted: () => {
        notification.success({
          message: (
            <Typography.Text>Đã cập nhật thời lượng bài hát</Typography.Text>
          )
        });
      }
    }
  );

  return { mutate, data, error, loading };
};

export const useBulkEncodeAudioSong = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ENCODE_AUDIO_SONG,
    {
      refetchQueries: ['GetSongs'],
      onCompleted: () => {
        notification.success({
          message: <Typography.Text>Đã cập nhật encode bài hát</Typography.Text>
        });
      }
    }
  );

  return { mutate, data, error, loading };
};

// export const useBulkCopyrightInfringment = () => {
//   const [mutate, { data, error, loading }] = useMutation(BULK_COPYRIGHT_INFRINGEMENT, {
//     refetchQueries: ['GetSongs'],
//     onCompleted: () => {
//       notification.success({
//         message: (
//           <Typography.Text>Đã cập nhật vi phạm bản quyền</Typography.Text>
//         )
//       });
//     }
//   });

//   return { mutate, data, error, loading };
// };

export const useBulkActionCopyRightInfringementSongs = actionField => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_COPYRIGHT_INFRINGTMENT_SONGS,
    {
      refetchQueries: ['GetSongs'],
      onCompleted: ({ bulkActionCopyRightInfringementSongs }) => {
        getBulkMessage(
          'Bài hát',
          bulkActionCopyRightInfringementSongs,
          actionField
        );
      }
    }
  );

  return { mutate, data, error, loading };
};

export const useBulkActionSongs = actionField => {
  const [mutate, { data, error, loading }] = useMutation(BULK_ACTION_SONGS, {
    refetchQueries: ['GetSongs'],
    onCompleted: ({ bulkActionSongs }) => {
      getBulkMessage('bài hát', bulkActionSongs, actionField);
    }
  });

  return { mutate, data, error, loading };
};

export const useBulkAddSongRelated = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ADD_SONGS_RELATED,
    {
      onCompleted: () => {
        notification.success({
          message: (
            <Typography.Text>Đã cập nhật bài hát liên quan</Typography.Text>
          )
        });
      },
      refetchQueries: ['GetSongs', 'GetSongById']
    }
  );
  return { mutate, data, error, loading };
};

export const useBulkExportSongs = () => {
  const [mutate, { data, error, loading }] = useMutation(BULK_EXPORT_SONG, {
    // onCompleted: () => {
    //   notification.success({
    //     message: <Typography.Text>Export thành công!</Typography.Text>
    //   });
    // }
  });

  return { mutate, data, error, loading };
};

export const useBulkExportSongByFields = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_EXPORT_SONG_BY_FIELDS
    // {
    //   onCompleted: () => {
    //     notification.success({
    //       message: <Typography.Text>Export thành công!</Typography.Text>
    //     });
    //   }
    // }
  );

  return { mutate, data, error, loading };
};
