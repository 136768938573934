import { gql } from '@apollo/client';

export const GET_BANNERS = gql`
  query GetBanners(
    $skip: Int
    $take: Int
    $order: [BannerSortInput!]
    $where: BannerFilterInput
  ) {
    banners(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        title
        slug
        image
      }
    }
  }
`;
