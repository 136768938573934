import React from 'react';

const Snow = () => {
  return (
    <div>
      <div className="snowflakes" aria-hidden="true">
        {[...Array(10).keys()].map((_, index) => (
          <div key={index} className="snowflake">
            ❅
          </div>
        ))}
      </div>
      <div>
        {[...Array(200).keys()].map((_, index) => (
          <div key={index} className="snow"></div>
        ))}
      </div>
    </div>
  );
};

export default Snow;
