import { ScissorOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Row,
  Space,
  TimePicker,
  Tooltip,
  Typography
} from 'antd';
import {
  CATEGORY_TYPE,
  DATE_FORMAT,
  DEBOUNCE_VALUE,
  RANK_VALUE,
  TIME_FORMAT,
  UPLOAD_TYPE
} from 'appConstants';
import ArtistAutocomplete from 'components/ArtistAutocomplete';
import AudioListUpload from 'components/AudioListUpload';
import CategoryAutocomplete from 'components/CategoryAutocomplete';
import CommonUpload from 'components/CommonUpload';
import GenerateFile from 'components/GenerateFile';
import ImageUpload from 'components/ImageUpload';
import ProviderSelect from 'components/ProviderSelect';
import RankSelect from 'components/RankSelect';
import TagAutocomplete from 'components/TagAutocomplete';
import Tiptap from 'components/Tiptap';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { debounce, get } from 'utils/lodash';
import { sanitizeSlug } from 'utils/parser';

const SongInfo = ({ song, isUpdate, editSong, addSong }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const [singerText, setSingerText] = useState();
  const title = Form.useWatch('title', form);
  const thumbnail = Form.useWatch('thumbnailSocial', form);
  const slug = Form.useWatch('slug', form);
  const reqGenFile = useMemo(
    () => ({
      title: title,
      thumbnail:
        typeof thumbnail === 'string'
          ? thumbnail
          : get(thumbnail, '[0].response.Data', song?.thumbnailSocial),
      slug: slug,
      artist: singerText,
      type: 'social/song',
      imageType: 'DEFAULT'
    }),
    [singerText, slug, song?.thumbnailSocial, thumbnail, title]
  );

  const onChangeSingers = (_, options) => {
    const text = options?.map(singer => singer?.children).join(', ');
    return setSingerText(text);
  };

  const onGenerateSharingFile = res => {
    form.setFieldsValue({ thumbnailSocial: res?.Data });
  };

  useEffect(() => {
    if (song) {
      form.setFieldsValue({
        title: song.title,
        slug: song.slug,
        intro: song.intro,
        lyric: song?.lyric,
        // lyric:
        //   song?.lyric &&
        //   he.decode(song?.lyric?.replace(/<[^>]*>/g, ''), {
        //     strict: true
        //   }),
        thumbnail: song.thumbnail,
        thumbnailSocial: song.thumbnailSocial,
        maHd: song.maHd,
        maBaiHat: song.maBaiHat,
        url: song.url,
        provider: song.provider,
        subProvider: song.subProvider,
        producer: song.producer,
        isMedley: song.isMedley,
        lyricFileUrl: song.lyricFileUrl,
        vocalRank: RANK_VALUE[song.vocalRank],
        releaseYear: song?.releaseYear && moment(song?.releaseYear, 'YYYY'),
        previewsStartTime:
          song?.previewsStartTime &&
          moment(song?.previewsStartTime, 'HH:mm:ss'),
        previewsDuration: song.previewsDuration,
        categoryIds: song.categories.map(category => category?.id),
        tagIds: song.tags.map(tag => tag?.id),
        singerIds: song.singers.map(singer => singer?.id),
        musicianIds: song.musicians.map(musician => musician?.id),
        publictDay: song.publictDay && moment(song.publictDay)
      });
      setSingerText(song?.singers?.map(singer => singer?.name).join(', '));
    }
  }, [song, form]);

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const onChangeTitle = debounce(() => {
    const { title, slug } = form.getFieldsValue();
    if (!slug) {
      const _slug = sanitizeSlug(title);
      form.setFieldsValue({ slug: _slug });
    }
  }, DEBOUNCE_VALUE);

  const onGenerateSlug = () => {
    const { title } = form.getFieldsValue();
    if (!title) return;
    const _slug = sanitizeSlug(title);
    form.setFieldsValue({ slug: _slug });
  };

  const onChangeFile = formParam => {
    return form.setFieldsValue(formParam);
  };

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const thumbnail = get(
        values.thumbnail,
        '[0].response.Data',
        song?.thumbnail
      );
      const thumbnailSocial =
        typeof values.thumbnailSocial === 'string'
          ? values.thumbnailSocial
          : get(
            values.thumbnailSocial,
            '[0].response.Data',
            song?.thumbnailSocial
          );
      const releaseYear = parseInt(moment(values.releaseYear).format('YYYY'));
      const previewsStartTime =
        values.previewsStartTime &&
        moment(values.previewsStartTime).format('HH:mm:ss');

      const params = {
        ...values,
        thumbnail,
        thumbnailSocial,
        releaseYear,
        previewsStartTime
      };
      // Test to call api

      delete params.urlFile;

      if (isUpdate) {
        await editSong({
          variables: {
            input: {
              id: parseInt(id),
              ...params
            }
          }
        });
      } else {
        await addSong({
          variables: {
            input: {
              ...params
            }
          },
          onCompleted: ({ addSong }) => {
            history.push('/chi-tiet-bai-hat/' + addSong?.id);
          }
        });
      }
    } catch (error) { }
  };

  return (
    <Card>
      <Form form={form} layout="vertical">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Tên bài hát"
              rules={[{ required: true, message: 'Nhập tên bài hát!' }]}
            >
              <Input
                placeholder="Nhập tên bài hát"
                onMouseOut={onChangeTitle}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="slug"
              label="Slug"
              rules={[{ required: true, message: 'Nhập slug!' }]}
            >
              <Input
                suffix={
                  <Tooltip title="Generate slug">
                    <ScissorOutlined
                      style={{ fontSize: 16 }}
                      onClick={onGenerateSlug}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="categoryIds"
              label="Thể loại"
              rules={[{ required: true, message: 'Chọn thể loại!' }]}
            >
              <CategoryAutocomplete
                allowClear
                category={song?.categories}
                type={CATEGORY_TYPE.SONG}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="tagIds"
              label="Tag"
              rules={[{ required: true, message: 'Chọn tag!' }]}
            >
              <TagAutocomplete allowClear tag={song?.tags} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="singerIds"
              label="Ca sĩ"
              rules={[{ required: true, message: 'Chọn ca sĩ!' }]}
            >
              <ArtistAutocomplete
                allowClear
                artist={song?.singers}
                onChange={onChangeSingers}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="musicianIds"
              label="Nhạc sĩ"
              rules={[{ required: true, message: 'Chọn nhạc sĩ!' }]}
            >
              <ArtistAutocomplete allowClear artist={song?.musicians} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="vocalRank"
              label="Xếp hạng giọng hát"
              rules={[{ required: true, message: 'Chọn xếp hạng giọng hát!' }]}
            >
              <RankSelect />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="isMedley"
              label=" "
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox>Bài hát là liên khúc</Checkbox>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="maHd" label="Mã hợp đồng">
              <Input placeholder="Nhập Mã hợp đồng" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="maBaiHat" label="Mã bài hát">
              <Input placeholder="Nhập mã bài hát" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="provider"
              label="Nhà cung cấp"
              rules={[{ required: true, message: 'Chọn nhà cung cấp!' }]}
            >
              <ProviderSelect />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="subProvider" label="Chi nhánh nhà cung cấp">
              <Input placeholder="Nhập chi nhánh nhà cung cấp" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="producer" label="Nhà sản xuất">
              <Input placeholder="Nhập nhà sản xuất" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="releaseYear" label="Năm phát hành">
              <DatePicker
                style={{ width: '100%' }}
                picker="year"
                placeholder="Năm phát hành"
                format={'YYYY'}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="publictDay"
              label="Thời gian đăng"
              rules={[{ required: true, message: 'Chọn thời gian đăng!' }]}
            >
              <DatePicker
                showTime
                style={{ width: '100%' }}
                format={DATE_FORMAT + ' ' + TIME_FORMAT}
                placeholder="Chọn thời gian đăng"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="previewsStartTime" label="Phát từ khi nào">
              <TimePicker
                style={{ width: '100%' }}
                placeholder="Phát từ khi nào"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="previewsDuration"
              label="Phát trong bao lâu (giây)"
            >
              <InputNumber
                style={{ width: '100%' }}
                min={0}
                max={10000}
                placeholder="Phát trong bao lâu"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="lyricFileUrl"
              label={
                <Space>
                  <Typography.Text>File lyric (Định dang .lrc)</Typography.Text>
                  <CommonUpload
                    acceptType=".lrc"
                    isSpecialType={true}
                    form={form}
                    onDone={url => onChangeFile({ lyricFileUrl: url })}
                    {...UPLOAD_TYPE.LYRIC_SONG}
                  />
                </Space>
              }
            >
              <Input placeholder="Nhập link lyric" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="url"
              rules={[{ required: true, message: 'Chọn bài hát!' }]}
              label={
                <Space>
                  <Typography.Text>
                    File bài hát (Định dang .mp3, .wav.)
                  </Typography.Text>
                  <AudioListUpload
                    form={form}
                    onDone={url => onChangeFile({ url: url })}
                    {...UPLOAD_TYPE.TRACK_SONG}
                  />
                </Space>
              }
            >
              <Input placeholder="Nhập link file" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="intro"
              label="Giới thiệu"
              rules={[{ required: true, message: 'Nhập giới thiệu!' }]}
            >
              <Input.TextArea rows={7} placeholder="Nhập giới thiệu" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="lyric"
              label="Lyric"
              rules={[{ required: true, message: 'Nhập lyric!' }]}
            >
              {/* <Input.TextArea rows={7} placeholder="Nhập lyric" /> */}
              <Tiptap />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item
                  name="thumbnail"
                  label="Hình đại diện (max 500KB)"
                  valuePropName="file"
                  getValueFromEvent={normFile}
                >
                  <ImageUpload
                    avatarShape="square"
                    size={128}
                    fontSize={64}
                    fullName="Hình đại diện"
                    {...UPLOAD_TYPE.SONG}
                  />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="thumbnailSocial"
                  label={
                    <Space>
                      <Typography.Text>
                        Share Thumbnail (max 500KB)
                      </Typography.Text>{' '}
                      <GenerateFile
                        values={reqGenFile}
                        onGenerate={onGenerateSharingFile}
                      />
                    </Space>
                  }
                  valuePropName="file"
                  getValueFromEvent={normFile}
                >
                  <ImageUpload
                    avatarShape="square"
                    size={128}
                    fontSize={64}
                    fullName="Share thumbnail"
                    freeStyle={isUpdate}
                    {...UPLOAD_TYPE.SHARE_SONG}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Row justify="end">
        <Col>
          <Button onClick={onSave} type="primary">
            Lưu
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default SongInfo;
