import { gql } from '@apollo/client';

export const GET_VIDEOS = gql`
  query GetVideos(
    $skip: Int
    $take: Int
    $order: [VideoSortInput!]
    $where: VideoFilterInput
  ) {
    videos(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        title
        intro
        thumbnail
        status
        slug
        uniqId
        clicks
        views
        url
        publicDate
        thumbnailSocial
        isMedley
        maHd
        maBaiHat
        isCopyrightInfringement
        relatedVideos {
          id
          title
        }
        categories {
          id
          name
        }
        singers {
          id
          name
        }
        musicians {
          id
          name
        }
        tags {
          id
          name
        }
        createdDate
        updatedDate
        createdBy
        updatedBy
      }
    }
  }
`;

export const GET_VIDEO_BY_ID = gql`
  query GetVideoById($id: Int!) {
    video(id: $id) {
      id
      title
      intro
      thumbnail
      status
      slug
      uniqId
      clicks
      views
      url
      thumbnailSocial
      isMedley
      maHd
      maBaiHat
      isCopyrightInfringement
      publicDate
      relatedVideos {
        id
        title
        thumbnail
        categories {
          id
          name
        }
        singers {
          id
          name
        }
        musicians {
          id
          name
        }
      }
      categories {
        id
        name
      }
      singers {
        id
        name
      }
      musicians {
        id
        name
      }
      tags {
        id
        name
      }
      playlists {
        id
        name
        thumbnail
        categories {
          id
          name
        }
      }
      metaSeo {
        metaTitle
        metaKeywords
        metaDescription
      }
    }
  }
`;
