export const initParams = category => {
  if (!category) return;
  const params = {
    id: category.id,
    name: category.name,
    slug: category.slug,
    intro: category.intro,
    type: category.type,
    groupId: category.groupId,
    onHeader: category.onHeader,
    onFooter: category.onFooter
  };
  return params;
};
