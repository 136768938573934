import { Typography } from 'antd';

import React from 'react';

import CategoryList from './components/CategoryList';
import CategoryFilter from './CategoryFilter';

const Category = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách chuyên mục</Typography.Title>

      <CategoryFilter />

      <CategoryList />
    </div>
  );
};

export default Category;
