import { useLazyQuery } from '@apollo/client';
import AutoComplete from 'components/AutoComplete';
import { GET_ARTISTS } from 'operations/queries';
import { useMemo } from 'react';

const ArtistAutocomplete = ({ artist, ...props }) => {
  const [searchArtist] = useLazyQuery(GET_ARTISTS);
  const defaultOption = useMemo(
    () =>
      artist
        ? Array.isArray(artist)
          ? artist.map(({ id, name }) => ({ value: id, label: name }))
          : { value: artist.id, label: artist.name }
        : null,
    [artist]
  );

  return (
    <AutoComplete
      mode="multiple"
      defaultOption={defaultOption}
      handleSearch={searchArtist}
      labelKey="name"
      valueKey="id"
      dataKey="artists"
      placeholder="Tìm nghệ sĩ"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default ArtistAutocomplete;
