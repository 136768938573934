import {
  Button,
  Card,
  Col,
  Form,
  Input,
  Row,
  DatePicker,
  Select
} from 'antd';
import {
  DATE_FORMAT,
  TIME_FORMAT,
} from 'appConstants';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { Link } from 'react-router-dom';
import moment from 'moment';
import {
  COMMON_STATUS_INT_DICTIONARY,
} from 'appConstants';

const Adv = ({
  adv,
  isUpdate,
  onEditAdvContact,
  onAddAdvContact
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const [type, setType] = useState(0);
  useEffect(async () => {
    if (adv) {
      let startTime = adv.startTime && moment(adv.startTime)
      let endTime = adv.endTime && moment(adv.endTime)
      setType(parseInt(adv.advertContentTypeId || 0))
      form.setFieldsValue({
        startTime, endTime,
        advertName: adv.advertName,
        advertContentTypeId: adv.advertContentTypeId,
        url: adv.url,
        width: adv.width,
        height: adv.height,
        advertDesc: adv.advertDesc,
        imagePath: adv.imagePath,
        imageHoverPath: adv.imageHoverPath,
        advertStatusId: adv.advertStatusId,
        id: adv.id,
        scriptContent: adv.scriptContent
      });
    }
  }, [adv, form]);

  const onSave = async (addMore) => {
    try {
      const values = await form.validateFields();
      if (values.advertStatusId == undefined) values.advertStatusId = 0
      const params = {
        ...values
      };
      if (isUpdate) {
        // update
        await onEditAdvContact({
          variables: {
            input: {
              ...params,
              id: parseInt(id)
            }
          }, onCompleted: () => {
            if (addMore) {
              form.resetFields()
              history.push('/chi-tiet-quang-cao');
            }
          }
        });
      } else {
        //  create
        await onAddAdvContact({
          variables: {
            input: params
          },
          onCompleted: ({ addIcAdvert }) => {
            if (addMore) {
              form.resetFields()
              history.push('/chi-tiet-quang-cao');
            } else
            history.push('/chi-tiet-quang-cao/' + addIcAdvert?.id);
          }
        });
      }
    } catch (error) { }
  };

  return (
    <Card>
      <Form form={form} layout="vertical">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="advertName"
              label="Tên quảng cáo"
              rules={[{ required: true, message: 'Nhập tên quảng cáo!' }]}
            >
              <Input placeholder="Nhập tên (tối đa 200 kí tự)" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="advertContentTypeId"
              label="Loại nội dung quảng cáo"
              rules={[{ required: true, message: 'Bắt buộc' }]}

            >
              <Select placeholder="Chọn loại" onChange={setType}>
                <Select.Option value={1} >File ảnh
                </Select.Option>
                <Select.Option value={2} >Script
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          {
            type == 1 &&
            <>
              <Col span={24}>
                <Form.Item
                  name="imagePath"
                  label="Đường dẫn ảnh"
                >
                  <Input placeholder="Nhập đường dẫn ảnh (tối đa 200 kí tự)" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="imageHoverPath"
                  label="Đường dẫn ảnh khi hover"
                >
                  <Input placeholder="Nhập đường dẫn ảnh hover (tối đa 200 kí tự)" />
                </Form.Item>
              </Col>
            </>

          }
          {
            type == 2 && <Col span={24}>
              <Form.Item
                name="scriptContent"
                label="Nội dung Script"
              >
                <Input.TextArea rows={3} placeholder="Nhập nội dung" />
              </Form.Item>
            </Col>
          }
          <Col span={12}>
            <Form.Item
              name="url"
              label="Url"
            >
              <Input placeholder="Nhập đường dẫn (tối đa 200 kí tự)" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="advertStatusId"
              label="Trạng thái"
            >
              <Select placeholder="Chọn " defaultValue={0} >
                <Select.Option value={0} >{COMMON_STATUS_INT_DICTIONARY[0]}
                </Select.Option>
                <Select.Option value={1} >{COMMON_STATUS_INT_DICTIONARY[1]}
                </Select.Option>
              </Select>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="startTime" label="Thời gian bắt đầu">
              <DatePicker
                showTime
                style={{ width: '100%' }}
                format={DATE_FORMAT + ' ' + TIME_FORMAT}
                placeholder="Bắt đầu khi nào"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="endTime" label="Thời gian kết thúc">
              <DatePicker
                showTime
                style={{ width: '100%' }}
                format={DATE_FORMAT + ' ' + TIME_FORMAT}
                placeholder="Kết thúc khi nào"
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="width"
              label="Độ rộng"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="height"
              label="Chiều cao"
            >
              <Input />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="advertDesc"
              label="Mô tả"
            >
              <Input.TextArea rows={3} placeholder="Nhập giới thiệu" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row justify="end" align="middle" gutter={16}>
        <Col>
          <Link to={'/quang-cao'}>
            <Button type="primary">
              Quay lại
            </Button>
          </Link>
        </Col>
        <Col>
          <Button onClick={() => onSave(true)} type="primary">
            Lưu và thêm mới
          </Button>
        </Col>
        <Col>
          <Button onClick={() => onSave(false)} type="primary">
            Lưu
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default Adv;
