import { Button } from 'antd';
import { ACTION_COPYRIGHT_DICTIONARY, COPYRIGHT_KEY_INT } from 'appConstants';

const CopyrightInfringementButton = ({
  isCopyrightInfringement,
  onClick,
  total
}) => {
  return (
    <Button
      type="primary"
      danger={isCopyrightInfringement === COPYRIGHT_KEY_INT.NON_COPYRIGHT}
      onClick={onClick}
      block
      style={{
        background:
          isCopyrightInfringement === COPYRIGHT_KEY_INT.COPYRIGHT && 'green',
        border: 'none'
      }}
    >
      {ACTION_COPYRIGHT_DICTIONARY[isCopyrightInfringement]} ({total})
    </Button>
  );
};

export default CopyrightInfringementButton;
