import React from 'react';
import { Select } from 'antd';
import { ORDER_BY_DICTIONARY } from 'appConstants';

const SearchSortSelect = ({ dict, ...props }) => {
  return (
    <Select style={{ width: 200, fontWeight: 'normal' }} {...props}>
      {Object.keys(dict ? dict : ORDER_BY_DICTIONARY).map(key => (
        <Select.Option value={key} key={key}>
          {dict ? dict[key].displayName : ORDER_BY_DICTIONARY[key].displayName}
        </Select.Option>
      ))}
    </Select>
  );
};

export default SearchSortSelect;
