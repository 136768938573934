import { useLazyQuery } from '@apollo/client';
import { Form } from 'antd';
import {
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import UserAutocomplete from 'components/UserAutocomplete';
import { GET_USERS } from 'operations/queries/queryUser';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { processFormItemName } from 'utils/filter';

const UserAutoCompleteFilter = () => {
  const [searchUser, { loading }] = useLazyQuery(GET_USERS);
  const [artist, setArtists] = useState([]);
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const userFilterName = useMemo(() => {
    return processFormItemName({
      prefix: FILTER_PARAMS_PREFIX,
      name: 'createdBy',
      operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ
    });
  }, []);

  const value = useMemo(() => {
    if (Array.isArray(searchParams[userFilterName])) {
      return searchParams[userFilterName];
    }

    return [searchParams[userFilterName]];
  }, [userFilterName, searchParams]);

  const getUsers = useCallback(async () => {
    try {
      const { data } = await searchUser({
        variables: {
          where: {
            or: value.map(eq => ({ userName: { eq } }))
          }
        }
      });

      setArtists(data?.artists?.items);
    } catch (e) {
      throw e;
    }
  }, [searchUser, value]);

  useEffect(() => {
    if (searchParams[userFilterName]) getUsers();
  }, [getUsers, userFilterName, searchParams]);

  return (
    <>
      <Form.Item name={userFilterName} label="Người tạo">
        <UserAutocomplete allowClear artist={artist} loading={loading} />
      </Form.Item>
    </>
  );
};

export default UserAutoCompleteFilter;
