import { useReactiveVar } from '@apollo/client';
import { Button, Form, Input } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import { UPLOAD_TYPE } from 'appConstants';
import ImageUpload from 'components/ImageUpload';

import { useEffect } from 'react';

const UserFormContent = ({ form, onSubmit }) => {
  const visibleDrawer = useReactiveVar(visibleDrawerVar);
  const user = visibleDrawer.record;

  useEffect(() => {
    if (user) {
      form.setFieldsValue({
        name: user?.name,
        email: user?.email,
        phone: user?.phone,
        address: user?.address,
        facebook: user?.facebook,
        twitter: user?.twitter,
        logo: user?.logo
      });
    }
    return () => form.resetFields();
  }, [user, form]);
  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  return (
    <Form form={form} layout="vertical" onFinish={onSubmit}>
      <Form.Item
        name="name"
        label="Đối tác"
        rules={[{ required: true, message: 'Nhập đối tác!' }]}
      >
        <Input placeholder="Nhập đối tác" />
      </Form.Item>

      <Form.Item
        name="email"
        label="Email"
        rules={
          !!user
            ? []
            : [{ required: true, type: 'email', message: 'Nhập email!' }]
        }
      >
        <Input placeholder="Nhập email" />
      </Form.Item>
      <Form.Item
        name="address"
        label="Địa chỉ"
        rules={[{ required: true, message: 'Nhập địa chỉ!' }]}
      >
        <Input placeholder="Nhập địa chỉ" />
      </Form.Item>

      <Form.Item
        name="phone"
        label="Điện thoại"
        rules={[
          { required: true, message: 'Nhập điện thoại!' },
          { min: 10, message: 'Tối thiểu 8 ký tự' }
        ]}
      >
        <Input placeholder="Nhập điện thoại" />
      </Form.Item>
      <Form.Item
        name="facebook"
        label="Facebook"
        rules={[{ required: true, message: 'Nhập facebook!' }]}
      >
        <Input placeholder="Nhập facebook" />
      </Form.Item>
      <Form.Item
        name="twitter"
        label="Twitter"
        rules={[{ required: true, message: 'Nhập twitter!' }]}
      >
        <Input placeholder="Nhập twitter" />
      </Form.Item>
      <Form.Item
        name="logo"
        label="logo"
        valuePropName="file"
        getValueFromEvent={normFile}
      >
        <ImageUpload
          avatarShape="square"
          size={128}
          fontSize={64}
          fullName="Thumbnail"
          {...UPLOAD_TYPE.PARTNER}
        />
      </Form.Item>

      <Form.Item style={{ display: 'none' }}>
        <Button htmlType="submit">Submit</Button>
      </Form.Item>
    </Form>
  );
};

export default UserFormContent;
