import { Select } from 'antd';
import { TOPIC_TYPE_DICTIONARY } from 'appConstants';
import React from 'react';
const { Option } = Select;

const TopicTypeSelect = props => {
  return (
    <Select
      allowClear
      placeholder="Chọn loại topic"
      style={{ width: '100%' }}
      {...props}
    >
      {Object.entries(TOPIC_TYPE_DICTIONARY).map(([key, value]) => (
        <Option key={key} value={key}>
          {value}
        </Option>
      ))}
    </Select>
  );
};

export default TopicTypeSelect;
