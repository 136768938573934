import { Space, Typography, Tag, Button } from 'antd';
import RemoveButton from 'components/RemoveButton';

import {
  COMMON_STATUS_INT_DICTIONARY,
  COMMON_STATUS_KEY_INT,
  ADV_DISPLAY
} from 'appConstants';
import 'moment/locale/vi';
import moment from 'moment';
import {
  UserOutlined,
  CalendarOutlined,
  SnippetsOutlined
} from '@ant-design/icons';
import { useRemoveIcAdvertPosition } from 'operations/mutations';
import { Link } from 'react-router-dom';

const RemoveAdv = ({ record }) => {
  const { mutate } = useRemoveIcAdvertPosition();
  return <RemoveButton record={record} actionRemove={mutate} titleKey="name" />;
};

const generateColor = status => {
  switch (status) {
    case COMMON_STATUS_KEY_INT.WAITING:
      return 'default';

    case COMMON_STATUS_KEY_INT.APPROVED:
      return 'green';

    default:
      break;
  }
};

const StatusTag = ({ status, ...props }) => {
  return (
    <Tag color={generateColor(status)} {...props}>
      {COMMON_STATUS_INT_DICTIONARY[status]}
    </Tag>
  );
};

export const columns = [
  {
    title: 'Stt',
    width: '50px',
    key: 'stt',
    dataIndex: 'stt',
    fixed:'left'
  },
  {
    title: 'Tên vị trí',
    dataIndex: 'positionName',
    key: 'positionName',
    fixed:'left',
    render: (_, record) => (
      <Space direction="vertical">
        <Typography.Text strong>
          <Link
            style={{ color: 'inherit' }}
            to={`/chi-tiet-vi-tri-quang-cao/${record?.id}`}
          >
            {record?.positionName}
          </Link>
        </Typography.Text>


      </Space>
    ),
    fixed: 'left',
  },
  {
    title: 'Loại hiển thị',
    dataIndex: 'advertDisplayTypeId',
    render: (_, { advertDisplayTypeId }) => (
      <Space direction="vertical">
        {ADV_DISPLAY[advertDisplayTypeId]}
      </Space>
    )
  },
  {
    title: 'Mô tả',
    dataIndex: 'positionDesc',
    key: 'positionDesc',
    render: (_, record) => (
      <Space direction="vertical">
        <Typography.Text>
          {record?.positionDesc}
        </Typography.Text>

      </Space>
    )
  },
  {
    title: 'Thông tin',
    dataIndex: 'related',
    render: (_, record) => (
      <Space direction="vertical">
        <Typography.Text>
          <CalendarOutlined title='ngày tạo' />
          {"  "}
          {record.crDateTime && moment(record.crDateTime).format('HH:mm DD/MM/YYYY')}
        </Typography.Text>
        {record.userName && (
          <Typography.Text>
            <UserOutlined title='người tạo' />
            {"  "}
            {record.userName}
          </Typography.Text>
        )}
      </Space>
    ),
  },
  {
    title: 'Thao tác',
    width: '120px',
    render: (_, record) =>

      <Space direction="horizontal">
        <Button onClick={record.action}
          title='Gán quảng cáo vào vị trí' type="primary"
          size="small" icon={<SnippetsOutlined />} />
        <RemoveAdv record={record} />
      </Space>
    ,
    toggleEdit: true,
    align: 'center'
  }
];
