import { BulbOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Card, Drawer, Result, Space, Table, Typography } from 'antd';
import { PAGE_SIZE } from 'appConstants';
import UserAvatar from 'components/UserAvatar';
import { useBulkActionTopSongs } from 'operations/mutations';
import { GET_SONGS } from 'operations/queries';
import { useEffect, useState } from 'react';

const SongSuggestion = () => {
  const [dataSource, setDataSource] = useState([]);
  const [open, setOpen] = useState(false);
  const { mutate: updateHotSongs } = useBulkActionTopSongs();
  const {
    loading: isSongsLoading,
    error: songsError,
    data: songsConnect
  } = useQuery(GET_SONGS, {
    variables: {
      take: PAGE_SIZE,
      order: [
        {
          views: 'DESC'
        }
      ],
      where: {
        isDelete: {
          eq: 0
        },
        status: {
          eq: 1
        },
        isCopyrightInfringement: {
          eq: 0
        }
      }
    }
  });

  const columns = [
    {
      title: 'Thứ tự',
      width: 80,
      align: 'center',
      render: (_, __, index) => (
        <Typography.Title level={2} style={{ margin: 0 }}>
          {index + 1}
        </Typography.Title>
      )
    },
    {
      title: 'Tiêu đề',
      dataIndex: 'name',
      className: 'drag-visible',
      render: (_, { thumbnail, name, title }) => (
        <Space>
          <UserAvatar
            fullName={name}
            avatar={thumbnail}
            shape="square"
            size={48}
          />
          <Typography.Text>{name || title}</Typography.Text>
        </Space>
      )
    },
    {
      title: 'Ca sĩ',
      dataIndex: 'singers',
      render: (_, record) => (
        <Typography.Text>
          {record?.singers?.map(singer => singer?.name).join(', ')}
        </Typography.Text>
      )
    },
    {
      title: 'View',
      dataIndex: 'views',
      render: (_, { views }) => <Typography.Text>{views}</Typography.Text>
    }
  ];

  useEffect(() => {
    if (!isSongsLoading && songsConnect) {
      const _data = songsConnect?.songs?.items?.map((item, index) => ({
        ...item,
        key: item.id,
        index
      }));
      setDataSource(_data);
    }
  }, [songsConnect, isSongsLoading]);

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  const onSave = async () => {
    try {
      const input = dataSource?.map(data => ({
        sid: data?.id,
        itemType: 'Hot'
      }));
      await updateHotSongs({
        variables: {
          input
        }
      });
    } catch (error) {}
  };
  if (songsError)
    return (
      <Card>
        <Result
          status="500"
          // title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      </Card>
    );

  return (
    <>
      <Button icon={<BulbOutlined />} onClick={showDrawer}>
        Gợi ý
      </Button>
      <Drawer
        zIndex={10}
        size="large"
        title="Gợi ý bài hát nổi bật"
        placement="right"
        onClose={onClose}
        open={open}
        extra={
          <Button type="primary" onClick={onSave}>
            Sử dụng gợi ý
          </Button>
        }
      >
        <Table
          // loading={fetchLoading}
          className="mb-md"
          pagination={false}
          dataSource={dataSource}
          columns={columns}
          rowKey="index"
        />
      </Drawer>
    </>
  );
};

export default SongSuggestion;
