import { Row, Space, Typography } from 'antd';
import { DeveloperIcon } from 'components/Icons';
const Dashboard = () => {
  return (
    <>
      <Typography.Title>Bảng điều khiển</Typography.Title>
      <Row justify="center">
        <Space direction="vertical" align="center">
          {/* <Image src={whellIcon} preview={false} /> */}
          <DeveloperIcon style={{ fontSize: 400 }} />
          <Typography.Title level={1}>Coming Soon</Typography.Title>
        </Space>
      </Row>
    </>
  );
};
export default Dashboard;
