import { DeleteOutlined } from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import ColName from 'components/ColName';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import RelatedUser from 'components/RelatedUser';
import TableRecordName from 'components/TableRecordName';
import TimeLine from 'components/TimeLine';
import UserStatusButton from 'components/UserStatusButton';
import 'moment/locale/vi';
import {
  useEditUser,
  useRemoveUser
} from 'operations/mutations/user/mutateUser';

const RemoveUser = ({ record }) => {
  const { mutate } = useRemoveUser();
  return (
    <DeleteConfirmPopup
      onDelete={() =>
        mutate({
          variables: {
            input: {
              userId: parseInt(record.userId)
            }
          }
        })
      }
      title={
        <Typography.Text>
          Bạn muốn xoá{' '}
          <Typography.Text strong>{record?.fullName}</Typography.Text> ?
        </Typography.Text>
      }
    >
      <Button danger type="primary" size="small" icon={<DeleteOutlined />} />
    </DeleteConfirmPopup>
  );
};

const Status = ({ record }) => {
  const { mutate } = useEditUser();

  return <UserStatusButton record={record} onClick={mutate} />;
};

const Name = ({ record }) => {
  const { fullName } = record;

  return (
    <Space direction="vertical">
      <TableRecordName
        name={fullName}
        setDrawerVisible={() =>
          visibleDrawerVar({ visible: true, record: record })
        }
      />
    </Space>
  );
};

export const columns = [
  {
    title: 'User',
    dataIndex: 'title',
    key: 'title',
    render: (_, record) => (
      <ColName primary={<Name record={record} />} secondary={record?.email} />
    ),
    fixed: 'left',
    width: '200px'
  },
  {
    title: 'Trạng thái',
    dataIndex: 'status',
    width: '100px',
    render: (_, record) => <Status record={record} />
  },
  {
    title: 'Tên đăng nhập',
    dataIndex: 'userName',
    align: 'left',
    width: '100px'
  },
  {
    title: 'Phòng ban',
    dataIndex: 'phongBan',
    align: 'left',
    width: '100px',
    render: phongBan => <Typography.Text>{phongBan?.name}</Typography.Text>
  },
  {
    title: 'Vai trò',
    dataIndex: 'roleName',
    align: 'left',
    width: '100px',
    render: roleName => <Typography.Text>{roleName?.title}</Typography.Text>
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '120px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    width: '120px',
    render: (_, { createdBy, updatedBy }) => (
      <RelatedUser creator={createdBy} updater={updatedBy} />
    )
  },
  {
    title: 'Thao tác',
    width: '50px',
    render: (_, record) => <RemoveUser record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
