import SeoCommon from 'containers/SeoCommon/SeoCommon';
import { initParams } from '../util';

const SeoInfo = ({ category, onEditCategory }) => {
  return (
    <SeoCommon
      initSeo={category?.metaSeo}
      initValues={initParams(category)}
      onUpdate={onEditCategory}
    />
  );
};

export default SeoInfo;
