import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';

export const EDIT_ROLE = gql`
  mutation EditRole($input: EditRoleInput) {
    editRole(input: $input) {
      id
    }
  }
`;

export const ADD_ROLE = gql`
  mutation AddNewRole($input: AddRoleInput) {
    addRole(input: $input) {
      id
      title
    }
  }
`;

export const REMOVE_ROLE = gql`
  mutation RemoveRole($input: RemoveRoleInput!) {
    removeRole(input: $input) {
      id
    }
  }
`;

export const BULK_ACTION_ROLES = gql`
  mutation BulkActionRoles($input: [BulkActionRoleInput]) {
    bulkActionRoles(input: $input) {
      id
    }
  }
`;

export const useAddRole = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_ROLE, {
    refetchQueries: ['GetRoles', 'getRoleById'],
    onCompleted: ({ addRole }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo vai trò{' '}
            <Typography.Text strong>{addRole.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useEditRole = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_ROLE, {
    refetchQueries: ['GetRoles', 'getRoleById'],
    onCompleted: ({ editRole }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa vai trò{' '}
            <Typography.Text strong>{editRole.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useRemoveRole = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_ROLE, {
    refetchQueries: ['GetRoles'],
    onCompleted: ({ removeRole }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá vai trò{' '}
            <Typography.Text strong>{removeRole.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useBulkActionRoles = () => {
  const [mutate, { data, error }] = useMutation(BULK_ACTION_ROLES, {
    refetchQueries: ['GetRoles'],
    onCompleted: ({ bulkActionRoles }) => {
      notification.success({
        message: <Typography.Text strong>Thành công</Typography.Text>,
        description: (
          <Typography.Text>
            {bulkActionRoles.length} vai trò đã được xoá 👌
          </Typography.Text>
        )
      });
    }
  });

  return { mutate, data, error };
};
