import { useLazyQuery } from '@apollo/client';
import { Button, Input } from 'antd';
import AutoComplete from 'components/AutoComplete';
import { useBulkAddArtistRelated } from 'operations/mutations';
import { GET_ARTISTS } from 'operations/queries';
import { useState } from 'react';

const CopyRelatedArtist = ({ dataSource }) => {
  const [searchartist] = useLazyQuery(GET_ARTISTS);
  const [artistId, setArtistId] = useState(null);
  const { mutate: updateArtistRelated } = useBulkAddArtistRelated();

  const onCloneRelatedArtist = async () => {
    try {
      if (!artistId) return;
      await updateArtistRelated({
        variables: {
          input: {
            artistId,
            relatedArtistIds: dataSource?.map(({ id }) => id)
          }
        }
      });
      setArtistId(null);
    } catch (error) {}
  };

  return (
    <Input.Group
      compact
      style={{
        width: '400px'
      }}
    >
      <AutoComplete
        allowClear
        value={artistId}
        handleSearch={searchartist}
        labelKey="name"
        valueKey="id"
        dataKey="artists"
        onChange={value => setArtistId(value)}
        size="middle"
        placeholder="Tìm nghệ sĩ để sao chép"
        style={{
          width: 'calc(100% - 100px)'
        }}
      />
      <Button
        disabled={!artistId}
        type="primary"
        onClick={onCloneRelatedArtist}
      >
        Sao chép
      </Button>
    </Input.Group>
  );
};

export default CopyRelatedArtist;
