import { CheckCircleOutlined, StopOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import {
  COMMON_STATUS_INT_DICTIONARY,
} from 'appConstants';

const StatusButton = ({ status, onClick, total }) => {
  const _status = status == 0 ? 1 : 0
  return (
    <Button
      type="primary"
      danger={_status === 0}
      onClick={onClick}
      block
      icon={
        _status === 1 ? (
          <CheckCircleOutlined />
        ) : (
          <StopOutlined />
        )
      }
      style={{
        background: _status === 1 && 'green',
        border: 'none'
      }}
    >
      {COMMON_STATUS_INT_DICTIONARY[_status]} ({total})
    </Button>
  );
};

export default StatusButton;
