import { useLazyQuery } from '@apollo/client';
import { Button, Input } from 'antd';
import AutoComplete from 'components/AutoComplete';
import { useBulkAddSongRelated } from 'operations/mutations';
import { GET_SONGS } from 'operations/queries';
import { useState } from 'react';

const CopyRelatedSong = ({ dataSource }) => {
  const [searchsong] = useLazyQuery(GET_SONGS);
  const [songId, setSongId] = useState(null);
  const { mutate: updateSongRelated } = useBulkAddSongRelated();

  const onCloneRelatedSong = async () => {
    try {
      if (!songId) return;
      await updateSongRelated({
        variables: {
          input: {
            songId,
            relatedSongIds: dataSource?.map(({ id }) => id)
          }
        }
      });
      setSongId(null);
    } catch (error) {}
  };

  return (
    <Input.Group
      compact
      style={{
        width: '400px'
      }}
    >
      <AutoComplete
        allowClear
        value={songId}
        handleSearch={searchsong}
        labelKey="title"
        valueKey="id"
        dataKey="songs"
        onChange={value => setSongId(value)}
        size="middle"
        placeholder="Tìm bài hát để sao chép"
        style={{
          width: 'calc(100% - 100px)'
        }}
      />
      <Button disabled={!songId} type="primary" onClick={onCloneRelatedSong}>
        Sao chép
      </Button>
    </Input.Group>
  );
};

export default CopyRelatedSong;
