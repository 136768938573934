import React from 'react';
import { Select } from 'antd';
import { COPYRIGHT_INT_DICTIONARY } from 'appConstants';

const CopyrightSelect = props => {
  return (
    <Select placeholder="Bản quyền" {...props} allowClear>
      {Object.keys(COPYRIGHT_INT_DICTIONARY).map(key => (
        <Select.Option value={key} key={key}>
          {COPYRIGHT_INT_DICTIONARY[key]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default CopyrightSelect;
