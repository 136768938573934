import { Typography } from 'antd';
import { DATE_FORMAT, TIME_FORMAT } from 'appConstants';
import ColName from 'components/ColName';
import moment from 'moment';
import 'moment/locale/vi';

export const columns = [
  {
    title: 'Họ và tên',
    dataIndex: 'fullName',
    key: 'fullName',
    render: fullName => (
      <ColName
        primary={
          <Typography.Text style={{ color: 'inherit' }}>
            {fullName}
          </Typography.Text>
        }
      />
    ),
    fixed: 'left',
    width: 'auto'
  },
  {
    title: 'Username',
    dataIndex: 'username',
    width: '20%'
  },
  {
    title: 'Email',
    dataIndex: 'email',
    width: '20%'
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdAt',
    width: '20%',
    render: createdAt => (
      <Typography.Text>
        {createdAt && moment(createdAt).format(DATE_FORMAT + ' ' + TIME_FORMAT)}
      </Typography.Text>
    )
  }
];
