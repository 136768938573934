import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_TAG = gql`
  mutation EditTag($input: EditTagInput) {
    editTag(input: $input) {
      id
      name
      safeName
    }
  }
`;

export const ADD_TAG = gql`
  mutation AddTag($input: AddTagInput) {
    addTag(input: $input) {
      id
      name
      safeName
    }
  }
`;

export const REMOVE_TAG = gql`
  mutation RemoveTag($id: Int!) {
    removeTag(id: $id) {
      id
      name
      safeName
    }
  }
`;

export const BULK_ACTION_TAGS = gql`
  mutation BulkActionTags($input: [BulkActionTagInput]) {
    bulkActionTags(input: $input) {
      id
      name
    }
  }
`;

// ----------------------------------------------------
// Hook

export const useAddTag = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_TAG, {
    onCompleted: ({ addTag }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo tag <Typography.Text strong>{addTag.name}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetTags']
  });
  return { mutate, data, error, loading };
};

export const useEditTag = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_TAG, {
    onCompleted: ({ editTag }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa tag{' '}
            <Typography.Text strong>{editTag.name}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetTags']
  });
  return { mutate, data, error, loading };
};

export const useBulkActionTags = actionField => {
  const [mutate, { data, error, loading }] = useMutation(BULK_ACTION_TAGS, {
    refetchQueries: ['GetTags'],
    onCompleted: ({ bulkActionTags }) => {
      getBulkMessage('tag', bulkActionTags, actionField);
    }
  });

  return { mutate, data, error, loading };
};

// --------------------------------------------

export const useRemoveTag = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_TAG, {
    refetchQueries: ['GetTags'],
    onCompleted: ({ removeTag }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá tag{' '}
            <Typography.Text strong>{removeTag.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};
