import { useQuery } from '@apollo/client';
import { Button, Divider, Space } from 'antd';

import {
  BULK_DATA_KEY,
  COMMON_STATUS_KEY_BY_INT,
  COMMON_STATUS_KEY_INT,
  COPYRIGHT_KEY_INT,
  DATE_FORMAT,
  ORDER_BY_DICTIONARY,
  PAGE_SIZE,
  TABLE_PROPS
} from 'appConstants';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import {
  useBulkActionCopyRightInfringementVideos,
  useBulkActionVideos,
  useBulkEncodeAudioVideo,
  useBulkExportVideoByFields,
  useBulkExportVideos,
  useBulkUpdateDurationVideo
} from 'operations/mutations';
import { GET_VIDEOS } from 'operations/queries';

import { DownloadOutlined, PlusOutlined } from '@ant-design/icons';
import ApproveButton from 'components/ApproveButton';
import CopyrightInfringementButton from 'components/CopyrightInfringementButton';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { processQueryFilerParams } from 'utils/filter';
import { isObjEmpty } from 'utils/lodash';
import { generateFileDownload } from 'utils/other';
import { columns } from '../columns';
import moment from 'moment';

const VideoList = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [videos, setVideos] = useState([]);
  const [total, setTotal] = useState();

  const { mutate: updateDuration, loading: durationLoading } =
    useBulkUpdateDurationVideo();
  const { mutate: encodeAudioVideo, loading: encodeLoading } =
    useBulkEncodeAudioVideo();

  const { mutate: updateStatus, loading: actionLoading } = useBulkActionVideos(
    BULK_DATA_KEY.STATUS
  );
  const { mutate: updateCopyright, loading: copyrightLoading } =
    useBulkActionCopyRightInfringementVideos(BULK_DATA_KEY.COPYRIGHT);

  const { mutate: exportVideos, loading: exportLoading } =
    useBulkExportVideos();
  const { mutate: exportVideoByFields } = useBulkExportVideoByFields();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const pageSize = useMemo(
    () => parseInt(searchParams.pageSize) || PAGE_SIZE,
    [searchParams.pageSize]
  );

  const filterQuery = useMemo(() => {
    let additonalQueries = {};
    const processedSearchParams = processQueryFilerParams(searchParams, true);
    if (!processedSearchParams) return null;

    if (processedSearchParams?.videoCates) {
      processedSearchParams.videoCates = {
        some: {
          cateId: {
            in: processedSearchParams?.videoCates
          }
        }
      };
    }

    if (processedSearchParams?.videoTags) {
      processedSearchParams.videoTags = {
        some: {
          tagId: {
            in: processedSearchParams?.videoTags
          }
        }
      };
    }

    if (processedSearchParams?.videoArtists) {
      processedSearchParams.videoArtists = {
        some: {
          or: {
            artistId: {
              in: processedSearchParams?.videoArtists
            }
          }
        }
      };
    }

    if (processedSearchParams?.vocalRank) {
      processedSearchParams.videoArtists = {
        some: {
          or: {
            ...processedSearchParams.videoArtists?.some?.or,
            vocalRank: {
              eq: parseInt(processedSearchParams?.vocalRank.eq)
            }
          }
        }
      };
      delete processedSearchParams.vocalRank;
    }

    const finalQuery = { ...processedSearchParams, ...additonalQueries };
    return isObjEmpty(finalQuery) ? null : finalQuery;
  }, [searchParams]);

  const createdDateQuery = useMemo(
    () => filterQuery?.createdDate,
    [filterQuery]
  );

  const {
    loading: isVideosLoading,
    error: videosError,
    data: videosConnect
  } = useQuery(GET_VIDEOS, {
    variables: {
      take: pageSize,
      skip: pageSize * page - pageSize,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    },
    fetchPolicy: 'no-cache'
  });

  const isLoading = useMemo(
    () =>
      isVideosLoading ||
      durationLoading ||
      encodeLoading ||
      actionLoading ||
      copyrightLoading,
    [
      actionLoading,
      copyrightLoading,
      durationLoading,
      encodeLoading,
      isVideosLoading
    ]
  );

  useEffect(() => {
    if (!isVideosLoading && videosConnect) {
      const _data = videosConnect?.videos?.items?.map(item => ({
        ...item,
        key: item.id
      }));
      setVideos(_data);
      setTotal(videosConnect?.videos?.totalCount);
    }
  }, [isVideosLoading, videosConnect]);

  const onPaginate = (page, pageSize) => {
    setSelectedKeys([]);
    setSelectedRows([]);
    history.push({
      search: queryString.stringify({ ...searchParams, page, pageSize })
    });
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({
        ...searchParams,
        page: 1,
        sort,
        pageSize
      })
    });
  };

  const onShowSizeChange = (_, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, pageSize })
    });
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedKeys);
    }
  };

  const status = useMemo(() => {
    return selectedRows.some(
      _row => _row.status === COMMON_STATUS_KEY_INT.WAITING
    )
      ? COMMON_STATUS_KEY_INT.APPROVED
      : COMMON_STATUS_KEY_INT.WAITING;
  }, [selectedRows]);

  const isCopyrightInfringement = useMemo(() => {
    return selectedRows.some(
      _row => _row.isCopyrightInfringement === COPYRIGHT_KEY_INT.COPYRIGHT
    )
      ? COPYRIGHT_KEY_INT.NON_COPYRIGHT
      : COPYRIGHT_KEY_INT.COPYRIGHT;
  }, [selectedRows]);

  const handleChangeStatus = async () => {
    try {
      const params = selectedRows.map(({ id }) => ({
        id,
        status: COMMON_STATUS_KEY_BY_INT[status]
      }));
      await updateStatus({
        variables: {
          input: params
        }
      });
      setSelectedKeys([]);
    } catch (error) {}
  };

  const onUpdateDuration = async () => {
    try {
      if (!selectedRows.length) return;
      await updateDuration({ variables: { videoIds: selectedKeys } });
      setSelectedKeys([]);
    } catch (error) {
      setSelectedKeys([]);
    }
  };

  const onEncodeRadioVideo = async () => {
    try {
      if (!selectedRows.length) return;
      await encodeAudioVideo({ variables: { videoIds: selectedKeys } });
      setSelectedKeys([]);
    } catch (error) {
      setSelectedKeys([]);
    }
  };

  const handleChangeCopyright = async () => {
    try {
      const params = selectedRows
        .filter(
          _item => _item.isCopyrightInfringement !== isCopyrightInfringement
        )
        .map(({ id }) => id);
      await updateCopyright({
        variables: {
          videoIds: params
        }
      });
      setSelectedKeys([]);
    } catch (error) {
      setSelectedKeys([]);
    }
  };

  const getInputExport = (filterQuery) => {
    if(filterQuery!=undefined)
    return {
      createdFrom: createdDateQuery?.gte,
      createdTo: createdDateQuery?.lte,
      title: filterQuery.title?.contains,
      status: filterQuery.status?.eq,
      createdBy: filterQuery.createdBy?.eq,
      uniqId: filterQuery.uniqId?.contains,
      isCopyrightInfringement:filterQuery.isCopyrightInfringement?.eq,
      videoCates:filterQuery.videoCates?.some?.cateId?.in,
      videoArtists:filterQuery.videoArtists?.some?.or?.artistId?.in,
      videoTags:filterQuery.videoTags?.some?.tagId?.in,
    }
    else return{}
  }

  const onExport = async () => {
    const exportBySelect = !!selectedKeys.length;
    const { data } = exportBySelect
      ? await exportVideos({
          variables: {
            input: {
              ids: selectedKeys
            }
          }
        })
      : await exportVideoByFields({
          variables: {
            input: getInputExport(filterQuery)
          }
        });

    const eventId = exportBySelect
      ? data.bulkExportVideos.eventId
      : data.exportVideosByFields.eventId;
    await generateFileDownload(eventId);
    setSelectedKeys([]);
    setSelectedRows([]);
  };

  if (videosError) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          {total ? (!!selectedKeys.length || createdDateQuery) && (
            <Button
              icon={<DownloadOutlined />}
              type="primary"
              onClick={onExport}
              loading={exportLoading}
            >
              Export (
              {!!selectedKeys.length
                ? selectedKeys?.length
                : `${moment(createdDateQuery?.gte.replace('+00:00', '')).format(
                    DATE_FORMAT
                  )} - ${moment(
                    createdDateQuery?.lte.replace('+00:00', '')
                  ).format(DATE_FORMAT)}`}
              )
            </Button>
          ):''}
          {!!selectedKeys.length && (
            <>
              <Divider type="vertical" />
              <CopyrightInfringementButton
                isCopyrightInfringement={isCopyrightInfringement}
                onClick={handleChangeCopyright}
                total={
                  selectedRows?.filter(
                    _item =>
                      _item.isCopyrightInfringement !== isCopyrightInfringement
                  )?.length
                }
              />
              <ApproveButton
                status={status}
                onClick={handleChangeStatus}
                total={
                  selectedRows?.filter(_item => _item.status !== status)?.length
                }
              />
              <Divider type="vertical" />
              <Button type="primary" onClick={onEncodeRadioVideo}>
                Encode Audio
              </Button>
              <Button type="primary" onClick={onUpdateDuration}>
                Cập nhật duration
              </Button>
            </>
          )}
          <SortSelect value={sort} onChange={setOrderBy} />
          <Link to={'/chi-tiet-video'}>
            <Button icon={<PlusOutlined />} type="primary">
              Thêm
            </Button>
          </Link>
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1500 }}
        columns={columns}
        dataSource={videos}
        pagination={{
          total,
          pageSize: pageSize,
          onChange: onPaginate,
          current: page * 1,
          quantity: videos?.length,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          onShowSizeChange: onShowSizeChange
        }}
        loading={isLoading}
        rowSelection={rowSelection}
      />
    </ListCard>
  );
};

export default VideoList;
