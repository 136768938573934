import { useReactiveVar } from '@apollo/client';
import { Form } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import UpdateCreateModal from 'containers/UpdateCreateModal';
import {
  useAddPartner,
  useEditPartner
} from 'operations/mutations/mutatePartner';
import React, { useCallback } from 'react';
import { get } from 'utils/lodash';
import { setError } from 'utils/other';
import PartnerFormContent from './PartnerFormContent';

const PartnerForm = () => {
  const { mutate: editPartner } = useEditPartner();
  const visibleDrawer = useReactiveVar(visibleDrawerVar);

  const [form] = Form.useForm();

  const { mutate: createPartner } = useAddPartner();

  const onSave = useCallback(async () => {
    try {
      const params = await form.validateFields();
      const logo = get(
        params?.logo,
        '[0].response.Data',
        visibleDrawer.record?.logo
      );

      await editPartner({
        variables: {
          input: {
            ...params,
            logo,
            id: visibleDrawer?.record?.id
          }
        }
      });

      visibleDrawerVar({ visible: false, record: null });
    } catch (e) {
      // throw e;
    }
  }, [form, visibleDrawer.record?.logo, visibleDrawer.record?.id, editPartner]);

  const onCreate = async () => {
    try {
      const params = await form.validateFields();

      const _logo = params.logo;
      const logo = _logo[0]?.response?.Data || _logo;

      await createPartner({
        variables: {
          addPartnerInput: {
            ...params,
            logo
          }
        }
      });
      form.resetFields();
      visibleDrawerVar(false);
    } catch (e) {
      setError(form, e);
    }
  };

  return (
    <UpdateCreateModal
      title={visibleDrawer.record ? 'Chi tiết đối tác' : 'Tạo đối tác'}
      onOk={visibleDrawer.record ? onSave : onCreate}
      onClose={() => {
        form.resetFields();
        visibleDrawerVar(false);
      }}
      open={visibleDrawer.visible}
      isUpdate={!!visibleDrawer.record}
      destroyOnClose
    >
      <PartnerFormContent
        form={form}
        onSubmit={visibleDrawer.record ? onSave : onCreate}
      />
    </UpdateCreateModal>
  );
};

export default PartnerForm;
