import { Space, Typography, Tag } from 'antd';
import RemoveButton from 'components/RemoveButton';

import {
  COMMENT_STATUS_CONTACT,
  COMMON_STATUS_KEY_INT
} from 'appConstants';
import 'moment/locale/vi';
import moment from 'moment';

import { useRemoveContact } from 'operations/mutations';

const RemovePageInfoContact = ({ record }) => {
  const { mutate } = useRemoveContact();
  return <RemoveButton record={record} actionRemove={mutate} titleKey="name" />;
};

const generateColor = status => {
  switch (status) {
    case COMMON_STATUS_KEY_INT.WAITING:
      return 'default';

    case COMMON_STATUS_KEY_INT.APPROVED:
      return 'green';

    default:
      break;
  }
};

const StatusTag = ({ status, ...props }) => {
  return (
    <Tag color={generateColor(status)} {...props}>
      {COMMENT_STATUS_CONTACT[status]}
    </Tag>
  );
};

export const columns = [
  {
    title: 'Người báo cáo',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => (
      <Space direction="vertical">
        <Typography.Text>
          <Typography.Text strong>Tên: </Typography.Text>
          {record?.fullName}
        </Typography.Text>

        <Typography.Text>
          <Typography.Text strong>Email: </Typography.Text>
          {record?.email}
        </Typography.Text>

        {record.phone && (
          <Typography.Text>
            <Typography.Text strong>SĐT: </Typography.Text>
            {record.phone}
          </Typography.Text>
        )}
        <Typography.Text>
          <Typography.Text strong>  CMND: </Typography.Text>
          {record.cmnd}
        </Typography.Text>
      </Space>
    ),
    fixed: 'left',
  },

  {
    title: 'Link yêu cầu gỡ',
    dataIndex: 'link',
    render: (_, { link, status, filePath }) => (
      <Space direction="vertical">
        <StatusTag status={status} />
        <a href={link} target="_blank" rel="noreferrer">
          <Typography.Text> {link}</Typography.Text>
        </a>
        {filePath &&
          <a href={filePath} target="_blank" rel="noreferrer">
            File minh chứng sản phẩm
          </a>}
      </Space>
    )
  },
  {
    title: 'Quyền đối với video ',
    dataIndex: 'rights',
    render: (_, { rights }) => (
      <Space direction="vertical">
        {
          rights && rights.length > 0 && rights.map((item, index) =>
            <Typography.Text key={index}>- {item}</Typography.Text>
          )
        }
      </Space>
    )
  },
  {
    title: 'Xác nhận',
    dataIndex: 'confirm',
    render: (_, { confirm }) => (
      <Space direction="vertical">
        {
          confirm && confirm.length > 0 && confirm.map((item, index) =>
            <Typography.Text key={index}> - {item}</Typography.Text>
          )
        }
      </Space>
    )
  },
  {
    title: 'Thông tin',
    dataIndex: 'related',
    render: (_, record) => (
      <Space direction="vertical">
        <Typography.Text>
          <Typography.Text strong>Ngày tạo: </Typography.Text>
          {record.createDate && moment(record.createDate).format('HH:mm DD/MM/YYYY')}
        </Typography.Text>

        {record.answer && (
          <Typography.Text>
            <Typography.Text strong>Xử lý: </Typography.Text>
            {record.answer}
          </Typography.Text>
        )}
        {record.createdDate && (
          <Typography.Text>
            <Typography.Text strong>Ngày sửa: </Typography.Text>
            {moment(record.createdDate).format('HH:mm DD/MM/YYYY')}
          </Typography.Text>
        )}

      </Space>
    ),
  },
  {
    // title: 'Thao tác',
    width: '80px',
    render: (_, record) => <RemovePageInfoContact record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
