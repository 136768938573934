import { LogoutOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useLogout } from 'operations/mutations/auth/mutateAuth';
import { useCurrentUser } from 'operations/queries/queryCurrentUser';
import { getAccessToken } from 'utils/authority';
import UserInfoDisplay from './components/UserInfoDisplay';

const CurrentUserMenu = () => {
  const { mutate: logout } = useLogout();
  const { client } = useCurrentUser();
  const token = getAccessToken();
  const onLogout = async () => {
    try {
      await logout({ variables: { input: { accessToken: token } } }).then(() =>
        client.resetStore()
      );
    } catch (error) {}
  };
  return (
    <div className="current-user-menu">
      {true && (
        <>
          <UserInfoDisplay />
          <Button type="text" icon={<LogoutOutlined />} onClick={onLogout}>
            Đăng xuất
          </Button>
        </>
      )}
    </div>
  );
};

export default CurrentUserMenu;
