import { useQuery } from '@apollo/client';
import { Button, Space } from 'antd';
import { useHistory, useLocation } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import { isObjEmpty } from 'utils/lodash';

import { PAGE_SIZE, TABLE_PROPS } from 'appConstants';
import ListCard from 'components/ListCard';
import Table from 'components/Table';
import { GET_ADV } from 'operations/queries';
import { PlusOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import queryString from 'query-string';
import { processQueryFilerParams } from 'utils/filter';

import { columns } from '../columns';
import StatusButton from './StatusButton';
import { useStatusContact } from 'operations/mutations';
const orderValue = {
  LATEST: {
    value: { crDateTime: 'ASC' },
    displayName: 'Cũ nhất'
  },
  EARLIEST: {
    value: { crDateTime: 'DESC' },
    displayName: 'Mới nhất'
  },
};

const IcAdvertList = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [icAdverts, seticAdverts] = useState([]);
  const [total, setTotal] = useState();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const filterQuery = useMemo(() => {
    const _searchParams = processQueryFilerParams(searchParams, true);
    let query = {}
    if (!_searchParams) return {}
    if (_searchParams.advertContentTypeId) query.advertContentTypeId = _searchParams.advertContentTypeId
    if (_searchParams.advertName) query.advertName = _searchParams.advertName
    if (_searchParams.advertStatusId) query.advertStatusId = _searchParams.advertStatusId
    if (_searchParams.crDateTime) query.crDateTime = _searchParams.crDateTime
    return { ...query }
  }, [searchParams]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const pageSize = useMemo(
    () => parseInt(searchParams.pageSize) || PAGE_SIZE,
    [searchParams.pageSize]
  );
  const {
    loading: isicAdvertsLoading,
    error: icAdvertsError,
    data: icAdvertsConnect
  } = useQuery(GET_ADV, {
    variables: {
      take: pageSize,
      skip: pageSize * page - pageSize,
      order: [orderValue[sort].value],
      where: isObjEmpty(filterQuery) ? null : filterQuery
    },
    fetchPolicy: 'no-cache'
  });

  const isLoading = useMemo(() => isicAdvertsLoading, [isicAdvertsLoading]);

  useEffect(() => {
    if (!isicAdvertsLoading && icAdvertsConnect) {
      const _data = icAdvertsConnect?.icAdverts?.items
      if (_data && _data.length) {
        _data.map((item, index) => {
          item.stt = (page - 1) * pageSize + index + 1
        })
      }
      seticAdverts(_data);
      setTotal(icAdvertsConnect?.icAdverts?.totalCount);
    }
  }, [isicAdvertsLoading, icAdvertsConnect]);

  const onPaginate = (page, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, page, pageSize })
    });
  };

  // const setOrderBy = sort => {
  //   history.push({
  //     search: queryString.stringify({
  //       ...searchParams,
  //       page: 1,
  //       sort,
  //       pageSize
  //     })
  //   });
  // };

  const onShowSizeChange = (_, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, pageSize })
    });
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedKeys);
    }
  };

  const { mutate } = useStatusContact();
  async function changeStatus(status) {
    let _status = status == 0 ? 1 : 0
    var input = selectedRows.map((item, index) => {
      let _item = {
        id: item.id,
        status: _status
      }
      return _item
    })
    await mutate({
      variables: {
        input
      }
    })
    setSelectedKeys([]);
    setSelectedRows([]);
  }


  if (icAdvertsError) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          {
            selectedRows && selectedRows.length > 0 ?
              <StatusButton
                status={selectedRows[0]?.advertStatusId}
                onClick={() => changeStatus(selectedRows[0]?.advertStatusId)}
                total={selectedRows.length} />
              :
              <>
                <Link to={'/chi-tiet-quang-cao'}>
                  <Button icon={<PlusOutlined />} type="primary">
                    Thêm
                  </Button>
                </Link>
              </>

          }
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={icAdverts}
        rowKey="id"
        pagination={{
          total,
          pageSize: pageSize,
          onChange: onPaginate,
          current: page * 1,
          quantity: icAdverts?.length,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          onShowSizeChange: onShowSizeChange
        }}
        loading={isLoading}
      //rowSelection={rowSelection}
      />
    </ListCard>
  );
};

export default IcAdvertList;
