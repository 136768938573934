import { useLazyQuery } from '@apollo/client';
import { Form } from 'antd';
import {
  FILTER_PARAMS_DATA_TYPE_SUFFIX,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import TagAutocomplete from 'components/TagAutocomplete';
import { GET_TAGS } from 'operations/queries/queryTag';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { processFormItemName } from 'utils/filter';

const TagAutoCompleteFilter = () => {
  const [searchTag, { loading }] = useLazyQuery(GET_TAGS);
  const [tag, setTags] = useState([]);
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const cateFilterName = useMemo(() => {
    return processFormItemName({
      prefix: FILTER_PARAMS_PREFIX,
      name: 'videoTags',
      operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.IN,
      dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.ARR
    });
  }, []);

  const value = useMemo(() => {
    if (Array.isArray(searchParams[cateFilterName])) {
      return searchParams[cateFilterName];
    }

    return [searchParams[cateFilterName]];
  }, [cateFilterName, searchParams]);

  const getTags = useCallback(async () => {
    try {
      const { data } = await searchTag({
        variables: {
          where: {
            or: value.map(eq => ({ id: { eq: parseInt(eq) } }))
          }
        }
      });

      setTags(data?.tags?.items);
    } catch (e) {
      throw e;
    }
  }, [searchTag, value]);

  useEffect(() => {
    if (searchParams[cateFilterName]) getTags();
  }, [getTags, cateFilterName, searchParams]);

  return (
    <>
      <Form.Item name={cateFilterName} label="Tag">
        <TagAutocomplete
          allowClear
          mode="multiple"
          tag={tag}
          loading={loading}
        />
      </Form.Item>
    </>
  );
};

export default TagAutoCompleteFilter;
