import { useQuery } from '@apollo/client';
import { Button, Space } from 'antd';

import { ORDER_BY_DICTIONARY, PAGE_SIZE, TABLE_PROPS } from 'appConstants';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import { GET_CONTACT } from 'operations/queries';

import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { columns } from '../columns';
import StatusButton from './StatusButton';
import { useStatusContact } from 'operations/mutations';

const IdReportYoutubeProblem = 3
const orderValue = {
  LATEST: {
    value: { createDate: 'ASC' },
    displayName: 'Cũ nhất'
  },
  EARLIEST: {
    value: { createDate: 'DESC' },
    displayName: 'Mới nhất'
  },
};

const statusSelectValue = {
  all: {
    value: undefined,
    displayName: 'Tất cả'
  },
  chuaXuLy: {
    value: 0,
    displayName: 'Chưa xử lý'
  },
  daXuLy: {
    value: 1,
    displayName: 'Đã xử lý'
  },
};


const ContactList = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [Contacts, setContacts] = useState([]);
  const [total, setTotal] = useState();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const filterQuery = useMemo(() => {
    let query = {
      problemId: { "eq": IdReportYoutubeProblem }
    }
    if (searchParams.status != undefined) {
      query.status = {
        eq: parseInt(searchParams.status)
      }
    }
    return query
  }, [searchParams]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const selectStatus = useMemo(
    () => {
      if (searchParams.status == 0)
        return "chuaXuLy"
      if (searchParams.status == 1)
        return "daXuLy"
      return "all"
    },
    [searchParams.status]
  );
  const pageSize = useMemo(
    () => parseInt(searchParams.pageSize) || PAGE_SIZE,
    [searchParams.pageSize]
  );

  const {
    loading: isContactsLoading,
    error: ContactsError,
    data: ContactsConnect
  } = useQuery(GET_CONTACT, {
    variables: {
      take: pageSize,
      skip: pageSize * page - pageSize,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    },
    fetchPolicy: 'no-cache'
  });

  const isLoading = useMemo(() => isContactsLoading, [isContactsLoading]);

  useEffect(() => {
    if (!isContactsLoading && ContactsConnect) {
      const _data = ContactsConnect?.contacts?.items?.map(
        item => {
          let moreInfor = {}
          try {
            moreInfor = JSON.parse(item.question)
          }
          catch { }
          const _item = {
            ...item,
            key: item.id,
            cmnd: moreInfor.cmnd,
            confirm: moreInfor.confirm,
            link: moreInfor.link,
            rights: moreInfor.rights
          }
          return _item

        })
      setContacts(_data);
      setTotal(ContactsConnect?.contacts?.totalCount);
    }
  }, [isContactsLoading, ContactsConnect]);

  const onPaginate = (page, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, page, pageSize })
    });
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({
        ...searchParams,
        page: 1,
        sort,
        pageSize
      })
    });
  };

  const setSelectStatus = val => {
    var status = statusSelectValue[val].value
    history.push({
      search: queryString.stringify({
        ...searchParams,
        page: 1,
        status,
        pageSize
      })
    });
  };

  const onShowSizeChange = (_, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, pageSize })
    });
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedKeys);
    }
  };

  const { mutate } = useStatusContact();
  async function changeStatus(status) {
    let _status = status == 0 ? 1 : 0
    var input = selectedRows.map((item, index) => {
      let _item = {
        id: item.id,
        status: _status
      }
      return _item
    })
    await mutate({
      variables: {
        input
      }
    })
    setSelectedKeys([]);
    setSelectedRows([]);
  }


  if (ContactsError) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          {
            selectedRows && selectedRows.length > 0 ?
              <StatusButton
                status={selectedRows[0]?.status}
                onClick={() => changeStatus(selectedRows[0]?.status)}
                total={selectedRows.length} />
              :
              <>
                <div>Trạng thái: </div>
                <SortSelect value={selectStatus} dict={statusSelectValue} onChange={setSelectStatus} />
                <SortSelect value={sort} dict={orderValue} onChange={setOrderBy} />
              </>

          }
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1000 }}
        columns={columns}
        dataSource={Contacts}
        pagination={{
          total,
          pageSize: pageSize,
          onChange: onPaginate,
          current: page * 1,
          quantity: Contacts?.length,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          onShowSizeChange: onShowSizeChange
        }}
        loading={isLoading}
        rowSelection={rowSelection}
      />
    </ListCard>
  );
};

export default ContactList;
