import { Drawer } from 'antd';
import React, { useCallback, useContext } from 'react';

import { UserContext } from '../UserProvider';
import UserAuditLogContent from './UserAuditLogContent';

const UserAuditLog = () => {
  const { setAuditLogTarget, auditLogTarget } = useContext(UserContext);

  const closeDrawer = useCallback(() => {
    setAuditLogTarget();
  }, [setAuditLogTarget]);

  return (
    <Drawer
      open={!!auditLogTarget}
      placement="right"
      width={600}
      onClose={closeDrawer}
      title="Lịch sử đăng nhập"
      getContainer={false}
      destroyOnClose
    >
      <UserAuditLogContent auditLogTarget={auditLogTarget} />
    </Drawer>
  );
};

export default UserAuditLog;
