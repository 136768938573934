import { Typography } from 'antd';

import React from 'react';

import ClientList from './components/ClientList';
import ClientFilter from './ClientFilter';

const Client = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Thống kê User Client</Typography.Title>

      <ClientFilter />

      <ClientList />
    </div>
  );
};

export default Client;
