import { Typography } from 'antd';

import React from 'react';

import SearchList from './components/SearchList';
import SearchFilter from './SearchFilter';

const Search = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách từ khoá tìm kiếm</Typography.Title>

      <SearchFilter />

      <SearchList />
    </div>
  );
};

export default Search;
