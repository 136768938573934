import RelatedUser from 'components/RelatedUser';
import RemoveButton from 'components/RemoveButton';

import TimeLine from 'components/TimeLine';

import 'moment/locale/vi';
import { useRemoveAlbum } from 'operations/mutations/album/mutateAlbum';

import ColName from 'components/ColName';
import CopyrightTag from 'components/CopyrightTag';
import StatusTag from 'components/StatusTag';
import { Link } from 'react-router-dom';
import { Space, Typography } from 'antd';
import moment from 'moment';
import { DATE_FORMAT, TIME_FORMAT } from 'appConstants';

const RemoveAlbum = ({ record }) => {
  const { mutate } = useRemoveAlbum();
  return (
    <RemoveButton record={record} actionRemove={mutate} titleKey="title" />
  );
};

export const columns = [
  {
    title: 'Album',
    dataIndex: 'title',
    key: 'title',
    render: (_, record) => (
      <ColName
        primary={
          <Link
            style={{ color: 'inherit' }}
            to={`/chi-tiet-album/${record?.id}`}
          >
            {record?.title}
          </Link>
        }
        secondary={record?.categories[0]?.name}
        avatar={{ image: record?.thumbnail, name: record?.title }}
        tag={<StatusTag status={record?.status} />}
        webUrl={
          record?.status &&
          `${process.env.REACT_APP_WEB_URL}/album/${record?.slug}.${record?.uniqId}.html`
        }
      />
    ),
    fixed: 'left',
    width: '120px'
  },

  {
    title: 'Bản quyền',
    dataIndex: 'isCopyrightInfringement',
    render: isCopyrightInfringement => (
      <CopyrightTag isCopyrightInfringement={isCopyrightInfringement} />
    ),
    width: '80px'
  },
  // {
  //   title: 'Trạng thái',
  //   dataIndex: 'statusName',
  //   render: (_, record) => <Status record={record} />,
  //   align: 'left',
  //   width: '100px'
  // },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '80px',
    render: (_, { createdDate, updatedDate, publicDate }) => (
      <Space direction="vertical">
        <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
        <Typography.Text>
          <Typography.Text strong> Phát hành: </Typography.Text>
          {publicDate &&
            moment(publicDate).format(DATE_FORMAT + ' ' + TIME_FORMAT)}
        </Typography.Text>
      </Space>
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    width: '80px',
    render: (_, { createdBy, updatedBy }) => (
      <RelatedUser creator={createdBy} updater={updatedBy} />
    )
  },
  {
    title: 'Thao tác',
    width: '50px',
    render: (_, record) => <RemoveAlbum record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
