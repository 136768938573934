import { useQuery } from '@apollo/client';
import { Spin, Tabs, Typography } from 'antd';
import {
  useAddAppNotification,
  useEditAppNotification
} from 'operations/mutations';
import { GET_APP_NOTIFICATION_BY_ID } from 'operations/queries';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import AppNotificationInfo from './components/AppNotificationInfo';

const AppNotificationDetail = () => {
  const { id } = useParams();
  const isUpdate = useMemo(() => !!id, [id]);
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const { data, loading: fetchLoading } = useQuery(GET_APP_NOTIFICATION_BY_ID, {
    variables: { id: parseInt(id) },
    skip: !isUpdate
  });

  const { mutate: editAppNotification, loading: editLoading } =
    useEditAppNotification();
  const { mutate: addAppNotification, loading: addLoading } =
    useAddAppNotification();

  const items = useMemo(
    () => [
      {
        label: 'Thông tin thông báo app',
        key: '1',
        children: (
          <AppNotificationInfo
            appNotification={data?.appNotification}
            isUpdate={isUpdate}
            editAppNotification={editAppNotification}
            addAppNotification={addAppNotification}
          />
        )
      }
    ],
    [addAppNotification, data?.appNotification, editAppNotification, isUpdate]
  );

  const onChangeTab = tab => {
    history.push({
      search: queryString.stringify({
        tab
      })
    });
  };

  return (
    <Spin spinning={fetchLoading || editLoading || addLoading}>
      <Typography.Title>
        {isUpdate ? 'Chi tiết thông báo app' : 'Tạo thông báo app'}
      </Typography.Title>
      <Tabs
        onChange={onChangeTab}
        defaultActiveKey={searchParams?.tab || '1'}
        type="card"
        items={items}
      />
    </Spin>
  );
};

export default AppNotificationDetail;
