import { Select } from 'antd';
import { APP_NOTIFICATION_SITE_DICTIONARY } from 'appConstants';

const AppNotificationSiteSelect = props => {
  return (
    <Select allowClear placeholder="Chọn site" {...props}>
      {Object.keys(APP_NOTIFICATION_SITE_DICTIONARY).map(key => (
        <Select.Option value={key} key={key}>
          {APP_NOTIFICATION_SITE_DICTIONARY[key]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default AppNotificationSiteSelect;
