import { useQuery } from '@apollo/client';
import { Spin, Tabs, Typography } from 'antd';
import {
  useAddWebNotification,
  useEditWebNotification
} from 'operations/mutations';
import { GET_WEB_NOTIFICATION_BY_ID } from 'operations/queries';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import WebNotificationInfo from './components/WebNotificationInfo';

const WebNotificationDetail = () => {
  const { id } = useParams();
  const isUpdate = useMemo(() => !!id, [id]);
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const { data, loading: fetchLoading } = useQuery(GET_WEB_NOTIFICATION_BY_ID, {
    variables: { id: parseInt(id) },
    skip: !isUpdate
  });

  const { mutate: editWebNotification, loading: editLoading } =
    useEditWebNotification();
  const { mutate: addWebNotification, loading: addLoading } =
    useAddWebNotification();

  const items = useMemo(
    () => [
      {
        label: 'Thông tin thông báo website',
        key: '1',
        children: (
          <WebNotificationInfo
            webNotification={data?.webNotification}
            isUpdate={isUpdate}
            editWebNotification={editWebNotification}
            addWebNotification={addWebNotification}
          />
        )
      }
    ],
    [addWebNotification, data?.webNotification, editWebNotification, isUpdate]
  );

  const onChangeTab = tab => {
    history.push({
      search: queryString.stringify({
        tab
      })
    });
  };

  return (
    <Spin spinning={fetchLoading || editLoading || addLoading}>
      <Typography.Title>
        {isUpdate ? 'Chi tiết thông báo website' : 'Tạo thông báo website'}
      </Typography.Title>
      <Tabs
        onChange={onChangeTab}
        defaultActiveKey={searchParams?.tab || '1'}
        type="card"
        items={items}
      />
    </Spin>
  );
};

export default WebNotificationDetail;
