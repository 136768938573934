import { UploadOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { AUDIO_UPLOAD_SIZE_LIMIT } from 'appConstants';
import Upload from 'components/Upload';

const VideoListUpload = ({
  size,
  onDone = f => f,
  disabled,
  type,
  ...props
}) => {
  const onChange = args => {
    if (args.file.status === 'done') {
      const fileParam = args.file.response.Data;
      onDone(fileParam);
    }
  };
  const acceptType = 'video/mp4, video/mov, video/avi, video/flv';
  const beforeUpload = file => {
    if (!acceptType.includes(file.type) || !file.type) {
      notification.error({
        message: 'Định dạng file không hợp lệ !'
      });
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < AUDIO_UPLOAD_SIZE_LIMIT;
    if (!isLt2M) {
      notification.error({
        message: 'Up file dưới ' + AUDIO_UPLOAD_SIZE_LIMIT + 'mb thôi nha 😡'
      });
    }
    return isLt2M;
  };

  return (
    <>
      <Upload
        accept={acceptType}
        showUploadList={false}
        onChange={onChange}
        beforeUpload={beforeUpload}
        type={type}
        // multiple
      >
        <Button disabled={disabled} type="default" icon={<UploadOutlined />}>
          Tải lên
        </Button>
      </Upload>
    </>
  );
};

export default VideoListUpload;
