import { gql } from '@apollo/client';

export const GET_ADV_POSITION = gql`
query GetIcAdvertPositions(
  $skip: Int
  $take: Int
  $order: [IcAdvertPositionSortInput!]
  $where: IcAdvertPositionFilterInput
) {
  icAdvertPositions(skip: $skip, take: $take, order: $order, where: $where) {
    totalCount
    items {
      id
      positionName
      positionDesc
      overflowWidth
      overflowHeight
      advertDisplayTypeId
      crUserId
      crDateTime
      width
      height
      userName
      advertPositionAdv{
        id
        advert{
          advertName
          advertContentTypeId
          advertStatusId
          id
        }
        pageName
      }
    }
  }
}  
`;
export const GET_ADV_POSITION_BY_ID = gql`
  query GetIcAdvertPositionById($id: Int!) {
    icAdvertPosition(id: $id) {
        id
        positionName
        positionDesc
        overflowWidth
        overflowHeight
        advertDisplayTypeId
        crUserId
        crDateTime
        width
        height
        advertPositionAdv{
          id
          advert{
            advertName
            advertContentTypeId
            advertStatusId
            id
          }
          pageName
        }
    }
  }
`;