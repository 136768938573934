import { RANK_VALUE } from 'appConstants';

export const initParams = song => {
  if (!song) return;
  const params = {
    id: song.id,
    vocalRank: RANK_VALUE[song.vocalRank],
    title: song.title,
    slug: song.slug,
    tagIds: song.tags.map(({ id }) => id),
    singerIds: song.singers.map(({ id }) => id),
    musicianIds: song.musicians.map(({ id }) => id),
    categoryIds: song.categories.map(({ id }) => id),
    isMedley: song.isMedley,
    publictDay: song.publictDay,
    lyric: song.lyric,
    intro: song.intro
  };
  return params;
};
