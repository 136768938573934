import { useLazyQuery } from '@apollo/client';
import AutoComplete from 'components/AutoComplete';
import { GET_USERS } from 'operations/queries/queryUser';
import { useMemo } from 'react';

const UserAutocomplete = ({ user, ...props }) => {
  const [searchUser] = useLazyQuery(GET_USERS);
  const defaultOption = useMemo(
    () =>
      user
        ? Array.isArray(user)
          ? user.map(({ userName, fullName }) => ({
              value: userName,
              label: fullName
            }))
          : { value: user.userName, label: user.fullName }
        : null,
    [user]
  );

  return (
    <AutoComplete
      // mode="multiple"
      defaultOption={defaultOption}
      handleSearch={searchUser}
      labelKey="fullName"
      valueKey="userName"
      dataKey="users"
      placeholder="Tìm user"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default UserAutocomplete;
