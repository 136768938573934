import { useQuery } from '@apollo/client';
import { Button, Card, Result, Space } from 'antd';

import { ORDER_BY_DICTIONARY, PAGE_SIZE, TABLE_PROPS } from 'appConstants';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import { GET_AUDIT_LOGS } from 'operations/queries';

import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { processQueryFilerParams } from 'utils/filter';
import { isObjEmpty } from 'utils/lodash';
import { columns } from '../columns';
const AuditLogList = () => {
  const { auditLog } = useLocation();
  const history = useHistory();
  const [auditLogs, setAuditLogs] = useState([]);
  const [total, setTotal] = useState([]);

  const auditLogParams = useMemo(() => queryString.parse(auditLog), [auditLog]);
  const page = useMemo(() => auditLogParams.page || 1, [auditLogParams.page]);
  const sort = useMemo(
    () => auditLogParams.sort || 'EARLIEST',
    [auditLogParams.sort]
  );

  const pageSize = useMemo(
    () => parseInt(auditLogParams.pageSize) || PAGE_SIZE,
    [auditLogParams.pageSize]
  );

  const onShowSizeChange = (_, pageSize) => {
    history.push({
      auditLog: queryString.stringify({ ...auditLogParams, pageSize })
    });
  };

  const filterQuery = useMemo(() => {
    let additonalQueries = {};
    const processedAuditLogParams = processQueryFilerParams(auditLogParams);

    if (!processedAuditLogParams) return null;

    if (processedAuditLogParams?.auditLogCates) {
      processedAuditLogParams.auditLogCates = {
        some: {
          cateId: {
            in: processedAuditLogParams?.auditLogCates
          }
        }
      };
    }

    const finalQuery = { ...processedAuditLogParams, ...additonalQueries };
    return isObjEmpty(finalQuery) ? null : finalQuery;
  }, [auditLogParams]);

  const {
    loading: isAuditLogsLoading,
    error: auditLogsError,
    data: auditLogsConnect
  } = useQuery(GET_AUDIT_LOGS, {
    variables: {
      take: pageSize,
      skip: pageSize * page - pageSize,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!isAuditLogsLoading && auditLogsConnect) {
      const _data = auditLogsConnect?.auditLogs?.items?.map(item => ({
        ...item,
        key: item.id
      }));
      setAuditLogs(_data);
      setTotal(auditLogsConnect?.auditLogs?.totalCount);
    }
  }, [auditLogsConnect, isAuditLogsLoading]);

  const onPaginate = (page, pageSize) => {
    history.push({
      auditLog: queryString.stringify({
        ...auditLogParams,
        page,
        pageSize
      })
    });
  };

  const setOrderBy = sort => {
    history.push({
      auditLog: queryString.stringify({
        ...auditLogParams,
        page: 1,
        sort,
        pageSize
      })
    });
  };

  if (auditLogsError)
    return (
      <Card>
        <Result
          status="500"
          // title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      </Card>
    );
  // if (!auditLogsConnect) return <div>None</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          <SortSelect value={sort} onChange={setOrderBy} />
          {/* <Link to={'/chi-tiet-chu-de'}>
            <Button type="primary">Thêm</Button>
          </Link> */}
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1500 }}
        columns={columns}
        dataSource={auditLogs}
        pagination={{
          total,
          pageSize: pageSize,
          onChange: onPaginate,
          current: page * 1,
          quantity: auditLogs?.length,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          onShowSizeChange: onShowSizeChange
        }}
        loading={isAuditLogsLoading}
      />
    </ListCard>
  );
};

export default AuditLogList;
