import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_VIDEO = gql`
  mutation EditVideo($input: EditVideoInput!) {
    editVideo(input: $input) {
      id
      title
    }
  }
`;

export const ADD_VIDEO = gql`
  mutation AddVideo($input: AddVideoInput!) {
    addVideo(input: $input) {
      id
      title
    }
  }
`;

export const REMOVE_VIDEO = gql`
  mutation RemoveVideo($id: Int!) {
    removeVideo(id: $id) {
      id
      title
    }
  }
`;

export const BULK_UPDATE_DURATION_VIDEO = gql`
  mutation BulkUpdateDurationVideos($videoIds: [Int!]) {
    bulkUpdateDurationVideos(videoIds: $videoIds) {
      id
      title
    }
  }
`;

export const BULK_ENCODE_AUDIO_VIDEO = gql`
  mutation BulkEncodeAudioVideos($videoIds: [Int!]) {
    bulkEncodeAudioVideos(videoIds: $videoIds) {
      id
      title
    }
  }
`;

// export const BULK_COPYRIGHT_INFRINGEMENT = gql`
//   mutation BulkMarkCopyRightInfringementVideos($videoIds: [Int!]) {
//     bulkMarkCopyRightInfringementVideos(videoIds: $videoIds) {
//       id
//       title
//     }
//   }
// `;

// Clear copyright infringement
export const BULK_COPYRIGHT_INFRINGTMENT_VIDEOS = gql`
  mutation BulkActionCopyRightInfringementVideos($videoIds: [Int!]) {
    bulkActionCopyRightInfringementVideos(videoIds: $videoIds) {
      id
      title
    }
  }
`;

export const BULK_ACTION_CATEGORIES = gql`
  mutation BulkActionCategories($input: [BulkActionCategoryInput]) {
    bulkActionCategories(input: $input) {
      id
      title
      status
      isDeleted
    }
  }
`;

export const BULK_ACTION_VIDEOS = gql`
  mutation BulkActionVideos($input: [BulkActionVideoInput]) {
    bulkActionVideos(input: $input) {
      id
      title
    }
  }
`;

export const BULK_ADD_VIDEOS_RELATED = gql`
  mutation BulkAddVideoRelated($input: BulkAddVideoRelatedInput) {
    bulkAddVideoRelated(input: $input) {
      id
      video {
        id
        title
      }
    }
  }
`;

export const BULK_EXPORT_VIDEO = gql`
  mutation BulkExportVideos($input: ExportBulkVideoInput) {
    bulkExportVideos(input: $input) {
      link
      id
      fileName
      eventId
      createdDate
      createdBy
    }
  }
`;

export const BULK_EXPORT_VIDEO_BY_FIELDS = gql`
  mutation ExportVideosByFields($input: ExportVideoInput) {
    exportVideosByFields(input: $input) {
      link
      id
      fileName
      eventId
      createdDate
      createdBy
    }
  }
`;

export const useAddVideo = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_VIDEO, {
    onCompleted: ({ addVideo }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo video{' '}
            <Typography.Text strong>{addVideo.name}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetVideos']
  });
  return { mutate, data, error, loading };
};

export const useEditVideo = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_VIDEO, {
    onCompleted: ({ editVideo }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa video{' '}
            <Typography.Text strong>{editVideo.title}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetVideos', 'GetVideoById']
  });
  return { mutate, data, error, loading };
};

export const useRemoveVideo = () => {
  const [mutate, { data, error, loading }] = useMutation(REMOVE_VIDEO, {
    refetchQueries: ['GetVideos'],
    onCompleted: ({ removeVideo }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá video{' '}
            <Typography.Text strong>{removeVideo.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useBulkUpdateDurationVideo = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_UPDATE_DURATION_VIDEO,
    {
      refetchQueries: ['GetVideos'],
      onCompleted: () => {
        notification.success({
          message: (
            <Typography.Text>Đã cập nhật thời lượng video</Typography.Text>
          )
        });
      }
    }
  );

  return { mutate, data, error, loading };
};

export const useBulkEncodeAudioVideo = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ENCODE_AUDIO_VIDEO,
    {
      refetchQueries: ['GetVideos'],
      onCompleted: () => {
        notification.success({
          message: <Typography.Text>Đã cập nhật encode video</Typography.Text>
        });
      }
    }
  );

  return { mutate, data, error, loading };
};

// export const useBulkCopyrightInfringment = () => {
//   const [mutate, { data, error, loading }] = useMutation(BULK_COPYRIGHT_INFRINGEMENT, {
//     refetchQueries: ['GetVideos'],
//     onCompleted: () => {
//       notification.success({
//         message: (
//           <Typography.Text>Đã cập nhật vi phạm bản quyền</Typography.Text>
//         )
//       });
//     }
//   });

//   return { mutate, data, error, loading };
// };

export const useBulkActionCopyRightInfringementVideos = actionField => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_COPYRIGHT_INFRINGTMENT_VIDEOS,
    {
      refetchQueries: ['GetVideos'],
      onCompleted: ({ bulkActionCopyRightInfringementVideos }) => {
        getBulkMessage(
          'video',
          bulkActionCopyRightInfringementVideos,
          actionField
        );
      }
    }
  );

  return { mutate, data, error, loading };
};

export const useBulkActionVideos = actionField => {
  const [mutate, { data, error, loading }] = useMutation(BULK_ACTION_VIDEOS, {
    refetchQueries: ['GetVideos'],
    onCompleted: ({ bulkActionVideos }) => {
      getBulkMessage('video', bulkActionVideos, actionField);
    }
  });

  return { mutate, data, error, loading };
};

export const useBulkAddVideoRelated = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ADD_VIDEOS_RELATED,
    {
      onCompleted: () => {
        notification.success({
          message: (
            <Typography.Text>Đã cập nhật video liên quan</Typography.Text>
          )
        });
      },
      refetchQueries: ['GetVideos', 'GetVideoById']
    }
  );
  return { mutate, data, error, loading };
};

export const useBulkExportVideos = () => {
  const [mutate, { data, error, loading }] = useMutation(BULK_EXPORT_VIDEO, {
    onCompleted: () => {
      notification.success({
        message: <Typography.Text>Export thành công!</Typography.Text>
      });
    }
  });

  return { mutate, data, error, loading };
};

export const useBulkExportVideoByFields = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_EXPORT_VIDEO_BY_FIELDS
  );

  return { mutate, data, error, loading };
};
