import {
  FILTER_PARAMS_DATA_TYPE,
  FILTER_PARAMS_PREFIX,
  FILTER_PARAMS_PREFIX_DATE
} from 'appConstants';
import moment from 'moment';

export const processQueryFilerParams = (params, isInt = false) => {
  const processedParams = Object.entries(params);

  let objQuery = {};

  Object.fromEntries(
    processedParams
      .filter(
        ([key, value]) =>
          key.startsWith(FILTER_PARAMS_PREFIX) ||
          key.startsWith(FILTER_PARAMS_PREFIX_DATE)
      )
      .map(([key, value]) => {
        const keyParser = key
          .replace(FILTER_PARAMS_PREFIX, '')
          .replace(FILTER_PARAMS_PREFIX_DATE, '');
        const [filterKey, operator, dataType] = keyParser.split('__');

        if (dataType === FILTER_PARAMS_DATA_TYPE.ARR && operator === 'in') {
          let arr = Array.isArray(value) ? value : [value];
          const query = {
            [filterKey]: arr.map(_v => parseInt(_v))
          };

          objQuery = { ...objQuery, ...query };

          return query;
        }

        if (dataType === FILTER_PARAMS_DATA_TYPE.ARR) {
          const query = {
            [filterKey]: Array.isArray(value)
              ? value.map(_v => ({ [operator]: isInt ? parseInt(_v) : _v }))
              : { [operator]: isInt ? parseInt(value) : value }
          };

          objQuery = { ...objQuery, ...query };

          return query;
        }

        if (dataType === FILTER_PARAMS_DATA_TYPE.DATE && operator === 'btw') {
          /* RISK */
          // chưa check thứ tự,
          // mặc định khi filter đưa lên params là đứng trước start,
          // sau là end

          const [start, end] = value;

          const query = {
            [filterKey]: {
              gte: moment(start).format('YYYY-MM-DD') + '+00:00',
              lte: moment(end).add(1, 'days').format('YYYY-MM-DD') + '+00:00'
              // gte: moment(start).unix(),
              // lte: moment(end).add(1, 'days').unix()
            }
          };

          objQuery = { ...objQuery, ...query };

          return query;
        }

        if (dataType === FILTER_PARAMS_DATA_TYPE.INT) {
          value = value * 1;
        } else if (dataType === FILTER_PARAMS_DATA_TYPE.DATE) {
          value = moment(value).format('YYYY-MM-DD') + '+00:00';
        }

        const condition = { [operator || 'contains']: value };
        const query = { [filterKey]: condition };

        objQuery = { ...objQuery, ...query };
        return query;
      })
  );

  const isEmptyQuery =
    Object.keys(objQuery).length === 0 && objQuery.constructor === Object;

  return isEmptyQuery ? null : objQuery;
};

export const processFormItemName = ({
  prefix = '',
  name = '',
  operatorSuffix = '',
  dataTypeSuffix = ''
}) => {
  return prefix + name + operatorSuffix + dataTypeSuffix;
};
