// import Role from 'pages/Role';
import Album from 'pages/Album';
import AlbumDetail from 'pages/AlbumDetail';
import AppNotification from 'pages/AppNotification';
import AppNotificationDetail from 'pages/AppNotificationDetail';
import Artist from 'pages/Artist';
import ArtistDetail from 'pages/ArtistDetail';
import AuditLog from 'pages/AuditLog';
import Category from 'pages/Category';
import CategoryDetail from 'pages/CategoryDetail';
import CategoryGroup from 'pages/CategoryGroup';
import CurrentUserDetail from 'pages/CurrentUserDetail';
import Dashboard from 'pages/Dashboard';
import HotSong from 'pages/HotSong';
import Login from 'pages/Login';
import Mood from 'pages/Mood';
import MoodDetail from 'pages/MoodDetail';
import NotFound from 'pages/NotFound';
import Playlist from 'pages/Playlist';
import PlaylistDetail from 'pages/PlaylistDetail';
import Role from 'pages/Role';
import RoleDetail from 'pages/RoleDetail';
import Search from 'pages/Search';
import Client from 'pages/Client';
import Song from 'pages/Song';
import SongDetail from 'pages/SongDetail';
import Tag from 'pages/Tag';
import TopBanner from 'pages/TopBanner';
import Topic from 'pages/Topic';
import TopicDetail from 'pages/TopicDetail';
import TopPopular from 'pages/TopPopular';
import Unauthorized from 'pages/Unauthorized';
import User from 'pages/User';
import Video from 'pages/Video';
import VideoDetail from 'pages/VideoDetail';
import WebNotification from 'pages/WebNotification';
import WebNotificationDetail from 'pages/WebNotificationDetail';
import { Redirect } from 'react-router';
import PageInfo from 'pages/PageInfo';
import PageInfoDetail from 'pages/PageInfoDetail';
import Partner from 'pages/Partner';
import Contact from 'pages/Contact';
import Adv from 'pages/Adv';
import AdvDetail from 'pages/AdvDetail';
import AdvPosition from 'pages/AdvPosition';
import AdvPositionDetail from 'pages/AdvPositionDetail';
import Comment from 'pages/Comment';
export const publicRoutes = [
  {
    path: '/dang-nhap',
    component: Login
  }
];

export const privateRoutes = [
  {
    path: '/',
    component: Dashboard,
    exact: true
    // component: showDB ? Dashboard : () => <Redirect to={'/lich-phat-song'} />
  },
  {
    path: '/danh-sach-topic',
    component: Topic,
    keyCode: 'topic'
  },
  {
    path: '/chi-tiet-topic/:id?',
    component: TopicDetail,
    keyCode: 'topicDetail'
  },
  {
    path: '/danh-sach-comment',
    component: Comment,
    keyCode: 'comment'
  },
  {
    path: '/danh-sach-chuyen-muc',
    component: Category,
    keyCode: 'cate'
  },
  {
    path: '/chi-tiet-chuyen-muc/:id?',
    component: CategoryDetail,
    keyCode: 'cateDetail'
  },
  {
    path: '/danh-sach-nhom-chuyen-muc',
    component: CategoryGroup,
    keyCode: 'categroup'
  },
  {
    path: '/danh-sach-user',
    component: User,
    keyCode: 'user'
  },
  {
    path: '/danh-sach-partner',
    component: Partner,
    keyCode: 'partner'
  },
  {
    path: '/ca-nhan',
    exact: true,
    component: () => <Redirect to="/ca-nhan/thong-tin-ca-nhan" />
  },
  {
    path: '/ca-nhan/:id?',
    component: CurrentUserDetail
  },
  // Muvi cms
  {
    path: '/danh-sach-playlist',
    component: Playlist,
    keyCode: 'playlist'
  },
  {
    path: '/chi-tiet-playlist/:id?',
    component: PlaylistDetail,
    keyCode: 'playlistDetail'
  },
  {
    path: '/danh-sach-bai-hat',
    component: Song,
    keyCode: 'song'
  },
  {
    path: '/chi-tiet-bai-hat/:id?',
    component: SongDetail,
    keyCode: 'songDetail'
  },
  {
    path: '/danh-sach-nghe-si',
    component: Artist,
    keyCode: 'artist'
  },
  {
    path: '/chi-tiet-nghe-si/:id?',
    component: ArtistDetail,
    keyCode: 'artistDetail'
  },
  {
    path: '/danh-sach-video',
    component: Video,
    keyCode: 'video'
  },
  {
    path: '/chi-tiet-video/:id?',
    component: VideoDetail,
    keyCode: 'videoDetail'
  },
  {
    path: '/danh-sach-chu-de',
    component: Mood,
    keyCode: 'mood'
  },
  {
    path: '/chi-tiet-chu-de/:id?',
    component: MoodDetail,
    keyCode: 'moodDetail'
  },
  {
    path: '/danh-sach-album',
    component: Album,
    keyCode: 'album'
  },
  {
    path: '/chi-tiet-album/:id?',
    component: AlbumDetail,
    keyCode: 'albumDetail'
  },
  {
    path: '/danh-sach-tag',
    component: Tag,
    keyCode: 'tag'
  },
  {
    path: '/danh-sach-vai-tro',
    component: Role,
    keyCode: 'role'
  },
  {
    path: '/chi-tiet-vai-tro/:id?',
    component: RoleDetail,
    keyCode: 'roleDetail'
  },
  {
    path: '/danh-sach-web-notification',
    component: WebNotification,
    keyCode: 'webNotification'
  },
  {
    path: '/chi-tiet-web-notification/:id?',
    component: WebNotificationDetail,
    keyCode: 'webNotificationDetail'
  },
  {
    path: '/danh-sach-app-notification',
    component: AppNotification,
    keyCode: 'appNotification'
  },
  {
    path: '/chi-tiet-app-notification/:id?',
    component: AppNotificationDetail,
    keyCode: 'appNotificationDetail'
  },
  {
    path: '/danh-sach-tu-khoa-tim-kiem',
    component: Search,
    keyCode: 'search'
  },
  {
    path: '/danh-sach-client',
    component: Client,
    keyCode: 'client'
  },
  {
    path: '/danh-sach-audit-log',
    component: AuditLog,
    keyCode: 'auditLog'
  },
  {
    path: '/homepage-thinh-hanh',
    component: TopPopular,
    keyCode: 'topPopular'
  },
  {
    path: '/homepage-bai-hat-noi-bat',
    component: HotSong,
    keyCode: 'hotSong'
  },
  {
    path: '/homepage-banner',
    component: TopBanner,
    keyCode: 'topBanner'
  },
  {
    path: '/danh-sach-thong-tin-trang',
    component: PageInfo,
    keyCode: 'pageInfoContact'
  },
  {
    path: '/chi-tiet-thong-tin-trang/:id?',
    component: PageInfoDetail,
    keyCode: 'pageInfoContactDetail'
  },
  {
    path: '/bao-cao-link-nhung-youtube',
    component: Contact,
    keyCode: 'pageInfoContact'
  },
  {
    path: '/quang-cao',
    component: Adv,
    keyCode: 'adv'
  },
  {
    path: '/chi-tiet-quang-cao/:id?',
    component: AdvDetail,
    keyCode: 'advDetail'
  },
  {
    path: '/vi-tri-quang-cao',
    component: AdvPosition,
    keyCode: 'advPosition'
  },
  {
    path: '/chi-tiet-vi-tri-quang-cao/:id?/:tabActive?',
    component: AdvPositionDetail,
    keyCode: 'advPositionDetail'
  },
  {
    path: '/chi-tiet-vi-tri-quang-cao/:id?',
    component: AdvPositionDetail,
    keyCode: 'advPositionDetail'
  },
  {
    path: '/404',
    component: NotFound
  },
  {
    path: '/unauthorized',
    component: Unauthorized
  }
];
