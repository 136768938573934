import { Button, Col, Form, Input, Row, Spin } from 'antd';
import MetaCard from 'components/MetaCard';
import { useChangePassword } from 'operations/mutations/user/mutateUser';

const ChangePassword = () => {
  const [form] = Form.useForm();

  const { mutate: changePassword, loading } = useChangePassword();

  const onSave = async () => {
    try {
      const formParams = await form.validateFields();

      if (formParams.newPassword !== formParams.retypeNewPassword) {
        throw new Error('Nhập lại mật khẩu mới không trùng khớp!');
      }

      const params = {
        newPassword: formParams.newPassword,
        oldPassword: formParams.oldPassword
        // token: getAccessToken()
      };

      await changePassword({ variables: { input: params } });
      form.resetFields();
    } catch (e) {
      // notification.error({
      //   message: e?.message || DEFAULT_ERROR_MESSAGE
      // });
    }
  };

  return (
    <MetaCard title="Đổi mật khẩu" style={{ marginTop: '24px' }}>
      <Spin spinning={loading}>
        <Form form={form} layout="vertical">
          <Row gutter={32}>
            <Col span={8}>
              <Form.Item
                label="Mật khẩu hiện tại"
                name="oldPassword"
                rules={[{ required: true, message: 'Nhập mật khẩu!' }]}
              >
                <Input.Password placeholder="Mật khẩu hiện tại" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Mật khẩu mới"
                name="newPassword"
                rules={[
                  { required: true, message: 'Nhập mật khẩu mới!' },
                  { min: 8, message: 'Tối thiểu 8 ký tự' }
                ]}
              >
                <Input.Password placeholder="Mật khẩu mới" />
              </Form.Item>
            </Col>

            <Col span={8}>
              <Form.Item
                label="Nhập lại mật khẩu mới"
                name="retypeNewPassword"
                rules={[
                  { required: true, message: 'Nhập lại mật khẩu mới!' },
                  { min: 8, message: 'Tối thiểu 8 ký tự' }
                ]}
              >
                <Input.Password placeholder="Nhập lại mật khẩu mới" />
              </Form.Item>
            </Col>
          </Row>

          <Row justify="end">
            <Button onClick={onSave} type="primary" loading={loading}>
              Cập nhật
            </Button>
          </Row>
        </Form>
      </Spin>
    </MetaCard>
  );
};

export default ChangePassword;
