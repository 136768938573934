import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  SaveOutlined,
  ScissorOutlined
} from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import {
  Button,
  Card,
  Form,
  Input,
  Popconfirm,
  Result,
  Space,
  Table,
  Tooltip,
  Typography
} from 'antd';
import { ORDER_BY_DICTIONARY, PAGE_SIZE } from 'appConstants';
import ListCard from 'components/ListCard';
import RelatedUser from 'components/RelatedUser';
import SortSelect from 'components/SortSelect';
import TimeLine from 'components/TimeLine';
import { useAddTag, useEditTag, useRemoveTag } from 'operations/mutations';
import { GET_TAGS } from 'operations/queries';
import queryString from 'query-string';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { processQueryFilerParams } from 'utils/filter';
import { isObjEmpty } from 'utils/lodash';
import { sanitizeSlug } from 'utils/parser';
const EditableContext = React.createContext(null);

const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const form = useContext(EditableContext);
  const onChangeName = () => {
    const { name, safeName } = form.getFieldsValue();
    if (!safeName) {
      const _slug = sanitizeSlug(name);
      form.setFieldsValue({ safeName: _slug });
    }
  };
  const onGenerateSlug = () => {
    const { name } = form.getFieldsValue();
    if (!name) return;
    const _slug = sanitizeSlug(name);
    form.setFieldsValue({ safeName: _slug });
  };

  const inputNode = (
    <Input
      onMouseOut={dataIndex === 'name' ? onChangeName : undefined}
      placeholder={`Nhập ${title}`}
      suffix={
        dataIndex === 'safeName' && (
          <Tooltip title="Generate slug">
            <ScissorOutlined
              style={{ fontSize: 16 }}
              onClick={onGenerateSlug}
            />
          </Tooltip>
        )
      }
    />
  );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          style={{
            margin: 0
          }}
          rules={[
            {
              required: true,
              message: `Nhập ${title}!`
            }
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

const TagList = () => {
  const [form] = Form.useForm();
  const [editingKey, setEditingKey] = useState('');
  const [isAdding, setIsAdding] = useState(false);
  const [tags, setTags] = useState([]);
  const [total, setTotal] = useState([]);
  const history = useHistory();

  const { mutate: editTag, loading: editLoading } = useEditTag();
  const { mutate: addTag, loading: addLoading } = useAddTag();
  const { mutate: removeTag } = useRemoveTag();

  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const pageSize = useMemo(
    () => parseInt(searchParams.pageSize) || PAGE_SIZE,
    [searchParams.pageSize]
  );

  const filterQuery = useMemo(() => {
    let additonalQueries = {};
    const processedSearchParams = processQueryFilerParams(searchParams);

    if (!processedSearchParams) return null;

    const finalQuery = { ...processedSearchParams, ...additonalQueries };
    return isObjEmpty(finalQuery) ? null : finalQuery;
  }, [searchParams]);

  const onShowSizeChange = (_, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, pageSize })
    });
  };

  const onPaginate = (page, pageSize) => {
    cancel();
    history.push({
      search: queryString.stringify({ ...searchParams, page, pageSize })
    });
  };

  const isEditing = record => record.key === editingKey;
  const {
    loading: isTagsLoading,
    error: tagsError,
    data: tagsConnect
  } = useQuery(GET_TAGS, {
    variables: {
      take: pageSize,
      skip: pageSize * page - pageSize,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!isTagsLoading && tagsConnect) {
      const _data = tagsConnect?.tags?.items?.map(item => ({
        ...item,
        key: item.id
      }));
      setTags(_data);
      setTotal(tagsConnect?.tags?.totalCount);
    }
  }, [tagsConnect, isTagsLoading]);

  const edit = record => {
    form.setFieldsValue({
      name: '',
      safeName: '',
      ...record
    });
    setEditingKey(record.key);
  };

  const cancel = () => {
    form.resetFields();
    setEditingKey('');
    setTags(tags => tags.filter(_tag => _tag.key !== 'addNew'));
    setIsAdding(false);
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  const onAddTag = () => {
    setTags(tags => [{ key: 'addNew' }, ...tags]);
    setEditingKey('addNew');
    setIsAdding(true);
  };

  const save = async key => {
    try {
      const row = await form.validateFields();
      const newData = [...tags];
      const index = newData.findIndex(item => key === item.key);

      if (key === 'addNew') {
        setEditingKey('');
        setIsAdding(false);
        form.resetFields();
        return await addTag({
          variables: {
            input: row
          }
        });
      }

      if (index > -1) {
        const item = newData[index];
        const params = { id: item.id, ...row };

        await editTag({
          variables: {
            input: params
          }
        });
        setEditingKey('');
        form.resetFields();
      }
    } catch (errInfo) {}
  };

  const onDelete = async id => {
    try {
      await removeTag({
        variables: {
          id: parseInt(id)
        }
      });
    } catch (error) {}
  };

  const columns = [
    {
      title: 'Tag',
      dataIndex: 'name',
      width: 'auto',
      editable: true
    },
    {
      title: 'Đường dẫn',
      dataIndex: 'safeName',
      width: '300px',
      editable: true
    },
    {
      title: 'Số bài hát',
      dataIndex: 'countSong',
      width: '100px',
      align: 'center'
    },
    {
      title: 'Số video',
      dataIndex: 'countVideo',
      width: '100px',
      align: 'center'
    },
    {
      title: 'Số album',
      dataIndex: 'countAlbum',
      width: '100px',
      align: 'center'
    },
    {
      title: 'Thời gian',
      dataIndex: 'timeLine',
      width: '300px',
      render: (_, { createdDate, updatedDate }) => (
        <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
      )
    },
    {
      title: 'Liên quan',
      dataIndex: 'related',
      width: '300px',
      render: (_, { createdBy, updatedBy }) => (
        <RelatedUser creator={createdBy} updater={updatedBy} />
      )
    },
    {
      title: 'Thao tác',
      dataIndex: 'operation',
      width: '100px',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <Space>
            <Button
              icon={<SaveOutlined />}
              type="link"
              onClick={() => save(record.key)}
            ></Button>
            <Popconfirm title="Bạn muốn huỷ bỏ?" onConfirm={cancel}>
              <Button
                icon={<CloseOutlined />}
                type="link"
                // onClick={cancel}
              ></Button>
            </Popconfirm>
          </Space>
        ) : (
          <Space>
            <Button
              type="link"
              icon={<EditOutlined />}
              disabled={editingKey !== ''}
              onClick={() => edit(record)}
            ></Button>
            <Popconfirm
              title={
                <Typography.Text>
                  Bạn muốn xoá{' '}
                  <Typography.Text strong>{record?.name}</Typography.Text> ?
                </Typography.Text>
              }
              onConfirm={() => onDelete(record.id)}
            >
              <Button
                type="link"
                danger
                icon={<DeleteOutlined />}
                disabled={editingKey !== ''}
              ></Button>
            </Popconfirm>
          </Space>
          // <Typography.Link
          //   disabled={editingKey !== ''}
          //   onClick={() => edit(record)}
          // >
          //   Edit
          // </Typography.Link>
        );
      }
    }
  ];
  const mergedColumns = columns.map(col => {
    if (!col.editable) {
      return col;
    }

    return {
      ...col,
      onCell: record => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record)
      })
    };
  });

  if (tagsError)
    return (
      <Card>
        <Result
          status="500"
          // title="500"
          subTitle="Sorry, something went wrong."
          extra={<Button type="primary">Back Home</Button>}
        />
      </Card>
    );
  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          <SortSelect value={sort} onChange={setOrderBy} />
          <Button type="primary" onClick={onAddTag}>
            Thêm
          </Button>
        </Space>
      }
    >
      <Form form={form} component={false}>
        <EditableContext.Provider value={form}>
          <Table
            loading={isTagsLoading || editLoading || addLoading}
            components={{
              body: {
                cell: EditableCell
              }
            }}
            bordered
            dataSource={tags}
            columns={mergedColumns}
            rowClassName="editable-row"
            pagination={{
              total,
              pageSize: pageSize + (isAdding && 1),
              onChange: onPaginate,
              current: page * 1,
              // quantity: songs?.length,
              showSizeChanger: true,
              pageSizeOptions: [10, 20, 50],
              onShowSizeChange: onShowSizeChange
            }}
          />
        </EditableContext.Provider>
      </Form>
    </ListCard>
  );
};

export default TagList;
