import { gql } from '@apollo/client';

export const GET_CLIENTS = gql`
  query GetClients(
    $skip: Int
    $take: Int
    $order: [OauthUserSortInput!]
    $where: OauthUserFilterInput
  ) {
    oAuthUsers(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        fullName
        username
        email
        createdAt
        updatedAt
      }
    }
  }
`;
