import { Button, Col, Form, Input, Row } from 'antd';
import {
  colProps,
  FILTER_PARAMS_DATA_TYPE_SUFFIX,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import UserStatusSelect from 'components/UserStatusSelect';
import FormBase from 'containers/FormBase';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { processFormItemName } from 'utils/filter';
import { ClearOutlined, FilterOutlined } from '@ant-design/icons';
import { useCallback } from 'react';

const UserFilter = () => {
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!location?.search) {
      form.resetFields();
    }
  }, [form, location?.search]);

  useEffect(() => {
    form.setFieldsValue(queryString.parse(history.location.search));
  }, [form, history.location.search]);

  const onClearFilter = useCallback(() => {
    form.resetFields();
    history.push({
      search: queryString.stringify({})
    });
  }, [form, history]);

  return (
    <FormBase form={form}>
      <Row gutter={8}>
        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'fullName',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.CONTAINS
            })}
            label="Họ tên"
          >
            <Input placeholder="Nhập họ tên" />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'email',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.CONTAINS
            })}
            label="Email"
          >
            <Input placeholder="Nhập email" />
          </Form.Item>
        </Col>

        <Col {...colProps}>
          <Form.Item
            name={processFormItemName({
              prefix: FILTER_PARAMS_PREFIX,
              name: 'status',
              operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.EQ,
              dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.INT
            })}
            label="Trạng thái"
          >
            <UserStatusSelect style={{ width: '100%' }} />
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={8} justify="end">
        <Button icon={<ClearOutlined />} onClick={onClearFilter}>
          Xoá lọc
        </Button>
        <Col>
          <Button icon={<FilterOutlined />} htmlType="submit">
            Lọc
          </Button>
        </Col>
      </Row>
    </FormBase>
  );
};

export default UserFilter;
