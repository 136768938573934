import { Typography } from 'antd';

import React from 'react';

import MoodList from './components/MoodList';
import MoodFilter from './MoodFilter';

const Mood = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách chủ đề</Typography.Title>

      <MoodFilter />

      <MoodList />
    </div>
  );
};

export default Mood;
