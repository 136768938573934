import { useLazyQuery } from '@apollo/client';
import AutoComplete from 'components/AutoComplete';
import { GET_MOODS } from 'operations/queries/queryMood';
import React, { useMemo } from 'react';

const MoodAutocomplete = ({ mood, ...props }) => {
  const [searchMood] = useLazyQuery(GET_MOODS);
  const defaultOption = useMemo(
    () =>
      mood
        ? Array.isArray(mood)
          ? mood.map(({ id, title }) => ({ value: id, label: title }))
          : { value: mood.id, label: mood.title }
        : null,
    [mood]
  );

  return (
    <AutoComplete
      mode="multiple"
      defaultOption={defaultOption}
      handleSearch={searchMood}
      labelKey="title"
      valueKey="id"
      dataKey="moods"
      placeholder="Tìm chủ đề"
      style={{ width: '100%' }}
      {...props}
    />
  );
};

export default MoodAutocomplete;
