import { ScissorOutlined } from '@ant-design/icons';
import { Button, Card, Checkbox, Col, Form, Input, Row, Tooltip } from 'antd';
import { DEBOUNCE_VALUE, UPLOAD_TYPE } from 'appConstants';
import CategoryGroupSelect from 'components/CategoryGroupSelect';
import CategoryTypeSelect from 'components/CategoryTypeSelect';
import ImageUpload from 'components/ImageUpload';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { debounce, get } from 'utils/lodash';
import { sanitizeSlug } from 'utils/parser';

const CategoryInfo = ({
  category,
  isUpdate,
  onEditCategory,
  onAddCategory
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (category) {
      form.setFieldsValue({
        name: category.name,
        slug: category.slug,
        intro: category.intro,
        type: category.type,
        thumbnail: category.thumbnail,
        groupId: category.groupId,
        onHeader: category.onHeader,
        onFooter: category.onFooter,
        thumbnailSocial: category.thumbnailSocial
      });
    }
  }, [category, form]);

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const onChangeTitle = debounce(() => {
    const { name, slug } = form.getFieldsValue();
    if (!slug) {
      const _slug = sanitizeSlug(name);
      form.setFieldsValue({ slug: _slug });
    }
  }, DEBOUNCE_VALUE);

  const onGenerateSlug = () => {
    const { name } = form.getFieldsValue();
    if (!name) return;
    const _slug = sanitizeSlug(name);
    form.setFieldsValue({ slug: _slug });
  };

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const thumbnail = get(
        values?.thumbnail,
        '[0].response.Data',
        category?.thumbnail
      );
      const thumbnailSocial = get(
        values?.thumbnailSocial,
        '[0].response.Data',
        category?.thumbnailSocial
      );

      const params = {
        ...values,
        thumbnail,
        thumbnailSocial,
        metaSeo: {
          metaDescription: null,
          metaKeywords: null,
          metaTitle: null
        }
      };

      if (isUpdate) {
        // update category
        await onEditCategory({
          variables: {
            input: {
              ...params,
              groupId: 4,
              id: parseInt(id)
            }
          }
        });
      } else {
        //  create category
        await onAddCategory({
          variables: {
            input: params
          },
          onCompleted: ({ addCategory }) => {
            history.push('/chi-tiet-chuyen-muc/' + addCategory?.id);
          }
        });
      }
    } catch (error) {}
  };

  return (
    <Card>
      <Form form={form} layout="vertical">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Tên chủ đề"
              rules={[{ required: true, message: 'Nhập tên chủ đề!' }]}
            >
              <Input placeholder="Nhập tên chủ đề" onMouseOut={onChangeTitle} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="slug"
              label="Slug"
              rules={[{ required: true, message: 'Nhập slug!' }]}
            >
              <Input
                suffix={
                  <Tooltip title="Generate slug">
                    <ScissorOutlined
                      style={{ fontSize: 16 }}
                      onClick={onGenerateSlug}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="type" label="Loại chuyên mục">
              <CategoryTypeSelect allowClear />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="groupId"
              label="Nhóm chuyên mục"
              rules={[{ required: true, message: 'Chọn nhóm chuyên mục!' }]}
            >
              <CategoryGroupSelect placeholder="Chọn nhóm chuyên mục" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="onHeader"
              label=" "
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox>Hiển thị ở Header</Checkbox>
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="onFooter"
              label=" "
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox>Hiển thị ở Footer</Checkbox>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="intro"
              label="Giới thiệu"
              rules={[{ required: true, message: 'Nhập giới thiệu!' }]}
            >
              <Input.TextArea rows={7} placeholder="Nhập giới thiệu" />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="thumbnail"
              label="Thumbnail"
              valuePropName="file"
              getValueFromEvent={normFile}
            >
              <ImageUpload
                avatarShape="square"
                size={128}
                fontSize={64}
                fullName="Thumbnail"
                {...UPLOAD_TYPE.THUMBNAIL_CATEGORY}
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="thumbnailSocial"
              label="Share thumbnail"
              valuePropName="file"
              getValueFromEvent={normFile}
              rules={[{ required: true, message: 'Chọn ảnh!' }]}
            >
              <ImageUpload
                avatarShape="square"
                size={128}
                fontSize={64}
                fullName="Share thumbnail"
                freeStyle={isUpdate}
                {...UPLOAD_TYPE.SHARE_CATEGORY}
              />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row justify="end">
        <Col>
          <Button onClick={onSave} type="primary">
            Lưu
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default CategoryInfo;
