import { Button, Card, Col, Form, Input, Row } from 'antd';
import { useEffect } from 'react';

const SeoCommon = ({ initSeo, initValues, onUpdate }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    if (initSeo) {
      form.setFieldsValue({
        metaTitle: initSeo?.metaTitle,
        metaDescription: initSeo?.metaDescription,
        metaKeywords: initSeo?.metaKeywords
      });
    }
  }, [form, initSeo]);

  const handleUpdate = async () => {
    try {
      const values = await form.validateFields();
      await onUpdate({
        variables: {
          input: {
            metaSeo: values,
            ...initValues
          }
        }
      });
    } catch (error) {}
  };

  return (
    <Card>
      <Form form={form} layout="vertical">
        <Row gutter={12}>
          <Col span={24}>
            <Form.Item name="metaTitle" label="SEO Title (tối đa 100 ký tự)">
              <Input placeholder="Nhập tiêu đề SEO" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="metaDescription"
              label="SEO Description (tối đa 100 ký tự)"
            >
              <Input.TextArea rows={7} placeholder="Nhập mô tả SEO" />
            </Form.Item>
          </Col>

          <Col span={24}>
            <Form.Item
              name="metaKeywords"
              label="SEO Keywords (tối đa 100 ký tự)"
            >
              <Input placeholder="Nhập keyword SEO" />
            </Form.Item>
          </Col>
        </Row>
      </Form>

      <Row justify="end">
        <Col>
          <Button onClick={handleUpdate} type="primary">
            Lưu
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default SeoCommon;
