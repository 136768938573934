import { useQuery } from '@apollo/client';
import { Spin, Tabs, Typography } from 'antd';
import {
  useAddIcAdvertPosition,
  useEditIcAdvertPosition
} from 'operations/mutations';
import { GET_ADV_POSITION_BY_ID } from 'operations/queries';
import { useMemo } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import AdvPosition from './components/AdvPosition';

const AdvPositionDetail = () => {
  const { id, tabActive } = useParams();
  const isUpdate = useMemo(() => !!id, [id]);
  const { data, loading: fetchLoading } = useQuery(GET_ADV_POSITION_BY_ID, {
    variables: { id: parseInt(id) },
    skip: !isUpdate
  });
  const { mutate: addPage, loading: editLoading } = useAddIcAdvertPosition();
  const { mutate: editPage, loading: addLoading } = useEditIcAdvertPosition();

  const items = useMemo(
    () =>
      [
        {
          label: 'Thông tin vị trí quảng cáo',
          key: 'info',
          children: (
            <AdvPosition
              adv={data?.icAdvertPosition}
              isUpdate={isUpdate}
              onEditAdvContact={editPage}
              onAddAdvContact={addPage}
            />
          )
        }
      ]
    ,
    [addPage, data?.icAdvertPosition, editPage, isUpdate]
  );

  return (
    <Spin spinning={fetchLoading || editLoading || addLoading}>
      <Typography.Title>
        {isUpdate ? `Vị trí ${data?.icAdvertPosition?.positionName || ""}` : 'Tạo vị trí quảng cáo'}
      </Typography.Title>
      <Tabs type="card" items={items} defaultActiveKey={tabActive == 2 ? 'advPosition' : 'info'} />
    </Spin>
  );
};

export default AdvPositionDetail;
