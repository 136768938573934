import { Select } from 'antd';
import { ARTIST_RANK_DICTIONARY } from 'appConstants';
import React from 'react';
const { Option } = Select;

const ArtistRankTypeSelect = ({ intValue = true, ...props }) => {
  return (
    <Select
      allowClear
      placeholder="Chọn độ ưu tiên"
      style={{ width: '100%' }}
      {...props}
    >
      {Object.entries(ARTIST_RANK_DICTIONARY).map(([key, value]) => (
        <Option key={parseInt(key)} value={intValue ? parseInt(key) : key}>
          {value}
        </Option>
      ))}
    </Select>
  );
};

export default ArtistRankTypeSelect;
