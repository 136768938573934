import { ScissorOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  Input,
  Row,
  Tooltip,
  Typography
} from 'antd';
import { DEBOUNCE_VALUE } from 'appConstants';
import Tiptap from 'components/Tiptap';
import { useEffect, useMemo } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { debounce } from 'utils/lodash';
import { sanitizeSlug } from 'utils/parser';

const PageInfo = ({
  pageInfo,
  isUpdate,
  onEditPageInfoContact,
  onAddPageInfoContact
}) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();
  const slug = Form.useWatch('slug', form);

  // check page address
  const isAddressPage = useMemo(() => slug === 'diachi', [slug]);

  useEffect(() => {
    if (pageInfo) {
      form.setFieldsValue({
        name: pageInfo.name,
        slug: pageInfo.slug,
        content: pageInfo.content,
        footer: pageInfo.footer === 'VISIBLE'
      });
    }
  }, [pageInfo, form]);

  useEffect(() => {
    if (pageInfo && isAddressPage) {
      form.setFieldsValue({
        phone: pageInfo.contactInfo?.phone,
        email: pageInfo.contactInfo?.email,
        address: pageInfo.contactInfo?.address
      });
    }
  }, [form, isAddressPage, pageInfo]);

  const onChangeName = debounce(() => {
    const { name, slug } = form.getFieldsValue();
    if (!slug) {
      const _slug = sanitizeSlug(name);
      form.setFieldsValue({ slug: _slug });
    }
  }, DEBOUNCE_VALUE);

  const onGenerateSlug = () => {
    const { name } = form.getFieldsValue();
    if (!name) return;
    const _slug = sanitizeSlug(name);
    form.setFieldsValue({ slug: _slug });
  };

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const { phone, email, address } = values;
      const _content = JSON.stringify({ phone, email, address });

      const params = {
        ...values,
        content: isAddressPage ? _content : values.content,
        footer: values?.footer ? 'VISIBLE' : 'INVISIBLE'
      };

      if (isUpdate) {
        // update
        await onEditPageInfoContact({
          variables: {
            input: {
              ...params,
              id: parseInt(id)
            }
          }
        });
      } else {
        //  create
        await onAddPageInfoContact({
          variables: {
            input: params
          },
          onCompleted: ({ addPageInfoContact }) => {
            history.push('/chi-tiet-thong-tin-trang/' + addPageInfoContact?.id);
          }
        });
      }
    } catch (error) {}
  };

  return (
    <Card>
      <Form form={form} layout="vertical">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="name"
              label="Tên trang"
              rules={[{ required: true, message: 'Nhập tên trang!' }]}
            >
              <Input placeholder="Nhập tên trang" onMouseOut={onChangeName} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="slug"
              label="Đường dẫn"
              rules={[{ required: true, message: 'Nhập slug!' }]}
            >
              <Input
                suffix={
                  <Tooltip title="Khởi tạo đường dẫn">
                    <ScissorOutlined
                      style={{ fontSize: 16 }}
                      onClick={onGenerateSlug}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="footer"
              label=" "
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox>Hiển thị ở Footer</Checkbox>
            </Form.Item>
          </Col>

          {isAddressPage ? (
            <>
              <Col span={12}>
                <Form.Item
                  name="phone"
                  label="Số điện thoại"
                  rules={[{ required: true, message: 'Nhập số điện thoại!' }]}
                >
                  <Input placeholder="Nhập số điện thoại" />
                </Form.Item>
              </Col>
              <Col span={12}>
                <Form.Item
                  name="email"
                  label="Hộp thư"
                  rules={[{ required: true, message: 'Nhập hộp thư!' }]}
                >
                  <Input placeholder="Nhập hộp thư" />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Form.Item
                  name="address"
                  label="Văn phòng"
                  rules={[{ required: true, message: 'Nhập văn phòng!' }]}
                >
                  <Input placeholder="Nhập văn phòng" />
                </Form.Item>
              </Col>
            </>
          ) : (
            <Col span={24}>
              <Form.Item
                name="content"
                label="Nội dung"
                rules={[{ required: true, message: 'Nhập nội dung!' }]}
              >
                <Tiptap />
              </Form.Item>
            </Col>
          )}
        </Row>
      </Form>

      <Row justify="end" align="middle" gutter={16}>
        {isAddressPage && (
          <Typography.Text type="secondary">
            ( Thông tin đặc biệt cho trang địa chỉ )
          </Typography.Text>
        )}
        <Col>
          <Button onClick={onSave} type="primary">
            Lưu
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default PageInfo;
