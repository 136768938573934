import { Select } from 'antd';
import { ACTION_NOTIFICATION_DICTIONARY } from 'appConstants';

const ActionNotificationSelect = props => {
  return (
    <Select placeholder="Chọn site" {...props}>
      {Object.keys(ACTION_NOTIFICATION_DICTIONARY).map(key => (
        <Select.Option value={key} key={key}>
          {ACTION_NOTIFICATION_DICTIONARY[key]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ActionNotificationSelect;
