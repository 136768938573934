export const DEFAULT_ERROR_MESSAGE = 'Oops đã có lỗi xảy ra!';

export const PAGE_SIZE = 10;

export const PAGE_SIZE_MAX = 50;

export const BULK_DATA_KEY = {
  STATUS: 'status',
  ORDINARILY: 'ordinarily',
  IS_DELETED: 'isDeleted',
  IS_HOT: 'isHot',
  COPYRIGHT: 'isCopyrightInfringement'
};

export const ORDER_BY_DICTIONARY = {
  LATEST: {
    value: { createdDate: 'ASC' },
    displayName: 'Cũ nhất'
  },
  EARLIEST: {
    value: { createdDate: 'DESC' },
    displayName: 'Mới nhất'
  },
  RECENTLY_CHANGE: {
    value: { updatedDate: 'DESC' },
    displayName: 'Vừa thay đổi'
  }
};
export const ORDER_BY_DICTIONARYV2 = {
  LATEST: {
    value: { crDateTime: 'ASC' },
    displayName: 'Cũ nhất'
  },
  EARLIEST: {
    value: { crDateTime: 'DESC' },
    displayName: 'Mới nhất'
  },
  RECENTLY_CHANGE: {
    value: { updateTime: 'DESC' },
    displayName: 'Vừa thay đổi'
  }
};

export const SEARCH_ORDER_BY_DICTIONARY = {
  LATEST: {
    value: { createdDate: 'ASC' },
    displayName: 'Cũ nhất'
  },
  EARLIEST: {
    value: { createdDate: 'DESC' },
    displayName: 'Mới nhất'
  },
  MOST_COUNT: {
    value: { count: 'DESC' },
    displayName: 'Tìm kiếm nhiều nhất'
  }
};

export const CLIENT_ORDER_BY_DICTIONARY = {
  LATEST: {
    value: { createdAt: 'ASC' },
    displayName: 'Cũ nhất'
  },
  EARLIEST: {
    value: { createdAt: 'DESC' },
    displayName: 'Mới nhất'
  }
};

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_FORMAT_CUSTOM = 'DD-MM-YYYY';
export const DATE_FORMAT_REQ = 'YYYY-MM-DD';
export const TIME_FORMAT = 'HH:mm';

export const BE_FILTER_DATE_FORMAT = 'YYYY-MM-DD';

export const DEBOUNCE_VALUE = 300;

//* *************** */
//* NOTE: HARD CODE */
//* *************** */
export const GENDER_KEY = {
  MALE: 'MALE',
  FEMALE: 'FEMALE'
};

export const GENDER_KEY_INT = {
  [GENDER_KEY.MALE]: '1',
  [GENDER_KEY.FEMALE]: '0'
};

export const GENDER_VALUE = {
  [GENDER_KEY_INT.MALE]: GENDER_KEY.MALE,
  [GENDER_KEY_INT.FEMALE]: GENDER_KEY.FEMALE
};

export const GENDER_DICTIONARY = {
  [GENDER_KEY_INT.MALE]: 'Nam',
  [GENDER_KEY_INT.FEMALE]: 'Nữ'
};

export const IMG_FILE_TYPES = ['.bmp', '.jpg', '.jpeg', '.gif', '.png'];
export const AUDIO_FILE_TYPES = ['.mp3', '.wav'];
export const VIDEO_FILE_TYPES = ['.mp4', '.mov', '.wmv'];
export const DOC_FILE_TYPES = ['.docx'];

export const STATUS_VALUE = {
  DRAFT: 1,
  APPROVED: 2,
  PUBLISHED: 3
};

export const STATUS_VALUE_STRING = {
  DRAFT: 'DRAFT',
  APPROVED: 'APPROVED',
  PUBLISHED: 'PUBLISHED'
};

export const STATUS_DICTIONARY = {
  [STATUS_VALUE.DRAFT]: 'Nháp',
  [STATUS_VALUE.APPROVED]: 'Đã duyệt',
  [STATUS_VALUE.PUBLISHED]: 'Đã phát hành'
};

export const FILTER_PARAMS_PREFIX = 'filter__';
export const FILTER_PARAMS_PREFIX_DATE = 'filter_date__';

export const FILTER_PARAMS_OPERATOR_SUFFIX = {
  EQ: '__eq',
  CONTAINS: '__contains',
  LTE: '__lte',
  GTE: '__gte',
  BTW: '__btw',
  IN: '__in'
};
export const FILTER_PARAMS_DATA_TYPE = {
  INT: 'int',
  DATE: 'date',
  DATERANGE: 'daterange',
  ARR: 'arr'
};
export const FILTER_PARAMS_DATA_TYPE_SUFFIX = {
  INT: `__${FILTER_PARAMS_DATA_TYPE.INT}`,
  DATE: `__${FILTER_PARAMS_DATA_TYPE.DATE}`,
  DATERANGE: `__${FILTER_PARAMS_DATA_TYPE.DATERANGE}`,
  ARR: `__${FILTER_PARAMS_DATA_TYPE.ARR}`
};

export const TABLE_PROPS = {
  scroll: {
    x: 1450
  }
};

//don vi tinh la mb nha cac ban
export const IMG_UPLOAD_SIZE_LIMIT = 0.5;
export const SCRIPT_UPLOAD_SIZE_LIMIT = 5;
export const AUDIO_UPLOAD_SIZE_LIMIT = 720;

export const STATUS_ACTION_TIMEOUT = 200;

export const AUTO_SAVE_INTERVAL = 30000;

export const CHANNEL_BROADCAST_KEY = {
  AM610KHZ: 'AM610KHZ',
  FM999MHZ: 'FM999MHZ',
  FM956MHZ: 'FM956MHZ',
  FM877MHZ: 'FM877MHZ'
};

export const CHANNEL_BROADCAST_DICTIONARY_DOWNLOAD = {
  [CHANNEL_BROADCAST_KEY.FM999MHZ]: 'FM999',
  [CHANNEL_BROADCAST_KEY.FM956MHZ]: 'FM956',
  [CHANNEL_BROADCAST_KEY.FM877MHZ]: 'FM877',
  [CHANNEL_BROADCAST_KEY.AM610KHZ]: 'AM610'
};

export const CHANNEL_BROADCAST_DICTIONARY = {
  [CHANNEL_BROADCAST_KEY.FM999MHZ]: 'FM 99.9 Mhz',
  [CHANNEL_BROADCAST_KEY.FM956MHZ]: 'FM 95.6 Mhz',
  [CHANNEL_BROADCAST_KEY.FM877MHZ]: 'FM 87.7 Mhz',
  [CHANNEL_BROADCAST_KEY.AM610KHZ]: 'AM 610 Khz'
};

export const CHANNEL_BROADCAST_DICTIONARY_IMG = [
  {
    value: CHANNEL_BROADCAST_KEY.AM610KHZ,
    label: CHANNEL_BROADCAST_DICTIONARY.AM610KHZ,
    img: 'imgs/610.png',
    color: 'red'
  },
  {
    value: CHANNEL_BROADCAST_KEY.FM999MHZ,
    label: [CHANNEL_BROADCAST_DICTIONARY.FM999MHZ],
    img: 'imgs/999.png',
    color: 'blue'
  },
  {
    value: CHANNEL_BROADCAST_KEY.FM956MHZ,
    label: CHANNEL_BROADCAST_DICTIONARY.FM956MHZ,
    img: 'imgs/856.png',
    color: 'gold'
  },
  {
    value: CHANNEL_BROADCAST_KEY.FM877MHZ,
    label: CHANNEL_BROADCAST_DICTIONARY.FM877MHZ,
    img: 'imgs/877.png',
    color: 'green'
  }
];

export const colProps = {
  xl: { span: 8 },
  span: 12
};

export const DATE_OF_WEEK_DICTIONARY = {
  0: 'Chủ nhật',
  1: 'Thứ 2',
  2: 'Thứ 3',
  3: 'Thứ 4',
  4: 'Thứ 5',
  5: 'Thứ 6',
  6: 'Thứ 7'
};

export const CATEGORY_STATUS_KEY = {
  DRAFT: 'DRAFT',
  IN_ACTIVE: 'IN_ACTIVE',
  ACTIVE: 'ACTIVE'
};

export const CATEGORY_STATUS_KEY_INT = {
  [CATEGORY_STATUS_KEY.DRAFT]: 0,
  [CATEGORY_STATUS_KEY.IN_ACTIVE]: 1,
  [CATEGORY_STATUS_KEY.ACTIVE]: 2
};

export const CATEGORY_STATUS_DICTIONARY = {
  [CATEGORY_STATUS_KEY.DRAFT]: 'Nháp',
  [CATEGORY_STATUS_KEY.IN_ACTIVE]: 'Chưa hoạt động',
  [CATEGORY_STATUS_KEY.ACTIVE]: 'Đã hoạt động'
};

export const CATEGORY_STATUS_INT_DICTIONARY = {
  [CATEGORY_STATUS_KEY_INT.DRAFT]: 'Nháp',
  [CATEGORY_STATUS_KEY_INT.IN_ACTIVE]: 'Chưa hoạt động',
  [CATEGORY_STATUS_KEY_INT.ACTIVE]: 'Đã hoạt động'
};

export const CATEGORY_TYPE_KEY = {
  BASIC: 'BASIC',
  DETAILED: 'DETAILED'
};

export const CATEGORY_TYPE_DICTIONARY = {
  [CATEGORY_TYPE_KEY.BASIC]: 'Cơ bản',
  [CATEGORY_TYPE_KEY.DETAILED]: 'Chi tiết'
};

export const CATEGORY_TYPE_KEY_INT = {
  [CATEGORY_TYPE_KEY.BASIC]: 1,
  [CATEGORY_TYPE_KEY.DETAILED]: 2
};

export const CATEGORY_TYPE_INT_DICTIONARY = {
  [CATEGORY_TYPE_KEY_INT.BASIC]: 'Cơ bản',
  [CATEGORY_TYPE_KEY_INT.DETAILED]: 'Chi tiết'
};

export const USER_STATUS_KEY = {
  DISABLED: 'DISABLED',
  ACTIVATED: 'ACTIVATED'
};

export const USER_STATUS_VALUE = {
  [USER_STATUS_KEY.DISABLED]: 0,
  [USER_STATUS_KEY.ACTIVATED]: 1
};

export const USER_STATUS_DICTIONARY = {
  [USER_STATUS_VALUE.DISABLED]: 'Tạm khoá',
  [USER_STATUS_VALUE.ACTIVATED]: 'Đang hoạt động'
};

export const USER_TYPE_KEY = {
  STAFF: 'STAFF',
  ADMIN: 'ADMIN'
};

export const USER_TYPE_VALUE = {
  3: USER_TYPE_KEY.STAFF,
  1: USER_TYPE_KEY.ADMIN
};

export const USER_TYPE_STR_DICTIONARY = {
  [USER_TYPE_KEY.STAFF]: 'Nhân viên',
  [USER_TYPE_KEY.ADMIN]: 'Admin'
};

export const USER_TYPE_DICTIONARY = {
  [USER_TYPE_VALUE.STAFF]: 'Nhân viên',
  [USER_TYPE_VALUE.ADMIN]: 'Admin'
};

// News status
export const NEWS_STATUS_KEY = {
  DRAFT: 'DRAFT',
  APPROVED: 'APPROVED'
};

export const NEWS_STATUS_KEY_INT = {
  [NEWS_STATUS_KEY.DRAFT]: 1,
  [NEWS_STATUS_KEY.APPROVED]: 2
};

export const NEWS_STATUS_DICTIONARY = {
  [NEWS_STATUS_KEY.DRAFT]: 'Nháp',
  [NEWS_STATUS_KEY.APPROVED]: 'Đã duyệt'
};

export const NEWS_STATUS_DICTIONARY_INT = {
  [NEWS_STATUS_KEY_INT.DRAFT]: 'Nháp',
  [NEWS_STATUS_KEY_INT.APPROVED]: 'Đã duyệt'
};

// News type
export const NEWS_TYPE_KEY = {
  TEXT: 'TEXT',
  AUDIO: 'AUDIO'
};

export const NEWS_TYPE_KEY_INT = {
  [NEWS_TYPE_KEY.TEXT]: 1,
  [NEWS_TYPE_KEY.AUDIO]: 2
};

export const NEWS_TYPE_DICTIONARY = {
  [NEWS_TYPE_KEY.TEXT]: 'Tin',
  [NEWS_TYPE_KEY.AUDIO]: 'Bài'
};

export const NEWS_TYPE_DICTIONARY_INT = {
  [NEWS_TYPE_KEY_INT.TEXT]: 'Tin',
  [NEWS_TYPE_KEY_INT.AUDIO]: 'Bài'
};

// Muvi CMS
export const PLAYLIST_STATUS_KEY = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED'
};

export const PLAYLIST_STATUS_KEY_INT = {
  [PLAYLIST_STATUS_KEY.WAITING]: 0,
  [PLAYLIST_STATUS_KEY.APPROVED]: 1
};

export const PLAYLIST_STATUS_DICTIONARY = {
  [PLAYLIST_STATUS_KEY.WAITING]: 'Đợi duyệt',
  [PLAYLIST_STATUS_KEY.APPROVED]: 'Đã duyệt'
};

export const PLAYLIST_STATUS_INT_DICTIONARY = {
  [PLAYLIST_STATUS_KEY_INT.WAITING]: 'Đợi duyệt',
  [PLAYLIST_STATUS_KEY_INT.APPROVED]: 'Đã duyệt'
};

export const RANK_KEY = {
  ELIMINATE: 'ELIMINATE',
  MEDIUM: 'MEDIUM',
  RATHER: 'RATHER',
  TALLEST: 'TALLEST'
};

export const RANK_DICTIONARY = {
  [RANK_KEY.ELIMINATE]: 'Loại bỏ',
  [RANK_KEY.MEDIUM]: 'Trung bình',
  [RANK_KEY.RATHER]: 'Khá',
  [RANK_KEY.TALLEST]: 'Cao nhất'
};

export const RANK_KEY_INT = {
  [RANK_KEY.ELIMINATE]: 1,
  [RANK_KEY.MEDIUM]: 2,
  [RANK_KEY.RATHER]: 3,
  [RANK_KEY.TALLEST]: 4
};

export const RANK_INT_DICTIONARY = {
  [RANK_KEY_INT.ELIMINATE]: 'Loại bỏ',
  [RANK_KEY_INT.MEDIUM]: 'Trung bình',
  [RANK_KEY_INT.RATHER]: 'Khá',
  [RANK_KEY_INT.TALLEST]: 'Cao nhất'
};
export const RANK_VALUE = {
  1: RANK_KEY.TALLEST,
  2: RANK_KEY.RATHER,
  3: RANK_KEY.MEDIUM,
  4: RANK_KEY.ELIMINATE
};

export const PROVIDER_DICTIONARY = {
  RIAV: 'RIAV',
  'Nam Việt': 'Nam Việt',
  YouTube: 'YouTube'
};

export const SONG_TYPE = {
  NORMAL: 0,
  YOUTUBE: 1
};

export const SONG_TYPE_DICTIONARY = {
  [SONG_TYPE.NORMAL]: 'Thường',
  [SONG_TYPE.YOUTUBE]: 'Youtube'
};

// status
export const COMMON_STATUS_KEY = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED'
};

export const COMMON_STATUS_KEY_INT = {
  [COMMON_STATUS_KEY.WAITING]: 0,
  [COMMON_STATUS_KEY.APPROVED]: 1
};
export const COMMENT_STATUS_CONTACT = {
  [1]: 'Đợi duyệt',
  [2]: 'Đã Duyệt',
  [3]: 'Bỏ duyệt',
};
export const COMMENT_STATUS_DICTIONARY = {
  [2]: 'Duyệt',
  [3]: 'Bỏ Duyệt',
};

export const COMMENT_STATUS_KEY = {
  WAITING: 'WAITING',
  APPROVED: 'APPROVED',
  ABORT:'ABORT'
};
export const COMMENT_STATUS_CODE = {
  [1]: 'WAITING',
  [2]: 'APPROVED',
  [3]: 'ABORT',
};
export const COMMENT_STATUS_KEY_INT = {
  [COMMENT_STATUS_KEY.WAITING]: 1,
  [COMMENT_STATUS_KEY.APPROVED]: 2,
  [COMMENT_STATUS_KEY.ABORT]: 3
};

export const COMMON_STATUS_KEY_BY_INT = {
  [COMMON_STATUS_KEY_INT.WAITING]: COMMON_STATUS_KEY.WAITING,
  [COMMON_STATUS_KEY_INT.APPROVED]: COMMON_STATUS_KEY.APPROVED
};

export const COMMON_STATUS_DICTIONARY = {
  [COMMON_STATUS_KEY.WAITING]: 'Đợi duyệt',
  [COMMON_STATUS_KEY.APPROVED]: 'Đã duyệt'
};

export const COMMON_STATUS_CONTACT = {
  [0]: 'Chưa xử lý',
  [1]: 'Đã xử lý'
};
export const COMMON_STATUS_INT_DICTIONARY = {
  [COMMON_STATUS_KEY_INT.WAITING]: 'Đợi duyệt',
  [COMMON_STATUS_KEY_INT.APPROVED]: 'Đã duyệt'
};

export const COMMON_ACTION_STATUS_DICTIONARY = {
  [COMMON_STATUS_KEY_INT.WAITING]: 'Bỏ duyệt',
  [COMMON_STATUS_KEY_INT.APPROVED]: 'Duyệt'
};

// Sending notification Status
export const SENDING_STATUS_KEY_INT = {
  WAITING: 0,
  SENT: 1
};

export const SENDING_STATUS_INT_DICTIONARY = {
  [SENDING_STATUS_KEY_INT.WAITING]: 'Chờ gửi',
  [SENDING_STATUS_KEY_INT.SENT]: 'Đã gửi'
};

// Copyright
export const COPYRIGHT_KEY = {
  COPYRIGHT: 'COPYRIGHT',
  NON_COPYRIGHT: 'NON_COPYRIGHT'
};

export const COPYRIGHT_KEY_INT = {
  [COPYRIGHT_KEY.COPYRIGHT]: 0,
  [COPYRIGHT_KEY.NON_COPYRIGHT]: 1
};

export const COPYRIGHT_DICTIONARY = {
  [COPYRIGHT_KEY.COPYRIGHT]: 'Không vi phạm',
  [COPYRIGHT_KEY.NON_COPYRIGHT]: 'Vi phạm bản quyền'
};

export const COPYRIGHT_INT_DICTIONARY = {
  [COPYRIGHT_KEY_INT.COPYRIGHT]: 'Không vi phạm',
  [COPYRIGHT_KEY_INT.NON_COPYRIGHT]: 'Vi phạm bản quyền'
};

export const ACTION_COPYRIGHT_DICTIONARY = {
  [COPYRIGHT_KEY_INT.COPYRIGHT]: 'Gỡ vi phạm bản quyền',
  [COPYRIGHT_KEY_INT.NON_COPYRIGHT]: 'Vi phạm bản quyền'
};

// Copyright

export const DELETE_KEY_INT = {
  NON: 0,
  SOFT: 1
};

export const DELETE_INT_DICTIONARY = {
  [DELETE_KEY_INT.NON]: 'Chưa xoá',
  [DELETE_KEY_INT.SOFT]: 'Xoá mềm'
};

// 6: nhạc sĩ
// 7: ca sĩ

export const UPLOAD_TYPE = {
  SONG: {
    type: 'thumbnails/song',
    imageType: 'SONG'
  },
  SHARE_SONG: {
    type: 'social/song',
    imageType: null
  },
  TRACK_SONG: {
    type: 'track/song',
    imageType: null
  },
  LYRIC_SONG: {
    type: 'lyric/song',
    imageType: null
  },
  SLIDE_PLAYLIST: {
    type: 'thumbnails/playlistslide',
    imageType: 'PLAYLIST_SLIDE'
  },
  THUMBNAIL_PLAYLIST: {
    type: 'thumbnails/playlist',
    imageType: 'PLAYLIST'
  },
  SHARE_PLAYLIST: {
    type: 'social/playlist',
    imageType: null
  },
  THUMBNAIL_ARTIST: {
    type: 'thumbnails/artist',
    imageType: 'ARTIST'
  },
  SHARE_ARTIST: {
    type: 'social/artist',
    imageType: null
  },
  BANNER_ARTIST: {
    type: 'banners/Artist',
    imageType: null
  },
  THUMBNAIL_VIDEO: {
    type: 'thumbnails/video',
    imageType: 'VIDEO'
  },
  FILE_VIDEO: {
    type: 'video',
    imageType: null
  },
  THUMBNAIL_MOOD: {
    type: 'thumbnails/moods',
    imageType: 'NOTIFICATION_IMAGE'
  },
  SHARE_MOOD: {
    type: 'social/moods',
    imageType: 'NOTIFICATION_IMAGE'
  },
  BANNER_MOOD: {
    type: 'Banner/Moods',
    imageType: null
  },
  THUMBNAIL_ALBUM: {
    type: 'thumbnails/album',
    imageType: 'ALBUM'
  },
  SHARE_ALBUM: {
    type: 'social/album',
    imageType: null
  },
  THUMBNAIL_CATEGORY: {
    type: 'img/cate',
    imageType: 'SQUARE'
  },
  SHARE_CATEGORY: {
    type: 'social/cate',
    imageType: 'SQUARE'
  },
  THUMBNAIL_WEB_NOTIFICATION: {
    type: 'notification',
    imageType: 'NOTIFICATION_IMAGE'
  },
  ICON_WEB_NOTIFICATION: {
    type: 'notification',
    imageType: 'SONG'
  },
  PARTNER: {
    type: 'thumbnails/partner',
    imageType: 'PARTNER'
  }
};

// Playlist type
export const PLAYLIST_TYPE_VALUE = {
  SONG: 'SONG',
  VIDEO: 'VIDEO'
};

export const PLAYLIST_TYPE_DICTIONARY = {
  [PLAYLIST_TYPE_VALUE.SONG]: 'Bài hát',
  [PLAYLIST_TYPE_VALUE.VIDEO]: 'Video/MV'
};

// Topic type
export const TOPIC_TYPE_VALUE = {
  ALBUM: 'ALBUM',
  PLAYLIST: 'PLAYLIST'
};

export const TOPIC_TYPE_DICTIONARY = {
  [TOPIC_TYPE_VALUE.ALBUM]: 'Album',
  [TOPIC_TYPE_VALUE.PLAYLIST]: 'Playlist'
};

// Priority
export const PRIORITY_KEY = {
  TALLEST: 'TALLEST',
  RATHER: 'RATHER',
  MEDIUM: 'MEDIUM',
  ELIMINATE: 'ELIMINATE'
};

export const PRIORITY_KEY_INT = {
  [PRIORITY_KEY.TALLEST]: 1,
  [PRIORITY_KEY.RATHER]: 2,
  [PRIORITY_KEY.MEDIUM]: 3,
  [PRIORITY_KEY.ELIMINATE]: 4
};

export const PRIORITY_DICTIONARY = {
  [PRIORITY_KEY_INT.TALLEST]: 'Cao nhất',
  [PRIORITY_KEY_INT.RATHER]: 'Khá',
  [PRIORITY_KEY_INT.MEDIUM]: 'Trung bình',
  [PRIORITY_KEY_INT.ELIMINATE]: 'Loại bỏ'
};

export const PRIORITY_VALUE = {
  [PRIORITY_KEY_INT.TALLEST]: PRIORITY_KEY.TALLEST,
  [PRIORITY_KEY_INT.RATHER]: PRIORITY_KEY.RATHER,
  [PRIORITY_KEY_INT.MEDIUM]: PRIORITY_KEY.MEDIUM,
  [PRIORITY_KEY_INT.ELIMINATE]: PRIORITY_KEY.ELIMINATE
};

// Artist Rank
export const ARTIST_RANK_KEY = {
  TALLEST: 'TALLEST',
  GOOD: 'GOOD',
  RATHER: 'RATHER',
  MEDIUM: 'MEDIUM',
  LOW: 'LOW'
};

export const ARTIST_RANK_KEY_INT = {
  [ARTIST_RANK_KEY.TALLEST]: 1,
  [ARTIST_RANK_KEY.GOOD]: 2,
  [ARTIST_RANK_KEY.RATHER]: 3,
  [ARTIST_RANK_KEY.MEDIUM]: 4,
  [ARTIST_RANK_KEY.LOW]: 5
};

export const ARTIST_RANK_DICTIONARY = {
  [ARTIST_RANK_KEY_INT.TALLEST]: 'Cao nhất',
  [ARTIST_RANK_KEY_INT.GOOD]: 'Tốt',
  [ARTIST_RANK_KEY_INT.RATHER]: 'Khá',
  [ARTIST_RANK_KEY_INT.MEDIUM]: 'Trung bình',
  [ARTIST_RANK_KEY_INT.LOW]: 'Thấp'
};

export const ARTIST_RANK_VALUE = {
  [ARTIST_RANK_KEY_INT.TALLEST]: ARTIST_RANK_KEY.TALLEST,
  [ARTIST_RANK_KEY_INT.GOOD]: ARTIST_RANK_KEY.GOOD,
  [ARTIST_RANK_KEY_INT.RATHER]: ARTIST_RANK_KEY.RATHER,
  [ARTIST_RANK_KEY_INT.MEDIUM]: ARTIST_RANK_KEY.MEDIUM,
  [ARTIST_RANK_KEY_INT.LOW]: ARTIST_RANK_KEY.LOW
};

export const PUBLISH_DICTIONARY = {
  0: 'Chưa hẹn phát hành',
  1: 'Đã hẹn phát hành'
};

export const NOTIFICATION_KEY = {
  NO_REPEAT: 'NO_REPEAT',
  REPEAT: 'REPEAT'
};

export const NOTIFICATION_DICTIONARY = {
  [NOTIFICATION_KEY.NO_REPEAT]: 'Một lần',
  [NOTIFICATION_KEY.REPEAT]: 'Định kỳ'
};

export const NOTIFICATION_KEY_INT = {
  [NOTIFICATION_KEY.NO_REPEAT]: 1,
  [NOTIFICATION_KEY.REPEAT]: 2
};

export const NOTIFICATION_INT_DICTIONARY = {
  [NOTIFICATION_KEY_INT.NO_REPEAT]: 'Loại bỏ',
  [NOTIFICATION_KEY_INT.REPEAT]: 'Định kỳ'
};
export const NOTIFICATION_VALUE = {
  1: NOTIFICATION_KEY.NO_REPEAT,
  2: NOTIFICATION_KEY.REPEAT
};

// Site Id
export const NOTIFICATION_SITE_KEY = {
  MUVI: 'MUVI',
  VOH: 'VOH',
  XO_SO_DAI_PHAT: 'XO_SO_DAI_PHAT',
  MUVI_APP: 'MUVI_APP'
};

export const NOTIFICATION_SITE_DICTIONARY = {
  [NOTIFICATION_SITE_KEY.MUVI]: 'muvi.vn',
  [NOTIFICATION_SITE_KEY.VOH]: 'voh.com.vn',
  [NOTIFICATION_SITE_KEY.XO_SO_DAI_PHAT]: 'xosodaiphat.vn'
};

export const NOTIFICATION_SITE_KEY_INT = {
  [NOTIFICATION_SITE_KEY.MUVI]: 1,
  [NOTIFICATION_SITE_KEY.VOH]: 2,
  [NOTIFICATION_SITE_KEY.XO_SO_DAI_PHAT]: 3
};

export const NOTIFICATION_SITE_INT_DICTIONARY = {
  [NOTIFICATION_SITE_KEY_INT.MUVI]: 'muvi.vn',
  [NOTIFICATION_SITE_KEY_INT.VOH]: 'voh.com.vn',
  [NOTIFICATION_SITE_KEY_INT.XO_SO_DAI_PHAT]: 'xosodaiphat.vn'
};

export const NOTIFICATION_SITE_VALUE = {
  1: NOTIFICATION_SITE_KEY.MUVI,
  2: NOTIFICATION_SITE_KEY.VOH,
  3: NOTIFICATION_SITE_KEY.XO_SO_DAI_PHAT
};
// Site App Id
export const APP_NOTIFICATION_SITE_KEY = {
  MUVI_APP: 'MUVI_APP'
};

export const APP_NOTIFICATION_SITE_DICTIONARY = {
  [APP_NOTIFICATION_SITE_KEY.MUVI_APP]: 'Muvi App'
};

export const APP_NOTIFICATION_SITE_KEY_INT = {
  [APP_NOTIFICATION_SITE_KEY.MUVI_APP]: 1
};

export const APP_NOTIFICATION_SITE_INT_DICTIONARY = {
  [APP_NOTIFICATION_SITE_KEY_INT.MUVI_APP]: 'Muvi App'
};

export const APP_NOTIFICATION_SITE_VALUE = {
  1: APP_NOTIFICATION_SITE_KEY.MUVI_APP
};

// ACtion click app notification
export const ACTION_NOTIFICATION_KEY = {
  SONG: 'SONG',
  VIDEO: 'VIDEO',
  ALBUM: 'ALBUM',
  PLAYLIST: 'PLAYLIST',
  ARTIST: 'ARTIST',
  TOPIC: 'TOPIC'
};

export const ACTION_NOTIFICATION_DICTIONARY = {
  [ACTION_NOTIFICATION_KEY.SONG]: 'Chi tiết bài hát',
  [ACTION_NOTIFICATION_KEY.VIDEO]: 'Chi tiết video',
  [ACTION_NOTIFICATION_KEY.ALBUM]: 'Chi tiết album',
  [ACTION_NOTIFICATION_KEY.PLAYLIST]: 'Chi tiết playlist',
  [ACTION_NOTIFICATION_KEY.ARTIST]: 'Chi tiết nghệ sĩ',
  [ACTION_NOTIFICATION_KEY.TOPIC]: 'Danh sách bài hát theo topic'
};

export const ACTION_NOTIFICATION_KEY_INT = {
  [ACTION_NOTIFICATION_KEY.SONG]: 1,
  [ACTION_NOTIFICATION_KEY.VIDEO]: 2,
  [ACTION_NOTIFICATION_KEY.ALBUM]: 3,
  [ACTION_NOTIFICATION_KEY.PLAYLIST]: 4,
  [ACTION_NOTIFICATION_KEY.ARTIST]: 5,
  [ACTION_NOTIFICATION_KEY.TOPIC]: 6
};

export const ACTION_NOTIFICATION_INT_DICTIONARY = {
  [ACTION_NOTIFICATION_KEY_INT.SONG]: 'Chi tiết bài hát',
  [ACTION_NOTIFICATION_KEY_INT.VIDEO]: 'Chi tiết video',
  [ACTION_NOTIFICATION_KEY_INT.ALBUM]: 'Chi tiết album',
  [ACTION_NOTIFICATION_KEY_INT.PLAYLIST]: 'Chi tiết playlist',
  [ACTION_NOTIFICATION_KEY_INT.ARTIST]: 'Chi tiết nghệ sĩ',
  [ACTION_NOTIFICATION_KEY_INT.TOPIC]: 'Danh sách bài hát theo topic'
};

export const ACTION_NOTIFICATION_VALUE = {
  1: ACTION_NOTIFICATION_KEY.SONG,
  2: ACTION_NOTIFICATION_KEY.VIDEO,
  3: ACTION_NOTIFICATION_KEY.ALBUM,
  4: ACTION_NOTIFICATION_KEY.PLAYLIST,
  5: ACTION_NOTIFICATION_KEY.ARTIST,
  6: ACTION_NOTIFICATION_KEY.TOPIC
};

// Top popular
export const POPULAR_TYPE_KEY = {
  PLAYLIST: 'PLAYLIST',
  SONG: 'SONG',
  ALBUM: 'ALBUM'
};

export const POPULAR_TYPE_KEY_INT = {
  1: POPULAR_TYPE_KEY.SONG,
  3: POPULAR_TYPE_KEY.ALBUM,
  4: POPULAR_TYPE_KEY.PLAYLIST
};

export const POPULAR_TYPE_DICTIONARY = {
  [POPULAR_TYPE_KEY.PLAYLIST]: 'Playlist',
  [POPULAR_TYPE_KEY.SONG]: 'Bài hát',
  [POPULAR_TYPE_KEY.ALBUM]: 'Album'
};

// Categỏy Type
export const CATEGORY_TYPE = {
  SONG: 1,
  VIDEO: 3,
  ALBUM: 4,
  PLAYLIST: 5
};

// const generateEnum = () => {
//   const params = [
//     { key: 'TALLEST', int: 1, dict: 'Cao nhất' },
//     { key: 'GOOD', int: 2, dict: 'Tốt' },
//     { key: 'RATHER', int: 3, dict: 'Khá' }
//   ];
//   const keys = {};
//   const keysInt = {};
//   const dictionary = {};
//   params.forEach(({ key, int, dict }) => {
//     keys[key] = key;
//     keysInt[key] = int;
//     dictionary[int] = dict;
//     return true;
//   });
// };

// generateEnum();


//quảng cáo
export const ADV_CONTENT_TYPE_ID = {
  IMAGE: 1,
  SCRIPT: 2,
};
export const ADV_CONTENT = {
  [ADV_CONTENT_TYPE_ID.IMAGE]: 'File ảnh',
  [ADV_CONTENT_TYPE_ID.SCRIPT]: 'Script',
};
export const ADV_DISPLAY_TYPE_ID = {
  Desktop: 1,
  Mobile: 2,
  Amp:3,
};
export const ADV_DISPLAY = {
  [ADV_DISPLAY_TYPE_ID.Desktop]: 'Desktop',
  [ADV_DISPLAY_TYPE_ID.Mobile]: 'Mobile',
  [ADV_DISPLAY_TYPE_ID.Amp]: 'Amp',
};

