import { DeleteOutlined, MenuOutlined } from '@ant-design/icons';
import { Button, message, Space, Table, Typography } from 'antd';
import { arrayMoveImmutable } from 'array-move';
import UserAvatar from 'components/UserAvatar';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  SortableContainer,
  SortableElement,
  SortableHandle
} from 'react-sortable-hoc';
import VideoSearch from './VideoSearch';

const SortableItem = SortableElement(props => <tr {...props} />);
const SortableBody = SortableContainer(props => <tbody {...props} />);

const VideoOfPlaylist = ({ onSelect, videos }) => {
  const [dataSource, setDataSource] = useState(videos || []);

  const DragHandle = SortableHandle(() => (
    <MenuOutlined
      style={{
        cursor: 'grab',
        color: '#999'
      }}
    />
  ));

  const onClearVideo = id => {
    const newData = dataSource.filter(_d => _d.id !== id);
    setDataSource(newData);
    onSelect(newData.map(_d => _d.id));
  };

  const columns = [
    {
      dataIndex: 'sort',
      width: 30,
      className: 'drag-visible',
      render: () => <DragHandle />
    },
    {
      title: 'Thứ tự',
      width: 80,
      align: 'center',
      render: (_, __, index) => (
        <Typography.Title level={2} style={{ margin: 0 }}>
          {index + 1}
        </Typography.Title>
      )
    },
    {
      title: 'Video',
      dataIndex: 'title',
      className: 'drag-visible',
      render: (title, { id, thumbnail }) => (
        <Space>
          <UserAvatar
            fullName={title}
            avatar={thumbnail}
            shape="square"
            size={48}
          />
          <Link target="_blank" to={`/chi-tiet-video/${id}`}>
            {title}
          </Link>
        </Space>
      )
    },
    {
      title: 'Ca sĩ',
      dataIndex: 'artist',
      render: artist => (
        <Space direction="vertical">
          {artist?.map(_a => (
            <Typography.Text>{_a?.name}</Typography.Text>
          ))}
        </Space>
      )
    },
    {
      title: 'Nhạc sĩ',
      dataIndex: 'musician',
      render: musician => (
        <Space direction="vertical">
          {musician?.map(_m => (
            <Typography.Text>{_m?.name}</Typography.Text>
          ))}
        </Space>
      )
    },
    {
      title: 'Thể loại',
      dataIndex: 'category',
      render: category => (
        <Space direction="vertical">
          {category?.map(_c => (
            <Typography.Text>{_c?.name}</Typography.Text>
          ))}
        </Space>
      )
    },

    {
      dataIndex: 'id',
      width: 50,
      render: id => (
        <Button
          onClick={() => onClearVideo(id)}
          size="small"
          danger
          icon={<DeleteOutlined />}
        ></Button>
      )
    }
  ];

  useEffect(() => {
    if (!!videos?.length) {
      const _videos = videos.map((video, index) => ({
        ...video,
        index,
        key: video.id
      }));
      setDataSource(_videos);
    }
  }, [videos]);

  const onSortEnd = ({ oldIndex, newIndex }) => {
    if (oldIndex !== newIndex) {
      const newData = arrayMoveImmutable(
        dataSource.slice(),
        oldIndex,
        newIndex
      ).filter(el => !!el);
      setDataSource(newData);
    }
  };

  const DraggableContainer = props => (
    <SortableBody
      useDragHandle
      disableAutoscroll
      helperClass="row-dragging"
      onSortEnd={onSortEnd}
      {...props}
    />
  );

  const DraggableBodyRow = ({ className, style, ...restProps }) => {
    const index = dataSource.findIndex(
      x => x.index === restProps['data-row-key']
    );
    return <SortableItem index={index} {...restProps} />;
  };

  const onAddVideoToPlaylist = video => {
    if (!video) return;
    const isExist = !!dataSource.find(_data => _data.id === video.id);
    if (isExist) {
      return message.error('Video đã tồn tại trong danh sách');
    }
    setDataSource(dataSource => [
      ...dataSource,
      { ...video, index: dataSource?.length }
    ]);
    onSelect([...dataSource, video].map(_video => _video.id));
    return message.success('Đã thêm video vào danh sách');
  };

  return (
    <div className="mb-md">
      <div className="mb-md">
        <Typography.Text>Danh video hát của playlist</Typography.Text>
        <VideoSearch onSelect={onAddVideoToPlaylist} />
      </div>
      <Table
        pagination={false}
        dataSource={dataSource}
        columns={columns}
        rowKey="index"
        components={{
          body: {
            wrapper: DraggableContainer,
            row: DraggableBodyRow
          }
        }}
      />
    </div>
  );
};

export default VideoOfPlaylist;
