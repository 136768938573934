import { Typography } from 'antd';
import React from 'react';
import TopPlaylist from './components/TopPlaylist';

const TopPopular = () => {
  return (
    <div>
      <Typography.Title>Quản lý HomePage thịnh hành</Typography.Title>
      <TopPlaylist />
    </div>
  );
};

export default TopPopular;
