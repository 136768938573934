import { CheckOutlined } from '@ant-design/icons';
import { Popover, Space, Typography } from 'antd';
import { Link } from 'react-router-dom';

const RelatedInfo = ({ data, subject, redirectUrl = '' }) => {
  const content = !!data?.length ? (
    <Space direction="vertical" size={0}>
      {data?.map(item => (
        <Space key={item?.id}>
          <CheckOutlined />
          <Typography.Text>{item?.title || item?.name}</Typography.Text>
        </Space>
      ))}
    </Space>
  ) : (
    <Typography.Text>Chưa có thông tin</Typography.Text>
  );
  return (
    <Popover
      content={content}
      overlayInnerStyle={{ maxHeight: '300px', overflowY: 'auto' }}
    >
      <Link to={redirectUrl}>
        {data?.length} {subject} liên quan
      </Link>
    </Popover>
  );
};

export default RelatedInfo;
