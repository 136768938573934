import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { searchBannerVar } from 'api/cache';
import AutoComplete from 'components/AutoComplete';
import { GET_BANNERS } from 'operations/queries';

const BannerSearch = ({ onSelect, ...props }) => {
  const [searchBanner] = useLazyQuery(GET_BANNERS);
  const searchedBanners = useReactiveVar(searchBannerVar);

  const onChange = value => {
    const record = searchedBanners.find(_record => _record.id === value);
    return onSelect(record);
  };

  return (
    <AutoComplete
      size="large"
      value={null}
      handleSearch={searchBanner}
      labelKey="title"
      valueKey="id"
      dataKey="banners"
      placeholder="Tìm banner thêm vào danh sách"
      style={{ width: '100%' }}
      onChange={onChange}
      updateCache={searchBannerVar}
      // getLabel={generateBannerLabel}
      {...props}
    />
  );
};

export default BannerSearch;
