import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { isLoggedInVar } from 'api/cache';
import queryString from 'query-string';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import {
  getAccessToken,
  getRefreshToken,
  removeAccessToken,
  removeRefreshToken,
  setAccessToken,
  setRefreshToken
} from 'utils/authority';

export const LOGIN_MUTATION = gql`
  mutation Login($input: LoginInput!) {
    login(input: $input) {
      id
      accessToken
      refreshToken
      accessTokenExpired
      refreshTokenExpired
      userId
      user {
        email
        userName
        fullName
      }
    }
  }
`;

export const REFRESH_TOKEN_MUTATION = gql`
  mutation RefreshToken($input: RefreshTokenInput!) {
    refreshToken(input: $input) {
      id
      accessToken
      refreshToken
      accessTokenExpired
      refreshTokenExpired
      userId
      user {
        email
        userName
        fullName
      }
    }
  }
`;

export const LOGOUT_MUTATION = gql`
  mutation Logout($input: RevokeTokenInput) {
    revoke(input: $input) {
      id
    }
  }
`;

export const useLogin = () => {
  let history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const [mutate, { data, error, loading }] = useMutation(LOGIN_MUTATION, {
    onCompleted: ({ login }) => {
      setAccessToken(login.accessToken);
      setRefreshToken(login.refreshToken);
      isLoggedInVar(true);
      history.push(searchParams?.redirect || '/');
      notification.success({
        message: (
          <Typography.Text>
            <Typography.Text strong>{login.user.name}</Typography.Text> đăng
            nhập thành công 💥
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useRefreshToken = () => {
  const [mutate, { data, error }] = useMutation(REFRESH_TOKEN_MUTATION, {
    variables: {
      input: { accessToken: getAccessToken(), refreshToken: getRefreshToken() }
    },
    onCompleted: ({ refreshToken }) => {
      setAccessToken(refreshToken.accessToken);
      setRefreshToken(refreshToken.refreshToken);
    }
  });
  return { mutate, data, error };
};

export const useLogout = () => {
  const [mutate, { data, error }] = useMutation(LOGOUT_MUTATION, {
    onCompleted: ({ revoke }) => {
      // useCurrentUser.getState().resetCurrentUser();
      isLoggedInVar(false);
      removeAccessToken();
      removeRefreshToken();
      // window.location.href = window.location.origin + '/dang-nhap';
    }
  });
  return { mutate, data, error };
};
