import { useQuery } from '@apollo/client';
import { Spin, Tabs, Typography } from 'antd';
import {
  useAddIcAdvert,
  useEditIcAdvert
} from 'operations/mutations';
import { GET_ADV_BY_ID } from 'operations/queries';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Adv from './components/Adv';

const AdvDetail = () => {
  const { id } = useParams();
  const isUpdate = useMemo(() => !!id, [id]);
  const [loading, setLoading] = useState(false)
  const { data, loading: fetchLoading } = useQuery(GET_ADV_BY_ID, {
    variables: { id: parseInt(id) },
    skip: !isUpdate
  });
  const { mutate: addPage, loading: editLoading } = useAddIcAdvert();
  const { mutate: editPage, loading: addLoading } = useEditIcAdvert();

  const items = useMemo(
    () => [
      {
        label: 'Thông tin quảng cáo',
        key: 'info',
        children: (
          <Adv
            adv={data?.icAdvert}
            isUpdate={isUpdate}
            onEditAdvContact={editPage}
            onAddAdvContact={addPage}
          />
        )
      }
    ],
    [addPage, data?.icAdvert, editPage, isUpdate]
  );

  return (
    <Spin spinning={fetchLoading || editLoading || addLoading}>
      <Typography.Title>
        {isUpdate ? 'Chi tiết quảng cáo' : 'Tạo quảng cáo'}
      </Typography.Title>
      <Tabs type="card" items={items} />
    </Spin>
  );
};

export default AdvDetail;
