import { ScissorOutlined } from '@ant-design/icons';
import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  Row,
  Space,
  Tooltip,
  Typography
} from 'antd';
import {
  CATEGORY_TYPE,
  DATE_FORMAT,
  DEBOUNCE_VALUE,
  TIME_FORMAT,
  UPLOAD_TYPE
} from 'appConstants';
import ArtistAutocomplete from 'components/ArtistAutocomplete';
import ImageUpload from 'components/ImageUpload';
import CategoryAutocomplete from 'components/CategoryAutocomplete';
import TagAutocomplete from 'components/TagAutocomplete';
import VideoListUpload from 'components/VideoListUpload';
import moment from 'moment';
import { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { debounce, get } from 'utils/lodash';
import { sanitizeSlug } from 'utils/parser';

const VideoInfo = ({ video, isUpdate, editVideo, addVideo }) => {
  const [form] = Form.useForm();
  const { id } = useParams();
  const history = useHistory();

  useEffect(() => {
    if (video) {
      form.setFieldsValue({
        title: video.title,
        slug: video.slug,
        intro: video.intro,
        thumbnail: video.thumbnail,
        maHd: video.maHd,
        maBaiHat: video.maBaiHat,
        url: video.url,
        isMedley: video.isMedley,
        cateIds: video.categories.map(category => category?.id),
        tagIds: video.tags.map(tag => tag?.id),
        singerIds: video.singers.map(singer => singer?.id),
        musicianIds: video.musicians.map(musician => musician?.id),
        publicDate: video.publicDate && moment(video.publicDate)
      });
    }
  }, [video, form]);

  const normFile = e => {
    if (Array.isArray(e)) {
      return e;
    }

    return e && e.fileList;
  };

  const onChangeTitle = debounce(() => {
    const { title, slug } = form.getFieldsValue();
    if (!slug) {
      const _slug = sanitizeSlug(title);
      form.setFieldsValue({ slug: _slug });
    }
  }, DEBOUNCE_VALUE);

  const onGenerateSlug = () => {
    const { title } = form.getFieldsValue();
    if (!title) return;
    const _slug = sanitizeSlug(title);
    form.setFieldsValue({ slug: _slug });
  };

  const onChangeFile = formParam => {
    return form.setFieldsValue(formParam);
  };

  const onSave = async () => {
    try {
      const values = await form.validateFields();
      const thumbnail = get(
        values.thumbnail,
        '[0].response.Data',
        video?.thumbnail
      );

      const params = {
        ...values,
        thumbnail
      };

      delete params.urlFile;

      if (isUpdate) {
        await editVideo({
          variables: {
            input: {
              id: parseInt(id),
              ...params
            }
          }
        });
      } else {
        await addVideo({
          variables: {
            input: {
              ...params
            }
          },
          onCompleted: ({ addVideo }) => {
            history.push('/chi-tiet-video/' + addVideo?.id);
          }
        });
      }
    } catch (error) {}
  };

  return (
    <Card>
      <Form form={form} layout="vertical">
        <Row gutter={12}>
          <Col span={12}>
            <Form.Item
              name="title"
              label="Tên video"
              rules={[{ required: true, message: 'Nhập tên video!' }]}
            >
              <Input placeholder="Nhập tên video" onMouseOut={onChangeTitle} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="slug"
              label="Slug"
              rules={[{ required: true, message: 'Nhập slug!' }]}
            >
              <Input
                suffix={
                  <Tooltip title="Generate slug">
                    <ScissorOutlined
                      style={{ fontSize: 16 }}
                      onClick={onGenerateSlug}
                    />
                  </Tooltip>
                }
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="cateIds"
              label="Thể loại"
              rules={[{ required: true, message: 'Chọn thể loại!' }]}
            >
              <CategoryAutocomplete
                allowClear
                category={video?.categories}
                type={CATEGORY_TYPE.VIDEO}
              />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item name="tagIds" label="Tag">
              <TagAutocomplete allowClear tag={video?.tags} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="singerIds"
              label="Ca sĩ"
              rules={[{ required: true, message: 'Chọn ca sĩ!' }]}
            >
              <ArtistAutocomplete allowClear artist={video?.singers} />
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item
              name="musicianIds"
              label="Nhạc sĩ"
              rules={[{ required: true, message: 'Chọn nhạc sĩ!' }]}
            >
              <ArtistAutocomplete allowClear artist={video?.musicians} />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="isMedley"
              label=" "
              valuePropName="checked"
              initialValue={false}
            >
              <Checkbox>Video/MV là liên khúc</Checkbox>
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="maHd" label="Mã hợp đồng">
              <Input placeholder="Nhập Mã hợp đồng" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item name="maBaiHat" label="Mã bài hát">
              <Input placeholder="Nhập mã bài hát" />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="publicDate"
              label="Thời gian đăng"
              rules={[{ required: true, message: 'Chọn thời gian đăng!' }]}
            >
              <DatePicker
                showTime
                style={{ width: '100%' }}
                format={DATE_FORMAT + ' ' + TIME_FORMAT}
                placeholder="Chọn thời gian đăng"
              />
            </Form.Item>
          </Col>

          <Col span={12}>
            <Form.Item
              name="url"
              rules={[{ required: true, message: 'Nhập file video!' }]}
              label={
                <Space>
                  <Typography.Text>File Video</Typography.Text>
                  <VideoListUpload
                    form={form}
                    onDone={url => onChangeFile({ url: url })}
                    {...UPLOAD_TYPE.FILE_VIDEO}
                  />
                </Space>
              }
            >
              <Input placeholder="Nhập link Video/MV" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Form.Item
              name="intro"
              label="Giới thiệu video"
              rules={[{ required: true, message: 'Nhập giới thiệu!' }]}
            >
              <Input.TextArea rows={7} placeholder="Nhập giới thiệu" />
            </Form.Item>
          </Col>
          <Col span={24}>
            <Row gutter={32}>
              <Col span={12}>
                <Form.Item
                  name="thumbnail"
                  label="Hình đại diện (max 500KB)"
                  valuePropName="file"
                  getValueFromEvent={normFile}
                >
                  <ImageUpload
                    avatarShape="square"
                    size={128}
                    fontSize={64}
                    fullName="Hình đại diện"
                    {...UPLOAD_TYPE.THUMBNAIL_VIDEO}
                  />
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Form>

      <Row justify="end">
        <Col>
          <Button onClick={onSave} type="primary">
            Lưu
          </Button>
        </Col>
      </Row>
    </Card>
  );
};

export default VideoInfo;
