import { useQuery } from '@apollo/client';
import { Spin, Tabs, Typography } from 'antd';
import { useAddVideo, useEditVideo } from 'operations/mutations';
import { GET_VIDEO_BY_ID } from 'operations/queries';
import { useMemo } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import RelatedVideos from './components/RelatedVideos';
import SeoInfo from './components/SeoInfo';
import VideoInfo from './components/VideoInfo';
import VideoPlaylistInfo from './components/VideoPlaylistInfo';
import queryString from 'query-string';

const VideoDetail = () => {
  const { id } = useParams();
  const isUpdate = useMemo(() => !!id, [id]);
  const history = useHistory();
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const { data, loading: fetchLoading } = useQuery(GET_VIDEO_BY_ID, {
    variables: { id: parseInt(id) },
    skip: !isUpdate
  });

  const { mutate: editVideo, loading: editLoading } = useEditVideo();
  const { mutate: addVideo, loading: addLoading } = useAddVideo();

  const items = useMemo(
    () => [
      {
        label: 'Thông tin Video/MV',
        key: '1',
        children: (
          <VideoInfo
            video={data?.video}
            isUpdate={isUpdate}
            editVideo={editVideo}
            addVideo={addVideo}
          />
        )
      },
      {
        label: 'Video liên quan',
        key: '2',
        children: (
          <RelatedVideos
            videoName={data?.video?.title}
            videos={data?.video?.relatedVideos}
          />
        ),
        disabled: !isUpdate
      },

      {
        label: 'Playlist',
        key: '3',
        children: <VideoPlaylistInfo video={data?.video} />,
        disabled: !isUpdate
      },
      {
        label: 'SEO',
        key: '4',
        children: (
          <SeoInfo
            isUpdate={isUpdate}
            video={data?.video}
            editVideo={editVideo}
          />
        ),
        disabled: !isUpdate
      }
    ],
    [addVideo, data?.video, editVideo, isUpdate]
  );

  const onChangeTab = tab => {
    history.push({
      search: queryString.stringify({
        tab
      })
    });
  };

  return (
    <Spin spinning={fetchLoading || editLoading || addLoading}>
      <Typography.Title>
        {isUpdate ? 'Chi tiết Video/MV' : 'Tạo Video/MV'}
      </Typography.Title>
      <Tabs
        onChange={onChangeTab}
        defaultActiveKey={searchParams?.tab || '1'}
        type="card"
        items={items}
      />
    </Spin>
  );
};

export default VideoDetail;
