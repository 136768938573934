import {
  DeleteOutlined,
  FacebookOutlined,
  MinusOutlined,
  PhoneOutlined,
  TwitterOutlined
} from '@ant-design/icons';
import { Button, Space, Typography } from 'antd';
import { visibleDrawerVar } from 'api/cache';
import ColName from 'components/ColName';
import DeleteConfirmPopup from 'components/DeleteConfirmPopup';
import RelatedUser from 'components/RelatedUser';
import StatusTag from 'components/StatusTag';
import TableRecordName from 'components/TableRecordName';
import TimeLine from 'components/TimeLine';
import 'moment/locale/vi';
import { useRemovePartner } from 'operations/mutations/mutatePartner';

const RemovePartner = ({ record }) => {
  const { mutate } = useRemovePartner();
  return (
    <DeleteConfirmPopup
      onDelete={() =>
        mutate({
          variables: {
            id: parseInt(record.id)
          }
        })
      }
      title={
        <Typography.Text>
          Bạn muốn xoá <Typography.Text strong>{record?.name}</Typography.Text>{' '}
          ?
        </Typography.Text>
      }
    >
      <Button danger type="primary" size="small" icon={<DeleteOutlined />} />
    </DeleteConfirmPopup>
  );
};

const Name = ({ record }) => {
  const { name } = record;

  return (
    <Space direction="vertical">
      <TableRecordName
        name={name}
        setDrawerVisible={() =>
          visibleDrawerVar({ visible: true, record: record })
        }
      />
    </Space>
  );
};

export const columns = [
  {
    title: 'Đối tác',
    dataIndex: 'name',
    key: 'name',
    render: (_, record) => (
      <ColName
        primary={<Name record={record} />}
        secondary={record?.email}
        avatar={{ image: record?.logo, name: record?.name }}
        tag={<StatusTag status={record?.status} />}
      />
    ),
    fixed: 'left',
    width: '200px'
  },
  {
    title: 'Liên hệ',
    width: '120px',
    key: 'contact',
    render: (_, record) => (
      <Space direction="vertical">
        <Space>
          <PhoneOutlined />
          <Typography.Text>
            {record?.phone || <MinusOutlined />}
          </Typography.Text>
        </Space>
        <Space>
          <FacebookOutlined />
          <Typography.Text>
            {record?.facebook || <MinusOutlined />}
          </Typography.Text>
        </Space>
        <Space>
          <TwitterOutlined />
          <Typography.Text>
            {record?.twitter || <MinusOutlined />}
          </Typography.Text>
        </Space>
      </Space>
    )
  },
  {
    title: 'Thời gian',
    dataIndex: 'timeLine',
    width: '120px',
    render: (_, { createdDate, updatedDate }) => (
      <TimeLine createdDate={createdDate} updatedDate={updatedDate} />
    )
  },
  {
    title: 'Liên quan',
    dataIndex: 'related',
    width: '120px',
    render: (_, { createdBy, updatedBy }) => (
      <RelatedUser creator={createdBy} updater={updatedBy} />
    )
  },
  {
    title: 'Thao tác',
    width: '50px',
    render: (_, record) => <RemovePartner record={record} />,
    toggleEdit: true,
    align: 'center'
  }
];
