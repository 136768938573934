import { Select } from 'antd';
import { RANK_DICTIONARY } from 'appConstants';

const RankSelect = props => {
  return (
    <Select placeholder="Chọn xếp hạng giọng hát" allowClear {...props}>
      {Object.keys(RANK_DICTIONARY).map(key => (
        <Select.Option value={key} key={key}>
          {RANK_DICTIONARY[key]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default RankSelect;
