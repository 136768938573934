import React from 'react';
import { Select } from 'antd';
import { PROVIDER_DICTIONARY } from 'appConstants';

const ProviderSelect = props => {
  return (
    <Select placeholder="Chọn nhà cung cấp" {...props}>
      {Object.keys(PROVIDER_DICTIONARY).map(key => (
        <Select.Option value={key} key={key}>
          {PROVIDER_DICTIONARY[key]}
        </Select.Option>
      ))}
    </Select>
  );
};

export default ProviderSelect;
