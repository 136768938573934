import { DownloadOutlined } from '@ant-design/icons';
import { useQuery } from '@apollo/client';
import { Button, Divider, Space } from 'antd';
import {
  BULK_DATA_KEY,
  COMMON_STATUS_KEY_BY_INT,
  COMMON_STATUS_KEY_INT,
  COPYRIGHT_KEY_INT,
  DATE_FORMAT,
  ORDER_BY_DICTIONARY,
  PAGE_SIZE,
  TABLE_PROPS
} from 'appConstants';
import ApproveButton from 'components/ApproveButton';
import CopyrightInfringementButton from 'components/CopyrightInfringementButton';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import moment from 'moment';
import {
  useBulkActionCopyRightInfringementPlaylists,
  useBulkActionPlaylists,
  useBulkExportPlaylistByFields,
  useBulkExportPlaylists
} from 'operations/mutations';
import { GET_PLAYLISTS } from 'operations/queries';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { processQueryFilerParams } from 'utils/filter';
import { isObjEmpty } from 'utils/lodash';
import { generateFileDownload } from 'utils/other';
import { columns } from '../columns';

const PlaylistList = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [playlists, setPlaylists] = useState([]);
  const [total, setTotal] = useState([]);

  const { mutate: updateStatus } = useBulkActionPlaylists(BULK_DATA_KEY.STATUS);
  const { mutate: updateCopyright } =
    useBulkActionCopyRightInfringementPlaylists(BULK_DATA_KEY.COPYRIGHT);

  const { mutate: exportPlaylists, loading: exportLoading } =
    useBulkExportPlaylists();

  const { mutate: exportPlaylistByFields } = useBulkExportPlaylistByFields();

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const pageSize = useMemo(
    () => parseInt(searchParams.pageSize) || PAGE_SIZE,
    [searchParams.pageSize]
  );

  const filterQuery = useMemo(() => {
    let additonalQueries = {};
    const processedSearchParams = processQueryFilerParams(searchParams);

    if (!processedSearchParams) return null;

    if (processedSearchParams?.playlistCates) {
      processedSearchParams.playlistCates = {
        some: {
          cateId: {
            in: processedSearchParams?.playlistCates
          }
        }
      };
    }

    const finalQuery = { ...processedSearchParams, ...additonalQueries };
    return isObjEmpty(finalQuery) ? null : finalQuery;
  }, [searchParams]);

  const createdDateQuery = useMemo(
    () => filterQuery?.createdDate,
    [filterQuery]
  );

  const {
    loading: isPlaylistsLoading,
    error: playlistsError,
    data: playlistsConnect
  } = useQuery(GET_PLAYLISTS, {
    variables: {
      take: pageSize,
      skip: pageSize * page - pageSize,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    },
    fetchPolicy: 'no-cache'
  });

  useEffect(() => {
    if (!isPlaylistsLoading && playlistsConnect) {
      const _data = playlistsConnect?.playlists?.items?.map(item => ({
        ...item,
        key: item.id
      }));
      setPlaylists(_data);
      setTotal(playlistsConnect?.playlists?.totalCount);
    }
  }, [isPlaylistsLoading, playlistsConnect]);

  const onPaginate = (page, pageSize) => {
    setSelectedRows([]);
    history.push({
      search: queryString.stringify({ ...searchParams, page, pageSize })
    });
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({
        ...searchParams,
        page: 1,
        sort,
        pageSize
      })
    });
  };

  const onShowSizeChange = (_, pageSize) => {
    history.push({
      search: queryString.stringify({ ...searchParams, pageSize })
    });
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const rowSelection = {
    selectedRowKeys: selectedRows.map(({ id }) => id),
    onChange: (_, selectedRows) => {
      setSelectedRows(selectedRows);
    }
  };

  const status = useMemo(() => {
    return selectedRows.some(
      _row => _row.status === COMMON_STATUS_KEY_INT.WAITING
    )
      ? COMMON_STATUS_KEY_INT.APPROVED
      : COMMON_STATUS_KEY_INT.WAITING;
  }, [selectedRows]);

  const isCopyrightInfringement = useMemo(() => {
    return selectedRows.some(
      _row => _row.isCopyrightInfringement === COPYRIGHT_KEY_INT.COPYRIGHT
    )
      ? COPYRIGHT_KEY_INT.NON_COPYRIGHT
      : COPYRIGHT_KEY_INT.COPYRIGHT;
  }, [selectedRows]);

  const handleChangeStatus = async () => {
    try {
      const params = selectedRows.map(({ id }) => ({
        id,
        status: COMMON_STATUS_KEY_BY_INT[status]
      }));
      await updateStatus({
        variables: {
          input: params
        }
      });
      setSelectedRows([]);
    } catch (error) {}
  };

  const handleChangeCopyright = async () => {
    try {
      const params = selectedRows
        .filter(
          _item => _item.isCopyrightInfringement !== isCopyrightInfringement
        )
        .map(({ id }) => id);
      await updateCopyright({
        variables: {
          playlistIds: params
        }
      });
      setSelectedRows([]);
    } catch (error) {}
  };

  const getInputExport = (filterQuery) => {
    if(filterQuery!=undefined)
    return {
      createdFrom: createdDateQuery?.gte,
      createdTo: createdDateQuery?.lte,
      name: filterQuery.name?.contains,
      isCopyrightInfringement:filterQuery.isCopyrightInfringement?.eq,
      playlistCates:filterQuery.playlistCates?.some?.cateId?.in,
      status: filterQuery.status?.eq,
    }
    else return{}
  }

  const onExport = async () => {
    const exportBySelect = !!selectedRows.length;
    const { data } = exportBySelect
      ? await exportPlaylists({
          variables: {
            input: {
              ids: selectedRows.map(({ id }) => id)
            }
          }
        })
      : await exportPlaylistByFields({
          variables: {
            input: getInputExport(filterQuery)
          }
        });

    const eventId = exportBySelect
      ? data.bulkExportPlaylists.eventId
      : data.exportPlaylistsByFields.eventId;
    await generateFileDownload(eventId);
    setSelectedRows([]);
  };

  if (playlistsError) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;
  // if (!playlistsConnect) return <div>None</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          {total ? (!!selectedRows.length || createdDateQuery) && (
            <Button
              icon={<DownloadOutlined />}
              type="primary"
              onClick={onExport}
              loading={exportLoading}
            >
              Export (
              {!!selectedRows.length
                ? selectedRows?.length
                : `${moment(createdDateQuery?.gte.replace('+00:00', '')).format(
                    DATE_FORMAT
                  )} - ${moment(
                    createdDateQuery?.lte.replace('+00:00', '')
                  ).format(DATE_FORMAT)}`}
              )
            </Button>
          ):''}
          {!!selectedRows.length && (
            <>
              <Divider type="vertical" />
              <CopyrightInfringementButton
                isCopyrightInfringement={isCopyrightInfringement}
                onClick={handleChangeCopyright}
                total={
                  selectedRows?.filter(
                    _item =>
                      _item.isCopyrightInfringement !== isCopyrightInfringement
                  )?.length
                }
              />
              <ApproveButton
                status={status}
                onClick={handleChangeStatus}
                total={
                  selectedRows?.filter(_item => _item.status !== status)?.length
                }
              />
            </>
          )}
          <SortSelect value={sort} onChange={setOrderBy} />
          <Link to={'/chi-tiet-playlist'}>
            <Button type="primary">Thêm</Button>
          </Link>
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1500 }}
        columns={columns}
        dataSource={playlists}
        pagination={{
          total,
          pageSize: pageSize,
          onChange: onPaginate,
          current: page * 1,
          quantity: playlists?.length,
          showSizeChanger: true,
          pageSizeOptions: [10, 20, 50],
          onShowSizeChange: onShowSizeChange
        }}
        loading={isPlaylistsLoading}
        rowSelection={rowSelection}
      />
    </ListCard>
  );
};

export default PlaylistList;
