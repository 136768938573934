import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_PAGE_INFO = gql`
  mutation EditPageInfoContact($input: EditPageInfoContactInput) {
    editPageInfoContact(input: $input) {
      id
      name
    }
  }
`;

export const ADD_PAGE_INFO = gql`
  mutation AddPageInfoContact($input: AddPageInfoContactInput) {
    addPageInfoContact(input: $input) {
      id
      name
    }
  }
`;

export const REMOVE_PAGE_INFO = gql`
  mutation RemovePageInfoContact($id: Int!) {
    removePageInfoContact(id: $id) {
      id
      name
    }
  }
`;

export const BULK_ACTION_PAGE_INFOS = gql`
  mutation BulkActionPageInfoContacts(
    $input: [BulkActionPageInfoContactInput]
  ) {
    bulkActionPageInfoContacts(input: $input) {
      id
      name
    }
  }
`;

// ----------------------------------------------------
// Hook

export const useAddPageInfoContact = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_PAGE_INFO, {
    onCompleted: ({ addPageInfoContact }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo thông tin trang{' '}
            <Typography.Text strong>{addPageInfoContact.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useEditPageInfoContact = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_PAGE_INFO, {
    onCompleted: ({ editPageInfoContact }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa thông tin trang{' '}
            <Typography.Text strong>{editPageInfoContact.name}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetPageInfoContactById', 'GetPageInfoContacts']
  });
  return { mutate, data, error, loading };
};

export const useBulkActionPageInfoContacts = actionField => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ACTION_PAGE_INFOS,
    {
      refetchQueries: ['GetPageInfoContacts'],
      onCompleted: ({ bulkActionPageInfoContacts }) => {
        getBulkMessage(
          'Thông tin trang',
          bulkActionPageInfoContacts,
          actionField
        );
      }
    }
  );

  return { mutate, data, error, loading };
};

// --------------------------------------------

export const useRemovePageInfoContact = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_PAGE_INFO, {
    refetchQueries: ['GetPageInfoContacts'],
    onCompleted: ({ removePageInfoContact }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá thông tin trang{' '}
            <Typography.Text strong>
              {removePageInfoContact.name}
            </Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};
