import { Button, Form, Image, Input, Row, Typography } from 'antd';
import bkg from 'assets/images/login-bkg.jpg';
import logo from 'assets/images/logo.svg';
import { useLogin } from 'operations/mutations/auth/mutateAuth';
import { useEffect, useRef } from 'react';

const Login = () => {
  const firstFocusRef = useRef(null);
  const { mutate: login, loading: isLoggingIn } = useLogin();

  const onFinish = async values => {
    try {
      await login({ variables: { input: { ...values } } });
    } catch (error) {}
  };

  useEffect(() => {
    if (firstFocusRef.current) firstFocusRef.current.focus();
  }, []);

  return (
    <div
      className="unauthorized-container-background"
      style={{ backgroundImage: `url(${bkg})` }}
    >
      <div className="unauthorized-container-foreground"></div>
      <div className="unauthorized-container">
        <div
          style={{
            margin: '16px auto'
          }}
        >
          <Image width={140} src={logo} preview={false} />
        </div>

        <Typography.Title style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          Đăng nhập
        </Typography.Title>

        <div className="unauthorized-container__form-wrapper">
          <Form
            name="basic"
            initialValues={{ remember: true }}
            onFinish={onFinish}
            layout="vertical"
          >
            <Form.Item
              label="Username"
              name="userName"
              rules={[{ required: true, message: 'Nhập userName!' }]}
            >
              <Input ref={firstFocusRef} />
            </Form.Item>

            <Form.Item
              label="Mật khẩu"
              name="password"
              rules={[{ required: true, message: 'Nhập mật khẩu!' }]}
            >
              <Input.Password />
            </Form.Item>

            {/* <div style={{ textAlign: 'end', marginBottom: '0.5em' }}>
              <Link to="/quen-mat-khau">Quên mật khẩu?</Link>
            </div> */}

            <Form.Item>
              <Button
                loading={isLoggingIn}
                style={{ width: '100%', marginTop: 16 }}
                type="primary"
                htmlType="submit"
              >
                Đăng nhập
              </Button>
            </Form.Item>
          </Form>
          <Row justify="center">
            <Typography.Title level={5} type="secondary">
              Hệ thống quản lý nhạc số
            </Typography.Title>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default Login;
