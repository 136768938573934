import { useQuery } from '@apollo/client';
import { Spin, Tabs, Typography } from 'antd';
import { useAddTopic, useEditTopic } from 'operations/mutations';
import { GET_TOPIC_BY_ID } from 'operations/queries';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import TopicInfo from './components/TopicInfo';

const TopicDetail = () => {
  const { id } = useParams();
  const isUpdate = useMemo(() => !!id, [id]);
  const { data, loading: fetchLoading } = useQuery(GET_TOPIC_BY_ID, {
    variables: { id: parseInt(id) },
    skip: !isUpdate
  });

  const { mutate: editTopic, loading: editLoading } = useEditTopic();
  const { mutate: addTopic, loading: addLoading } = useAddTopic();

  const items = useMemo(
    () => [
      {
        label: 'Thông tin topic',
        key: 'info',
        children: (
          <TopicInfo
            topic={data?.topic}
            isUpdate={isUpdate}
            onEditTopic={editTopic}
            onAddTopic={addTopic}
          />
        )
      }
      // {
      //   label: 'SEO',
      //   key: 'seo',
      //   children: (
      //     <SeoInfo
      //       isUpdate={isUpdate}
      //       topic={data?.topic}
      //       onEditTopic={editTopic}
      //     />
      //   ),
      //   disabled: !isUpdate
      // }
    ],
    [addTopic, data?.topic, editTopic, isUpdate]
  );

  return (
    <Spin spinning={fetchLoading || editLoading || addLoading}>
      <Typography.Title>
        {isUpdate ? 'Chi tiết topic' : 'Tạo topic'}
      </Typography.Title>
      <Tabs type="card" items={items} />
    </Spin>
  );
};

export default TopicDetail;
