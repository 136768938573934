import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_CATEGORY = gql`
  mutation EditCategory($input: EditCategoryInput) {
    editCategory(input: $input) {
      id
      name
    }
  }
`;

export const ADD_CATEGORY = gql`
  mutation AddCategory($input: AddCategoryInput) {
    addCategory(input: $input) {
      id
      name
    }
  }
`;

export const REMOVE_CATEGORY = gql`
  mutation RemoveCategory($id: Int!) {
    removeCategory(id: $id) {
      id
      name
    }
  }
`;

export const BULK_ACTION_CATEGORIES = gql`
  mutation BulkActionCategorys($input: [BulkActionCategoryInput]) {
    bulkActionCategorys(input: $input) {
      id
      name
    }
  }
`;

export const EDIT_CATEGORY_GROUP = gql`
  mutation EditCategoryGroup($input: EditCategoryGroupInput) {
    editCategoryGroup(input: $input) {
      id
      name
    }
  }
`;

export const ADD_CATEGORY_GROUP = gql`
  mutation AddCategoryGroup($input: AddCategoryGroupInput) {
    addCategoryGroup(input: $input) {
      id
      name
    }
  }
`;

// export const REMOVE_CATEGORY_GROUPS = gql`
//   mutation RemoveCategory($id: Int!) {
//     removeCategory(id: $id) {
//       id
//       name
//     }
//   }
// `;

// ----------------------------------------------------
// Hook

export const useAddCategory = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_CATEGORY, {
    onCompleted: ({ addCategory }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo chuyên mục{' '}
            <Typography.Text strong>{addCategory.title}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useEditCategory = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_CATEGORY, {
    onCompleted: ({ editCategory }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa chuyên mục{' '}
            <Typography.Text strong>{editCategory.title}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetCategoryById', 'GetCategories']
  });
  return { mutate, data, error, loading };
};

export const useBulkActionCategories = actionField => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ACTION_CATEGORIES,
    {
      refetchQueries: ['GetCategories'],
      onCompleted: ({ bulkActionCategorys }) => {
        getBulkMessage('Chuyên mục', bulkActionCategorys, actionField);
      }
    }
  );

  return { mutate, data, error, loading };
};

// --------------------------------------------

export const useRemoveCategory = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_CATEGORY, {
    refetchQueries: ['GetCategories'],
    onCompleted: ({ removeCategory }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá chuyên mục{' '}
            <Typography.Text strong>{removeCategory.title}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useAddCategoryGroup = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_CATEGORY_GROUP, {
    onCompleted: ({ addCategoryGroup }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo nhóm chuyên mục{' '}
            <Typography.Text strong>{addCategoryGroup.name}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetCategoryGroups']
  });
  return { mutate, data, error, loading };
};

export const useEditCategoryGroup = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_CATEGORY_GROUP, {
    onCompleted: ({ editCategoryGroup }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa nhóm chuyên mục{' '}
            <Typography.Text strong>{editCategoryGroup.name}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetCategoryGroups']
  });
  return { mutate, data, error, loading };
};
