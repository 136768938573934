import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';
import { getBulkMessage } from 'utils/other';

export const EDIT_ARTIST = gql`
  mutation EditArtist($input: EditArtistInput) {
    editArtist(input: $input) {
      id
      name
    }
  }
`;

export const ADD_ARTIST = gql`
  mutation AddArtist($input: AddArtistInput) {
    addArtist(input: $input) {
      id
      name
    }
  }
`;

export const REMOVE_ARTIST = gql`
  mutation RemoveArtist($id: Int!) {
    removeArtist(id: $id) {
      id
      name
    }
  }
`;

export const BULK_ACTION_ARTISTS = gql`
  mutation BulkActionArtists($input: [BulkActionArtistInput]) {
    bulkActionArtists(input: $input) {
      id
      name
    }
  }
`;

export const BULK_ADD_ARTISTS_RELATED = gql`
  mutation BulkAddArtistRelated($input: BulkAddArtistRelatedInput) {
    bulkAddArtistRelated(input: $input) {
      id
      artist {
        id
        name
      }
    }
  }
`;

export const BULK_EXPORT_ARTIST = gql`
  mutation BulkExportArtists($input: ExportBulkArtistInput) {
    bulkExportArtists(input: $input) {
      link
      id
      fileName
      eventId
      createdDate
      createdBy
    }
  }
`;

export const BULK_EXPORT_ARTIST_BY_FIELDS = gql`
  mutation ExportArtistsByFields($input: ExportArtistInput) {
    exportArtistsByFields(input: $input) {
      link
      id
      fileName
      eventId
      createdDate
      createdBy
    }
  }
`;

// ----------------------------------------------------
// Hook

export const useAddArtist = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_ARTIST, {
    onCompleted: ({ addArtist }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã tạo nghệ sĩ{' '}
            <Typography.Text strong>{addArtist.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error, loading };
};

export const useEditArtist = () => {
  const [mutate, { data, error, loading }] = useMutation(EDIT_ARTIST, {
    onCompleted: ({ editArtist }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã chỉnh sửa nghệ sĩ{' '}
            <Typography.Text strong>{editArtist.name}</Typography.Text>
          </Typography.Text>
        )
      });
    },
    refetchQueries: ['GetArtistById', 'GetArtists']
  });
  return { mutate, data, error, loading };
};

export const useBulkActionArtists = actionField => {
  const [mutate, { data, error, loading }] = useMutation(BULK_ACTION_ARTISTS, {
    refetchQueries: ['GetArtists'],
    onCompleted: ({ bulkActionArtists }) => {
      getBulkMessage('Artist', bulkActionArtists, actionField);
    }
  });

  return { mutate, data, error, loading };
};

// --------------------------------------------

export const useRemoveArtist = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_ARTIST, {
    refetchQueries: ['GetArtists'],
    onCompleted: ({ removeArtist }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá artist{' '}
            <Typography.Text strong>{removeArtist.name}</Typography.Text>
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};

export const useBulkAddArtistRelated = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_ADD_ARTISTS_RELATED,
    {
      onCompleted: () => {
        notification.success({
          message: (
            <Typography.Text>Đã cập nhật nghệ sĩ liên quan</Typography.Text>
          )
        });
      },
      refetchQueries: ['GetArtists', 'GetArtistById']
    }
  );
  return { mutate, data, error, loading };
};

export const useBulkExportArtists = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_EXPORT_ARTIST,
    {}
  );

  return { mutate, data, error, loading };
};

export const useBulkExportArtistByFields = () => {
  const [mutate, { data, error, loading }] = useMutation(
    BULK_EXPORT_ARTIST_BY_FIELDS
  );

  return { mutate, data, error, loading };
};
