import { Collapse, Typography } from 'antd';

import React from 'react';

import VideoList from './components/VideoList';
import VideoFilter from './VideoFilter';

const Video = () => {
  return (
    <div className="spacing-container">
      <Typography.Title>Danh sách Video/MV</Typography.Title>

      <Collapse defaultActiveKey={['1']} style={{ marginBottom: 16 }}>
        <Collapse.Panel header="Bộ lọc Video/MV" key="1">
          <VideoFilter />
        </Collapse.Panel>
      </Collapse>

      <VideoList />
    </div>
  );
};

export default Video;
