import { useLazyQuery, useReactiveVar } from '@apollo/client';
import { searchPopulartVar } from 'api/cache';
import AutoComplete from 'components/AutoComplete';
import { GET_ALBUMS, GET_PLAYLISTS, GET_SONGS } from 'operations/queries';
import { useMemo } from 'react';

const PlaylistSearch = ({ type, onSelect, ...props }) => {
  const [searchPlaylist] = useLazyQuery(GET_PLAYLISTS);
  const [searchSong] = useLazyQuery(GET_SONGS);
  const [searchAlbum] = useLazyQuery(GET_ALBUMS);
  const searchedPopulars = useReactiveVar(searchPopulartVar);

  const modalType = useMemo(() => {
    switch (type) {
      case 'PLAYLIST':
        return {
          action: searchPlaylist,
          text: 'playlist',
          dataKey: 'playlists',
          labelKey: 'name'
        };
      case 'SONG':
        return {
          action: searchSong,
          text: 'bài hát',
          dataKey: 'songs',
          labelKey: 'title'
        };
      case 'ALBUM':
        return {
          action: searchAlbum,
          text: 'album',
          dataKey: 'albums',
          labelKey: 'title'
        };
      default:
        break;
    }
  }, [searchAlbum, searchPlaylist, searchSong, type]);

  const placeholder = useMemo(() => {
    const _text = type
      ? `Tìm ${modalType?.text} thêm vào danh sách`
      : 'Chọn thể loại để tìm kiếm';
    return _text;
  }, [modalType?.text, type]);

  const onChange = value => {
    const record = searchedPopulars.find(_record => _record.id === value);
    return onSelect(record);
  };

  return (
    <AutoComplete
      disabled={!type}
      size="large"
      value={null}
      handleSearch={modalType?.action}
      labelKey={modalType?.labelKey || 'name'}
      valueKey="id"
      dataKey={modalType?.dataKey}
      placeholder={placeholder}
      style={{ width: '100%' }}
      onChange={onChange}
      updateCache={searchPopulartVar}
      isResetOptions={true}
      {...props}
    />
  );
};

export default PlaylistSearch;
