import { useQuery } from '@apollo/client';
import { Button, Space } from 'antd';

import {
  BULK_DATA_KEY,
  COMMON_STATUS_KEY_BY_INT,
  COMMON_STATUS_KEY_INT,
  ORDER_BY_DICTIONARY,
  PAGE_SIZE,
  TABLE_PROPS
} from 'appConstants';
import ListCard from 'components/ListCard';
import SortSelect from 'components/SortSelect';
import Table from 'components/Table';
import { useBulkActionAppNotifications } from 'operations/mutations';
import { GET_APP_NOTIFICATIONS } from 'operations/queries';

import { PlusOutlined } from '@ant-design/icons';
import ApproveButton from 'components/ApproveButton';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { Link } from 'react-router-dom';
import { processQueryFilerParams } from 'utils/filter';
import { isObjEmpty } from 'utils/lodash';
import { columns } from '../columns';

const AppNotificationList = () => {
  const { search } = useLocation();
  const history = useHistory();
  const [appNotifications, setAppNotifications] = useState([]);
  const [total, setTotal] = useState();

  const { mutate: updateStatus, loading: actionLoading } =
    useBulkActionAppNotifications(BULK_DATA_KEY.STATUS);

  const searchParams = useMemo(() => queryString.parse(search), [search]);
  const page = useMemo(() => searchParams.page || 1, [searchParams.page]);
  const sort = useMemo(
    () => searchParams.sort || 'EARLIEST',
    [searchParams.sort]
  );
  const filterQuery = useMemo(() => {
    let additonalQueries = {};
    const processedSearchParams = processQueryFilerParams(searchParams, true);
    if (!processedSearchParams) return null;

    if (processedSearchParams?.appNotificationCates) {
      processedSearchParams.appNotificationCates = {
        some: {
          cateId: {
            in: processedSearchParams?.appNotificationCates
          }
        }
      };
    }

    if (processedSearchParams?.appNotificationTags) {
      processedSearchParams.appNotificationTags = {
        some: {
          tagId: {
            in: processedSearchParams?.appNotificationTags
          }
        }
      };
    }

    if (processedSearchParams?.appNotificationArtists) {
      processedSearchParams.appNotificationArtists = {
        some: {
          or: {
            artistId: {
              in: processedSearchParams?.appNotificationArtists
            }
          }
        }
      };
    }

    if (processedSearchParams?.vocalRank) {
      processedSearchParams.appNotificationArtists = {
        some: {
          or: {
            ...processedSearchParams.appNotificationArtists?.some?.or,
            vocalRank: {
              eq: parseInt(processedSearchParams?.vocalRank.eq)
            }
          }
        }
      };
      delete processedSearchParams.vocalRank;
    }

    const finalQuery = { ...processedSearchParams, ...additonalQueries };
    return isObjEmpty(finalQuery) ? null : finalQuery;
  }, [searchParams]);

  const {
    loading: isAppNotificationsLoading,
    error: appNotificationsError,
    data: appNotificationsConnect
  } = useQuery(GET_APP_NOTIFICATIONS, {
    variables: {
      take: PAGE_SIZE,
      skip: PAGE_SIZE * page - PAGE_SIZE,
      order: [ORDER_BY_DICTIONARY[sort].value],
      where: filterQuery
    },
    fetchPolicy: 'no-cache'
  });

  const isLoading = useMemo(
    () => isAppNotificationsLoading || actionLoading,
    [actionLoading, isAppNotificationsLoading]
  );

  useEffect(() => {
    if (!isAppNotificationsLoading && appNotificationsConnect) {
      const _data = appNotificationsConnect?.appNotifications?.items?.map(
        item => ({
          ...item,
          key: item.id
        })
      );
      setAppNotifications(_data);
      setTotal(appNotificationsConnect?.appNotifications?.totalCount);
    }
  }, [isAppNotificationsLoading, appNotificationsConnect]);

  const onPaginate = page => {
    history.push({
      search: queryString.stringify({ ...searchParams, page })
    });
  };

  const setOrderBy = sort => {
    history.push({
      search: queryString.stringify({ ...searchParams, page: 1, sort })
    });
  };

  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);

  const rowSelection = {
    selectedRowKeys: selectedKeys,
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRows(selectedRows);
      setSelectedKeys(selectedKeys);
    }
  };

  const status = useMemo(() => {
    return selectedRows.some(
      _row => _row.approvingStatus === COMMON_STATUS_KEY_INT.WAITING
    )
      ? COMMON_STATUS_KEY_INT.APPROVED
      : COMMON_STATUS_KEY_INT.WAITING;
  }, [selectedRows]);

  const handleChangeStatus = async () => {
    try {
      const params = selectedRows.map(({ id }) => ({
        id,
        approvingStatus: COMMON_STATUS_KEY_BY_INT[status]
      }));
      await updateStatus({
        variables: {
          input: params
        }
      });
      setSelectedKeys([]);
    } catch (error) {}
  };

  if (appNotificationsError) return <div>Oops, đã có vấn đề xảy ra 😅😅😅</div>;

  return (
    <ListCard
      left={`Có ${total} kết quả`}
      right={
        <Space>
          {!!selectedKeys.length && (
            <>
              <ApproveButton
                status={status}
                onClick={handleChangeStatus}
                total={
                  selectedRows?.filter(_item => _item.status !== status)?.length
                }
              />
            </>
          )}
          <SortSelect value={sort} onChange={setOrderBy} />
          <Link to={'/chi-tiet-app-notification'}>
            <Button icon={<PlusOutlined />} type="primary">
              Thêm
            </Button>
          </Link>
        </Space>
      }
    >
      <Table
        {...TABLE_PROPS}
        scroll={{ x: 1500 }}
        columns={columns}
        dataSource={appNotifications}
        pagination={{
          total,
          pageSize: PAGE_SIZE,
          onChange: onPaginate,
          current: page * 1,
          quantity: appNotifications?.length
        }}
        loading={isLoading}
        rowSelection={rowSelection}
      />
    </ListCard>
  );
};

export default AppNotificationList;
