import {
    Card,
    Col,
    Form,
    Row,
    Button, Table
} from 'antd';

import { columnsApa } from './columnsApa';
import { useQuery } from '@apollo/client';
import { GET_ADV_POSITION_BY_ID } from 'operations/queries';

import AdvAutocomplete from 'components/AdvSelect/AdvAutocomplete';
import PageAutocomplete from 'components/AdvSelect/PageAutocomplete';
import { useEffect, useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';

import {
    useAddIcAdvertPositionAdv,
} from 'operations/mutations';
const AdvPositionAdv = ({
    id
}) => {
    const [adv, setAdv] = useState();
    const [page, setPage] = useState();
    const [data, setData] = useState();
    const { mutate: addItem, loading: editLoading } = useAddIcAdvertPositionAdv();
    const [loading, setLoading] = useState(false);
    const { data: dataQuery, loading: fetchLoading, refetch } = useQuery(GET_ADV_POSITION_BY_ID, {
        variables: { id: parseInt(id) },
    });
    useEffect(async () => {
        if (dataQuery && dataQuery.icAdvertPosition) {
            setData(dataQuery.icAdvertPosition.advertPositionAdv)
        }
    }, [dataQuery]);

    const onSave = async () => {
        setLoading(true)
        try {
            await addItem({
                variables: {
                    input: {
                        advertId: parseInt(adv),
                        pageId: parseInt(page || 0),
                        advertPositionId: parseInt(id)
                    }
                },
                onCompleted: (res) => {
                    setAdv(null)
                    setPage(null)
                    refetch()
                }
            });
        } catch {

        }

        setLoading(false)

    };


    return (
        <>
            {
                id &&
                <>
                    <Row gutter={10}>
                        <Col span={10}>
                            <Form.Item
                                label="Chọn quảng cáo"
                            >
                                <AdvAutocomplete value={adv} onChange={setAdv} allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={10}>
                            <Form.Item
                                label="Chọn trang"
                            >
                                <PageAutocomplete value={page} onChange={setPage} allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={4}>
                            {
                                adv &&
                                <Button icon={<PlusOutlined />} onClick={onSave} type="primary">
                                    Thêm
                                </Button>
                            }

                        </Col>

                    </Row>
                    {
                        data && data.length > 0 &&
                        <Table
                            rowKey="id"
                            columns={columnsApa}
                            dataSource={data}
                            loading={loading}
                            pagination={false}
                        />

                    }
                </>
            }
        </>


    );
};

export default AdvPositionAdv;
