import { gql, useMutation } from '@apollo/client';
import { notification, Typography } from 'antd';

export const ADD_ADV_POSITION_ADV = gql`
  mutation AddIcAdvertPositionAdvert($input: AddIcAdvertPositionAdvertInput) {
    addIcAdvertPositionAdvert(input: $input) {
      id
      advert{
        advertName
        advertContentTypeId
        advertStatusId
        id
      }
      pageName
    }
  }
`;

export const REMOVE_ADV_POSITION_ADV = gql`
  mutation RemoveIcAdvertPositionAdvert($id: Int!) {
    removeIcAdvPositionAdvert(id: $id) {
      id
    }
  }
`;


// ----------------------------------------------------
// Hook

export const useAddIcAdvertPositionAdv = () => {
  const [mutate, { data, error, loading }] = useMutation(ADD_ADV_POSITION_ADV, {
    refetchQueries: ['GetIcAdvertPositionById'],
    onCompleted: () => {
      notification.success({
        message: (
          <Typography.Text>
            Đã thêm mới
          </Typography.Text>
        )
      });
    },refetchQueries:[]
  });
  return { mutate, data, error, loading };
};



// export const useBulkActionPageInfoContacts = actionField => {
//   const [mutate, { data, error, loading }] = useMutation(
//     BULK_ACTION_ADVS,
//     {
//       refetchQueries: ['GetPageInfoContacts'],
//       onCompleted: ({ bulkActionPageInfoContacts }) => {
//         getBulkMessage(
//           'Thông tin trang',
//           bulkActionPageInfoContacts,
//           actionField
//         );
//       }
//     }
//   );

//   return { mutate, data, error, loading };
// };

// --------------------------------------------

export const useRemoveIcAdvertPositionAdv = () => {
  const [mutate, { data, error }] = useMutation(REMOVE_ADV_POSITION_ADV, {
    refetchQueries: ['GetIcAdvertPositionById'],
    onCompleted: ({ removeIcAdv }) => {
      notification.success({
        message: (
          <Typography.Text>
            Đã xoá 
          </Typography.Text>
        )
      });
    }
  });
  return { mutate, data, error };
};
