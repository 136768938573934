import { gql } from '@apollo/client';

export const GET_AUDIT_LOGS = gql`
  query GetAuditLogs(
    $skip: Int
    $take: Int
    $order: [AuditLogSortInput!]
    $where: AuditLogFilterInput
  ) {
    auditLogs(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        eventType
        tableName
        actionId
        recordId
        columnName
        originalValue
        newValue
        createdDate
        createdBy
        createDate
        createBy
      }
    }
  }
`;
