import { gql } from '@apollo/client';

export const GET_ARTISTS = gql`
  query GetArtists(
    $skip: Int
    $take: Int
    $order: [ArtistSortInput!]
    $where: ArtistFilterInput
  ) {
    artists(skip: $skip, take: $take, order: $order, where: $where) {
      totalCount
      items {
        id
        name
        stageName
        birthday
        gender
        intro
        image
        banner
        createdDate
        updatedDate
        status
        slug
        subscribed
        searchSlug
        thumbnailSocial
        uniqId
        artistType
        uniqId
        artistRank
        priority
        countSong
        countVideo
        countAlbum
        createBy
        createdBy
        createdDate
        updatedBy
        updatedDate
        relatedArtists {
          id
          name
          image
          artistRank
          priority
        }
      }
    }
  }
`;

export const GET_ARTIST_BY_ID = gql`
  query GetArtistById($id: Int!) {
    artist(id: $id) {
      id
      name
      stageName
      birthday
      gender
      intro
      image
      banner
      createdDate
      updatedDate
      status
      slug
      subscribed
      searchSlug
      thumbnailSocial
      uniqId
      artistType
      uniqId
      artistRank
      priority
      relatedArtists {
        id
        name
        image
        artistRank
        priority
      }
      metaSeo {
        metaTitle
        metaKeywords
        metaDescription
      }
    }
  }
`;
