import { Typography } from 'antd';
import { DATE_FORMAT, TIME_FORMAT } from 'appConstants';
import ColName from 'components/ColName';
import moment from 'moment';
import 'moment/locale/vi';

export const columns = [
  {
    title: 'Chủ đề',
    dataIndex: 'keyword',
    key: 'keyword',
    render: (_, record) => (
      <ColName
        primary={
          <Typography.Text style={{ color: 'inherit' }}>
            {record?.keyword}
          </Typography.Text>
        }
      />
    ),
    fixed: 'left',
    width: 'auto'
  },
  {
    title: 'Đường dẫn',
    dataIndex: 'safeKeyword',
    width: '20%'
  },
  {
    title: 'Số lượt tìm kiếm',
    dataIndex: 'count',
    width: '20%'
  },
  {
    title: 'Ngày tạo',
    dataIndex: 'createdDate',
    width: '20%',
    render: createdDate => (
      <Typography.Text>
        {createdDate &&
          moment(createdDate).format(DATE_FORMAT + ' ' + TIME_FORMAT)}
      </Typography.Text>
    )
  }
];
