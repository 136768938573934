import { gql } from '@apollo/client';

export const GET_ADV = gql`
query GetIcAdverts(
  $skip: Int
  $take: Int
  $order: [IcAdvertSortInput!]
  $where: IcAdvertFilterInput
) {
  icAdverts(skip: $skip, take: $take, order: $order, where: $where) {
    totalCount
    items {
      id
      advertName
      imagePath
      imageHoverPath
      startTime
      endTime
      advertDesc
      url
      scriptContent
      width
      height
      advertStatusId
      advertContentTypeId
      crUserId
      crDateTime
      userName
      positionAdvs{
        id
        positionName
        pageName
      }
    }
  }
}  
`;
export const GET_ADV_BY_ID = gql`
  query GetIcAdvertById($id: Int!) {
    icAdvert(id: $id) {
      id
      advertName
      imagePath
      imageHoverPath
      startTime
      endTime
      advertDesc
      url
      scriptContent
      width
      height
      advertStatusId
      advertContentTypeId
      crUserId
      crDateTime
      positionAdvs{
        id
        positionName
        pageName
      }
    }
  }
`;