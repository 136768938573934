import { useLazyQuery } from '@apollo/client';
import { Form } from 'antd';
import {
  CATEGORY_TYPE,
  FILTER_PARAMS_DATA_TYPE_SUFFIX,
  FILTER_PARAMS_OPERATOR_SUFFIX,
  FILTER_PARAMS_PREFIX
} from 'appConstants';
import CategoryAutocomplete from 'components/CategoryAutocomplete';
import { GET_CATEGORIES } from 'operations/queries/queryCategory';
import queryString from 'query-string';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { processFormItemName } from 'utils/filter';

const CategoryAutoCompleteFilter = () => {
  const [searchCategory, { loading }] = useLazyQuery(GET_CATEGORIES);
  const [category, setCategories] = useState([]);
  const { search } = useLocation();
  const searchParams = useMemo(() => queryString.parse(search), [search]);

  const cateFilterName = useMemo(() => {
    return processFormItemName({
      prefix: FILTER_PARAMS_PREFIX,
      name: 'playlistCates',
      operatorSuffix: FILTER_PARAMS_OPERATOR_SUFFIX.IN,
      dataTypeSuffix: FILTER_PARAMS_DATA_TYPE_SUFFIX.ARR
    });
  }, []);

  const value = useMemo(() => {
    if (Array.isArray(searchParams[cateFilterName])) {
      return searchParams[cateFilterName];
    }

    return [searchParams[cateFilterName]];
  }, [cateFilterName, searchParams]);

  const getCategories = useCallback(async () => {
    try {
      const { data } = await searchCategory({
        variables: {
          where: {
            or: value.map(eq => ({ id: { eq: parseInt(eq) } }))
          }
        }
      });

      setCategories(data?.categories?.items);
    } catch (e) {
      throw e;
    }
  }, [searchCategory, value]);

  useEffect(() => {
    if (searchParams[cateFilterName]) getCategories();
  }, [getCategories, cateFilterName, searchParams]);

  return (
    <>
      <Form.Item name={cateFilterName} label="Thể loại">
        <CategoryAutocomplete
          allowClear
          mode="multiple"
          category={category}
          loading={loading}
          type={CATEGORY_TYPE.PLAYLIST}
        />
      </Form.Item>
    </>
  );
};

export default CategoryAutoCompleteFilter;
